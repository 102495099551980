import { registerHandler } from "../handler";
import { isCountry } from "src/utils/userSegment";

declare const fpdTrackSurveyResponse: (payload: string) => void;
declare const fpdTrackPurchase: (erev: string, userId: string) => void;

function isUKGeolocation() {
  return isCountry(["UK", "GB", "IE"]);
}

// UK TV - All Response Media - Tag4ARM
export default function registerHandlers() {
  registerHandler("initiateCheckout", ({ surveyAnswers }) => {
    if (isUKGeolocation()) {
      const payload = surveyAnswers
        ? JSON.stringify({
            whereDidYouHear: surveyAnswers.whereDidYouHear,
            whereDidYouHearAdditional: surveyAnswers.hdyhauAdditionalResponse,
          })
        : "{}";
      fpdTrackSurveyResponse(payload);
    }
  });

  registerHandler("purchase", ({ order, userData }) => {
    if (isUKGeolocation()) {
      fpdTrackPurchase(order.erev_9_months, userData.user_id);
    }
  });
}
