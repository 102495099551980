import { createSlice } from "@reduxjs/toolkit";
import { getMeristemContext } from "src/utils/meristemContext";

export type CountryCode =
  | "US"
  | "CA"
  | "IE"
  | "AU"
  | "NZ"
  | "GB"
  | "UK"
  | "DE"
  | "ES"
  | "AT";

export interface GeoLocationState {
  city_name: string;
  continent_code: string;
  country_code: CountryCode;
  is_eu_citizen: boolean;
  postal_code: string;
  subdivision: string;
  is_forced_country_code: boolean;
}

const extractData = (data: GeoLocationState) => {
  return {
    city_name: data.city_name,
    continent_code: data.continent_code,
    country_code: data.country_code,
    is_eu_citizen: data.is_eu_citizen,
    postal_code: data.postal_code,
    subdivision: data.subdivision,
    is_forced_country_code: data.is_forced_country_code,
  };
};

const geoLocationSlice = createSlice({
  name: "geoLocation",
  initialState: extractData(
    getMeristemContext() as unknown as GeoLocationState
  ),
  reducers: {},
});

export default geoLocationSlice;
