import { roundRect, triangle } from "@utils/shapes.js";
import { compassColors } from "@utils/styling";

export function drawLabel(
  chartInstance,
  text,
  tick,
  startTick,
  endTick,
  color = compassColors.tarocco
) {
  if (tick < startTick) {
    return;
  }
  const { ctx, width } = chartInstance;
  ctx.save();
  ctx.textAlign = "center";
  ctx.fillStyle = color;

  const fontSize = width <= 350 ? 10 : 12;
  ctx.font = `${fontSize}pt 'Brown LL', sans-serif`;
  const textUpper = text.toUpperCase();

  if (tick > endTick) {
    const textWithArrow = `${textUpper} →`;
    const textMeasurement = ctx.measureText(textWithArrow);
    ctx.fillText(textWithArrow, width - textMeasurement.width / 2 - 20, 30);
  } else {
    const dataPointsOnLine = chartInstance.controller.getDatasetMeta(1).data;
    const dataPointToDrawRelativeTo =
      // eslint-disable-next-line no-underscore-dangle
      dataPointsOnLine[tick]._model;

    const textMeasurement = ctx.measureText(textUpper);
    const crossBrowserBoundingBoxDescent =
      textMeasurement.fontBoundingBoxDescent ||
      textMeasurement.actualBoundingBoxDescent;
    const crossBrowserBoundingBoxAscent =
      textMeasurement.fontBoundingBoxAscent ||
      textMeasurement.actualBoundingBoxAscent;
    const textHeight =
      crossBrowserBoundingBoxDescent + crossBrowserBoundingBoxAscent;
    const pad = 18; // some hard coded value
    const xOffset = textMeasurement.width / 2 + pad;

    const yCoord = dataPointToDrawRelativeTo.y + 3;

    // eslint-disable-next-line no-underscore-dangle
    const endDatapoint = dataPointsOnLine[endTick]._model; // This gets us the xcoord of the goal label box which we should avoid overlapping with

    const collideX =
      dataPointToDrawRelativeTo.x + textMeasurement.width + pad >
      endDatapoint.x - 30; // The 30 should probably represent the width of the goal rectangle
    const collideY = yCoord + textHeight > endDatapoint.y - 55; // 55 is the rough height of the goal box
    const flip = collideX && collideY;

    ctx.fillText(
      textUpper,
      dataPointToDrawRelativeTo.x + (flip ? -xOffset : xOffset),
      yCoord
    );
  }
  ctx.restore();
}

export function renderGoal(
  chartInstance,
  topText,
  bottomText,
  tick,
  color = compassColors.tarocco
) {
  const { ctx, width } = chartInstance;
  const fontSize = width <= 350 ? 10 : 12;
  ctx.save();
  ctx.textAlign = "center";
  ctx.fillStyle = color;
  const dataPointsOnLine = chartInstance.controller.getDatasetMeta(0).data;
  const dataPointToDrawRelativeTo =
    // eslint-disable-next-line no-underscore-dangle
    dataPointsOnLine[tick]._model;

  drawLabelBox(
    ctx,
    topText.toUpperCase(),
    bottomText.toUpperCase(),
    dataPointToDrawRelativeTo.x,
    dataPointToDrawRelativeTo.y - 33,
    color,
    fontSize
  );
  ctx.restore();
}

function drawLabelBox(ctx, topText, bottomText, x, y, color, fontSize = 12) {
  const pad = 8;
  const textMargin = 4;
  ctx.font = `${fontSize}pt 'Brown LL', sans-serif`;
  const topTextMeasurement = ctx.measureText(topText);
  const bottomTextMeasurement = ctx.measureText(bottomText);
  const rectangleWidth =
    Math.max(topTextMeasurement.width, bottomTextMeasurement.width) + pad * 2;
  const crossBrowserBoundingBoxDescent =
    topTextMeasurement.actualBoundingBoxDescent;
  const crossBrowserBoundingBoxAscent =
    topTextMeasurement.actualBoundingBoxAscent;
  const textHeight =
    crossBrowserBoundingBoxDescent + crossBrowserBoundingBoxAscent;
  const rectangleHeight = textHeight * 2 + pad * 2 + textMargin;
  const rectangleX = x - rectangleWidth / 2;
  const textXPos = rectangleX + rectangleWidth / 2;
  roundRect(
    ctx,
    rectangleX,
    y - rectangleHeight + pad,
    rectangleWidth,
    rectangleHeight,
    color,
    10
  );
  triangle(
    // Janky triangle
    ctx,
    rectangleX + rectangleWidth / 4,
    y,
    rectangleWidth / 2,
    20,
    color
  );
  // draw text
  ctx.fillStyle = "#FFFFFF";
  ctx.fillText(topText, textXPos, y - textHeight - textMargin);
  ctx.fillText(bottomText, textXPos, y);
}
