import React, { useEffect } from "react";
import { generatePath, Redirect, Route, useParams } from "react-router-dom";

// eslint-disable-next-line no-underscore-dangle
declare const __WORDPRESS_DOMAIN__: string;

function ExternalRedirect({ href }: { href: string }) {
  const match = useParams();
  useEffect(() => {
    window.location.replace(generatePath(href, match));
  });

  return null as JSX.Element;
}

// Place path redirects here. Paths here are react-router paths
// https://github.com/pillarjs/path-to-regexp#usage
// These paths are given priority over the src/pages paths.
export default function getRedirects() {
  const redirects: { from: string; to?: string; href?: string }[] = [
    // Canonicalize ja pages
    {
      from: "/(jp|ja-JP)/:path*",
      to: "/ja",
    },
    {
      from: "/ja/:path+",
      to: "/ja",
    },

    // Email opt-in page
    {
      from: "/(de|de-DE)/confirmEmailSuccess",
      to: "/p/confirmEmailSuccess?lc=de",
    },

    // Careers
    {
      from: "/:subfolder?/careers/:child(life-at-noom|culture)",
      href: `${__WORDPRESS_DOMAIN__}/careers/:child`,
    },
    {
      from: "/:subfolder?/careers/",
      href: `${__WORDPRESS_DOMAIN__}/careers`,
    },
    {
      from: "/news/noom-raises-58-million-led-by-sequoia",
      href: `${__WORDPRESS_DOMAIN__}/blog/in-the-news/wellness-company-noom-raises-58-million-led-by-sequoia-capital-to-grow-its-team-and-improve-its-consumer-offering/`,
    },
    {
      from: "/news/noom-quadruples-revenue-237-million",
      href: `${__WORDPRESS_DOMAIN__}/blog`,
    },

    // Canonicalize en routes on paths sans lang code
    {
      from: "/(en|en-GB|en-AU|en-NZ|en-IE)",
      to: "/",
    },
    {
      from: "/(en|en-GB|en-AU|en-NZ|en-IE)/programs/health-weight/:routeId",
      to: "/programs/health-weight/:routeId",
    },
  ];
  return redirects.map((redirect) =>
    redirect.href ? (
      <Route key={redirect.from} path={redirect.from} exact>
        <ExternalRedirect href={redirect.href} />
      </Route>
    ) : (
      <Redirect
        key={redirect.from}
        from={redirect.from}
        to={redirect.to}
        exact
      />
    )
  );
}
