import { ShippingInfo, ShippingInfoError } from "src/funnels/offers/models";
import { typedKeys } from "./typeWrappers";

/**
 * Check if value is a valid email address
 * @see https://www.w3resource.com/javascript/form/email-validation.php
 * @see https://www.jochentopf.com/email/chars.html
 */
export function isEmail(val: string) {
  // uncomment for loose mode
  // return val && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(val.trim());

  // strict mode:
  // * allow any valid characters in personal data
  // * ~> except "." cannot start or end personal data
  // * domain segments may repeat (no TLD validation)
  // * disallow "_" because hostnames prevent it
  if (!val || !val.length) return false;
  let [user, domain] = val.trim().split("@");

  try {
    // handle non-english domains
    const tmp = new URL(`http://${domain || ""}`).hostname;
    domain = tmp;
  } catch (e) {
    //
  }

  return (
    user &&
    domain &&
    user.length <= 64 &&
    domain.length <= 253 &&
    !/(^\.|\.{2}|[.,();]$)/.test(user) &&
    /^[\w!#$%&'*+-<>/=?^_`{|}~."\\()]{1,64}$/.test(user) &&
    /^[a-z0-9]+(?:[a-z0-9-.]{0,61}[a-z0-9])?(\.([a-z0-9]([-]{2})?){2,})$/i.test(
      domain
    )
  );
}

/**
 * Super simple validation for a name.
 * TODO(patrick): We should add stronger validation for names.
 */
export function isName(name: string) {
  return !!name;
}

/**
 * Validate shipping address for upsell purchases. Only validates
 * if the field is empty or not.
 */
export function validateAddress(shippingInfo: ShippingInfo) {
  const error: ShippingInfoError = {};

  typedKeys(shippingInfo).forEach((field) => {
    switch (field) {
      case "name":
      case "phone":
        error[field] = !shippingInfo[field];
        break;
      case "address":
        Object.keys(shippingInfo.address).forEach((addressField) => {
          switch (addressField) {
            case "street":
            case "city":
            case "zipcode":
              error[addressField] = !shippingInfo.address[addressField];
              break;
            default:
            // do nothing
          }
        });
        break;
      default:
      // do nothing
    }
  });

  return error;
}

export function isZipcodeValid(zipcode: string, country: string) {
  if (country === "US") {
    return /\d{5}/.test(zipcode);
  }
  if (country === "CA") {
    return /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/.test(
      zipcode.toUpperCase()
    );
  }
  if (country === "BR") {
    return /\d{5}[-]?\d{3}/.test(zipcode);
  }
  if (country === "IN") {
    return /\b\d{6}\b/.test(zipcode);
  }
  return true;
}
