import Unit, { UnitType } from "src/components/unit/Unit";
import { isCountryStones } from "@utils/userSegment";

type PacingLookup = {
  [key: number]: number[];
};

// The default configuration of the graph weight loss pacing for all plan lengths
export const REGULAR_WEIGHT_LOSS_PACING: PacingLookup = {
  1: [0, 0, 1.2, 0.2],
  2: [0, 0.8, 1.2, 0.2],
  3: [0, 0.8, 1.2, 0.8, 0.2],
  4: [0, 0.6, 1.6, 1.2, 0.6, 0.2],
  5: [0, 0.6, 1.6, 1.2, 1, 0.6, 0.2],
  6: [0, 0.6, 1, 1.6, 1.4, 1, 0.4, 0.2],
  7: [0.6, 1.4, 1.8, 1.4, 1, 0.6, 0.4, 0.2],
  8: [0.6, 1.4, 1.8, 1.4, 1, 0.8, 0.6, 0.4, 0.2],
  9: [0, 0.6, 0.8, 1, 1.2, 1.4, 1.4, 1.2, 1, 0.6, 0.2],
  10: [0, 0.4, 0.8, 1, 1.4, 1.6, 1.7, 1.5, 1.2, 0.4, 0.2],
  11: [0.6, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2],
  12: [1.2, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2, 0.1],
  13: [1.2, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2, 0.1],
  18: [1.2, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2, 0.1],
  19: [1.2, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2, 0.1],
  20: [1.2, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2, 0.1],
};

// The steeper configuration of the graph weight loss pacing for all plan lengths
// This will apply for GB users or ones that are using KG as weight unit and have a weight loss goal less than 19kg
export const STEEPER_WEIGHT_LOSS_PACING: PacingLookup = {
  1: [0, 0, 1.2, 0.2],
  2: [0, 0.8, 1.2, 0.2],
  3: [0, 0.8, 1.2, 0.8, 0.2],
  4: [0, 0.6, 1.2, 1.2, 0.6, 0.2],
  5: [0, 0.6, 1.6, 1.2, 1, 0.6, 0.2],
  6: [0, 0.6, 1, 1.4, 1.4, 1, 0.4, 0.2],
  7: [0.6, 1.4, 1.8, 1.4, 1, 0.6, 0.4, 0.2],
  8: [0.6, 1.4, 1.6, 1.2, 1, 0.8, 0.6, 0.4, 0.2],
  9: [0, 0.6, 0.8, 1, 1.2, 1.4, 1.4, 1.2, 1, 0.6, 0.2],
  10: [0, 0.4, 0.8, 1, 1.4, 1.6, 1.7, 1.5, 1.2, 0.4, 0.2],
  11: [0, 0.4, 0.8, 1, 1.4, 1.6, 1.7, 1.5, 1.2, 0.4, 0.2],
  12: [1.2, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2, 0.1],
  13: [1.2, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2, 0.1],
  18: [1.2, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2, 0.1],
  19: [1.2, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2, 0.1],
  20: [1.2, 1.5, 1.6, 1.4, 1.4, 1.2, 1, 0.8, 0.6, 0.6, 0.6, 0.2, 0.1],
};

export function shouldShowSteeperSlope(
  totalWeightLossGoal: number,
  unit?: UnitType
) {
  return (
    isCountryStones() ||
    (unit && unit === Unit.KILOGRAM && totalWeightLossGoal < 19)
  );
}

export function calculateWeightAtMonth(
  startWeight: number,
  idealWeight: number,
  monthsIn: number,
  planDuration: number,
  idealWeightUnit?: string
) {
  if (monthsIn <= 0) {
    return startWeight;
  }
  if (monthsIn >= planDuration) {
    return idealWeight;
  }

  let monthsToReduce = monthsIn;
  if ([7, 8, 10, 12].includes(planDuration)) {
    monthsToReduce -= 1; // We skip the first month on the graph for the 7, 8, 10, 12 plans, so adjust accordingly
  }

  const totalWeightLossGoal = startWeight - idealWeight;
  const showSteeperSlope = shouldShowSteeperSlope(
    totalWeightLossGoal,
    Unit[idealWeightUnit]
  );
  const pacing: number[] = showSteeperSlope
    ? STEEPER_WEIGHT_LOSS_PACING[planDuration]
    : REGULAR_WEIGHT_LOSS_PACING[planDuration];

  const monthlyWeightLossGoal = totalWeightLossGoal / planDuration;

  let weight = startWeight;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= monthsToReduce; i++) {
    // Rounding errors can cause the weight to be off when using stone pounds as the ideal weight unit
    weight -=
      idealWeightUnit === Unit.STONE.id
        ? pacing[i] * monthlyWeightLossGoal
        : Math.round(pacing[i] * monthlyWeightLossGoal);
  }
  if (weight < idealWeight) {
    weight = idealWeight;
  }
  return weight;
}
