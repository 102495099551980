import i18n from "@i18n";

export const MONTH_NAMES_ABBR = [
  i18n.t("dates:Jan"),
  i18n.t("dates:Feb"),
  i18n.t("dates:Mar"),
  i18n.t("dates:Apr"),
  i18n.t("dates:May"),
  i18n.t("dates:Jun"),
  i18n.t("dates:Jul"),
  i18n.t("dates:Aug"),
  i18n.t("dates:Sep"),
  i18n.t("dates:Oct"),
  i18n.t("dates:Nov"),
  i18n.t("dates:Dec"),
];

export const MONTH_NAMES_FULL = [
  i18n.t("dates:January"),
  i18n.t("dates:February"),
  i18n.t("dates:March"),
  i18n.t("dates:April"),
  i18n.t("dates:May"),
  i18n.t("dates:June"),
  i18n.t("dates:July"),
  i18n.t("dates:August"),
  i18n.t("dates:September"),
  i18n.t("dates:October"),
  i18n.t("dates:November"),
  i18n.t("dates:December"),
];
