import React from "react";
import isEmpty from "lodash/isEmpty";

import i18n from "@i18n";
import {
  initializeExistingUser,
  UserDataState,
} from "@utils/redux/slices/userData";
import { trackEvent } from "@utils/api/tracker";
import Button from "src/design-system/components/Button/Button";

import { fontLargeHeadline } from "src/design-system/styles/fonts";
import styled from "@emotion/styled";
import { isFromEmail } from "src/utils/userSegment";
import { flexMiddle } from "src/design-system/styles/layout";
import { usePageView } from "src/hooks/tracking";
import { useAppDispatch } from "src/hooks/redux";

type Props = {
  onAccept: () => void;
  onReject: () => void;
  userData?: UserDataState;
};

const Title = styled.h1`
  ${fontLargeHeadline};
  margin: 0;
  font-weight: normal;
  word-break: break-word:
`;

const ButtonWrapper = styled.div`
  ${flexMiddle};
  width: 100%;
  margin-top: 50px;
`;

/**
 * This component is shown when we know the users identity (via nuid query params)
 * and want to ensure the user explicitly acknowledges that the URL is designed for
 * the user with the nuid. This helps reduce instances of users forwarding their email
 * campaign links to their friends/family, etc. and unknowingly assuming the original
 * user's survey answers.
 */
export default function WelcomeBack({ onAccept, onReject, userData }: Props) {
  const dispatch = useAppDispatch();

  const displayedName = userData?.name || userData?.email;
  const fromEmail = isFromEmail();
  const hasAnswers = !isEmpty(userData);

  usePageView("OnWelcomeBackPage", {
    isFromEmail: fromEmail,
    hasAnswers,
  });

  const onAcceptClicked = async () => {
    await trackEvent("WelcomeBackAccepted", { isFromEmail: fromEmail });
    // This also sets the cookie
    await dispatch(initializeExistingUser(userData));
    onAccept();
  };

  const onRejectClicked = async () => {
    await trackEvent("WelcomeBackRejected", { isFromEmail: fromEmail });
    onReject();
  };

  return (
    <>
      <Title>
        {displayedName
          ? i18n.t("accountSignup:welcomeBackName", { name: displayedName })
          : i18n.t("accountSignup:welcomeBack")}
      </Title>
      <ButtonWrapper>
        <Button
          type="button"
          data-cy="account-accept"
          onClick={onAcceptClicked}
        >
          {i18n.t("accountSignup:continue")}
        </Button>
      </ButtonWrapper>
      {displayedName && (
        <ButtonWrapper>
          <Button
            type="button"
            designStyle="secondaryCta"
            data-cy="account-reject"
            onClick={onRejectClicked}
          >
            {i18n.t("accountSignup:notName", { name: displayedName })}
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
}
