import getStore from "@utils/redux/store";
import { isCountryImperial } from "./userSegment";

import { isDynamicPlanGraphEligible } from "./userSegment/features";

export function canShowDynamicPlanGraph(state = getStore().getState()) {
  return isDynamicPlanGraphEligible(state) && canRetrieveWeight(state);
}

export function canRetrieveWeight(state = getStore().getState()) {
  const { userData } = state;
  if (!userData) return false;
  const surveyAnswers = userData[userData.surveyNameSpace];
  const hasKgWeights =
    !!surveyAnswers?.weightKg && !!surveyAnswers?.idealWeightKg;
  if (isCountryImperial(state)) {
    // NOTE(kevinh): We are now requiring that imperial countries have weights in kg as well
    // since that is required to calculate personalized pace information
    return (
      hasKgWeights && !!surveyAnswers?.weight && !!surveyAnswers?.idealWeight
    );
  }
  return hasKgWeights;
}
