import { JsonObject } from "type-fest";

/* eslint-disable no-console */
export interface OptimizelyExperiment {
  name: string;
  variation: string;
  targeting?: JsonObject;
}

interface OptimizelySXExperimentWindow extends Window {
  experiments: OptimizelyExperiment[];
}

declare let window: OptimizelySXExperimentWindow;

/**
 * This class is initiated as a singleton for Optimizely SX experiments generated by
 * the Experiment Configuration Tool (ECT) to interface with. Since Optimizely experiments
 * fire at random and unpredictable times, we initialize this on the window,
 * then optimizely experiments use `registerExperiment` and `registerFilter` to
 * add experiments and "filters".
 *
 * On purchase, when we request the active experiments to send to the backend, we
 * run through all the registered experiments and apply all the filters.
 *
 * SX Experiments have a general hierarchy where:
 * 1. Sometimes experiments can stack across all users.
 * 2. Sometimes we run an experiment, targeting only a specific subset of users. These
 *    users are then isolated from the population. The filter allows us to isolate
 *    the users by filtering out the other users. To filter, we have a "targeting"
 *    object which can hold any type of information. It is only used for filtering
 *    purposes and is optional.
 */
class OptimizelySXExperiment {
  private experiments: OptimizelyExperiment[];
  private filters: ((
    experiment: OptimizelyExperiment
  ) => boolean | OptimizelyExperiment)[];

  constructor() {
    this.experiments = [];
    this.filters = [];
  }

  registerExperiment(name: string, variation: string, targeting = {}) {
    this.experiments.push({ name, variation, targeting });
  }

  registerFilter(
    filter: (experiment: OptimizelyExperiment) => boolean | OptimizelyExperiment
  ) {
    if (typeof filter !== "function") {
      console.error("Filters must be functions");
      return;
    }
    console.warn(
      "Warning! Using filters can be dangerous since it affects other experiments. Use with caution!"
    );
    this.filters.push(filter);
  }

  getActiveExperiments() {
    let filteredExperiments: OptimizelyExperiment[] = [];

    try {
      const legacyExperiments = window?.experiments || [];
      legacyExperiments.forEach((experiment) => {
        this.registerExperiment(experiment.name, experiment.variation);
      });

      this.experiments.forEach((experiment) => {
        if (this.filters.every((filter) => filter(experiment))) {
          filteredExperiments.push(experiment);
        }
      });

      // Clean out the targeting array;
      filteredExperiments = filteredExperiments.map((e) => {
        delete e.targeting;
        return e;
      });
    } catch (error) {
      console.error(error);
    }
    return filteredExperiments;
  }
}

export default OptimizelySXExperiment;
