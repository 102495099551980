import React from "react";
import Question from "@components/refactored-survey/question-types/Question";
import CheckboxWithOtherQuestion, {
  CheckboxWithOtherQuestionDefinition,
} from "../../question-types/CheckboxWithOtherQuestion";
import {
  questionTitle,
  questionOptions,
} from "@components/refactored-survey/question-types/Question.module.less";
import {
  CustomQuestionDefinition,
  registerQuestion,
} from "../../QuestionDefinition";

export const Q_MEAL_PLAN_DIETARY_RESTRICTIONS = registerQuestion({
  id: "mealPlanDietaryRestrictions",
  type: "radio",
  question: () => {
    return (
      <>
        <h1 className={questionTitle}>
          Congrats on committing to your health with the Premium Bundle! Now
          let's customize your Premium Bundle meal and workout plans.
        </h1>
        <h2 className={questionTitle}>Do you have any dietary restrictions?</h2>
      </>
    );
  },
  options: [
    {
      id: "paleo",
      text: "Paleo (no grains or legumes please!)",
    },
    {
      id: "lowCarbOrKeto",
      text: "Low-carb or keto (easy on the grains and starches)",
    },
    {
      id: "vegetarian",
      text: "Vegetarian (I don’t eat meat but I eat eggs or dairy)",
    },
    {
      id: "vegan",
      text: "Vegan (no animal products please!)",
    },
    {
      id: "gluten",
      text: "Gluten and/or dairy free (I avoid these common allergens)",
    },
    {
      id: "pescatarian",
      text: "Pescatarian",
    },
    {
      id: "eggless",
      text: "Eggless",
    },
    {
      id: "noSeafood",
      text: "No seafood",
    },
    {
      id: "noPork",
      text: "No pork",
    },
    {
      id: "anything",
      text: "Nope (I can eat pretty much anything)",
    },
  ],
});

export const Q_WORKOUT_PLAN_LIMITATIONS =
  registerQuestion<CheckboxWithOtherQuestionDefinition>({
    id: "workoutPlanLimitations",
    type: "custom",
    component: CheckboxWithOtherQuestion,
    question:
      "Do you have any physical limitations or restrictions? (Select all that apply)",
    options: [
      {
        id: "painOrLosingBreath",
        text: "I cannot walk or be physically active for 10 minutes continuously without experiencing pain or losing my breath",
      },
      {
        id: "injuredOrRecovering",
        text: "I’m currently injured or recovering from an injury",
      },
      {
        id: "physicalTherapy",
        text: "I’m in physical or occupational therapy",
      },
      {
        id: "badJointsOrKnee",
        text: "I have bad joints and/or knee",
      },
      {
        id: "cantJump",
        text: "I can't jump up and down",
      },
      {
        id: "unableToDoCardio",
        text: "I’m unable to do intense cardio",
      },
      {
        id: "doctorRestrictions",
        text: "My doctor has placed certain restrictions on my exercise",
      },
      {
        id: "other",
        text: "Other",
      },
      {
        id: "none",
        text: "Nope, I can try most things",
      },
    ],
  });

export class DisqualifiedMessage extends Question<CustomQuestionDefinition> {
  override onMount() {
    this.props.registerAnswer({}, true);
  }

  override renderOptions() {
    return <></>;
  }

  override renderWithControls() {
    return (
      <div className={questionOptions}>
        <h1 className={questionTitle}>
          It seems like our workout guides will not be able to fit your needs.
          The last thing we’d want is for pain or an injury to stop your
          weight-loss journey before it’s begun. You will still receive a custom
          meal plan.
        </h1>
        <h2 className={questionTitle}>
          Please reach out to Support{" "}
          <a
            href="http://web.noom.com/support/contact-support/"
            target="_blank"
            rel="noreferrer"
          >
            using this form
          </a>{" "}
          to receive a refund. Be sure to select “submit a billing inquiry” and
          then “Exercise plan / meal plan / DNA kit” from the drop down menus.
          In the text box, please include “not eligible for workout guide” at
          the beginning of your message along with any other questions you may
          have and one of our Support agents will take care of you.
        </h2>
      </div>
    );
  }
}

export const Q_DISQUALIFIED = registerQuestion({
  id: "disqualified",
  type: "custom",
  component: DisqualifiedMessage,
  next: {
    text: "Continue",
  },
});

export const Q_WORKOUT_PLAN_FREQUENCY = registerQuestion({
  id: "workoutPlanFrequency",
  type: "radio",
  question: "How many days a week do you want to be active?",
  options: [
    {
      id: "3to4Days",
      text: "3 to 4 days per week",
    },
    {
      id: "5to6Days",
      text: "5 to 6 days per week",
    },
  ],
});

export const Q_WORKOUT_PLAN_EQUIPMENT = registerQuestion({
  id: "workoutPlanEquipment",
  type: "radio",
  question: "Do you prefer an equipment based or an equipment free program?",
  options: [
    {
      id: "equipmentBased",
      text: "Equipment based",
    },
    {
      id: "equipmentFree",
      text: "Equipment free program",
    },
  ],
});
