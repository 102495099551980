import { createSlice } from "@reduxjs/toolkit";
import { routeConstants } from "src/utils/constants";
import { getMeristemContext } from "src/utils/meristemContext";

const routeSlice = createSlice({
  name: "routeId",
  // TODO: Move type upstream
  initialState:
    (getMeristemContext()?.route_id as routeConstants) || routeConstants.direct,
  reducers: {},
});

export default routeSlice;
