import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Unit from "src/components/unit/Unit";
import { convertUnits } from "src/components/unit/UnitUtils";
import { updateUserContext } from "src/utils/api/tracker";
import { isCountryImperial } from "src/utils/userSegment";
import { AppDispatch, GetAppState } from "../store";

export type SurveyAnswer = AnyJson;
export interface SurveyAnswersState {
  [key: string]: SurveyAnswer;
}

const surveyAnswersSlice = createSlice({
  name: "surveyAnswers",
  initialState: {} as SurveyAnswersState,
  reducers: {
    addSurveyAnswers(state, action: PayloadAction<SurveyAnswersState>) {
      return { ...state, ...action.payload };
    },
    setSurveyAnswers(state, action: PayloadAction<SurveyAnswersState>) {
      return { ...action.payload };
    },
  },
});

export const { addSurveyAnswers, setSurveyAnswers } =
  surveyAnswersSlice.actions;

export function addMissingInAppSurveyBiometrics() {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    const metricProperties = ["weightKg", "idealWeightKg", "heightCm"];
    const imperialProperties = [
      "weight",
      "idealWeight",
      "heightFeet",
      "heightInch",
    ];

    const { surveyAnswers } = getState();
    const hasMetric = metricProperties.every(
      (property) => surveyAnswers[property] != null
    );
    const hasImperial = imperialProperties.every(
      (property) => surveyAnswers[property] != null
    );
    let newSurveyAnswers: SurveyAnswersState = {};

    if (isCountryImperial() && hasMetric && !hasImperial) {
      const { weightKg, idealWeightKg, heightCm } = surveyAnswers;
      const { mainUnitValue: heightFeet, secondaryUnitValue: heightInch } =
        convertUnits({ mainUnitValue: heightCm }, Unit.CENTIMETER, Unit.FEET);

      newSurveyAnswers = {
        weight: Math.round(
          convertUnits({ mainUnitValue: weightKg }, Unit.KILOGRAM, Unit.POUND)
            .mainUnitValue
        ),
        idealWeight: Math.round(
          convertUnits(
            { mainUnitValue: idealWeightKg },
            Unit.KILOGRAM,
            Unit.POUND
          ).mainUnitValue
        ),
        heightFeet,
        heightInch,
      };

      dispatch(addSurveyAnswers(newSurveyAnswers));
    } else if (!hasMetric && hasImperial) {
      // We always want metric units in the survey answers for mixpanel logging
      const { weight, idealWeight, heightFeet, heightInch } = surveyAnswers;

      newSurveyAnswers = {
        weightKg: convertUnits(
          { mainUnitValue: weight },
          Unit.POUND,
          Unit.KILOGRAM
        ).mainUnitValue,
        idealWeightKg: convertUnits(
          { mainUnitValue: idealWeight },
          Unit.POUND,
          Unit.KILOGRAM
        ).mainUnitValue,
        heightCm: convertUnits(
          { mainUnitValue: heightFeet, secondaryUnitValue: heightInch },
          Unit.FEET,
          Unit.CENTIMETER
        ).mainUnitValue,
      };

      dispatch(addSurveyAnswers(newSurveyAnswers));
    }

    // For in-app, we need to manually update the mixpanel user context with the biometrics
    const allProperties = imperialProperties.concat(metricProperties);
    const allSurveyAnswers = { ...surveyAnswers, ...newSurveyAnswers };
    const newUserContext: SurveyAnswersState = {};
    allProperties.forEach((property) => {
      if (allSurveyAnswers[property] != null) {
        newUserContext[property] = allSurveyAnswers[property];
      }
    });
    updateUserContext(newUserContext);
  };
}

export default surveyAnswersSlice;
