import { isEnUS, isDeLanguage } from "./locale";
import { routeConstants } from "../constants";
import { CoreReduxState } from "../redux/store";
import { checkSegments } from "./util";
import { getTrackingParams } from "../services/VisitTracker";

export const isEmailWinback = checkSegments({
  oneOf: [routeConstants.emailWinback],
});
export const isEmailMain = checkSegments({
  oneOf: [routeConstants.emailMain],
});

export const isFromEmail = checkSegments({
  oneOf: [isEmailMain, isEmailWinback],
});

/** Verifies if current user comes from an email blast */
export const isEmailBlast = (state?: CoreReduxState) => {
  return isFromEmail(state) && getTrackingParams().utm_source === "blast";
};

export const isEnUSEmail = checkSegments({
  allOf: [isEnUS],
  oneOf: [isFromEmail],
});

export const isDeEmailMain = checkSegments({
  allOf: [isDeLanguage],
  oneOf: [isEmailMain],
});
