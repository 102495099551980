import getStore from "@utils/redux/store";
import { registerHandler } from "../handler";

declare const snaptr: any;

export default function registerHandlers() {
  registerHandler("lead", ({ email }) => {
    if (email) {
      // Enables advanced matching for Snapchat
      snaptr("init", "e030eb4f-d3fd-4c13-9338-9c0a3b6847ad", {
        user_email: email.toLowerCase(),
      });
    }
    snaptr("track", "SIGN_UP", { value: "healthy-weight" });
  });

  registerHandler("initiateCheckout", ({ plan }) => {
    const { promoCode } = getStore().getState();
    snaptr("track", "START_CHECKOUT", {
      value: plan.lv,
      currency: plan.currency || "USD",
      discount_code: promoCode.promoCodeApplied,
    });
  });

  registerHandler("purchase", ({ order, plan }) => {
    snaptr("track", "PURCHASE", {
      order_value: order.eltv_13_months,
      currency: plan.currency || "USD",
      transaction_id: order.subscriptionId,
      price: order.eltv_13_months,
    });
  });
}
