import { css } from "@emotion/react";

export const zIndex = {
  newContext: 0,
  header: 100,
  footer: 200,

  modal: 1000,
  quickSilver: 1_000_000,
};

export const zIndexNewStackingContext = css`
  isolation: isolate;
`;

export const zIndexModal = css`
  z-index: ${zIndex.modal};
`;
