import { SurveyAnswer } from "src/utils/redux/slices/surveyAnswers";
import { getWeightInformation } from "@utils/calculateGoals";
import {
  getDayDelta,
  getMonthDelta,
  shift_days,
  shift_months,
  toYYYY_MM_DD,
} from "@utils/datetime";
import { convertLbToKg } from "src/components/unit/UnitUtils";
import personalizedPaceJson from "src/static/personalizedPace.json";
import {
  getGraphIdByIndex,
  getCachedPaceFromGraphId,
} from "src/components/refactored-survey/question-sets/payment-survey-questions/weight-graph/WeightGraph";

/*
FORMULA FOR CALCULATING PACE
IMPORTANT: THIS GIVES US A VALUE IN LBS/WEEK WHICH WE MUST CONVERT AND STORE AS KG/WEEK
pace = I + W * <WeightKG> + ∑CI + a[j] + (1-j/3) * T*(g-c)

I: intercept, all users start here
W: coefficient for users' weight in kg
T: coefficient for users' normalized goal in kg
c: normalization factor for users' goals in kg
n: starting max months for estimate
m: max month incrementor (each graph decreases max months by n)
sl/sh: slowest and highest speed allowed at each graph
a: speed-increase factor applied at each graph after the first, 3*a was stolen from intercept

Source for these numbers: https://github.com/noom/data-analysis/blob/master/notebooks/craig/wl-graph-shape/parameters.py
*/

const I = 0.03043;
const W = 0.0107018;
const T = 0.0080264;
const c = 8;
const a = [0.0, 0.0, 0.0, 0.75];

const MIN_PACE = 1; // lb / week
const ADJUSTED_MIN_PACE = [0.5, 0.67, 0.84, MIN_PACE]; // adjusted min paces corresponding to each of the weight loss graphs
const MAX_PACE = 2; // lb / week
const ADJUST_MAX_PACE_LESS_THAN_5KG = [2, 1.8, 1.7, 1.5]; // adjusted max paces only for users with weight loss goal < 5kg
const MAX_MONTHS_FOR_PACE = 13; // max amount of months that it should take to lose user's weight according to their pace (effectively sets another min pace)
const ADJUSTED_MAX_MONTHS_FOR_PACE = [16, 15, 14, MAX_MONTHS_FOR_PACE];
const MIN_MONTH_PLAN = 2;
const MAX_MONTH_PLAN = 12;

// Utility for formatting certain pieces of data to be passed into the backend/CS
export function prepareDataForBackend(
  pace: number,
  surveyAnswers: SurveyAnswer
) {
  const payload: any = {};
  const { weightValueKg, targetWeightKg } = getWeightInformation(surveyAnswers);

  if (!weightValueKg || !targetWeightKg) {
    // we cannot calculate targetWeightDate, but it is required for coach
    // server if request contains weightLossData. If we cannot calculate
    // do not include it in request at all
    return null;
  }

  payload.weightLossSpeedPerWeekInKg = pace;

  payload.weightInKg = weightValueKg;
  payload.targetWeightInKg = targetWeightKg;
  payload.targetWeightDate = toYYYY_MM_DD(
    getTargetDateFromPace(pace, weightValueKg, targetWeightKg)
  );

  if (surveyAnswers.importantDateTime) {
    payload.weightLossImportantDate = toYYYY_MM_DD(
      new Date(surveyAnswers.importantDateTime)
    );
  }
  if (
    surveyAnswers.importantDate?.[0] === "yes" &&
    surveyAnswers.importantDateDetails?.[0]
  ) {
    payload.weightLossImportantDateDescription =
      surveyAnswers.importantDateDetails?.[0];
  }
  if (surveyAnswers.heightCm) {
    payload.heightInCm = surveyAnswers.heightCm;
  }
  return payload;
}

export function getTargetDateFromPace(
  pace: number, // kg / week
  weightKg: number, // kg
  idealWeightKg: number // kg
) {
  const currentDate = new Date();
  // Get amount of weight to be lost in kg, divide by pace (kg/week) and multiply by 7 to get number of days til target
  const numDays = ((weightKg - idealWeightKg) / pace) * 7;

  const targetWeightDate = new Date(shift_days(numDays, currentDate).getTime());

  // Make sure that the target date is not less than 1 for the weight graph
  const monthDelta = getMonthDelta(currentDate, targetWeightDate);
  if (monthDelta < 1) {
    return shift_months(1, currentDate);
  }

  return targetWeightDate;
}

export function getRecommendedPlanDurationFromPace(
  pace: number, // kg / week
  weightKg: number, // kg
  idealWeightKg: number // kg
) {
  const targetDate = getTargetDateFromPace(pace, weightKg, idealWeightKg);
  const currentDate = new Date();
  let recommendedPlanDuration = getMonthDelta(currentDate, targetDate);
  if (targetDate.getDate() > currentDate.getDate()) {
    recommendedPlanDuration += 1;
  }
  return Math.min(
    Math.max(recommendedPlanDuration, MIN_MONTH_PLAN),
    MAX_MONTH_PLAN
  ); // Clamp recommended plan duration
}

// Calulates the pace in kg/week needed to lose X weight in N months
function calculatePaceFromMonths(months: number, weightDifference: number) {
  const currentDate = new Date();
  const targetDate = shift_months(months, currentDate);
  const numDays = getDayDelta(currentDate, targetDate);
  const numWeeks = numDays / 7;
  return weightDifference / numWeeks;
}

// Returns value in kg/week
function calculateMinPaceFromIndex(graphIndex: number) {
  let minPace = convertLbToKg({
    mainUnitValue: ADJUSTED_MIN_PACE[graphIndex],
  }).mainUnitValue;
  for (let i = 0; i < graphIndex; i++) {
    const graphId = getGraphIdByIndex(i);
    const previousPace = getCachedPaceFromGraphId(graphId);
    if (previousPace) {
      minPace = Math.max(minPace, previousPace);
    }
  }
  return minPace;
}

export function calculateAdjustedPace(
  surveyAnswers: SurveyAnswer,
  graphIndex: number
) {
  const { weightValueKg, targetWeightKg } = getWeightInformation(surveyAnswers);
  const weightDifference = weightValueKg - targetWeightKg;

  // I + W * <WeightKG> + ∑CI term
  const pace = calculateRawPaceInLbs(surveyAnswers);

  // a[j] + (1-j/3) * T*(g-c) term
  const indexAdjustments =
    a[graphIndex] + (1 - graphIndex / 3) * T * (weightDifference - c);

  // I + W * <WeightKG> + ∑CI + a[j] + (1-j/3) * T*(g-c)
  let adjustedPace = pace + indexAdjustments;

  // Make sure pace is inbetween min and max pace values
  adjustedPace = Math.max(
    Math.min(
      adjustedPace,
      weightDifference < 5
        ? ADJUST_MAX_PACE_LESS_THAN_5KG[graphIndex]
        : MAX_PACE
    ),
    ADJUSTED_MIN_PACE[graphIndex]
  );

  // Convert units to kg since this is how the data will be consumed
  const adjustPaceInKgPerWeek = convertLbToKg({
    mainUnitValue: adjustedPace,
  }).mainUnitValue;

  // Clamp pace so that the expected weight loss date never exceeds certain amount of months
  const clampedPace = Math.max(
    adjustPaceInKgPerWeek,
    calculatePaceFromMonths(
      ADJUSTED_MAX_MONTHS_FOR_PACE[graphIndex],
      weightDifference
    )
  );

  // Clamp pace so that the value seen at a certain weight graph is never lower than the previous seen weight graphs
  return Math.max(clampedPace, calculateMinPaceFromIndex(graphIndex));
}

export function calculateTruePace(surveyAnswers: SurveyAnswer) {
  // The "true" value of the pace that we are passing along to CS should just be the adjusted pace
  // value given an index of 3 (meaning the pace value that the final update graph uses)
  return calculateAdjustedPace(surveyAnswers, 3);
}

// IMPORTANT: this function takes in kg values as inputs but uses a formula that spits
// out an answer in lbs/week.
// This is the I + W * <WeightKG> + ∑CI term of formula
// Source for personalized pace json: https://github.com/noom/data-analysis/blob/master/notebooks/craig/wl-graph-shape/coefficient_json.json
function calculateRawPaceInLbs(surveyAnswers: SurveyAnswer) {
  const { weightKg } = surveyAnswers;
  // if we can't capture weightKg value, just return max pace
  if (!weightKg) {
    return MAX_PACE;
  }

  let sumC = 0;
  Object.entries(surveyAnswers).forEach(
    ([key, answers]: [string, [string]]) => {
      if (Array.isArray(answers) && personalizedPaceJson[key]) {
        for (const answer of answers) {
          sumC += personalizedPaceJson[key][answer] || 0;
        }
      }
    }
  );
  return I + W * weightKg + sumC;
}
