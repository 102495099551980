import React, { ChangeEvent, RefObject } from "react";
import i18n from "@i18n";
import { RadioQuestion } from "@components/refactored-survey/question-types/RadioQuestion";
import { questionOptions } from "@components/refactored-survey/question-types/Question.module.less";
import RadioQuestionOptions from "./RadioQuestionOptions";
import RadioTextInput from "./RadioTextInput";
import {
  CustomQuestionDefinition,
  OptionsInputDefinition,
  QuestionOption,
  QuestionProps,
} from "src/components/refactored-survey/QuestionDefinition";
import { SurveyAnswer } from "src/utils/redux/slices/surveyAnswers";
import { isMobile } from "src/utils/userSegment";

export type RadioWithInputOtherQuestionDefinition = OptionsInputDefinition &
  CustomQuestionDefinition & {
    otherOption?: {
      id: string;
      text: string;
    };
    belowOtherOptions?: QuestionOption[];
  };

class RadioWithInputOther<
  Def extends RadioWithInputOtherQuestionDefinition = RadioWithInputOtherQuestionDefinition
> extends RadioQuestion<Def> {
  readonly otherInputRef: RefObject<HTMLInputElement>;
  otherFieldId: string;

  constructor(props: QuestionProps<Def>) {
    super(props);

    const surveyAnswer = this.getSurveyAnswer(props.question.id);
    this.otherFieldId = props.question.otherOption?.id || "other";
    this.state = {
      otherInput:
        surveyAnswer?.[0] === this.otherFieldId ? surveyAnswer[1] : "",
    };

    this.handleOtherInputChange = this.handleOtherInputChange.bind(this);
    this.handleOtherInputClick = this.handleOtherInputClick.bind(this);

    this.otherInputRef = React.createRef();

    this.hideControls = false;
    this.useStickyControls = true;
  }

  override handleClick(e) {
    const answers: SurveyAnswer = {};
    // NOTE(patrick): The old survey wrapped radio question answers in an array.
    // We want to preserve this aspect of the old survey for now. In the future,
    // figure we want to unwrap radio question values.
    answers[this.props.question.id] = [e.target.value];
    const answerIsValid = this.validateAnswer(answers);
    this.props.registerAnswer(answers, answerIsValid);
  }

  handleOtherInputClick(e) {
    this.otherInputRef.current.focus();

    // Prevent the sticky next button from covering the input when the mobile
    // keyboard pops up.
    if (isMobile()) {
      window.scrollTo(0, document.body.scrollHeight);
    }

    // If the user clicks back on the input field after filling it out previously,
    // make sure to store their input.
    if (this.state.otherInput) {
      const { value } = e.target;
      const ans: SurveyAnswer = {};
      ans[this.props.question.id] = [this.otherFieldId, value];
      const isValid = this.validateAnswer(ans);
      this.props.registerAnswer(ans, isValid);
    }
  }

  handleOtherInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    this.setState({ otherInput: value });

    if (value) {
      const ans: SurveyAnswer = {};
      ans[this.props.question.id] = [this.otherFieldId, value];
      const isValid = this.validateAnswer(ans);
      this.props.registerAnswer(ans, isValid);
    } else {
      this.props.registerAnswer(null, false);
    }
  }

  override renderOptions(options: QuestionOption[]) {
    const placeholder =
      this.props.question.otherOption?.text ||
      i18n.t("survey:sharedInputs:radioWithInputOther:other");

    return (
      <div className={questionOptions}>
        <RadioQuestionOptions
          options={options}
          questionId={this.props.question.id}
          getQuestionAnswerKey={() => this.getQuestionAnswerKey()}
          handleClick={this.handleClick}
        />
        <RadioTextInput
          getQuestionAnswerKey={() => this.getQuestionAnswerKey()}
          inputValue={this.state.otherInput}
          handleInputChange={this.handleOtherInputChange}
          name={this.otherFieldId}
          placeholder={placeholder}
          handleClick={this.handleOtherInputClick}
          inputRef={this.otherInputRef}
        />
        {this.props.question.belowOtherOptions && (
          <RadioQuestionOptions
            options={this.props.question.belowOtherOptions}
            questionId={this.props.question.id}
            getQuestionAnswerKey={() => this.getQuestionAnswerKey()}
            handleClick={this.handleClick}
          />
        )}
      </div>
    );
  }
}

export default RadioWithInputOther;
