/**
 * Utility to detect bots.
 *
 * We discovered that the Google Ads-bot was causing our experiment allocation
 * script to favor one variation over another. These bots also cause junk
 * data to be added to mixpanel. We want to keep from tracking bot users.
 *
 * Copyright (C) 2019 Noom, Inc.
 * Author: Patrick
 */
import crawlers from "crawler-user-agents";

// TODO: Move this into meristemContext and exec in worker

/**
 * Check if the userAgent string is from a bot.
 */
function isBot(userAgent: string) {
  if (userAgent) {
    return crawlers.some((data) => {
      const pattern = new RegExp(data.pattern);
      return pattern.test(userAgent);
    });
  }
  return null;
}

/**
 * Determine if the current browsing session is a bot.
 */
export const USER_IS_BOT = isBot(window.navigator.userAgent);

export default isBot;
