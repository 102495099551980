import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { paymentSource } from "src/utils/constants";

interface Discount {
  formatted_amount: string;
  percentage: number;
}

export interface Plan {
  batch_symbol: string | null;
  braintree_id: string;
  currency: string;
  currency_symbol: string;
  discounted_plan_price: number;
  duration_in_months: number;
  discounts: {
    total_discount?: Discount;
    default_discount?: Discount;
    promo_discount?: Discount;
  };
  formatted_promo_discount_amount: string;
  billing_cycle_in_months: number;
  gross_price?: string;
  has_trial: boolean;
  is_vip: boolean;
  ltv: number;
  lv: number;
  merchant_account: string;
  plan_price: number;
  price: number;
  promo_discount_amount: number;
  regular_price: number;
  sales_tax_amount: number;
  monthly_price: number;
  discounted_monthly_price: number;
  regular_monthly_price: number;
  weekly_price: number;
  discounted_weekly_price: number;
  regular_weekly_price: number;
  starter_fee: number;
  trial_fee: number;
  noom_plan_id: string;
  trial_duration: number;
  payment_source?: paymentSource.braintree | paymentSource.stripe;
  curriculum?: string;

  trial_item_options?: {
    price: number;
    trial_sale_item_id: string;
  }[];
  sale_item_id?: string;
  one_source_tax_code?: string;
}

export type PlansState = Record<string, Plan>;

const plansSlice = createSlice({
  name: "plans",
  initialState: {} as PlansState,
  reducers: {
    updatePlans(state, action: PayloadAction<PlansState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updatePlans } = plansSlice.actions;

export default plansSlice;
