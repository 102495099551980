import {
  isBaseline,
  isCore,
  isCorePaid,
  isCoreWinback,
  isEnLanguage,
  isEnUS,
  isEnIntl,
  isInApp,
  isInApp14DayiOS,
  isWinback,
  isEmailMain,
  isIOS,
  isEnUSWinback,
  isApp14DayBaseRoute,
  isEnBaseline,
  isDeLanguage,
  isCountry,
  isCoreDirect,
  isEnIntlBaseline,
  isEnUSInAppWinback,
  isAffiliateWithoutTrial,
  isDeEmailMain,
} from ".";
import { routeConstants } from "../constants";
import getStore, { CoreReduxState } from "../redux/store";

import { isFromEmail, isEnUSEmail } from "./email";
import { isUS } from "./locale";
import { checkSegments } from "./util";
import {
  ELIGIBLE_HEALTH_RISKS,
  OPTIMIZELY_TARGETED_FOR_TELEHEALTH,
} from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";
import {
  calculateCurrentBMIImperial,
  getCourseDuration,
} from "@utils/calculateGoals";
import { getTrackingParams } from "../services/VisitTracker";
import { canRetrieveWeight } from "../refactoredSurvey";
import { TELEHEALTH_PLAN } from "src/funnels/payment-en/pages/telehealthRedirects";

// eslint-disable-next-line no-underscore-dangle
declare const __API_DOMAIN__: string;

const HW_NON_VIP_BATCH_ID_DEV = 1;
const HW_NON_VIP_BATCH_ID_PROD = 354;
const PROD_API_DOMAIN = "https://buyflow-api.noom.com";

const devModeFlagSet =
  new URLSearchParams(window.location.search).get("idm") === "true";

export function isDevMode() {
  return devModeFlagSet;
}

export const isCollapsedPaymentEligible = (state?: CoreReduxState) => {
  return isEnLanguage(state) && !isInApp(state);
};

export const isHMEligible = (state?: CoreReduxState) =>
  isEnUS(state) && isCore(state) && isIOS();

/**
 * Verifies that the user is eligible for Compass styles.
 * Currently only baselined for all English buyflows
 */
export const isCompassEligible = isEnLanguage;

const hadPreviousITunesSubscription = () => {
  const urlParams = new URLSearchParams(window.location.search);
  // This parameter is set by the growth backend in the internal app endpoint
  // More details about that here: https://github.com/noom/eng-handbook/blob/master/teams/growth/product/Winbacks.md#more-about-in-app-routes
  return urlParams.get("had_itunes_sub") === "true";
};

export const isBraintreeStripeiOSEligible = checkSegments({
  allOf: [isEnUS, hadPreviousITunesSubscription],
  oneOf: [routeConstants.app14DayReturning],
});

/**
 * Checks if the user can use the native app in-app purchase flow.
 */
export const isInAppNativePurchaseEligible = checkSegments({
  allOf: [isEnUS],
  noneOf: [isBraintreeStripeiOSEligible],
  oneOf: [isInApp14DayiOS],
});

/**
 * Checks if the user can use the web-based in-app purchase flow.
 */
export const isInAppWebPurchaseEligible = checkSegments({
  oneOf: [
    routeConstants.app14DayFtWeb,
    routeConstants.app14DayWeb,
    routeConstants.app14DayWebReturning,
    isBraintreeStripeiOSEligible,
  ],
});

export const isFastForwardEligible = checkSegments({
  allOf: [isEnUS],
  noneOf: [isTelehealthPlan],
  oneOf: [isCore, routeConstants.winback],
});

const isNoomPremiumEligibleUS = checkSegments({
  allOf: [isEnUS],
  oneOf: [
    isBaseline,
    isCoreWinback,
    routeConstants.affiliateTrialNPB,
    routeConstants.emailMain,
    routeConstants.referrals,
  ],
});

const isNoomPremiumEligibleIntl = checkSegments({
  allOf: [isEnIntl],
  oneOf: [
    isBaseline,
    isCoreWinback,
    routeConstants.affiliateTrialNPB,
    routeConstants.emailMain,
    routeConstants.referrals,
    routeConstants.freeTrial,
  ],
});

export const isAddonStoreEligible = checkSegments({
  allOf: [isEnUS],
  noneOf: [isInApp, isTelehealthPlan],
  oneOf: [
    routeConstants.direct,
    routeConstants.exsf01d,
    routeConstants.exsf01,
    isAffiliateWithoutTrial,
    routeConstants.emailMain,
    routeConstants.referrals,
    routeConstants.freeTrial,
  ],
});

export const isWelcomeToNoomEligible = checkSegments({
  oneOf: [
    isBaseline,
    isDeEmailMain,
    routeConstants.winback,
    routeConstants.app14DayWeb,
    routeConstants.app14Day,
    routeConstants.app14DayFtWeb,
    routeConstants.app14DayReturning,
  ],
});

export const userHasPurchasedNoomPremium = () => {
  const { purchasedNoomPremium } = getStore().getState();
  return purchasedNoomPremium;
};

export const isNoomPremiumConsultationSurveyEligible = checkSegments({
  allOf: [isUS, userHasPurchasedNoomPremium],
  oneOf: [
    isBaseline,
    routeConstants.affiliateTrialNPB,
    routeConstants.referrals,
  ],
});

export const isImportantDateEligible = checkSegments({
  allOf: [isEnUS],
  oneOf: [isBaseline, isCoreWinback, isFromEmail],
});

export const isFivePercentEligible = checkSegments({
  allOf: [isEnUS],
  oneOf: [isBaseline],
});

/**
 * Implements the logic for targeting users for dynamic plan graph(ex417)
 */
export const isDynamicPlanGraphEligible = checkSegments({
  oneOf: [
    isBaseline,
    isWinback,
    isEnUSEmail,
    isDeEmailMain,
    isInApp,
    isEnUSInAppWinback,
  ],
});

export const isFinalGraphEligible = checkSegments({
  oneOf: [
    isEnUSWinback,
    isEnBaseline,
    isEnUSEmail,
    isApp14DayBaseRoute,
    isEnUSInAppWinback,
  ],
});

/**
 * Verifies if the current user should see the final update graph
 */
export const shouldShowFinalUpdateGraph = (
  { userData } = getStore().getState()
) => {
  const { weight, idealWeight, weightKg, idealWeightKg } =
    userData[userData?.surveyNameSpace] || {};
  const courseDuration = getCourseDuration();

  // EnUSBaseline route
  if (
    weight &&
    idealWeight &&
    weightKg &&
    idealWeightKg &&
    isFivePercentEligible() &&
    courseDuration <= 8
  ) {
    return true;
  }

  return shouldCondenseOrShowFinalUpdateGraph(courseDuration);
};

export const shouldCondenseGraphSteps = () => {
  const courseDuration = getCourseDuration();
  return shouldCondenseOrShowFinalUpdateGraph(courseDuration);
};

function shouldCondenseOrShowFinalUpdateGraph(courseDuration: number) {
  const isCourseDurationEligible =
    courseDuration >= 2 &&
    ((courseDuration <= 8 && isEnIntlBaseline()) || courseDuration <= 6);
  return (
    canRetrieveWeight() && isFinalGraphEligible() && isCourseDurationEligible
  );
}

export const hasNoTrial = checkSegments({
  oneOf: [
    routeConstants.affiliateNoTrial,
    routeConstants.affiliateNoTrialPromo,
    routeConstants.appNoTrial,
    routeConstants.appNoTrialReturning,
    routeConstants.ndpp,
  ],
});

export const isTrialEligible = (
  { recommendedPlan } = getStore().getState()
) => {
  return recommendedPlan.has_trial && !hasNoTrial();
};

export function showChooseTrialPage(state = getStore().getState()) {
  if (!state.serverContext.trial_fees) {
    return false;
  }
  if (isEnLanguage(state)) {
    return state.serverContext.choose_trial_fee;
  }
  return isCoreDirect(state) || isCorePaid(state) || isDeEmailMain();
}

export const isPromoCodeBoxDisabled = checkSegments({
  allOf: [
    (state?: CoreReduxState) => isEnLanguage(state) || isDeLanguage(state),
  ],
  oneOf: [isInApp, isCorePaid, isWinback, routeConstants.affiliateNoTrial],
});

export const shouldValidateTokenFlag = checkSegments({
  oneOf: [routeConstants.appUpsell],
});

export const shouldShowOnscreenBackButtons = checkSegments({
  oneOf: [
    routeConstants.app14DayReturning,
    routeConstants.app14DayWebReturning,
    routeConstants.app14DayWeb,
    routeConstants.app14DayFtWeb,
    routeConstants.app14Day,
    routeConstants.appNoTrial,
  ],
});

export const shouldShowExplainerLoadingPage = checkSegments({
  oneOf: [
    isBaseline,
    isWinback,
    routeConstants.app14Day,
    routeConstants.app14DayWeb,
  ],
});

export const shouldUseMobileOptimizedControls = checkSegments({
  oneOf: [
    isBaseline,
    isWinback,
    routeConstants.app14Day,
    routeConstants.app14DayWeb,
    routeConstants.hmPaid,
    routeConstants.hmDirect,
  ],
});

// used exclusively for users coming through the initial buyflow
// determines if the user will even see the insurance reimbursement survey
export const isEligibleForTelehealthReimbursement = (
  { surveyAnswers, geoLocation } = getStore().getState()
): boolean => {
  // this value is set (and can be turned on/off, throttled) by Optimizely
  // (2021/08/10) https://app.optimizely.com/v2/projects/2753280231/experiments/20510260333
  const optimizelyTargetedForTelehealth = sessionStorage.getItem(
    OPTIMIZELY_TARGETED_FOR_TELEHEALTH
  );

  const isEligibleState =
    geoLocation?.country_code === "US" &&
    // 12/08/2021: Re-enable when we allow people to sign up for buyflow from Florida again
    geoLocation?.subdivision in { CA: "CA" };

  let currentBMI;
  const { heightFeet, heightInch, weight } = surveyAnswers;

  if (
    heightFeet !== undefined &&
    heightInch !== undefined &&
    weight !== undefined
  ) {
    currentBMI = parseFloat(
      calculateCurrentBMIImperial(heightFeet, heightInch, weight)
    );
  }
  const risks = (surveyAnswers?.currentHealthRisk ?? []) as string[];
  const userHealthRisks: string[] = risks.concat(
    surveyAnswers?.currentHealthRiskMale ?? []
  );
  const isEligibleHealthRisk = userHealthRisks.some((risk) =>
    ELIGIBLE_HEALTH_RISKS.includes(risk)
  );

  return (
    optimizelyTargetedForTelehealth &&
    isEligibleState &&
    isCore() &&
    (currentBMI >= 30 || (currentBMI >= 25 && isEligibleHealthRisk))
  );
};

export function isTelehealthPlan(state = getStore().getState()) {
  return state.recommendedPlan.noom_plan_id === TELEHEALTH_PLAN;
}

export const shouldRedirectToProgramSwitch = (
  { activeSubscriptionData } = getStore().getState()
): boolean => {
  return (
    activeSubscriptionData.hasActiveSubscription &&
    activeSubscriptionData.activeSubscriptionCurriculumSlug &&
    activeSubscriptionData.activeSubscriptionCurriculumSlug.endsWith("en_US") &&
    ((activeSubscriptionData.batchId === HW_NON_VIP_BATCH_ID_PROD &&
      __API_DOMAIN__ === PROD_API_DOMAIN) ||
      activeSubscriptionData.batchId === HW_NON_VIP_BATCH_ID_DEV)
  );
};

export const isEligibleForExitIntentModalEmail = () => {
  return (
    isEnIntl() && isEmailMain() && getTrackingParams().utm_source === "drip"
  );
};

export const hasPromoCode = () => {
  const { promoCode } = getStore().getState();

  // TODO shouldn't rely on URL params for this; and if we are, should be an arg to this function
  const code = new URLSearchParams(window.location.search).get("promoCode");
  return !!(code && code.length) || !!promoCode?.promoCodeApplied;
};

export const hasOffers = (state: CoreReduxState) => {
  const context = state?.serverContext;
  return context.has_offers;
};
export const isTaxExclusiveCountry = (state?: CoreReduxState) =>
  isCountry(["US", "CA"], state);

export const isNoomPremiumConsultationSurveyDisqualified = (
  state = getStore().getState()
) => {
  const { surveyAnswers } = state;
  return surveyAnswers?.workoutPlanLimitations?.includes("painOrLosingBreath");
};

export const isNoomPremiumEligible = () => {
  const { promoCode } = getStore().getState();
  const isNoomPremiumEligibleBySegments =
    isNoomPremiumEligibleUS() || isNoomPremiumEligibleIntl();

  return !promoCode?.promoCodeIsVip && isNoomPremiumEligibleBySegments;
};

export const isEligibleForPersonalizedPace = () => {
  return isUS();
};

export const requiresDoubleOptInForEmail = (state?: CoreReduxState) => {
  return isCountry(["DE", "AT"], state);
};

export const showAppReviewBadges = () => {
  return !isInApp() && !isUS() && !isEnIntl();
};
