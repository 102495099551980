import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ensurePageSetLoaded } from "src/pageDefinitions/pageSets";
import { PageSet } from "src/pageDefinitions/types";
import { setContextType } from "src/utils/meristemContext";
import { AppDispatch, GetAppState } from "../store";

export type LinearBuyflowState = {
  pageSetName: string;
  isPageSetInit: boolean;
};

const linearBuyflow = createSlice({
  name: "linearBuyflow",
  initialState: {
    pageSetName: null,
    isPageSetInit: false,
  } as LinearBuyflowState,
  reducers: {
    setActivePageSet(state, action: PayloadAction<PageSet | null>) {
      return {
        pageSetName: action.payload?.id,
        isPageSetInit: false,
      };
    },
    setPageSetInit(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isPageSetInit: action.payload,
      };
    },
  },
});

export const { setPageSetInit } = linearBuyflow.actions;

// WARN: This is a hack. This will be revisited as server context work progresses
const pageSetLayerServerContextMap = {
  "main-survey": "landing",
  "payment-survey": "payment",
  "payment-checkout": "payment",
  "noom-premium-survey": "noom-premium",
};
export function setActivePageSet(pageSet: PageSet) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    await ensurePageSetLoaded(pageSet, getState());

    dispatch(linearBuyflow.actions.setActivePageSet(pageSet));

    // Note that this is hacky and we should evolve as we work out the future of
    // page sets, rerolling, and server context. Too many dependencies to to
    // cleaner solution at the moment.
    if (pageSetLayerServerContextMap[pageSet.layer]) {
      setContextType(pageSetLayerServerContextMap[pageSet.layer]);
    }
  };
}
export function clearActivePageSet() {
  return linearBuyflow.actions.setActivePageSet(null);
}

export default linearBuyflow;
