import Cookies from "js-cookie";

/**
 * Helper for parsing the cookie for autologin data (in app authentication).
 *
 * Copyright (C) 2020 Noom Inc.
 * Author: Sumin
 */

const IN_APP_COOKIE = "autologin_data";
const WEB_COOKIE = "identity_data";
const ACCESS_CODE = "accessCode";
const ACCESS_TOKEN = "accessToken";

export function getInAppAutoCookie() {
  return getAuthCookie(IN_APP_COOKIE);
}

export function getWebAuthCookie() {
  return getAuthCookie(WEB_COOKIE);
}

export function getAuthCookie(cookieName: string) {
  const cookie = Cookies.get(cookieName);
  if (cookie) {
    return parseAuthCookie(cookie);
  }
  return null;
}

function parseAuthCookie(cookie: string) {
  // Potential type leakage, but these should probably be opaque anyway
  const parsedCookieDict = JSON.parse(cookie) as Record<string, string>;
  if (ACCESS_TOKEN in parsedCookieDict && ACCESS_CODE in parsedCookieDict) {
    return parsedCookieDict;
  }
  return null;
}

export function setInAppAutoCookie(accessCode: string, accessToken: string) {
  if (__NODE_ENV__ !== "production" && (!accessCode || !accessToken)) {
    throw new Error(
      "accessCode and accessToken must be provided. See WorkingWithInAppUpsells in app handbook"
    );
  }

  const cookie = {
    accessCode,
    accessToken,
    email: "jupst1@ta.noom.com",
  };
  Cookies.set(IN_APP_COOKIE, JSON.stringify(cookie), {
    expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
  });
}

export function getAccessToken() {
  const authCookie = getInAppAutoCookie();
  return authCookie?.[ACCESS_TOKEN];
}
