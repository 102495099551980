import React, { SyntheticEvent } from "react";
import Question from "./Question";
import {
  OptionsInputDefinition,
  QuestionOption,
  QuestionProps,
} from "../QuestionDefinition";
import { SurveyAnswersState } from "src/utils/redux/slices/surveyAnswers";
import { questionOptions } from "@components/refactored-survey/question-types/Question.module.less";
import styles from "./CheckboxQuestion.module.less";

export class CheckboxQuestion<
  Def extends OptionsInputDefinition = OptionsInputDefinition,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ExtraProps = {}
> extends Question<Def, ExtraProps> {
  readonly noneOption?;

  constructor(props: QuestionProps<Def, ExtraProps>) {
    super(props);

    const options = this.evalOptions();
    if (Array.isArray(options)) {
      this.noneOption = options.find((option) => option.id === "none");
    }

    this.handleClick = this.handleClick.bind(this);

    this.useStickyControls = true;
  }

  handleClick(e: SyntheticEvent, option: string) {
    e.preventDefault();
    let checkboxArray: string[] = [];
    if (this.props.question.id in this.props.surveyAnswers) {
      checkboxArray = [...this.props.surveyAnswers[this.props.question.id]];
    }

    const optionIndex = checkboxArray.indexOf(option);
    if (optionIndex >= 0) {
      checkboxArray.splice(optionIndex, 1);
    } else {
      if (option === "none") {
        checkboxArray = [];
      } else if (this.noneOption) {
        // Uncheck none option if checked.
        checkboxArray = checkboxArray.filter((id) => id !== this.noneOption.id);
      }
      checkboxArray.push(option);
    }

    const ans: SurveyAnswersState = {};
    ans[this.props.question.id] = checkboxArray;
    this.props.registerAnswer(ans, this.validateAnswer(ans));
  }

  override validateAnswer(answer: SurveyAnswersState) {
    // If a custom validator is passed into props. use that instead.
    if (
      "validator" in this.props.question &&
      typeof this.props.question.validator === "function"
    ) {
      return this.props.question.validator(answer);
    }
    if (
      answer != null &&
      this.props.question.id in answer &&
      answer[this.props.question.id]
    ) {
      return answer[this.props.question.id].length > 0;
    }
    return false;
  }

  isChecked(id: string) {
    if (this.props.question.id in this.props.surveyAnswers) {
      const answerArray = this.props.surveyAnswers[this.props.question.id];
      if (answerArray.indexOf(id) >= 0) {
        return true;
      }
    }
    return false;
  }

  override renderOptions(options: QuestionOption[]) {
    return (
      <div className={questionOptions}>
        {options.map((option) => (
          <label
            htmlFor={option.id}
            key={option.id}
            className={`${styles.checkboxQuestionOption} ${
              this.isChecked(option.id) ? styles.checked : ""
            }`}
          >
            <input
              type="checkbox"
              checked={this.isChecked(option.id)}
              name={this.props.question.id}
              id={option.id}
              onClick={(e) => this.handleClick(e, option.id)}
              onChange={(e) => this.handleClick(e, option.id)}
            />
            <span
              className={`${styles.checkbox} ${
                this.isChecked(option.id) ? styles.checked : ""
              }`}
            />
            {option.text}
          </label>
        ))}
      </div>
    );
  }
}
