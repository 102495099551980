import { QuestionList } from "@components/refactored-survey/QuestionDefinition";
import {
  Q_DISQUALIFIED,
  Q_MEAL_PLAN_DIETARY_RESTRICTIONS,
  Q_WORKOUT_PLAN_EQUIPMENT,
  Q_WORKOUT_PLAN_FREQUENCY,
  Q_WORKOUT_PLAN_LIMITATIONS,
} from "@components/refactored-survey/question-sets/consultation-survey-questions/Consultation";

export const CONSULTATION_SURVEY: QuestionList = [
  Q_MEAL_PLAN_DIETARY_RESTRICTIONS,
  Q_WORKOUT_PLAN_LIMITATIONS,
  Q_DISQUALIFIED,
  Q_WORKOUT_PLAN_FREQUENCY,
  Q_WORKOUT_PLAN_EQUIPMENT,
];
