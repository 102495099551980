import React, { useState } from "react";
import { FlexFrame, MainColumn } from "src/components/core/BodyLayout";
import { useOnce } from "src/hooks/lifecycle";
import { useServerContext, useUserData } from "src/hooks/redux";
import i18n from "src/locales";
import { trackEvent } from "src/utils/api/tracker";
import {
  fetchExistingUser,
  hasValidAccount,
  UserDataState,
} from "src/utils/redux/slices/userData";
import { getURLParams } from "src/utils/urlParams";
import { isDeLanguage, isEsLanguage, isFromEmail } from "src/utils/userSegment";
import { setNoomSessionStorage } from "src/utils/noomSessionStorage";
import { SessionStorageKey } from "src/utils/constants";

import Footer from "@components/footer/FooterPayment";
import LoggedInHeader from "@components/logged-in-header/LoggedInHeader";

import AccountSignup from "./AccountSignup";
import { Body, Title } from "./Account";
import WelcomeBack from "./WelcomeBack";
import goto from "src/pageDefinitions/goto";

/**
 * When a user is given a link in an email, that link will contain a `nuid` parameter that
 * identifies the user. However, users may try to share that email link with others, so we want to
 * first verify that they are indeed the intended user before letting them continue.
 * If they say they are not, this will return them to landing.
 */
export function VerifyUser({ children }: { children: React.ReactNode }) {
  // Data
  const userDataInStore = useUserData();
  const serverContext = useServerContext();
  const userIdInParams = getURLParams()?.nuid;

  // Local state
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<UserDataState>(userDataInStore);

  // Fetch user data from nuid in query params (if present)
  // and use for display on WelcomeBack component.
  const loadUserDataFromQueryParams = async () => {
    if (!userIdInParams) {
      setLoading(false);
      return;
    }
    try {
      const response = await fetchExistingUser(userIdInParams);
      setUserData(response);
    } finally {
      setLoading(false);
    }
  };

  // Skip prompt if the user ID in the URL query params matches the one in the cookie
  const userMatches =
    userIdInParams && userIdInParams === serverContext.user_id;

  // Only prompt WelcomeBack confirmation on email routes, where the link could've been shared/forwarded. In-App expects
  // nuid param to work with no prompt
  const fromEmail = isFromEmail();

  // ESDE has its own codepath that handles email campaign links differently. Bypass this check for now.
  // TODO(mattr): Remove this flag once ESDE paths have been migrated to LBF.
  const isEsDe = isDeLanguage() || isEsLanguage();

  // Confirm if we know enough about the user, otherwise, we'll prompt them for more info.
  const isValidAccount = hasValidAccount(userData);
  // Show prompt if route is email-* and an nuid is present in the query params (temporarily applies to all non-ESDE routes)
  const needsPrompt = fromEmail && !!userIdInParams && !isEsDe;
  const canSkip = userMatches;

  const stripNuidParamAndRefresh = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("nuid");
    window.location.search = params.toString();
  };

  const onUserIdentityConfirmed = () => {
    setNoomSessionStorage(SessionStorageKey.userConfirmedIdentity, true);
    stripNuidParamAndRefresh();
  };

  useOnce(() => {
    const trackAndCheckSkip = async () => {
      if (needsPrompt) {
        await trackEvent("OnVerifyUser", {
          canSkip,
          isValidAccount,
          requestedUserId: userIdInParams,
        });
        if (!canSkip) {
          // Prepare to initialize the WelcomeBack component
          loadUserDataFromQueryParams();
        }
      }
    };

    trackAndCheckSkip();
  });

  if (needsPrompt && !canSkip) {
    let content;
    if (isValidAccount) {
      // Only show "Welcome back" screen when the user is visiting from
      // an email campaign link. This is to ensure the user confirms that
      // the link they're visiting is specifically designed for that user.
      content = (
        <WelcomeBack
          userData={userData}
          onAccept={onUserIdentityConfirmed}
          onReject={() => goto.landing()}
        />
      );
    } else {
      // No userId or user data associated with account.
      // Prompt user for name and email to continue.
      // This use case can happen if CS gives an anonymous user a direct link to a plan,
      // or if an internal user clicks on any link protected by <AccountRequired> for testing
      content = (
        <AccountSignup
          userData={userData}
          onComplete={() => stripNuidParamAndRefresh()}
        />
      );
    }

    return (
      <FlexFrame>
        <LoggedInHeader />
        <MainColumn margin={24} maxWidth={600} data-cy="verify-user">
          <Body>
            {loading ? (
              <Title>{i18n.t("accountSignup:loading")}</Title>
            ) : (
              content
            )}
          </Body>
          <Footer />
        </MainColumn>
      </FlexFrame>
    );
  }

  // Transparent passthrough if no prompt is needed or can be skipped
  return <>{children}</>;
}
