import React, { createRef } from "react";
import Unit from "@components/unit/Unit";

import {
  BrightSpotGraphStage,
  calculateHalfwayWeightLoss,
  getBrightSpots,
  getWeightInformation,
  getCourseDuration,
} from "@utils/calculateGoals";

import { connect } from "react-redux";
import getStore, { CoreReduxState, AppDispatch } from "src/utils/redux/store";
import { UserDataState } from "@utils/redux/slices/userData";
import { addSurveyAnswers } from "@utils/redux/slices/surveyAnswers";
import { trackEvent } from "@utils/api/tracker";
import saveToBraze from "@utils/brazeUploader";
import {
  addMonths,
  shift_days,
  getMonthDelta,
  getDayDelta,
} from "@utils/datetime";
import drawWeightGraph, {
  getImportantEventSubtitle,
} from "@utils/graphs/weightGraph/weightGraphV2";

import drawPersonalizedPaceWeightGraph, {
  getImportantEventSubtitle as getPersonalizedPaceImportantEventSubtitle,
} from "@utils/graphs/weightGraph/personalizedPaceWeightGraph";

import { canShowDynamicPlanGraph } from "@utils/refactoredSurvey";
import {
  isEnUSBaselineOrWinback,
  isEnUSBaselineOrCoreWinback,
  isUS,
  isEnIntlBaseline,
  isEnUSInAppWinback,
} from "@utils/userSegment";
import i18n from "@i18n";
import { question } from "@components/refactored-survey/question-types/Question.module.less";
import styles from "./WeightGraph.module.less";

import Question from "@components/refactored-survey/question-types/Question";
import {
  BaseQuestionDefinition,
  QuestionProps,
  registerQuestion,
} from "src/components/refactored-survey/QuestionDefinition";
import {
  isFivePercentEligible,
  isImportantDateEligible,
  shouldCondenseGraphSteps,
  isEligibleForPersonalizedPace,
} from "src/utils/userSegment/features";
import { getKeepItOffLength } from "src/utils/graphs/weightGraph/keepItOff";
import { formatToDecimal } from "src/utils/stringUtils";
import { Text } from "src/components/core/Text";
import {
  getTargetDateFromPace,
  calculateAdjustedPace,
  getRecommendedPlanDurationFromPace,
} from "src/utils/pace";
import { getNoomPlanIdFromKey } from "@utils/plans";

export function getAdjustedPlanLength(
  planDuration: number,
  daysPushed: number
) {
  const initialPlanLength = planDuration;
  const initialDate = addMonths(new Date(), initialPlanLength);
  const finalDate = shift_days(daysPushed, initialDate);

  return initialPlanLength + getMonthDelta(initialDate, finalDate);
}

// TODO put this somewhere else?
const graphIndexMap = {
  choosePlan: 0,
  firstUpdateGraph: 1,
  secondUpdateGraph: 2,
  finalUpdateGraph: 3,
};
export function getIndexFromGraphId(questionId: string) {
  return graphIndexMap[questionId];
}
export function getGraphIdByIndex(index: number) {
  return Object.keys(graphIndexMap).find((key) => graphIndexMap[key] === index);
}

/*
Helper function for retrieving the id of the previous weight loss graph
*/
function getPreviousGraphId(questionId: string) {
  const graphIndex = getIndexFromGraphId(questionId);
  return getGraphIdByIndex(graphIndex - 1) || "";
}

export function getPaceIdFromGraphId(questionId: string) {
  return `${questionId}Pace`;
}

export function getCachedPaceFromGraphId(questionId: string) {
  const { surveyAnswers } = getStore().getState();
  return surveyAnswers[getPaceIdFromGraphId(questionId)];
}

export function getPaceFromGraphId(questionId: string) {
  const { surveyAnswers } = getStore().getState();
  return calculateAdjustedPace(surveyAnswers, getIndexFromGraphId(questionId));
}

/*
  Function for determining whether or not the user's pace has increased from the last time they saw an update graph
  */
export function hasIncreasedPace(questionId: string, pace: number) {
  const previousGraphId = getPreviousGraphId(questionId);
  return previousGraphId && pace > getCachedPaceFromGraphId(previousGraphId);
}

interface ExtraProps {
  userData: CoreReduxState["userData"];
  plans: CoreReduxState["plans"];
  afterContent?: React.ReactNode;
  addFivePercentMarker?: boolean;
  // Set to true to skip every other weight label for longer duration programs
  skipEveryOtherLabel?: boolean;
  showIncreasedPace?: boolean;
  addSurveyAnswersDispatch: any;
}

type WeightGraphDefinition = BaseQuestionDefinition & {
  component: React.ComponentType<
    QuestionProps<WeightGraphDefinition, ExtraProps>
  >;
  daysPushed: (willShowFinalUpdateGraph: boolean) => number;
  steps: (willShowFinalUpdateGraph: boolean) => number;
  speed: number;
  showIncreasedPace?: boolean;
};

class WeightGraph extends Question<WeightGraphDefinition, ExtraProps> {
  readonly weight;
  readonly targetWeight;
  readonly targetWeightDisplay;
  readonly weightKg;
  readonly idealWeightKg;
  readonly steps: number;
  readonly daysPushed: number;
  readonly startingDate: Date;
  readonly finalDate: Date;
  readonly currentPace: number;
  readonly previousPace: number;
  readonly targetDateFromPace: Date;
  readonly previousTargetDateFromPace: Date;
  readonly speed: number;
  readonly stepSize: number;

  // WARN: The fields below are likely used in the render pipeline and may
  // have unrendered side effects as currently implemented
  // TODO: Move the fields that are read by render and change over time into state
  private stepNumber: number;

  private graph: any;
  private updateTimeout: ReturnType<typeof setTimeout>;

  canvasRef = createRef<HTMLCanvasElement>();

  constructor(props: QuestionProps<WeightGraphDefinition, ExtraProps>) {
    super(props);

    const { userData } = this.props;
    const surveyAnswers = userData[userData.surveyNameSpace];

    const {
      weightValue,
      weightValueKg,
      weightUnit,
      weightDisplayUnit,
      targetWeight,
      targetWeightKg,
      targetWeightDisplay,
    } = getWeightInformation(surveyAnswers);

    this.weight = weightValue;
    this.targetWeight = targetWeight;
    this.targetWeightDisplay = targetWeightDisplay;
    this.weightKg = weightValueKg;
    this.idealWeightKg = targetWeightKg;

    const condenseGraphSteps = shouldCondenseGraphSteps();
    this.daysPushed = this.props.question.daysPushed(condenseGraphSteps);
    this.stepNumber = 1;
    this.graph = null;

    this.currentPace =
      getCachedPaceFromGraphId(this.props.question.id) ||
      getPaceFromGraphId(this.props.question.id);
    this.previousPace = getCachedPaceFromGraphId(
      getPreviousGraphId(this.props.question.id)
    );

    this.targetDateFromPace = getTargetDateFromPace(
      this.currentPace,
      this.weightKg,
      this.idealWeightKg
    );
    this.previousTargetDateFromPace = getTargetDateFromPace(
      this.previousPace,
      this.weightKg,
      this.idealWeightKg
    );
    this.startingDate = this.getStartingDate();
    this.finalDate = this.getFinalDate();

    this.state = {
      currentDate: this.getCurrentDate(),
      weightUnit,
      weightDisplayUnit,
      targetWeight,
      targetWeightDisplay,
    };

    this.steps = getDayDelta(this.finalDate, this.startingDate);
    this.stepSize = isEligibleForPersonalizedPace()
      ? Math.max(this.steps / 25, 1) // We want there to be approx ~25 total ticks for larger date ranges
      : 1;

    this.speed = isEligibleForPersonalizedPace()
      ? Math.min(5000 / (this.steps / this.stepSize), 300)
      : this.props.question.speed;

    this.updateDate = this.updateDate.bind(this);
  }

  getCurrentDate() {
    return shift_days(-this.stepNumber, this.startingDate);
  }

  // This is the starting date value that the counter will tick from and finally land on finalDate
  getStartingDate() {
    if (isEligibleForPersonalizedPace()) {
      return this.previousTargetDateFromPace;
    }
    const planLength = getCourseDuration();
    return shift_days(this.daysPushed + 1, addMonths(new Date(), planLength));
  }

  // This is the date that the date counter will finally land on and display
  getFinalDate() {
    if (isEligibleForPersonalizedPace()) {
      return this.targetDateFromPace;
    }
    const condenseGraphSteps = shouldCondenseGraphSteps();
    const planLength = getCourseDuration();
    return shift_days(
      this.daysPushed - this.props.question.steps(condenseGraphSteps) + 1,
      addMonths(new Date(), planLength)
    );
  }

  updateDate() {
    this.setState({
      currentDate: this.getCurrentDate(),
    });

    this.stepNumber += this.stepSize;
    this.scheduleUpdate();
  }

  scheduleUpdate() {
    if (this.stepNumber <= this.steps) {
      this.updateTimeout = setTimeout(this.updateDate, this.speed);
    } else {
      this.stepNumber = this.steps;
      this.setState({
        currentDate: this.getCurrentDate(),
      });
    }
  }

  override onMount() {
    if (this.props.question.id !== "finalUpdateGraph") {
      // Don't cache pace for the final update graph
      const pace = {};
      pace[getPaceIdFromGraphId(this.props.question.id)] = this.currentPace;
      this.props.addSurveyAnswersDispatch(pace);
    }
    this.props.registerAnswer({}, true);

    // Track pace information
    const choosePlanPace = this.props.surveyAnswers?.choosePlanPace;
    const choosePlanTargetDate = getTargetDateFromPace(
      choosePlanPace,
      this.weightKg,
      this.idealWeightKg
    );
    trackEvent(getPaceIdFromGraphId(this.props.question.id), {
      CurrentProjectedSpeed: this.currentPace,
      CurrentProjectedDays: getDayDelta(new Date(), this.targetDateFromPace),
      PreviousProjectedSpeed: this.previousPace,
      PreviousProjectedDays: getDayDelta(
        new Date(),
        this.previousTargetDateFromPace
      ),
      FirstProjectDays: getDayDelta(new Date(), choosePlanTargetDate),
    });

    if (isEligibleForPersonalizedPace()) {
      const recommendedPlanDuration = getRecommendedPlanDurationFromPace(
        this.currentPace,
        this.weightKg,
        this.idealWeightKg
      );
      if (recommendedPlanDuration && !this.props.serverContext.plan_override) {
        saveToBraze(
          this.props.userData.user_id,
          {
            recommended_plan_url: getNoomPlanIdFromKey(
              `${recommendedPlanDuration}`
            ),
          },
          null
        );
      }
    }

    const recommendedPlanDuration = getMonthDelta(new Date(), this.finalDate);

    if (isImportantDateEligible()) {
      let subtitle;
      if (isEligibleForPersonalizedPace()) {
        subtitle = getPersonalizedPaceImportantEventSubtitle(
          this.weight,
          this.targetWeight,
          this.finalDate,
          this.props.surveyAnswers
        );
      } else {
        subtitle = getImportantEventSubtitle(
          this.weight,
          this.targetWeight,
          recommendedPlanDuration,
          this.finalDate,
          this.props.userData[this.props.userData.surveyNameSpace]
        );
      }
      this.setState({ subtitle });
    }

    if (isEligibleForPersonalizedPace()) {
      // NOTE (kevinh): If custom fonts are not cached, the chart will render using default fonts and will
      // only apply custom fonts on graph reload. In order to prevent this behaviour, we will wait to draw the chart
      // only when the fonts are ready.
      //  More reading here: https://www.chartjs.org/docs/latest/general/fonts.html#loading-fonts
      // https://stackoverflow.com/questions/51699341/chart-js-font-only-renders-after-you-reload-the-canvas
      document.fonts.ready.then(() => {
        this.graph = drawPersonalizedPaceWeightGraph(
          this.weight,
          this.targetWeight,
          this.state.weightUnit,
          this.finalDate,
          this.graph,
          this.props.userData[this.props.userData.surveyNameSpace],
          isImportantDateEligible(),
          this.props.addFivePercentMarker && isFivePercentEligible()
        );
      });
    } else {
      this.graph = drawWeightGraph(
        this.weight,
        this.targetWeight,
        recommendedPlanDuration,
        this.state.weightUnit,
        this.graph,
        this.canvasRef.current,
        getKeepItOffLength(),
        this.state.weightDisplayUnit,
        this.props.userData[this.props.userData.surveyNameSpace],
        isImportantDateEligible(),
        this.props.addFivePercentMarker && isFivePercentEligible(),
        this.props.skipEveryOtherLabel
      );
    }
    this.scheduleUpdate();
  }

  override componentWillUnmount() {
    clearTimeout(this.updateTimeout);
  }

  override renderQuestion() {
    if (this.props.question.question && !isEligibleForPersonalizedPace()) {
      return (
        <h1 className={styles.weightGraphTitle}>
          {this.props.question.question}
        </h1>
      );
    }
    return null;
  }

  override renderWithControls() {
    return (
      <div
        className={`${question} ${
          styles[`question-${this.props.question.id}`]
        } ${
          isEnUSBaselineOrCoreWinback()
            ? styles[`reducedMargin-${this.props.question.id}`]
            : ""
        }`}
        data-cy="question"
        data-cy-question={this.props.question.id}
      >
        {this.renderQuestionFunctionOrJSX()}
        {this.renderOptionsFunctionOrJSX()}
        {this.renderExtraContent()}
      </div>
    );
  }

  renderMonth(
    monthAnimate: boolean,
    currentMonth: string,
    previousMonth: string,
    renderComma = false
  ) {
    return (
      <div className={styles.animationContainer}>
        <div
          key={`currentMonth-${this.stepNumber}`}
          className={`${styles.currentDate} ${styles.largeText} ${
            monthAnimate ? styles.animate : ""
          }`}
        >
          {` ${currentMonth}${renderComma ? "," : ""}`}
        </div>
        <div
          key={`previousMonth-${this.stepNumber}`}
          className={`${styles.previousDate} ${styles.largeText} ${
            monthAnimate ? styles.animate : ""
          }`}
        >
          {` ${previousMonth}${renderComma ? "," : ""}`}
        </div>
      </div>
    );
  }

  renderDay(
    dayAnimate: boolean,
    currentDay: number,
    previousDay: number,
    renderComma = false
  ) {
    return (
      <div className={styles.animationContainer}>
        <div
          key={`currentDay-${this.stepNumber}`}
          className={`${styles.currentDate} ${styles.largeText} ${
            dayAnimate ? styles.animate : ""
          }`}
        >
          {` ${currentDay}${renderComma ? "," : ""}`}
        </div>
        <div
          key={`previousDay-${this.stepNumber}`}
          className={`${styles.previousDate} ${styles.largeText} ${
            dayAnimate ? styles.animate : ""
          }`}
        >
          {` ${previousDay}${renderComma ? "," : ""}`}
        </div>
      </div>
    );
  }

  renderYear(animate: boolean, currentYear: number, previousYear: number) {
    return (
      <div className={styles.animationContainer}>
        <div
          key={`currentYear-${this.stepNumber}`}
          className={`${styles.currentDate} ${styles.largeText} ${
            animate ? styles.animate : ""
          }`}
        >
          {` ${currentYear}`}
        </div>
        <div
          key={`previousYear-${this.stepNumber}`}
          className={`${styles.previousDate} ${styles.largeText} ${
            animate ? styles.animate : ""
          }`}
        >
          {` ${previousYear}`}
        </div>
      </div>
    );
  }

  showYearInHeadline(date: Date) {
    return (
      isEligibleForPersonalizedPace() && getMonthDelta(new Date(), date) >= 12
    );
  }

  getHeaderText() {
    if (isEligibleForPersonalizedPace()) {
      if (this.props.showIncreasedPace) {
        return "We predict you'll be";
      }
      return "We still predict you'll be";
    }
    return "Based on your answers, you'll be";
  }

  override renderOptions() {
    // Determine if we want to tick down from startingDate to finalDate or just display finalDate statically
    const shouldTick = !isEligibleForPersonalizedPace() || !!this.previousPace;

    const shouldAnimate =
      (this.stepNumber !== 1 || this.props.showIncreasedPace) && shouldTick;

    const currentDate = shouldTick
      ? new Date(this.state.currentDate)
      : new Date(this.finalDate);

    const previousDate = shift_days(1, currentDate);
    const currentDay = currentDate.getDate();
    const previousDay = previousDate.getDate();
    const currentMonth = currentDate.toLocaleString("default", {
      month: "long",
    });

    const previousMonth = previousDate.toLocaleString("default", {
      month: "long",
    });

    const currentYear = currentDate.getFullYear();
    const previousYear = previousDate.getFullYear();

    let displayTargetValue = `${Math.round(this.targetWeight)}`;
    if (this.state.weightDisplayUnit.id === Unit.STONE.id) {
      displayTargetValue = `${this.state.targetWeightDisplay.mainUnitValue} ${
        Unit.STONE.label
      } ${
        this.state.targetWeightDisplay.secondaryUnitValue
          ? `${this.state.targetWeightDisplay.secondaryUnitValue} ${Unit.POUND.label}`
          : ""
      }`;
    } else if (this.state.weightDisplayUnit.id === Unit.POUND.id) {
      displayTargetValue = `${formatToDecimal(
        this.state.targetWeightDisplay.mainUnitValue
      )} lb`;
    } else {
      displayTargetValue = `${formatToDecimal(
        this.state.targetWeightDisplay.mainUnitValue
      )} kg`;
    }

    function shouldAnimateMonth() {
      return currentMonth !== previousMonth;
    }

    function shouldAnimateYear() {
      return currentYear !== previousYear;
    }

    return (
      <div className={styles.weightGraphContainer}>
        <div
          className={`${styles.weightGraphRow_1} ${
            isImportantDateEligible() ? styles.marginBottom20px : ""
          }`}
        >
          <div className={styles.weightGraphSummary}>
            <p
              className={
                isEligibleForPersonalizedPace()
                  ? styles.plansHeaderTextPersonalizedPace
                  : styles.plansHeaderText
              }
            >
              {this.getHeaderText()}
            </p>
            <div
              className={
                isEligibleForPersonalizedPace()
                  ? styles.weightGraphSummaryHeadlinePersonalizedPace
                  : styles.weightGraphSummaryHeadline
              }
            >
              <div className={styles.line}>
                <span className={styles.largeText}>{displayTargetValue}</span>
                &nbsp;
                <span id={styles.by}>by</span>&nbsp;
              </div>
              <div className={styles.line}>
                {/* For US Markets we render the animated date as Month Day but for international markets we render the animated date as Day Month */}
                {isUS() ? (
                  <>
                    {this.renderMonth(
                      shouldAnimate && shouldAnimateMonth(),
                      currentMonth,
                      previousMonth
                    )}
                    &nbsp;
                    {this.renderDay(
                      shouldAnimate,
                      currentDay,
                      previousDay,
                      this.showYearInHeadline(currentDate)
                    )}
                  </>
                ) : (
                  <>
                    {this.renderDay(shouldAnimate, currentDay, previousDay)}
                    &nbsp;
                    {this.renderMonth(
                      shouldAnimate && shouldAnimateMonth(),
                      currentMonth,
                      previousMonth,
                      this.showYearInHeadline(currentDate)
                    )}
                  </>
                )}
                {this.showYearInHeadline(currentDate) ? (
                  <>
                    &nbsp;
                    {this.renderYear(
                      shouldAnimate && shouldAnimateYear(),
                      currentYear,
                      previousYear
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {!!this.state.subtitle && (
              <div className={styles.weightGraphSummarySubtitle}>
                {this.state.subtitle}
              </div>
            )}
          </div>
        </div>
        <div className={styles.weightGraphRow_2}>
          <div className={styles.weightGraph}>
            <div className={styles.analysisResult}>
              <div className={styles.chartContainer}>
                <canvas
                  id="analysis-result--mobile"
                  ref={this.canvasRef}
                  className={`${
                    isEligibleForPersonalizedPace()
                      ? styles.personalizedPaceChart
                      : styles.chart
                  } ${isImportantDateEligible() ? styles.minHeight250px : ""}`}
                />
              </div>
              {!isEligibleForPersonalizedPace() && (
                <div className={styles.programSummary}>
                  <>
                    {getKeepItOffLength() === 2 && (
                      <div className={styles.arrow}>
                        <div className={styles.text}>The weight stays off!</div>
                        <div className={styles.line} />
                        <div className={styles.point} />
                      </div>
                    )}
                  </>
                </div>
              )}
              {(isEnUSBaselineOrWinback() ||
                isEnIntlBaseline() ||
                isEnUSInAppWinback()) &&
                this.props.afterContent}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const connector = connect(
  (state: CoreReduxState) => ({
    userData: state.userData,
    serverContext: state.serverContext,
    recommendedPlan: state.recommendedPlan,
    surveyAnswers: state.surveyAnswers,
    plans: state.plans,
  }),
  (dispatch: AppDispatch) => ({
    addSurveyAnswersDispatch: (userdata: UserDataState) =>
      dispatch(addSurveyAnswers(userdata)),
  })
);

export const WeightGraphConnected = connector(WeightGraph);

export const FIRST_WEIGHT_GRAPH = registerQuestion<WeightGraphDefinition>({
  id: "firstUpdateGraph",
  type: "custom",
  component: (props) => {
    const pace =
      getCachedPaceFromGraphId("firstUpdateGraph") ||
      getPaceFromGraphId("firstUpdateGraph");
    const showIncreasedPace = hasIncreasedPace("firstUpdateGraph", pace);

    function renderText() {
      if (isEligibleForPersonalizedPace()) {
        if (showIncreasedPace) {
          return (
            <>
              <p>
                {i18n.t(
                  "survey:questions:weightGraph:firstWeightGraphWithIncreasedPace"
                )}
              </p>
            </>
          );
        }
        return (
          <>
            <p>
              {i18n.t(
                "survey:questions:weightGraph:firstWeightGraphPersonalizedPace"
              )}
            </p>
          </>
        );
      }
      return (
        <>
          <p>{i18n.t("survey:questions:weightGraph:firstWeightGraph1")}</p>
          <p>{i18n.t("survey:questions:weightGraph:firstWeightGraph2")}</p>
        </>
      );
    }

    return (
      <WeightGraphConnected
        {...props}
        afterContent={
          <div
            className={
              isEligibleForPersonalizedPace()
                ? styles.weightGraphAfterContentPersonalizedPace
                : styles.weightGraphAfterContent
            }
          >
            {" "}
            {isEnIntlBaseline() && (
              <div className={styles.brightSpots}>
                <ul>
                  {getBrightSpots(BrightSpotGraphStage.FIRST_UPDATE_GRAPH).map(
                    (brightSpot: string) => {
                      return <li key={brightSpot}>{brightSpot}</li>;
                    }
                  )}
                  <li>
                    <Text
                      i18nKey="survey:questions:weightGraph:weightForecast"
                      values={{
                        halfwayAmount: calculateHalfwayWeightLoss(
                          props.recommendedPlan.billing_cycle_in_months
                        ),
                        halfwayDate: addMonths(
                          new Date(),
                          props.recommendedPlan.billing_cycle_in_months / 2
                        ).toLocaleString("default", { month: "long" }),
                      }}
                      components={{
                        halfway: <span className={styles.halfwayWeightLoss} />,
                      }}
                    />
                  </li>
                </ul>
              </div>
            )}
            {renderText()}
          </div>
        }
        skipEveryOtherLabel
        showIncreasedPace={showIncreasedPace}
      />
    );
  },
  back: { disabled: true },
  next: { text: "CONTINUE" },
  daysPushed: () => 40,
  steps: (condenseGraphSteps) => (condenseGraphSteps ? 15 : 17),
  speed: 300,
  shouldShowQuestion: () => canShowDynamicPlanGraph(),
});

export const SECOND_WEIGHT_GRAPH = registerQuestion<WeightGraphDefinition>({
  id: "secondUpdateGraph",
  type: "custom",
  component: (props) => {
    const pace =
      getCachedPaceFromGraphId("secondUpdateGraph") ||
      getPaceFromGraphId("secondUpdateGraph");
    const showIncreasedPace = hasIncreasedPace("secondUpdateGraph", pace);

    function renderText() {
      if (isEligibleForPersonalizedPace()) {
        if (showIncreasedPace) {
          return (
            <>
              <p>
                {i18n.t(
                  "survey:questions:weightGraph:secondWeightGraphWithIncreasedPace"
                )}
              </p>
            </>
          );
        }
        return (
          <>
            <p>
              {i18n.t(
                "survey:questions:weightGraph:secondWeightGraphPersonalizedPace"
              )}
            </p>
          </>
        );
      }
      return (
        <>
          <p>{i18n.t("survey:questions:weightGraph:secondWeightGraph")}</p>
        </>
      );
    }

    return (
      <WeightGraphConnected
        {...props}
        afterContent={
          <div
            className={
              isEligibleForPersonalizedPace()
                ? styles.weightGraphAfterContentPersonalizedPace
                : styles.weightGraphAfterContent
            }
          >
            {" "}
            {isEnIntlBaseline() && (
              <div className={styles.brightSpots}>
                <ul>
                  {getBrightSpots(BrightSpotGraphStage.SECOND_UPDATE_GRAPH).map(
                    (brightSpot: string) => {
                      return <li key={brightSpot}>{brightSpot}</li>;
                    }
                  )}
                </ul>
              </div>
            )}
            {renderText()}
          </div>
        }
        skipEveryOtherLabel
        showIncreasedPace={showIncreasedPace}
      />
    );
  },
  back: { disabled: true },
  next: { text: "CONTINUE" },
  daysPushed: (condenseGraphSteps) => (condenseGraphSteps ? 26 : 24),
  steps: (condenseGraphSteps) => (condenseGraphSteps ? 13 : 25),
  speed: 300,
  shouldShowQuestion: () => canShowDynamicPlanGraph(),
});
