/* eslint-disable no-restricted-globals */

import { mainSurveyLayer } from "@pageDefinitions/goto/constants";
import { routeConstants } from "src/utils/constants";
import getStore from "src/utils/redux/store";
import { captureException } from "src/utils/sentry";
import { getSearchQuery } from "src/utils/urlParams";
import { isEnLanguage, isHM as isHMSegment } from "src/utils/userSegment";
import goto from ".";
import { getPageSet, getPageSetForLayer } from "../pageSets";
import {
  isInsideAndroidWebView,
  isInsideIOSWebKit,
  pushReroll,
  reroll,
  setParam,
} from "./utils";

declare const Android: any;

// "/"
// "/programs/health-weight/:routeId"
// "/(en|en-GB|en-AU|en-NZ|en-IE|es|de|de-DE|es-ES)/programs/health-weight/:routeId"
// "/(en|en-GB|en-AU|en-NZ|en-IE|es|de|de-DE|es-ES)"

export function isBuyflow() {
  const activePageSet = getPageSet(
    getStore().getState().linearBuyflow.pageSetName
  );
  if (activePageSet?.layer === mainSurveyLayer) {
    return true;
  }

  return (
    location.pathname === "/" ||
    /^\/(en|en-GB|en-AU|en-NZ|en-IE|es|de|de-DE|es-ES)\/?/.test(
      location.pathname
    ) ||
    /(\/programs\/(health-weight|healthy-mind|mood)\/.*)$/.test(
      location.pathname
    ) ||
    /\/healthy-weight-program\//.test(location.pathname)
  );
}

function buyflowRoot(isHMParam = isHMSegment()) {
  if (location.pathname !== "/" || !isEnLanguage()) {
    const { language, routeId } = getStore().getState();
    if (routeId) {
      if (isHMParam) {
        if (routeId === routeConstants.direct) {
          return `/programs/mood/hm-direct`;
        }
        return `/programs/mood/${routeId}`;
      }

      return `/${language ?? "en"}/programs/health-weight/${routeId}`;
    }
  }

  return "/";
}

export const linkTo = {
  survey(isHM?: boolean) {
    const landing = buyflowRoot(isHM) + location.search;
    return `${landing}#/survey`;
  },
};

export default {
  // Landing
  landing() {
    reroll(buyflowRoot());
  },
  // Survey
  async survey() {
    const pageSet = await getPageSetForLayer(
      mainSurveyLayer,
      getStore().getState()
    );
    if (pageSet) {
      reroll(`/ps/${mainSurveyLayer}${location.search}`);
    } else {
      const url = linkTo.survey();

      // We need to reroll ESDE at the moment
      pushReroll(url, () => isBuyflow() && isEnLanguage());
    }
  },

  /**
   * This is intended mostly as a stopgap and may not work for all future implementations.
   * @deprecated Use page set navigation instead.
   */
  async surveyQuestion(question: string) {
    const pageSet = await getPageSetForLayer(
      mainSurveyLayer,
      getStore().getState()
    );
    if (pageSet) {
      goto.pageSet(pageSet.id, question ? `/survey/${question}` : undefined);
      return;
    }

    let url = linkTo.survey();
    if (question) {
      url += `/${question}`;
    }
    pushReroll(url, isBuyflow);
  },

  async choosePlan() {
    const state = getStore().getState();
    const pageSet = await getPageSetForLayer(mainSurveyLayer, state);
    if (pageSet) {
      await goto.pageSet(pageSet.id, `/survey/choosePlan`);
      return;
    }

    const { geoLocation, userData } = state;
    const queryParams = new URLSearchParams(location.search);
    // Add fcc if exists, for the in-app buyflows, in order to enforce location based on device locale.
    if (geoLocation?.is_forced_country_code) {
      setParam(queryParams, "fcc", geoLocation.country_code);
    }
    if (!isBuyflow()) {
      setParam(queryParams, "email", userData.email);
    }

    const queryParamsString = getSearchQuery(queryParams);

    const url = `${buyflowRoot()}${queryParamsString}#/choosePlan`;
    pushReroll(url, isBuyflow);
  },

  nativeBuyflowExit() {
    if (isInsideIOSWebKit()) {
      try {
        (window as any).webkit.messageHandlers.buyflowExit.postMessage({});
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativeBuyflowExit.ios");
      }
    }
    if (isInsideAndroidWebView()) {
      try {
        Android.buyflowExit();
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativeBuyflowExit.android");
      }
    }

    // eslint-disable-next-line no-console
    console.error("nativeBuyflowExit: no message handler found");

    return false;
  },
};
