import { registerHandler } from "../handler";

const ADS_WIZZ_LEAD_PIXEL =
  "https://adresults-8-adswizz.attribution.adswizz.com/fire?pixelId=449a71c4-519c-4fb9-857c-c93796acf685&type=sitevisit&subtype=FormSubmission1&aw_0_req.gdpr=true";
const ADS_WIZZ_CONVERSION_PIXEL =
  "https://adresults-8-adswizz.attribution.adswizz.com/fire?pixelId=00a886b1-3e86-490c-9a28-de4a91f34688&type=sitevisit&subtype=Purchase1&aw_0_req.gdpr=true";

function fireAdsWizzPixel(url: string, order?: any) {
  const adsWizzPixel = document.createElement("img");
  adsWizzPixel.setAttribute("id", "adswizz-pixel");
  if (order) {
    adsWizzPixel.setAttribute(
      "src",
      `${url}&conversionValue=${order.eltv_13_months}`
    );
  } else {
    adsWizzPixel.setAttribute("src", url);
  }
  adsWizzPixel.setAttribute("height", "1px");
  adsWizzPixel.setAttribute("width", "1px");
  adsWizzPixel.setAttribute("style", "display:none;");
  adsWizzPixel.setAttribute("alt", "");

  document.body.appendChild(adsWizzPixel);
}

export default function registerHandlers() {
  registerHandler("lead", () => {
    fireAdsWizzPixel(ADS_WIZZ_LEAD_PIXEL);
  });

  registerHandler("purchase", ({ order }) => {
    fireAdsWizzPixel(ADS_WIZZ_CONVERSION_PIXEL, order);
  });
}
