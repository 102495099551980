import { SessionStorageKey } from "@utils/constants";

const NOOM_SESSION_STORAGE_PREFIX = "noom_";

export const getNoomSessionStorage = <
  T extends Exclude<any, undefined> = string
>(
  key: keyof typeof SessionStorageKey
): T => {
  return JSON.parse(sessionStorage.getItem(NOOM_SESSION_STORAGE_PREFIX + key));
};

export const setNoomSessionStorage = (
  key: keyof typeof SessionStorageKey,
  value: Exclude<any, undefined>
) =>
  sessionStorage.setItem(
    NOOM_SESSION_STORAGE_PREFIX + key,
    JSON.stringify(value)
  );
