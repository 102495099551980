/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/**
 * Methods for tracking events on the client. Currently tracks to Mixpanel.
 *
 * Mixpanel is tracked on the server-side using the growth pixel. Relies on pulling in the
 * redux state to get the distinct_id.
 *
 * Copyright (C) 2018 Noom, Inc.
 * @author sumin, patrick
 */
import isEqual from "lodash/isEqual";
import { send } from "@utils/fetch";
import getStore from "@utils/redux/store";
import { captureException } from "@utils/sentry";
import { getStoredExperiments } from "@utils/experiment";
import visitTracker, { getTrackingParams } from "@utils/services/VisitTracker";
import capitalizeFirstLetter from "@utils/capitalizeFirstLetter";
import { USER_IS_BOT } from "../botDetector";
import { typedKeys } from "../typeWrappers";
import {
  ExperimentDefinition,
  extractStartEvent,
  getMeristemContext,
  getSha,
  Targeting,
} from "../meristemContext";
import { SurveyAnswersState } from "../redux/slices/surveyAnswers";
import { routeConstants, SessionStorageKey } from "@utils/constants";
import { ReadonlyDeep } from "type-fest";
import { isInApp } from "../userSegment";
import { prepareGrowthAPIParameters } from "../services/api-params";
import { getNoomSessionStorage } from "@utils/noomSessionStorage";
import { ConsultType } from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";

const REFERRER_TO_SEARCH_ENGINE_MAPPING = {
  ".*duckduckgo\\..*": "duckduckgo",
  ".*yahoo\\..*": "yahoo",
  ".*yandex\\..*": "yandex",
  ".*baidu\\..*": "baidu",
  ".*naver\\..*": "naver",
  ".*google\\..*": "google",
  ".*bing\\..*": "bing",
};
const REFERRER_IGNORE_REGEX = ".*(app|email|gclid|paid).*";
export const REFERRER_MATCH = [
  "^http://localhost:8080",
  "^https://web.noom.com",
  "^https://try.noom.com",
  "^https://ww(w|1).noom.com(/)?$",
  "^https://ww(w|1).noom.com/(en|es|de|ja|jp)",
  "^https://ww(w|1).noom.com/programs/health-weight/",
  "^https://ww(w|1).noom.com/(en|es|de|ja|jp)/programs",
  "^https://ww(w|1).noom.com/(purchase|about|careers|news)",
  "^https://buyflow-web-assets.noom.com",
  "^https://buyflow-web-assets.test.wsli.dev",
  "^https://buyflow-lambda.(dev|test).wsli.dev",
]
  .map((domain) => `(${domain})`)
  .reduce((val, acc) => `${acc}|${val}`);

function sendPixelRequest(
  requestBody: JsonObject,
  requestType: string,
  version = "v2"
) {
  // Remove Google Go's read aloud bot from traffic as this results in overcounting for traffic, without conversions.
  // Based on the evidence we could put together, this is run in conjunction with the actual browser, which itself
  // reports correctly.
  if (navigator.userAgent.includes("Google-Read-Aloud")) {
    return Promise.resolve();
  }

  return send("POST", `/pixel/${version}/i/${requestType}/`, requestBody, {
    params: prepareGrowthAPIParameters(),
  }).catch((e: any) => {
    captureException(e, "EventTracker-Failed to fetch");
  });
}

/**
 * Set people properties using the growth pixel.
 */
function pixelPeopleSet(properties: JsonObject) {
  const { userData } = getStore().getState();
  const people = JSON.stringify(properties);
  if (people === "{}") return;
  const requestBody = {
    mixpanel_distinct_id: userData.mixpanel_distinct_id,
    people,
  };
  sendPixelRequest(requestBody, "people_set");
}

export function updateUserContext(properties: JsonObject) {
  console.log("EventTracker", "updateUserContext", properties);
  pixelPeopleSet(properties);
}

export function setInitialPeopleProperties() {
  const { userData, geoLocation, language } = getStore().getState();
  const properties: JsonObject = {
    mixpanelDistinctId: userData.mixpanel_distinct_id,
    country: geoLocation.country_code,
    subdivision: geoLocation.subdivision,
    language,
  };
  if (USER_IS_BOT) properties.userIsBot = true;
  updateUserContext(properties);
}

/**
 * Returns the FullStory session url.
 */
function getFullStoryProperties() {
  try {
    return { "FullStory Session": window.FS.getCurrentSessionURL() };
  } catch (e) {
    return { fsError: e };
  }
}

/**
 * Collect client specific properties.
 */
function collectClientProperties() {
  let clientProperties: JsonObject = {};
  if (typeof window !== "undefined") {
    clientProperties.$screen_width = window.screen.width;
    clientProperties.$screen_height = window.screen.height;
  }
  clientProperties = { ...clientProperties, ...getFullStoryProperties() };
  return clientProperties;
}

/**
 * Collect properties about the user's visitor status using visitorStatus slice
 */
function collectVisitorStatusProperties() {
  const { visitorStatus } = getStore().getState();
  // Format properties with the proper prefix
  const properties: JsonObject = {};
  Object.keys(visitorStatus).forEach((item) => {
    properties[`visitorStatus_${item}`] = visitorStatus[item];
  });
  return properties;
}

function collectVisitProperties() {
  const urlParams = getTrackingParams();

  const utmParamsMapping = {
    utm_source: "UTM Source - Current",
    utm_medium: "UTM Medium - Current",
    utm_campaign: "UTM Campaign - Current",
    utm_content: "UTM Content - Current",
    utm_term: "UTM Term - Current",
    referrer: "Referrer URL",
  };
  const mixpanelProperties: JsonObject = {};
  Object.keys(urlParams).forEach((paramKey) => {
    if (paramKey in utmParamsMapping) {
      const mappingKey = paramKey as keyof typeof utmParamsMapping;
      mixpanelProperties[utmParamsMapping[mappingKey]] = urlParams[paramKey];
    } else {
      mixpanelProperties[paramKey] = urlParams[paramKey];
    }
  });
  return mixpanelProperties;
}

export function collectAppStateProperties() {
  try {
    const { routeId, upid, recommendedPlan, linearBuyflow } =
      getStore().getState();
    return {
      routeId,
      noomPlanId: recommendedPlan.noom_plan_id,
      isForcedPlan: recommendedPlan.isForcedPlan,
      userSelectedTrialFee: recommendedPlan.userSelectedTrialFee,
      pageSet: linearBuyflow.pageSetName,
      upid,
    };
  } catch (err) {
    return {};
  }
}

/**
 * Get optimizely properties that are stored in mixpanel_properties from serverContext.
 */
function getOptimizelyPropertiesFromMixpanelProperties() {
  const { serverContext } = getStore().getState();
  const mixpanelProperties = serverContext.mixpanel_properties || {};
  const properties: JsonObject = {};
  Object.keys(mixpanelProperties).forEach((property) => {
    if (/^Optimizely/.test(property)) {
      properties[property] = mixpanelProperties[property];
    }
  });
  return properties;
}

/**
 * Fetches active experiments from the Optimizely SDK available as a utility on
 * the window variable. It then formats optimizely experiments with the following
 * key value format:
 *
 * "Optimizely Experiment Name (Experiment Id): Variation Name (Variation Id)"
 */
function getOptimizelyPropertiesFromOptimizelySDK() {
  const properties: JsonObject = {};
  const optimizelySDK = window?.optimizely;
  if (optimizelySDK) {
    try {
      const experiments = optimizelySDK
        ?.get("state")
        ?.getExperimentStates({ isActive: true });

      if (experiments) {
        Object.values(experiments).forEach((value: any) => {
          const experiment = `Optimizely ${value.experimentName} (${value.id})`;
          const variation = `${value.variation.name} (${value.variation.id})`;
          properties[experiment] = variation;
        });
      }
    } catch (e) {
      console.warn(e);
    }
  }

  return properties;
}

/**
 * Get Optimizely properties from all sources.
 * @returns {Object}
 */
function getOptimizelyProperties() {
  const optimizelyPropertiesFromMixpanelProperties =
    getOptimizelyPropertiesFromMixpanelProperties();
  const clientOptimizelyProperties = getOptimizelyPropertiesFromOptimizelySDK();

  const allOptimizelyProperties = {
    ...optimizelyPropertiesFromMixpanelProperties,
    ...clientOptimizelyProperties,
  };

  return allOptimizelyProperties;
}

/**
 * Given an array of experiments, return an object with formatted experiment key.
 */
export function formatExperimentsStateString(
  experiments: ReadonlyDeep<ExperimentDefinition[]>
) {
  return experiments.reduce((acc, experiment) => {
    const payload = {
      ...acc,
      [`Meristem ${experiment.experimentName}`]: experiment.variationName,
    };
    const expVarPropertyName = `${experiment.experimentName}:${experiment.variationName}`;
    if (experiment.shaOverride)
      payload[`${expVarPropertyName} SHA Override`] = experiment.shaOverride;
    if (experiment.sha) {
      payload[`${expVarPropertyName} SHA`] = experiment.sha;
    }

    return payload;
  }, {} as JsonObject);
}

/**
 * Get meristem experimentName: variationName to be sent with events.
 * The formatting of the key:value is a legacy format following the original precedent
 * we set when tracking optimizely experiments but the formatting makes it
 * difficult to slice the data in Mixpanel.
 */
function getMeristemExperimentState() {
  const experimentStates = getStoredExperiments();
  if (experimentStates) {
    return formatExperimentsStateString(experimentStates);
  }
  return null;
}

/**
 * Format Meristem Experiments under the sha, experimentName, and variationName
 * property keys so we can more easily analyze experiment data in Mixpanel. This
 * utilizes list properties to contain the experiments in the case of multiple
 * experiments sharing a SHA.
 */
function getMeristemExperimentProperties() {
  const experimentStates = getStoredExperiments();
  if (experimentStates) {
    const payload = {
      meristemSha: getSha(),
      meristemExperiments: [],
      meristemVariations: [],
      meristemTreatment: "baseline",
      meristemContextType: getMeristemContext()?.context_type || null,
    };

    experimentStates.forEach((experiment) => {
      payload.meristemExperiments.push(experiment.experimentName);
      payload.meristemVariations.push(
        `${experiment.experimentName}/${experiment.variationName}`
      );
      payload.meristemTreatment =
        experiment.variationName === "control" ? "control" : "variation";
    });

    return payload;
  }
  return null;
}

/**
 * Format experiment state formatted as visit data.
 */
export function formatMeristemExperimentStateVisitData(
  experimentsState: ReadonlyDeep<ExperimentDefinition[]>
) {
  if (experimentsState && experimentsState.length > 0) {
    return {
      meristem_experiment: formatExperimentsStateString(experimentsState),
    };
  }
  return null;
}

/**
 * Collect SEO properties(search engine extracted from document.referrer)
 * We also should return empty dict if referrer contains certain words
 */
function collectSEOProperties() {
  try {
    const { referrer } = document;
    let searchEngine = null;
    if (
      referrer &&
      referrer.length > 0 &&
      !referrer.match(REFERRER_IGNORE_REGEX)
    ) {
      searchEngine =
        REFERRER_TO_SEARCH_ENGINE_MAPPING[
          typedKeys(REFERRER_TO_SEARCH_ENGINE_MAPPING).find((key) =>
            referrer.match(key)
          )
        ];
    }
    if (searchEngine) return { searchEngine };
    return {};
  } catch {
    return {};
  }
}

/**
 * Collect telehealth properties if in a /telehealth path.
 */
const collectTelehealthProperties = (): JsonObject => {
  try {
    const route = window.location.pathname.replace(/\//g, "");
    if (route !== routeConstants.telehealth) {
      return {};
    }

    const wheelConsultId = getNoomSessionStorage<string>(
      SessionStorageKey.wheelConsultId
    );
    const workflow = getNoomSessionStorage<string>(SessionStorageKey.workflow);
    const consultType = getNoomSessionStorage<ConsultType>(
      SessionStorageKey.consultType
    );
    const isNewPatient = !!getNoomSessionStorage<boolean>(
      SessionStorageKey.isNewPatient
    );
    const isTelehealthBuyflow = !!getNoomSessionStorage<boolean>(
      SessionStorageKey.isTelehealthBuyflow
    );

    return {
      isNewPatient,
      isTelehealthBuyflow,
      consultType,
      workflow,
      wheelConsultId,
    };
  } catch (err) {
    return {};
  }
};

// A place to cache our optimizely properties to compare for subsequent visit tracker calls.
let OPTIMIZELY_PROPERTY_CACHE = {};

const queuedPixels: [string, JsonObject][] = [];

/**
 * Track events using the growth pixel.
 */
function pixelTrackEvent(name: string, properties: JsonObject) {
  let userData;
  const geoLocation = getMeristemContext();
  const language = getMeristemContext().language_code;

  // Avoid going boom if this is called before redux is init.
  try {
    ({ userData } = getStore().getState());
  } catch (e) {
    /* NOP */
  }

  // Defer logs until we have init the user
  if (!userData?.mixpanel_distinct_id) {
    queuedPixels.push([name, properties]);
    return Promise.resolve();
  }

  const clientProperties = collectClientProperties();
  const visitorStatusProperties = collectVisitorStatusProperties();
  const optimizelyProperties = getOptimizelyProperties();
  const meristemExperimentState = getMeristemExperimentState();
  const meristemExperimentProperties = getMeristemExperimentProperties();

  const requestBody = {
    mixpanel_distinct_id: userData.mixpanel_distinct_id,
    currentUrl: document.URL,
    eventName: name,
    eventProperties: {
      country: geoLocation.country_code,
      subdivision: geoLocation.subdivision,
      language,
      ...clientProperties,
      ...visitorStatusProperties,
      ...optimizelyProperties,
      ...meristemExperimentState,
      ...meristemExperimentProperties,
      ...collectVisitProperties(),
      ...collectSEOProperties(),
      ...collectTelehealthProperties(),
      ...collectAppStateProperties(),
      ...properties,
    },
  };
  console.log(requestBody.eventProperties);

  // Maybe add the new_session property to mixpanel.
  // TODO(sumin): See if we can get rid of this.
  const { referrer } = document;
  if (
    window.active_session !== true &&
    referrer.match(REFERRER_MATCH) == null &&
    !isInApp()
  ) {
    requestBody.eventProperties.new_session = true;
    window.active_session = true;
  }

  // If there are new optimizely properties make sure to update the visit.
  if (!isEqual(OPTIMIZELY_PROPERTY_CACHE, optimizelyProperties)) {
    OPTIMIZELY_PROPERTY_CACHE = optimizelyProperties;
    visitTracker.updateRawData({
      optimizely_properties: optimizelyProperties,
    });
  }

  return sendPixelRequest(requestBody, "track", "v3");
}

function trackExperimentStartedEvent(
  experimentName: string,
  variationName: string
) {
  const eventName = "$experiment_started";
  const properties = {
    "Experiment name": `Meristem ${experimentName}`,
    "Variant name": variationName,
  };
  console.log("EventTracker", "experimentStarted", properties);
  return pixelTrackEvent(eventName, properties);
}

/**
 * Determines if the provided properties matches the given startEvent
 * and its filters (if there are any)
 */
export function matchesStartEventFilters(
  properties: JsonObject,
  startEvent: ReadonlyDeep<{ filters?: Targeting[] }>
) {
  let startEventValid = false;
  if (startEvent) {
    startEventValid = true;
    // Check if the properties passed in passes the provided filters
    // Each filter behaves as an additional AND conditional
    if (startEvent.filters) {
      for (let i = 0; i < startEvent.filters.length; i += 1) {
        const { key, operator, value } = startEvent.filters[i];
        if (operator === "IS SET") {
          startEventValid = Object.prototype.hasOwnProperty.call(
            properties,
            key
          );
        } else if (operator === "EQUALS") {
          startEventValid = properties[key] === value;
        } else if (operator === "IN") {
          startEventValid = value.includes(properties[key]);
        } else if (operator === "NOT EQUALS") {
          startEventValid = properties[key] !== value;
        } else if (operator === "NOT IN") {
          startEventValid = !value.includes(properties[key]);
        }
        if (!startEventValid) {
          // Break early if a filter does not match
          break;
        }
      }
    }
  }
  return startEventValid;
}

/**
 * Determine if we should fire off an Experiment Started tracking event based on the currently
 * enrolled experiment(s), eventName and properties
 */
function maybeTrackExperimentStarted(
  eventName: string,
  properties: JsonObject
) {
  // Experiments that the user is currently enrolled in
  const experimentStates = getStoredExperiments();
  if (experimentStates) {
    experimentStates.forEach((experimentState) => {
      const { experimentName, variationName } = experimentState;
      // Look for start event belonging to our current experiment that matches our eventName
      const startEvent = extractStartEvent(experimentName, eventName);
      const shouldFire = matchesStartEventFilters(properties, startEvent);
      if (shouldFire) {
        trackExperimentStartedEvent(experimentName, variationName);
      }
    });
  }
}

export function flushTrackerQueue() {
  const promises = queuedPixels.map(([name, properties]) =>
    pixelTrackEvent(name, properties)
  );
  queuedPixels.length = 0;
  return Promise.all(promises);
}

/**
 * Track a mixpanel event.
 */
export function trackEvent(eventName: string, properties: JsonObject = {}) {
  console.log("EventTracker", "trackEvent", eventName, properties);
  const experimentStartedTracker = maybeTrackExperimentStarted(
    eventName,
    properties
  );
  const eventTracker = pixelTrackEvent(eventName, properties);
  return Promise.all([eventTracker, experimentStartedTracker]);
}

export function trackSurveyAnswers(
  questionId: keyof SurveyAnswersState,
  answers: SurveyAnswersState
) {
  const questionIdCapitalized = capitalizeFirstLetter(`${questionId}`);

  trackEvent(`AnsweredQuestion${questionIdCapitalized}`, answers);
  updateUserContext(answers);
}

export function trackGotEmail(email: string) {
  // TODO(sumin): Remove this since we add email and name to people properties on the server-side.
  return trackEvent("GotEmail", {
    EnteredEmail: email,
  });
}
