/* eslint-disable no-restricted-globals */
import { CHECK_EMAIL_REMINDER_PARAM } from "@components/collapsible-header/CollapsibleHeader";
import getStore from "src/utils/redux/store";
import { reroll, setParam, synchronizeUpid } from "./utils";

export default {
  referral({ showEmailReminder }: { showEmailReminder?: boolean }) {
    const queryParams = new URLSearchParams(location.search);
    synchronizeUpid(queryParams, getStore());
    setParam(queryParams, CHECK_EMAIL_REMINDER_PARAM, showEmailReminder);
    reroll("/referral", queryParams);
  },
};
