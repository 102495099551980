import React, { useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import getStore from "@utils/redux/store";
import initializeApplication from "@utils/initialization";
import { trackEvent } from "@utils/api/tracker";
import { QuickSilver } from "./utils/qa/QuickSilver";

import "./styles/global.less";
import { MultiRouterHack } from "./hooks/historyHack";
import { getRouterComponents } from "./router";
import { useOnce } from "./hooks/lifecycle";
import getRedirects from "./redirects";
import { PageSetGate } from "./hooks/pageSet";
import { subscribePersistence } from "./utils/redux/persistence";
import { AppInitProvider } from "./AppInitGate";
import { CommonHead } from "./heads/Common";
import { SurveyContextProvider } from "./hooks/survey";

const appInitPromise = initializeApplication();

const App = () => {
  const [appInitialized, setAppInitialized] = useState(false);

  useOnce(() => {
    const init = async () => {
      subscribePersistence(getStore());
      await appInitPromise;

      // Finally allow application to render.
      setAppInitialized(true);

      trackEvent("OnAppInit");

      // After user being on the buyflow for at least 30 seconds, trigger mixpanel tracking for GoodClick
      setTimeout(() => {
        trackEvent("GoodClick");
      }, 30000);
    };
    init();
  });

  return (
    <BrowserRouter>
      <CommonHead />
      <MultiRouterHack />

      <AppInitProvider value={appInitialized}>
        <ReduxProvider store={getStore()}>
          <SurveyContextProvider>
            <PageSetGate>
              <Switch>
                {
                  // Note that we need to do the concat here vs. treating redirect and router components as
                  // their own components as Switch needs the elements to be direct children.
                  getRedirects().concat(getRouterComponents())
                }
              </Switch>

              <QuickSilver />
            </PageSetGate>
          </SurveyContextProvider>
        </ReduxProvider>
      </AppInitProvider>
    </BrowserRouter>
  );
};

export default App;
