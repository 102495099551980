import { PageCondition } from "./conditions";
import ACTIONS from "./actions";
import goto from "./goto";

export enum Direction {
  FORWARD = "FORWARD",
  BACKWARD = "BACKWARD",
  CURRENT = "CURRENT",
}

export type PageList = (Block | Survey | Page | SwitchCondition)[];

export interface PageSet {
  id: string;
  description?: string;
  pages: PageList;
  layer: string;
  conditions?: PageCondition[];
}

/** @notExported */
type InheritablePageParams = {
  conditions?: PageCondition[];
  actions?: Action[];
  // Hook that triggers when this block is passed over, ie conditions not met
  onSkip?: Action[];
  props?: PropertyBag & {
    /**
     * Numeric index of the progress bar this component is part of, if displayed.
     *
     * - 1 | 2 | 3 -> Progress bar section
     * - false -> No progress bar
     */
    progressBar?: number | false;
  };
};

/** @notExported */
type GoToActions = {
  [name in keyof typeof goto]?: Parameters<typeof goto[name]>;
};

export type Block = {
  block: PageList;

  // Hook that triggers at the exit of the block
  onComplete?: Action[];
} & InheritablePageParams;

export type Survey = Block & {
  name: string;
  surveyNameSpace: string;
  loggedInHeader?: boolean;

  // Hook that triggers for after each page in block
  onAnswered?: Action[];
} & InheritablePageParams;

export type Page = {
  pathname?: string;
  goto?: GoToActions;
} & InheritablePageParams;

export type SwitchCondition = {
  switch: string;
  cases: {
    [key: string]: Page;
    default?: Page;
  };
} & InheritablePageParams;

export interface Action {
  type: keyof typeof ACTIONS;
  params?: PropertyBag;
}
