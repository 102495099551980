/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import { isEnUS } from "@utils/userSegment";
import "./FooterPayment.less";
import { Text } from "../core/Text";
import { getMeristemContext } from "src/utils/meristemContext";

type Props = {
  contactTranslationKey?: string;
};

const FooterLink = ({
  href,
  children,
}: {
  href: string;
  children?: React.ReactNode;
}) => (
  <a href={href} className="footer-link" target="_blank" rel="noreferrer">
    {children}
  </a>
);

const footerLinks = {
  TocLink: (
    <FooterLink href="https://web.noom.com/terms-and-conditions-of-use/" />
  ),
  PrivacyLink: <FooterLink href="https://web.noom.com/noom-privacy-policy/" />,
  ResearchLink: <FooterLink href="https://web.noom.com/research-policy/" />,
  GooglePrivacyLink: <FooterLink href="https://policies.google.com/privacy" />,
  GoogleTocLink: <FooterLink href="https://policies.google.com/terms" />,
  SupportLink: (
    <FooterLink href="https://web.noom.com/support/contact-support" />
  ),
  EmailHMSupportLink: <FooterLink href="mailto:hmsupport@noom.com" />,
  EmailSupportLink: <FooterLink href="mailto:programsupport@noom.com" />,
};

const localeSpecificFooterLinks = {
  es: {
    TocLink: <FooterLink href="https://web2.noom.com/es/es-tos/" />,
    PrivacyLink: (
      <FooterLink href="https://web2.noom.com/es/es-privacy-policy/" />
    ),
    ResearchLink: (
      <FooterLink href="https://web2.noom.com/es/research-policy-spanish/" />
    ),
  },
  de: {
    TocLink: <FooterLink href="https://web2.noom.com/de/de-tos/" />,
    PrivacyLink: (
      <FooterLink href="https://web2.noom.com/de/de-privacy-policy/" />
    ),
    ResearchLink: (
      <FooterLink href="https://web2.noom.com/de/research-policy-german/" />
    ),
  },
  ja: {
    TocLink: <FooterLink href="https://web2.noom.com/jp/jp-tos/" />,
    PrivacyLink: (
      <FooterLink href="https://web2.noom.com/jp/jp-privacy-policy/" />
    ),
    EmailSupportLink: <FooterLink href="mailto:programsupport-ja@noom.com" />,
  },
};

// Merge locale-specific links with default links
const { language_code } = getMeristemContext();
const resolvedLinks = {
  ...footerLinks,
  ...localeSpecificFooterLinks[language_code],
};

/**
 * Footer shown on the Payment page
 */
function PaymentFooter({ contactTranslationKey = "contact" }: Props) {
  if (isEnUS()) {
    // Use copy without additional line breaks for contactWithoutTC only
    const translationKeyUSCore =
      contactTranslationKey === "contactWithoutTC"
        ? "contactWithoutTCCompact"
        : contactTranslationKey;
    return (
      // Use different styling when smartphone req footer is enabled
      <footer className="footer-with-smartphone-req">
        <Text
          parent="div"
          i18nKey={`paymentFooter:${translationKeyUSCore}`}
          components={resolvedLinks}
        />
      </footer>
    );
  }
  return (
    <footer className="footer">
      <Text
        parent="div"
        i18nKey={`paymentFooter:${contactTranslationKey}`}
        components={resolvedLinks}
      />
    </footer>
  );
}

export default PaymentFooter;
