import { addSurveyAnswers } from "@utils/redux/slices/surveyAnswers";
import { resolveValue } from "src/pageDefinitions/values";
import { trackEvent } from "src/utils/api/tracker";
import { logDebug } from "src/utils/logging";
import { loadWinbackInfo } from "src/utils/redux/slices/winbackInfo";
import { trackViewContentWithParamsExsf0 } from "src/utils/services/ConversionTracker";
import { ActionHandler, ActionHandlerParams } from "..";
import { getPageSetSurvey } from "./util";

type Params = {
  eventName?: string;
};

export const onStartPaymentSurvey: ActionHandler = ({
  pageSet,
  page,
  store,
  params = {},
}: {
  params?: Params;
} & ActionHandlerParams) => {
  const survey = getPageSetSurvey(pageSet, page);
  const eventName = params.eventName || `Started${survey.name}`;

  const { surveyNameSpace } = survey;
  if (surveyNameSpace && surveyNameSpace !== "surveyAnswers") {
    logDebug(`Loading survey answers from ${surveyNameSpace}`);
    const lookupValue = resolveValue(surveyNameSpace, store.getState());
    if (lookupValue) {
      store.dispatch(addSurveyAnswers(lookupValue));
    }
  }

  trackEvent(eventName);
  trackViewContentWithParamsExsf0({
    content_type: `FBMG-${eventName}`,
  });

  store.dispatch(loadWinbackInfo());
};
