import { trackEvent } from "@utils/api/tracker";
import { saveSurveyAnswers } from "src/utils/redux/slices/userData";
import { captureException } from "src/utils/sentry";
import { ActionHandlerParams } from "..";
import { getPageSetSurvey } from "./util";
import { trackViewContentWithParamsExsf0 } from "src/utils/services/ConversionTracker";
import invariant from "tiny-invariant";

type Params = {
  finishedEvent?: string;
  lead?: string;

  dateKey?: string;
  extraProps?: JsonObject;
};

export const onCompletePaymentSurvey = async ({
  params = {},
  store,
  pageSet,
  page,
}: {
  params?: Params;
} & ActionHandlerParams) => {
  const survey = getPageSetSurvey(pageSet, page);
  const finishedEvent = params.finishedEvent || `Finished${survey.name}`;

  const { surveyNameSpace } = survey;
  const { surveyAnswers } = store.getState();

  // saveSurveyAnswers stores in userData slice. This check ensures that the JSON config
  // will properly load that data in the future/on refresh.
  invariant(
    surveyNameSpace.startsWith("userData."),
    "surveyNameSpace must start with userData for this action."
  );

  try {
    await store.dispatch(
      saveSurveyAnswers(surveyAnswers, {
        surveyNameSpace: surveyNameSpace.replace("userData.", ""),
        dateKey: "purchaseSurveyCompleteDateISO",
      })
    );

    trackEvent(finishedEvent, surveyAnswers);
    trackViewContentWithParamsExsf0({
      content_type: `FBMG-${finishedEvent}`,
    });
  } catch (e) {
    // TODO: Tag and throw or log and throw?
    captureException(e, "onCompletePurchaseSurvey");
    throw e;
  }
};
