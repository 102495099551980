/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MouseEventHandler } from "react";
import { QuestionOption } from "src/components/refactored-survey/QuestionDefinition";
import { questionOption } from "@components/refactored-survey/question-types/Question.module.less";
import {
  radioQuestionOption,
  checkedWithInput,
} from "@components/refactored-survey/question-types/RadioQuestion.module.less";

interface RadioQuestionOptionsProps {
  options: QuestionOption[];
  questionId: string;
  getQuestionAnswerKey: () => string;
  handleClick: MouseEventHandler<HTMLElement>;
}

function RadioQuestionOptions({
  options,
  questionId,
  getQuestionAnswerKey,
  handleClick,
}: RadioQuestionOptionsProps) {
  return (
    <>
      {options.map((option) => (
        <label
          key={option.id}
          htmlFor={option.id}
          className={`${questionOption} ${radioQuestionOption} ${
            option.id === getQuestionAnswerKey() ? checkedWithInput : ""
          }`}
          onClick={handleClick}
        >
          <input
            type="radio"
            defaultValue={option.id}
            name={questionId}
            id={option.id}
            defaultChecked={option.id === getQuestionAnswerKey()}
            onClick={handleClick}
          />
          {option.text}
        </label>
      ))}
    </>
  );
}

export default RadioQuestionOptions;
