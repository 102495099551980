import { getDayDelta } from "../datetime";
import {
  REGULAR_WEIGHT_LOSS_PACING,
  STEEPER_WEIGHT_LOSS_PACING,
} from "./weightPacing";

/**
 * Utility function to calculate the date which a target weight will be reached.
 * Throws error if date will not be reached during plan.
 */
export function getTargetWeightDate(
  startWeight: number,
  endWeight: number,
  targetWeight: number,
  planDuration: number,
  isSteepPacing = false
): Date | undefined {
  if (targetWeight > startWeight || targetWeight < endWeight) {
    return undefined;
  }

  const targetDate = new Date();
  const pacing: number[] | undefined = isSteepPacing
    ? STEEPER_WEIGHT_LOSS_PACING[planDuration]
    : REGULAR_WEIGHT_LOSS_PACING[planDuration];

  if (!pacing) {
    return targetDate;
  }

  const totalWeightLossGoal = startWeight - endWeight;
  const monthlyWeightLossGoal = totalWeightLossGoal / planDuration;

  let weight = startWeight;
  for (let i = 0; i <= planDuration; i++) {
    const currentMonthyGoal = pacing[i] * monthlyWeightLossGoal;

    // target weight reached i months into plan
    if (weight - currentMonthyGoal < targetWeight) {
      // Offset month by 1 to account for starting month in pacing
      targetDate.setMonth(targetDate.getMonth() + i - 1);

      const nextMonthDate = new Date(targetDate);
      nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

      const numDays = getDayDelta(targetDate, nextMonthDate);
      const dailyGoal = currentMonthyGoal / numDays;

      let days = 0;
      while (weight > targetWeight) {
        weight -= dailyGoal;
        days += 1;
      }

      targetDate.setDate(targetDate.getDate() + days);
      return targetDate;
    }

    weight -= currentMonthyGoal;
  }

  targetDate.setMonth(targetDate.getMonth() + planDuration);
  return targetDate;
}
