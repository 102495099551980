/* eslint-disable no-console */
import Cookies from "js-cookie";
import { send } from "@utils/fetch";
import { captureException } from "@utils/sentry";
import isEmpty from "lodash/isEmpty";
import { isEU, isFromEmail, isUS } from "@utils/userSegment";
import { prepareDataForBackend, calculateTruePace } from "@utils/pace";
import { setSurveyNameSpace } from "./surveyNameSpace";
import { addSurveyAnswers, SurveyAnswersState } from "./surveyAnswers";
import { updateVisitorStatusState } from "./visitorStatus";
import { timestamp, timezone, toISO } from "src/utils/datetime";
import { ServerContextState } from "./serverContext";
import { flushTrackerQueue } from "src/utils/api/tracker";
import { isEmail } from "src/utils/validate";
import {
  isDevMode,
  isInAppWebPurchaseEligible,
} from "src/utils/userSegment/features";

import type { AppDispatch, GetAppState } from "@utils/redux/store";

export type UserDataState = {
  user_id?: string;
  mixpanel_distinct_id?: string;
  name?: string;
  email?: string;
  country?: string;

  vip?: boolean;

  weight?: number;
  heightFeet?: number;
  heightInch?: number;

  // Enum of surveys below
  surveyNameSpace?:
    | "personaSurveyAnswers"
    | "personaSurveyUS"
    | "personaSurveyNonUS"
    | "personaSurveyHM"
    | "purchaseSurveyAnswers"
    | "purchaseSurveyAnswersHM";

  personaSurveyAnswers?: SurveyAnswersState;
  personaSurveyUS?: SurveyAnswersState;
  personaSurveyNonUS?: SurveyAnswersState;
  personaSurveyHM?: SurveyAnswersState;

  purchaseSurveyAnswers?: SurveyAnswersState;
  purchaseSurveyAnswersHM?: SurveyAnswersState;

  zip_tax_state?: string;

  language?: string;

  growthPlanDuration?: number;

  access_code?: string;
  last_login_device_OS?: string;

  force_merge?: boolean;
  upsell?: {
    products: string[];
  };
};

/**
 * Action types
 */
export const UPDATE_USER_DATA = "userData/updateUserData";

interface UserDataAction {
  type: typeof UPDATE_USER_DATA;
  userData: Partial<UserDataState>;
}

/**
 * Action
 * Updates the store with new userdata
 */
export function updateUserData(userData: UserDataState): UserDataAction {
  return {
    type: UPDATE_USER_DATA,
    userData,
  };
}

/**
 * The Django API uses the `_userId` cookie for some purposes. This utility helps
 * us set the cookie whenever its updated.
 */
function setUserIdCookie(userId: string, serverContext: ServerContextState) {
  if (!userId) return;
  const cookiedUserId = Cookies.get("_userId");
  if (cookiedUserId !== userId) {
    Cookies.set("_userId", userId, {
      domain:
        process.env.NODE_ENV !== "production"
          ? undefined
          : serverContext.session_cookie_domain,
    });
  }
}

/**
 * Thunk
 */
export function fetchAndInitializeExistingUser(userId: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetchExistingUser(userId);
      await dispatch(initializeExistingUser(response));
    } catch (e) {
      captureException(e, "UserDataLoad-Failed to fetch");
    }
  };
}

export async function fetchExistingUser(userId: string) {
  console.log(`loading => users/${userId}`);
  const json = await send("POST", `/userdata/api/v4/user/data/`, {
    clientUserId: userId,
  });
  // Make sure the user has a userId field set in their userData.
  const userData: UserDataState = { ...json.data };
  if (!userData.user_id) {
    userData.user_id = userId;
  }
  if (!userData.mixpanel_distinct_id) {
    userData.mixpanel_distinct_id = userId;
  }
  return userData;
}

export function initializeExistingUser(userData: UserDataState) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    dispatch(updateUserData(userData));

    setUserIdCookie(userData.user_id, getState().serverContext);
    const mainSurveyNameSpace =
      userData?.surveyNameSpace ||
      (isUS() ? "personaSurveyUS" : "personaSurveyNonUS");
    const backendMainSurveyAnswers = userData?.[mainSurveyNameSpace] || {};

    dispatch(setSurveyNameSpace(mainSurveyNameSpace));
    if (!isEmpty(backendMainSurveyAnswers)) {
      dispatch(addSurveyAnswers(backendMainSurveyAnswers));
    }

    // This needs to be done after we receive the mixpanel id above, otherwise
    // these requests will fail.
    await flushTrackerQueue();
  };
}

export function hasValidAccount(userData?: UserDataState) {
  if (!userData) return false;
  const { email } = userData;
  const emailValid = email && isEmail(email);
  // Depends only on statics (geo, params) so this is safe even if we aren't yet initialized
  const allowInAppPurchase = isInAppWebPurchaseEligible();
  const isAllowedThrough = emailValid || isDevMode() || allowInAppPurchase;
  return isAllowedThrough;
}

/**
 * Thunk
 */
export function initializeNewUser(serverContext: ServerContextState) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    const data = {
      mixpanel_distinct_id: serverContext.distinct_id,
      user_id: serverContext.user_id,
    };
    dispatch(updateUserData(data));

    setUserIdCookie(serverContext.user_id, getState().serverContext);

    const nameSpace = isUS() ? "personaSurveyUS" : "personaSurveyNonUS";
    dispatch(setSurveyNameSpace(nameSpace));

    // This needs to be done after we receive the mixpanel id above, otherwise
    // these requests will fail.
    flushTrackerQueue();

    return Promise.resolve(data);
  };
}

/**
 * Thunk
 * Generic save user data call, updates entire userdata object.
 */
export function setUserProperties(properties: Partial<UserDataState>) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    return submitUserDataUpdate(dispatch, getState, "save", properties);
  };
}

/**
 * Thunk
 * Updates the main survey answers.
 */
export function saveMainSurveyAnswers(
  surveyAnswers: SurveyAnswersState,
  {
    surveyNameSpace,
    dateKey = "mainSurveyCompleteDateISO",
    extraProps,
  }: {
    surveyNameSpace?: string;
    dateKey?: string;
    extraProps?: JsonObject;
  } = {}
) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    const state = getState();

    const timezoneName = timezone();
    const timestampString = `${timestamp()}[${timezone()}]`;

    const properties: JsonObject = {
      name: surveyAnswers.name || "",

      clientTimestamp: timestampString,
      timezone: timezoneName,
      isEUCitizen: isEU(state),
      [dateKey]: toISO(),

      surveyNameSpace: surveyNameSpace || state.surveyNameSpace,
      [surveyNameSpace || state.surveyNameSpace]: surveyAnswers,
      ...extraProps,
    };

    // NOTE(patrick): If we pass an empty string email to the back end, userdata merge
    //                will throw a 500 error.
    const email = surveyAnswers.email || "";
    if (email) {
      properties.email = email;
      properties.latestMixPanelAlias = email;
    }

    if (state.geoLocation.country_code != null && state.language != null) {
      properties.language = state.language;
      properties.country = state.geoLocation.country_code;
    }

    if (state.geoLocation.subdivision != null) {
      properties.subdivision = state.geoLocation.subdivision;
    }

    if (state.geoLocation?.postal_code) {
      properties.postalCode = state.geoLocation.postal_code;
    }

    if (surveyAnswers.gdprConsent != null) {
      properties.consent = surveyAnswers.gdprConsent;
    }

    properties.routeId = state.routeId;

    return submitUserDataUpdate(
      dispatch,
      getState,
      email ? "saveMainSurvey" : "save",
      properties
    );
  };
}

export function saveSurveyAnswers(
  surveyAnswers: SurveyAnswersState,
  {
    surveyNameSpace,
    dateKey,
    extraProps,
  }: {
    surveyNameSpace: string;
    dateKey: string;
    extraProps?: JsonObject;
  }
) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    const state = getState();

    const properties: JsonObject = {
      [dateKey]: toISO(),

      [surveyNameSpace]: surveyAnswers,
      ...extraProps,
    };

    if (state.geoLocation.country_code != null && state.language != null) {
      properties.language = state.language;
      properties.country = state.geoLocation.country_code;
    }

    if (state.geoLocation.subdivision != null) {
      properties.subdivision = state.geoLocation.subdivision;
    }

    return submitUserDataUpdate(dispatch, getState, "save", properties);
  };
}

/**
 * Thunk
 */
export function saveProfile(properties: { email: string; name: string }) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    return submitUserDataUpdate(dispatch, getState, "saveProfile", properties);
  };
}

/**
 * Thunk
 */
export function saveCareersNewsletter(properties: JsonObject) {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    return submitUserDataUpdate(
      dispatch,
      getState,
      "saveCareersNewsletter",
      properties
    );
  };
}

/**
 * Utility function used by the thunks to execute the api call. It requires
 * the dispatch function generated by the thunks to update the store and state
 * when the updated userData is received from the server.
 */
function submitUserDataUpdate(
  dispatch: AppDispatch,
  getState: GetAppState,
  saveType: string,
  properties: JsonObject
) {
  const { userData, serverContext, surveyAnswers } = getState();
  const pace = calculateTruePace(surveyAnswers);
  let payload = properties;
  payload.lastUpdated = Date.now();
  payload.clientUserId = serverContext.user_id;

  // Extract and pass in certain values needed for app/CS as part of userData
  const data = prepareDataForBackend(pace, surveyAnswers);
  payload = { ...data, ...payload };

  console.log(`saving => users/${userData.user_id}`, payload);
  return send("POST", `/userdata/api/v4/user/data/${saveType}/`, payload)
    .then((json) => {
      const newUserData = { ...json.data };
      if (json?.user_id) newUserData.user_id = json.user_id;
      if (json?.distinct_id)
        newUserData.mixpanel_distinct_id = json.distinct_id;
      dispatch(updateUserData(newUserData));

      // In the case where the backend user was freshly created, then we do not learn anything
      // new about the user. Therefore, only fire off tracking event if not new user.
      // The exception is when the user is on an email route. In that case, treat any new
      // user created events as new information
      // This prevents multiple unnecessary visitorState tracking events
      const isNewUser = json?.is_new_user || false;
      if (!isNewUser || isFromEmail()) {
        dispatch(
          updateVisitorStatusState({
            user_id: newUserData.user_id,
            email: userData.email,
            is_new_user: isNewUser,
          })
        );
      }

      setUserIdCookie(json?.user_id || newUserData.user_id, serverContext);

      console.log("Got user_id from server", json.user_id);
      console.log("Got distinct_id from server", json.distinct_id);
    })
    .catch((e: any) => {
      captureException(e, "UserDataSave-Failed to fetch");
    });
}

function userDataReducer(
  state: UserDataState,
  action: UserDataAction
): UserDataState {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return { ...state, ...action.userData };
    default:
      return state || {};
  }
}

export default userDataReducer;
