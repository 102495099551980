import getStore from "@utils/redux/store";
import { getMeristemContext } from "src/utils/meristemContext";
import { registerHandler } from "../handler";

declare const jumbleberry: any;

function isEligibleForJumbleberryPixel(): boolean {
  const { country_code: countryCode } = getMeristemContext();
  return ["US", "CA", "NZ", "AU"].includes(countryCode);
}

// Owning PM for jumbleberry pixel [Kenneth]
export default function registerHandlers() {
  registerHandler(
    "init",
    (): void => {
      const { country_code: countryCode } = getMeristemContext();
      const isAUorNZ = ["AU", "NZ"].includes(countryCode);
      const isUSorCA = ["US", "CA"].includes(countryCode);

      if (typeof jumbleberry !== "undefined" && isUSorCA) {
        jumbleberry(
          "init",
          "5M7RBLplphzmjxBDK-pgPzoyM5W3VT6PdmfKkrFQ02mVK2NkzAToFgPM37MvIbp1P_-lqgPToe3hK5GtonLZjw~~",
          jumbleberry.g("click_id")
        );
      } else if (typeof jumbleberry !== "undefined" && isAUorNZ) {
        jumbleberry(
          "init",
          "OIJAD1-n09dBE0zZajLaLL3wlyzfGOavSpHCT91z9A6_tbbumP3B4GpXeBbzOiq7M4dCJYW2yDDh-jAnIQGXPQ~~",
          jumbleberry.g("click_id")
        );
      }
    },
    { retry: false }
  );

  registerHandler("lead", () => {
    if (isEligibleForJumbleberryPixel()) {
      jumbleberry("track", "Lead", {
        value: "healthy-weight",
      });
    }
  });

  registerHandler("initiateCheckout", ({ plan }) => {
    if (isEligibleForJumbleberryPixel()) {
      const { promoCode } = getStore().getState();
      jumbleberry("track", "InitiateCheckout", {
        value: plan.lv,
        currency: plan.currency ? plan.currency : "USD",
        discount_code: promoCode.promoCodeApplied,
      });
    }
  });

  registerHandler("purchase", ({ order, plan }) => {
    jumbleberry("track", "Purchase", {
      order_value: order.eltv_13_months,
      currency: plan.currency ? plan.currency : "USD",
      transaction_id: order.subscriptionId,
    });
  });
}
