/* eslint-disable no-restricted-globals */

import { getMeristemContext, setContextType } from "src/utils/meristemContext";
import getStore from "src/utils/redux/store";
import { captureException } from "src/utils/sentry";
import { getSearchQuery } from "src/utils/urlParams";
import { isHM, isInApp } from "src/utils/userSegment";
import {
  isDevMode,
  isInAppNativePurchaseEligible,
  isInAppWebPurchaseEligible,
} from "src/utils/userSegment/features";
import goto from ".";
import { getPageSetForLayer } from "../pageSets";
import { isBuyflow } from "./buyflow";
import {
  isInsideAndroidWebView,
  isInsideIOSWebKit,
  pushReroll,
  setParam,
} from "./utils";
import { setRecommendPlanById } from "../../utils/redux/slices/recommendedPlan";
import { clearPromoCode } from "src/utils/redux/slices/promoCode";
import { TELEHEALTH_PLAN } from "src/funnels/payment-en/pages/telehealthRedirects";

declare const Android: any;

// "/purchase/:planId"
// "/purchase/(en|es|de)/:planId"
// "/purchase-hm/:planId"
function isPurchase() {
  return getMeristemContext().context_type === "payment";
}

// Checks redux to see if there is a forced plan. Return that plan id if it exists,
// return the passed in plan id otherwise
function getFinalPlanId(planId: string) {
  const { recommendedPlan } = getStore().getState();
  const finalPlanId = recommendedPlan.isForcedPlan
    ? recommendedPlan.noom_plan_id
    : planId;
  return finalPlanId;
}

function purchaseRoot(planId?: string) {
  const state = getStore().getState();
  const { language, userData, geoLocation, routeId, recommendedPlan } = state;

  const root = `/purchase${isHM(state) ? "-hm" : ""}`;

  const pathMatch = /^\/purchase(?:-hm)?(?:\/(?:en|es|de))?\/(.*)/.exec(
    location.pathname
  );

  const finalPlanId = planId || recommendedPlan.noom_plan_id || pathMatch?.[1];

  /* istanbul ignore next sanity */
  if (!finalPlanId) {
    throw new Error("No plan id provided");
  }

  let purchaseUrl = isHM(state) ? `${root}/` : `${root}/${language || "en"}/`;
  purchaseUrl += finalPlanId;

  const queryParams = new URLSearchParams();

  // Add route to purchase page url params since cookies don't work 100% of the time.
  setParam(queryParams, "route", routeId);

  // Add fcc if exists, for the in-app buyflows, in order to enforce location based on device locale.
  if (geoLocation?.is_forced_country_code) {
    setParam(queryParams, "fcc", geoLocation.country_code);
  }

  const currentUrlParams = new URLSearchParams(window.location.search);
  currentUrlParams.forEach((value, key) => {
    queryParams.set(key, value);
  });

  // (justin) params not necessary if not in telehealth flow
  queryParams.delete("userId");
  queryParams.delete("noomPlanId");

  if (isInApp()) {
    setParam(queryParams, "email", userData.email);
  }

  if (isDevMode()) {
    setParam(queryParams, "idm", true);
  }
  return `${purchaseUrl.replace(/\/$/, "")}/${getSearchQuery(queryParams)}`;
}

export default {
  async purchaseSurvey(planId?: string, question?: string) {
    const finalPlanId = getFinalPlanId(planId);
    if (finalPlanId) {
      await getStore().dispatch(setRecommendPlanById(finalPlanId, true));
      await getStore().dispatch(clearPromoCode());
    }

    // Since we don't reroll going into payment funnel at this point,
    // we need to reset the context type to ensure we load the appropriate
    // server context on nav.
    if (isBuyflow()) {
      setContextType("payment");
    }

    const pageSet = await getPageSetForLayer(
      "payment-survey",
      getStore().getState()
    );
    if (pageSet) {
      await goto.pageSet(pageSet.id);
      return;
    }

    const url = `${purchaseRoot(finalPlanId)}#/createAccount${
      question ? `/${question}` : ""
    }`;
    pushReroll(url, () => isPurchase());
  },

  checkout() {
    // Temporary stub for payment-checkout pagesets
    // TODO: Put this all into the page set
    if (isInApp() && !isInAppWebPurchaseEligible()) {
      goto.nativePurchase();
    } else {
      goto.buildPlan();
    }
  },

  async welcomeToNoom(planId?: string) {
    const finalPlanId = getFinalPlanId(planId);
    if (finalPlanId) {
      await getStore().dispatch(setRecommendPlanById(finalPlanId, true));
    }
    pushReroll(`${purchaseRoot(finalPlanId)}#/welcomeToNoom`, isPurchase);
  },
  account() {
    pushReroll(`${purchaseRoot()}#/account`, isPurchase);
  },
  async finalUpdateGraph(planId?: string) {
    const finalPlanId = getFinalPlanId(planId);
    if (finalPlanId) {
      await getStore().dispatch(setRecommendPlanById(finalPlanId, true));
    }
    pushReroll(`${purchaseRoot(finalPlanId)}#/finalUpdateGraph`, isPurchase);
  },
  buildPlan() {
    pushReroll(`${purchaseRoot()}#/buildPlan`, isPurchase);
  },
  async chooseTrialPrice(planId?: string) {
    const finalPlanId = getFinalPlanId(planId);
    if (finalPlanId) {
      await getStore().dispatch(
        setRecommendPlanById(finalPlanId, !isPurchase())
      );
    }
    pushReroll(`${purchaseRoot(finalPlanId)}#/chooseTrialPrice`, isPurchase);
  },
  payment() {
    pushReroll(`${purchaseRoot()}#/payment`, isPurchase);
  },
  async paymentDetails(planId?: string) {
    const finalPlanId = getFinalPlanId(planId);
    if (finalPlanId) {
      await getStore().dispatch(setRecommendPlanById(finalPlanId, true));
    }
    pushReroll(`${purchaseRoot(finalPlanId)}#/details`, isPurchase);
  },
  async telehealthPaymentDetails() {
    await getStore().dispatch(setRecommendPlanById(TELEHEALTH_PLAN, true));

    pushReroll(`${purchaseRoot(TELEHEALTH_PLAN)}#/details`, isPurchase);
  },
  programSwitchDetails() {
    pushReroll(`${purchaseRoot()}#/programSwitchDetails`, isPurchase);
  },
  programSwitchConfirmation() {
    pushReroll(`${purchaseRoot()}#/programSwitchConfirmation`, isPurchase);
  },
  stickToHWConfirmation() {
    pushReroll(`${purchaseRoot()}#/stickToHWConfirmation`, isPurchase);
  },

  nativePurchase() {
    if (isInsideIOSWebKit()) {
      try {
        // NOTE(norbert): the buyflowPurchaseNoDetails endpoint was only introduced
        // in the iOS 9.14 release, so we need to check if it exists before calling it
        if (
          isInAppNativePurchaseEligible() &&
          (window as any).webkit.messageHandlers.buyflowPurchaseNoDetails
        ) {
          // If app and route support web purchase, use that
          goto.buildPlan();
        } else {
          (window as any).webkit.messageHandlers.buyflowPurchase.postMessage(
            {}
          );
        }
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativePurchase.ios");
      }
    }

    if (isInsideAndroidWebView()) {
      try {
        Android.buyflowPurchase();
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativePurchase.android");
      }
    }

    // eslint-disable-next-line no-console
    console.error("nativePurchase: no message handler found");

    return false;
  },
  nativePurchaseNoDetails() {
    if (
      isInAppNativePurchaseEligible() &&
      (window as any).webkit?.messageHandlers?.buyflowPurchaseNoDetails
    ) {
      try {
        (
          window as any
        ).webkit.messageHandlers.buyflowPurchaseNoDetails.postMessage({});
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativePurchaseNoDetails.ios");
      }
    }

    // eslint-disable-next-line no-console
    console.error("nativePurchaseNoDetails: no message handler found");

    return false;
  },
  nativePurchaseComplete() {
    if (isInsideIOSWebKit()) {
      try {
        (
          window as any
        ).webkit.messageHandlers.buyflowPurchaseCompleted.postMessage({});
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativePurchaseComplete.ios");
      }
    }
    if (isInsideAndroidWebView()) {
      try {
        Android.buyflowPurchaseCompleted();
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativePurchaseComplete.android");
      }
    }

    // eslint-disable-next-line no-console
    console.error("nativePurchaseComplete: no message handler found");
    return false;
  },
};
