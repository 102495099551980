import { deviceOS, routeConstants } from "@utils/constants";
import { checkSegments } from "./util";
import { isInApp } from "./in-app";
import { isEmailWinback } from "./email";
import { isEnLanguage, isEnUS, isUS } from "./locale";
import { CoreReduxState } from "../redux/store";
import { getMeristemContext } from "../meristemContext";

export interface SegmentFunction {
  (state?: CoreReduxState): boolean;
  load?: (store?: CoreReduxState) => Promise<void>;
}

// Baseline/control routes
export const isCore = checkSegments({
  oneOf: [
    routeConstants.exsf01,
    routeConstants.exsf01d,
    routeConstants.awsem,
    routeConstants.direct,
  ],
});
export const isAffiliateWithoutTrial = checkSegments({
  oneOf: [
    routeConstants.affiliateNoTrial,
    routeConstants.affiliateNoTrialPromo,
  ],
});
export const isBaseline = checkSegments({
  oneOf: [isCore, isAffiliateWithoutTrial],
});

export const isCoreDirect = checkSegments({
  oneOf: [routeConstants.direct, routeConstants.baseline],
});

export const isDirect = checkSegments({
  oneOf: [routeConstants.direct],
});

export const isCorePaid = checkSegments({
  oneOf: [routeConstants.exsf01, routeConstants.exsf01d, routeConstants.awsem],
});
export const isAdWordsSEM = checkSegments({
  oneOf: [routeConstants.awsem],
});
export const isEnUSAdWordsSEM = checkSegments({
  allOf: [isEnUS],
  oneOf: [isAdWordsSEM],
});

export const isWinback = checkSegments({
  oneOf: [routeConstants.winback],
});
export const isCoreWinback = checkSegments({
  oneOf: [isEmailWinback, isWinback],
});

export const isHM = checkSegments({
  oneOf: [
    routeConstants.hmDirect,
    routeConstants.hmExsf01,
    routeConstants.hmExsf01d,
    routeConstants.hmPaid,
    routeConstants.hmAwsem,
  ],
});

export const isPreSignup = () =>
  ["landing", "main-survey", "payment"].includes(
    getMeristemContext().context_type
  );

export * from "./locale";
export * from "./demographics";
export * from "./email";
export * from "./in-app";

export const isMobile = () => {
  return !!getMeristemContext().device_is_mobile;
};

export const isIOS = () => {
  return getMeristemContext().device_os === deviceOS.iOS;
};

// Old style

/**
 * Verifies if current user is on main routes and is from US with EN language
 */
export const isEnUSBaseline = checkSegments({
  allOf: [isEnUS],
  oneOf: [isBaseline],
});

/**
 * Verifies if current user is on winback route and is from US with EN language
 */
export const isEnUSWinback = checkSegments({
  allOf: [isEnUS],
  oneOf: [isWinback],
});

/**
 * Verifies if current user is on (winback or baseline/main routes) and is from US with EN language
 */
export const isEnUSBaselineOrWinback = checkSegments({
  allOf: [isEnUS],
  oneOf: [isBaseline, isWinback],
});

/**
 * Verifies if current user is on (core-winback or baseline/main routes) and is from US with EN language
 */
export const isEnUSBaselineOrCoreWinback = checkSegments({
  allOf: [isEnUS],
  oneOf: [isBaseline, isCoreWinback],
});

/**
 * Verifies if current user is on main routes and with EN language
 */
export const isEnBaseline = checkSegments({
  allOf: [isEnLanguage],
  oneOf: [isBaseline],
});

/**
 * Verifies if current user is on main routes and with EN language and not US
 */
export const isEnIntlBaseline = () => {
  return isEnBaseline() && !isUS();
};
/**
 * Verifies that the user is on the English US buyflow on the web (not inapp)
 */
export const isEnUSWeb = (state?: CoreReduxState) =>
  isEnUS(state) && !isInApp(state);
