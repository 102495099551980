import { useOnce } from "src/hooks/lifecycle";
import { useHistory } from "react-router-dom";
import { scrollToTop } from "src/utils/scroll";

const ScrollToTop = () => {
  const history = useHistory();

  useOnce(() => {
    return history.listen(() => {
      scrollToTop();
    });
  });

  return null as JSX.Element;
};

export default ScrollToTop;
