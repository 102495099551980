import React from "react";
import i18n from "@i18n";
import Button from "@components/button/Button";
import styles from "./Controls.module.less";
import { NavButtonConfig } from "./QuestionDefinition";
import { Text } from "../core/Text";

interface ControlsProperties {
  next?: NavButtonConfig;

  isLoading?: boolean;
  canProceed?: boolean;

  /** Callback handler executed when the user clicks the next button. */
  onClickNext?: () => void;

  hideControls?: boolean;
  useStickyControls?: boolean;
  "data-cy"?: string;
}
const Controls = ({
  next = { text: i18n.t("survey-controls-2:next") },

  isLoading,
  canProceed,

  onClickNext,
  hideControls = false,
  useStickyControls = false,
  "data-cy": dataCy = "survey-next",
}: ControlsProperties) => {
  if (hideControls || (next && next.disabled)) {
    return null;
  }

  const shouldUseStickyControls = () => {
    if (canProceed && useStickyControls) {
      return styles.sticky;
    }
    return "";
  };

  return (
    <>
      <div className={`${styles.controls} ${shouldUseStickyControls()}`}>
        <Button
          type={onClickNext ? "button" : "submit"}
          onClick={onClickNext}
          disabled={!canProceed || isLoading}
          data-cy={dataCy}
        >
          <Text i18nKeyOrText={next.text} />
        </Button>
      </div>
      {/* pad bottom of page when button is sticky. */}
      {shouldUseStickyControls() && (
        <div className={styles.controlsPlaceholder} />
      )}
    </>
  );
};

export default Controls;
