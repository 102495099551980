import { createAsyncThunk } from "@reduxjs/toolkit";
import i18n from "src/locales";
import { setPromoCode } from "@utils/redux/slices/promoCode";
import saveToBraze from "../brazeUploader";
import { format, shift_days, toISO } from "../datetime";
import { getOptimizelyExperiments } from "../experiment";
import { send } from "../fetch";
import { getDefaultTrialFee } from "../plans";
import { paymentTypes, updateCheckoutState } from "../redux/slices/checkout";
import { Plan } from "../redux/slices/plans";
import {
  RecommendedPlanState,
  updateRecommendedPlan,
} from "../redux/slices/recommendedPlan";
import { ServerContextState } from "../redux/slices/serverContext";
import { AppDispatch, CoreReduxState, GetAppState } from "../redux/store";
import { captureException } from "../sentry";
import { prepareGrowthAPIParameters } from "../services/api-params";
import {
  trackPurchase,
  trackViewContentWithParamsExsf0,
} from "../services/ConversionTracker";
import { errorConstants } from "../services/PurchaseErrorMessages";
import { isCA, isEnIntl, isInApp, isIOS } from "../userSegment";
import {
  isCollapsedPaymentEligible,
  isInAppWebPurchaseEligible,
  showChooseTrialPage,
} from "../userSegment/features";
import { trackEvent } from "./tracker";
import { getReferralCode } from "@utils/api/referrals";
import { decoratePurchaseRequestWithProductCatalog } from "@utils/redux/slices/productPlan";
import { getCountryCode } from "../meristemContext";
import { postcodeValidator } from "postcode-validator";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { UserDataState } from "@utils/redux/slices/userData";

const PROMO_CODE_VALIDATION_URL = "/discounts/api/v1/validate/";
const SALES_TAX_CALCULATION_URL = "/api/tax";

export type SalesTaxAPIResponse = {
  is_tax_inclusive: boolean;
  plan: Plan;
  sales_tax_amount: number;
  sales_tax_state: string;
  sales_tax_rate: number;
};

export function validatePromoCode(promoCode: string) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    try {
      trackEvent("RedeemingPromoCode", {
        promoCode,
      });
      const {
        checkout,
        promoCode: promoCodeSlice,
        recommendedPlan,
        geoLocation,
        serverContext,
        surveyAnswers,
      } = getState();
      const params: JsonObject = {
        noom_plan_id: recommendedPlan.noom_plan_id,
        code: promoCode,
        trialFee:
          recommendedPlan.trial_fee || getDefaultTrialFee(recommendedPlan),
        forced_country_code: geoLocation.is_forced_country_code
          ? geoLocation.country_code
          : undefined,
        // NOTE(guorui): for a normal user, surveyAnswers.email will contain the user's email
        // and serverContext.email will be null.
        // serverContext.email is populated when the user deeplinks to the payment page
        // with the email param set
        email: serverContext.email || surveyAnswers.email,
      };

      // NOTE(patrick): For legacy reasons this endpoint sends data as x-www-form-urlencoded
      // but receives responses from the django backend as JSON.
      // Convert our params object into a URLSearchParam format to send to the server.
      // In the future, this can be replaced with JSON.stringify.
      const paramsPayload = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (value != null) {
          paramsPayload.append(key, `${value}`);
        }
      });

      const response: Plan = await send(
        "POST",
        PROMO_CODE_VALIDATION_URL,
        paramsPayload
      );

      if (response.formatted_promo_discount_amount === "0") {
        const toThrow: any = new Error("checkout:errorPromo");
        toThrow.i18nMessage = "checkout:errorPromo";
        throw toThrow;
      }

      const updatedPlan: Partial<Plan> = {
        ...recommendedPlan,
        price: response.price,
        monthly_price: response.monthly_price,
        weekly_price: response.weekly_price,
        discounted_plan_price: response.discounted_plan_price,
        trial_fee: response.trial_fee,
        discounts: {
          ...recommendedPlan.discounts,
          total_discount: response.discounts.total_discount,
        },
        // NOTE(patrick)[2020/04/14]: The batch symbol can be modified by the server.
        // It is most likely because the curriculum is changed from HW to VIP in the
        // case the promo code is for a VIP user.
        // NOTE(patrick)[2020/04/14]: We get the batch_symbol in recommendedPlan but save it
        // as `curriculum` and pass it back to the payment endpoint as `curriculum` in
        // the plan. `batch_symbol` will still be set in recommendedPlan but it's not
        // to be confused with `curriculum`.
        ...(response.batch_symbol && { curriculum: response.batch_symbol }),
      };
      dispatch(updateRecommendedPlan(updatedPlan));

      dispatch(
        setPromoCode({
          promoCodeError: "",
          promoCodeApplied: promoCode,
          promoDiscountAmount: response.promo_discount_amount,
          promoDiscountPercentage: response.discounts.promo_discount.percentage,
          promoCodeIsVip: response.is_vip,
        })
      );

      trackEvent("PromoCodeRedeemed", {
        promoCode,
      });

      if (promoCodeSlice.promoCodeIsVip) {
        trackEvent("RedeemedPromoCodeVIP", {
          promoCode,
        });
      }

      // NOTE(Rose): In the case that promocode is entered after zipcode, the disclaimer and order breakdown don't update
      // with the right totals. Forcing rerender by updating state or using this.forceUpdate() don't seem to work, as the
      // rendering seems tied to calculateSalesTax().
      if (checkout.zipcode) {
        dispatch(calculateSalesTax({ zipcode: checkout.zipcode }));
      }
    } catch (err: any) {
      const { response } = err;
      let promoCodeError = response?.data?.message || err;
      if (response?.status === 406) {
        promoCodeError = i18n.t("checkout:invalidCode");
      } else if (err.i18nMessage) {
        promoCodeError = i18n.t(err.i18nMessage);
      }
      dispatch(
        setPromoCode({
          promoCodeApplied: "",
          promoDiscountAmount: 0,
          promoDiscountPercentage: 0,
          promoCodeIsVip: false,
          promoCodeError,
        })
      );
      trackEvent("InvalidPromoCodeEntered", {
        promoCode,
      });
    }
  };
}

/**
 * Given a US zipcode or CA postal code and region, fetch calculated sales tax and then
 * update the plan stored in the state and salesTaxAmount.
 */
export function calculateSalesTax(regionInfo: {
  zipcode?: string;
  region?: string;
}) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const {
      checkout,
      promoCode,
      recommendedPlan,
      geoLocation,
      serverContext,
      surveyAnswers,
    } = getState();
    // NOTE(sumin): There's a bit of a delay in updating the zipcode inside billingAddress,
    //              so we're using a hack for now to overwrite it with the most up-to-date zipcode.
    const billingAddress = { ...checkout.billingAddress };
    if (regionInfo.zipcode) {
      billingAddress.zipcode = regionInfo.zipcode;
    }
    if (regionInfo.region) {
      billingAddress.region = regionInfo.region;
    }
    // Both region and zipcode are required to calculate sales tax for Candian users
    // so in the case the zipcode is provided but an empty value for region is set for billing address,
    // exit early.
    if (!billingAddress.zipcode || (isCA() && !billingAddress.region)) {
      return Promise.resolve({});
    }

    const basePayload = {
      country: geoLocation.country_code,
      continentCode: geoLocation.continent_code,
      zipcode: billingAddress.zipcode,
      noomPlanId: recommendedPlan.noom_plan_id,
      promoCode: promoCode.promoCodeApplied,
      trialFee: recommendedPlan.trial_fee,
      billingAddress,
      // NOTE(guorui): for a normal user, surveyAnswers.email will contain the user's email
      // and serverContext.email will be null.
      // serverContext.email is populated when the user deeplinks to the payment page
      // with the email param set
      email: serverContext.email || surveyAnswers.email,
    };
    // EN-INTL surface includes information about tax-inclusive pricing
    let payload;
    if (isEnIntl()) {
      payload = { ...basePayload, returnTaxForTaxInclusivePlans: true };
    } else {
      payload = basePayload;
    }

    return send("POST", SALES_TAX_CALCULATION_URL, payload)
      .then((data: SalesTaxAPIResponse) => {
        const { plan } = data;
        // hacky temp fix: tax endpoint returns different payment source
        // clearing up the payment form
        delete plan.payment_source;
        dispatch(updateRecommendedPlan(plan));
        dispatch(
          updateCheckoutState({
            salesTaxAmount: data.sales_tax_amount,
            salesTaxRate: data.sales_tax_rate || 0,
            salesTaxState: data.sales_tax_state,
            zipcode: billingAddress.zipcode,
            isTaxInclusive: data.is_tax_inclusive,
          })
        );
        return data;
      })
      .catch((err: any) => {
        captureException(err);
      });
  };
}

export function isValidZipCode(zipcode: string): boolean {
  return postcodeValidator(zipcode, getCountryCode());
}

const PURCHASE_URL = "/api/payment/v2/purchase_program/";

export interface PurchaseProgramResponse {
  eltv_13_months: number;
  email: string;
  erev_9_months: number;
  name: string;
  payment_cc_last_4: string;
  payment_cc_type: string;
  subscriptionId: string;
  upid: string;
}

export const requestPurchase = createAsyncThunk<
  {
    response: PurchaseProgramResponse;
    request: ReturnType<typeof preparePurchaseRequest>;
  },
  {
    paymentNonce: string;
    recaptchaToken: string;
  },
  {
    state: CoreReduxState;
  }
>("checkoutPurchase/request", async (params, thunkApi) => {
  const coreState = thunkApi.getState();
  const { recommendedPlan } = coreState;

  const purchaseRequest = preparePurchaseRequest(coreState);

  purchaseRequest.paymentMethodNonce = params.paymentNonce;
  purchaseRequest.recaptchaToken = params.recaptchaToken || null;

  trackEvent("SubmittedStartSubscriptionRequest", {
    paymentMethod: purchaseRequest.paymentMethodType,
    planId: recommendedPlan.braintree_id,
  });
  let response: PurchaseProgramResponse;
  try {
    response = await send("POST", PURCHASE_URL, purchaseRequest, {
      params: prepareGrowthAPIParameters(),
    });
  } catch (e) {
    const errorData = e.response.data;
    let errorType = errorData?.errorType || errorConstants.errorUnknown;
    // NOTE(sumin): Quick hack to be backwards compatible but show sales tax location error.
    // Once backend sends ERROR_SALES_TAX_LOCATION as the errorType, we can remove this.
    // TODO: Did this happen?
    if (errorData?.errorCode === errorConstants.errorSalesTaxLocation) {
      errorType = errorConstants.errorSalesTaxLocation;
    }

    trackEvent("FailedToStartSubscription", {
      error: errorType,
      result: errorType,
      request: purchaseRequest,
      errorData,
    });
    // TODO move to reducer
    thunkApi.dispatch(updateCheckoutState({ processing: false }));
    throw e;
  }
  // After successful signup, track events
  try {
    await trackPurchaseSuccess(
      coreState,
      response,
      purchaseRequest.paymentMethodType,
      purchaseRequest.email,
      purchaseRequest.name
    );
  } catch (e) {
    // Trackers failed, but let the user continue; just report this error.
    captureException(e);
  }
  return {
    response,
    request: purchaseRequest,
  };
});

function hasTrialFee(
  context: ServerContextState,
  recommendedPlan: RecommendedPlanState,
  state: CoreReduxState
) {
  return (
    showChooseTrialPage(state) ||
    (context.trial_fee_waived === false &&
      recommendedPlan.trial_fee > 0 &&
      recommendedPlan.has_trial)
  );
}

function preparePurchaseRequest(coreState: CoreReduxState) {
  // Extract necessary state from Redux
  const {
    checkout,
    promoCode,
    paymentEnrollmentForm,
    userData,
    recommendedPlan,
    geoLocation,
    language,
  } = coreState;
  // Only use info from paymentEnrollmentForm slice if eligible for collapsed payment enrollment UI
  // and not VIP
  const name =
    isCollapsedPaymentEligible() && !promoCode.promoCodeIsVip
      ? paymentEnrollmentForm.enrollmentInfo.name
      : userData.name;
  const email =
    isCollapsedPaymentEligible() && !promoCode.promoCodeIsVip
      ? paymentEnrollmentForm.enrollmentInfo.email.toLowerCase()
      : userData.email.toLowerCase();
  const paymentMethod =
    checkout.paymentType === "PAYPAL" ? "PayPal" : "CreditCard";
  // NOTE(patrick): growthProgramStartDateISO is used by braze and is required to be ISO8601.
  const growthProgramStartDateISO = toISO();
  const expirationDate = shift_days(recommendedPlan.trial_duration, new Date());
  const growthTrialEndDate = format(
    i18n.t("checkout:dateFormat"),
    expirationDate
  );
  const growthTrialEndDateISO = toISO(expirationDate);

  let paymentProcessor;
  if (checkout.paymentType === "PAYPAL") {
    paymentProcessor = "BRAINTREE";
  } else if (checkout.walletPay) {
    paymentProcessor = "STRIPE";
  } else {
    paymentProcessor = recommendedPlan.payment_source;
  }

  // Assemble the purchase request.
  let purchaseRequest = {
    // Only use info from paymentEnrollmentForm if eligible for collapsed payment enrollment UI and
    // not VIP
    name,
    email,
    countryCode: geoLocation.country_code,
    continentCode: geoLocation.continent_code,
    noomPlanId: recommendedPlan.noom_plan_id,
    braintreeId: recommendedPlan.braintree_id,
    merchantAccount: recommendedPlan.merchant_account,
    language,
    curriculum: recommendedPlan.curriculum ? recommendedPlan.curriculum : "HW",
    inAppPurchase: isInAppWebPurchaseEligible(coreState),

    paymentMethodType: paymentMethod,
    plan: recommendedPlan,
    paymentProcessor,
    zipcode: checkout.zipcode,
    growthProgramStartDateISO,
    growthTrialEndDate,
    growthTrialEndDateISO,

    billingAddress: {
      ...(checkout.billingAddress || {
        country: geoLocation.country_code,
      }),
      name: checkout.billingName,
    },
    shouldEnroll: undefined,
    experiments: undefined,
    promoCodeApplied: undefined,
    promoDiscountAmount: undefined,
    promoCodeIsVip: undefined,
    trialFee: undefined,
    growthPaymentPayPal: undefined,
    paymentMethodNonce: undefined,
    recaptchaToken: undefined,

    subscriptionSaleItemId: undefined,
    oneSourceTaxCode: recommendedPlan?.one_source_tax_code,
    trialSaleItemId: undefined,
    // NOTE(mattr): Temp feature flag for UserData V4 endpoints.
    // Remove once UserData V3 is fully deprecated.
    mergeUsersByEmail: true,
  };

  purchaseRequest = decoratePurchaseRequestWithProductCatalog(
    purchaseRequest,
    recommendedPlan
  );

  // Pass in shouldEnroll only if eligible for collapsed payment enrollment UI and not VIP
  if (isCollapsedPaymentEligible(coreState) && !promoCode.promoCodeIsVip) {
    purchaseRequest.shouldEnroll = true;
  }

  purchaseRequest.experiments = getOptimizelyExperiments();

  if (promoCode.promoCodeApplied) {
    purchaseRequest.promoCodeApplied = promoCode.promoCodeApplied;
    purchaseRequest.promoDiscountAmount = promoCode.promoDiscountAmount;
    purchaseRequest.promoCodeIsVip = promoCode.promoCodeIsVip;
  }

  if (hasTrialFee(coreState.serverContext, recommendedPlan, coreState)) {
    purchaseRequest.trialFee = recommendedPlan.trial_fee;
  }

  if (checkout.paymentType === paymentTypes.PAYPAL) {
    purchaseRequest.growthPaymentPayPal = checkout.paypalAccount;
  }
  return purchaseRequest;
}

async function submitReferrals(
  surveyAnswers: SurveyAnswersState,
  name: string,
  email: string,
  userData: UserDataState
) {
  try {
    if (surveyAnswers.inviteFriendsFamily?.length > 0) {
      const { referral_code } = await getReferralCode();
      const eventData = {
        event_name: "signedUpAndSubmittedReferral",
        event_data: {
          name,
          referrer_email: email,
          referral_emails: surveyAnswers.inviteFriendsFamily,
          referral_code,
          referral_link: `https://www.noom.com/programs/health-weight/referrals/?upv=3&sp=noom_referral&utm_source=paid_referral&utm_medium=referral&utm_campaign=friendsandfam&step=pros&lang=en&referral_code=${referral_code}#/`,
        },
      };
      await saveToBraze(userData.user_id, null, eventData);
    }
  } catch (e) {
    captureException(e, "brazeSignedUpAndSubmittedReferral");
  }
}

async function trackPurchaseSuccess(
  coreState: CoreReduxState,
  response: JsonObject,
  paymentMethod: string,
  email: string,
  name: string
) {
  const { recommendedPlan, userData } = coreState;
  // Track the signup.
  const trackerPayload = {
    planId: recommendedPlan.braintree_id,
    planDuration: recommendedPlan.billing_cycle_in_months,
    planCurrency: recommendedPlan.currency,
    planOriginalPrice: recommendedPlan.regular_price,
    planPrice: recommendedPlan.price,
    hasTrial: recommendedPlan.has_trial,
    paymentMethod,
    paymentCCLast4: response.payment_cc_last_4,
    paymentCCType: response.payment_cc_type,
    trialFeeAmount: recommendedPlan.trial_fee || 0,
    ...response,
  };
  await trackEvent("OnSignedUp", trackerPayload);
  trackViewContentWithParamsExsf0({
    content_type: "FBMG-OnSignedUp",
  });

  if (isInApp(coreState) && isIOS()) {
    await saveToBraze(userData.user_id, null, {
      event_name: "ios_app_signup",
    });
  }

  // Fire tracking pixels
  const personaSurveyAnswers =
    userData.personaSurveyAnswers ||
    userData.personaSurveyUS ||
    userData.personaSurveyNonUS ||
    {};

  const { purchaseSurveyAnswers } = userData;
  const surveyAnswers = {
    ...personaSurveyAnswers,
    ...purchaseSurveyAnswers,
  };

  await submitReferrals(surveyAnswers, name, email, userData);

  await trackPurchase(email, recommendedPlan, surveyAnswers, response);
}
