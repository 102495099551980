import { SegmentFunction } from ".";
import { routeConstants } from "../constants";
import getStore, { CoreReduxState } from "../redux/store";

/**
 * Generates a method that will check a combination of routes and
 * custom segments.
 */
export function checkSegments({
  oneOf,
  allOf,
  noneOf,
}: {
  oneOf?: (routeConstants | SegmentFunction)[];
  allOf?: (routeConstants | SegmentFunction)[];
  noneOf?: (routeConstants | SegmentFunction)[];
}) {
  const ret: SegmentFunction = (state = getStore().getState()) => {
    return (
      (!oneOf || oneOf.some((check) => runCheck(check, state))) &&
      (!allOf || !allOf.find((check) => !runCheck(check, state))) &&
      (!noneOf || !noneOf.find((check) => runCheck(check, state)))
    );
  };

  const loaders = [
    ...(oneOf?.map(extractLoader) || []),
    ...(allOf?.map(extractLoader) || []),
    ...(noneOf?.map(extractLoader) || []),
  ].filter(Boolean);

  // Conditionally apply the loader API. If we apply this everywhere, there could be a fair
  // amount of unnecessary load calls.
  if (loaders.length > 0) {
    ret.load = (state): Promise<void> => {
      return Promise.all(loaders.map((loader) => loader(state))).then(() => {});
    };
  }
  return ret;
}

function runCheck(
  check: SegmentFunction | routeConstants,
  state: CoreReduxState
) {
  return typeof check === "function" ? check(state) : check === state.routeId;
}

function extractLoader(check: SegmentFunction | routeConstants) {
  return typeof check === "function" && "load" in check
    ? check.load
    : undefined;
}
