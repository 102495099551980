import invariant from "tiny-invariant";
import { ReadonlyDeep } from "type-fest";
import { CoreReduxState } from "src/utils/redux/store";

import { Direction, Page, PageList, PageSet, SwitchCondition } from "../types";
import { conditionsPass, loadKeys } from "../conditions";
import {
  findLoadableConditionKeys,
  findLoadablePageSetKeys,
  resolvePage,
} from "./eval";

const PAGE_SETS: Record<string, PageSet> = {};

export type PageLocation = number[];

function normalizePath(pathname: string) {
  return pathname?.replace(/^\/|\/$/g, "");
}

function expandSwitchCases(switchCondition: SwitchCondition) {
  const cases: SwitchCondition["cases"] = {};
  if (switchCondition.cases.default) {
    cases.default = switchCondition.cases.default;
  }

  Object.keys(switchCondition.cases).forEach((caseName) => {
    const values = caseName.split("|");
    const casePage = switchCondition.cases[caseName];

    invariant(
      !casePage.props?.progressBar,
      `Progress bar is not supported in switch case ${casePage.pathname}. Define on top switch page for all cases.`
    );

    values.forEach((value) => {
      cases[value] = {
        ...casePage,
        pathname: normalizePath(casePage.pathname),
        actions: [
          ...(switchCondition.actions || []),
          ...(casePage.actions || []),
        ],
        props: {
          ...switchCondition.props,
          ...casePage.props,
        },
      };
    });
  });

  // eslint-disable-next-line no-param-reassign
  switchCondition.cases = cases;
}

export async function ensureLayerConditionsLoaded(state: CoreReduxState) {
  const conditionKeys = new Set<string>();

  Object.values(PAGE_SETS).forEach((pageSet) => {
    const pageSetConditionKeys = findLoadableConditionKeys(pageSet.conditions);
    pageSetConditionKeys.forEach((key) => {
      conditionKeys.add(key);
    });
  });

  return loadKeys(Array.from(conditionKeys), state);
}
export async function ensurePageSetLoaded(
  pageSet: PageSet,
  state: CoreReduxState
) {
  const loadableConditionKeys = findLoadablePageSetKeys(pageSet.pages);
  return loadKeys(loadableConditionKeys, state);
}

export function getPageSet(pageSetId: string): PageSet | undefined {
  return PAGE_SETS[pageSetId];
}

export async function getPageSetFromPage(
  pathname: string,
  state: CoreReduxState
) {
  await ensureLayerConditionsLoaded(state);

  return Object.values(PAGE_SETS).find((pageSet) => {
    if (!conditionsPass(pageSet.conditions, state, Direction.FORWARD)) {
      return null;
    }
    return getPage(pageSet, pathname, state);
  });
}

export function getPageAtLocation(
  activePageSet: PageSet,
  location: PageLocation
): PageList[0] {
  if (!location) {
    return undefined;
  }

  let page = activePageSet.pages[location[0]];

  for (let i = 1; i < location.length; i++) {
    if (!page) {
      return undefined;
    }
    invariant("block" in page, `Expected page a depth ${i} to be block.`);
    page = page.block[location[i]];
  }

  return page;
}

export function getActivePage(pathname: string, state: CoreReduxState) {
  const activePageSetName = state.linearBuyflow.pageSetName;

  const activePageSet = getPageSet(activePageSetName);
  const activePage = getPage(activePageSet?.pages, pathname, state);

  return { activePageSet, activePage };
}

/**
 * Returns the resolved page matching the given block name. I.e.
 * switch and block statements will be evaluated and child returned.
 */
export function getPage(
  pages: PageList | PageSet,
  pathname: string,
  state: CoreReduxState
) {
  if (!pages) {
    return undefined;
  }

  const needle = normalizePath(pathname);
  function checkPage(page: Page) {
    const firstPage = resolvePage(page, state, Direction.FORWARD);

    return (
      firstPage?.pathname === needle &&
      conditionsPass(firstPage.conditions, state, Direction.FORWARD)
    );
  }

  const pageList = "pages" in pages ? pages.pages : pages;

  let ret: Page;
  pageList?.find((page) => {
    if ("switch" in page) {
      return Object.values(page.cases).find((casePage) => {
        if (checkPage(casePage)) {
          ret = casePage;
          return true;
        }
        return false;
      });
    }
    if ("block" in page) {
      const blockChild = getPage(page.block, pathname, state);
      if (blockChild) {
        ret = blockChild;
      }
      return !!blockChild;
    }
    if (checkPage(page)) {
      ret = page;
      return true;
    }
    return false;
  });
  return ret;
}

export function getPageLocation(
  pages: PageList | PageSet,
  pathnameOrPage: string | PageList[0]
): PageLocation {
  if (!pages || !pathnameOrPage) {
    return undefined;
  }

  const needle =
    typeof pathnameOrPage === "string" && normalizePath(pathnameOrPage);
  function checkPage(page: Page) {
    if (typeof pathnameOrPage === "string") {
      return page.pathname === needle;
    }
    return page === pathnameOrPage;
  }

  const pageList = "pages" in pages ? pages.pages : pages;

  for (let i = 0; i < pageList.length; i++) {
    const page = pageList[i];
    if (page === pathnameOrPage) {
      return [i];
    }
    if ("switch" in page) {
      if (Object.values(page.cases).find(checkPage)) {
        return [i];
      }
    } else if ("block" in page) {
      const index = getPageLocation(page.block, pathnameOrPage);
      if (index) {
        return [i].concat(index);
      }
    } else if (checkPage(page)) {
      return [i];
    }
  }

  return undefined;
}

export function registerPageSet(pageSet: PageSet) {
  invariant(
    !PAGE_SETS[pageSet.id] || PAGE_SETS[pageSet.id] === pageSet,
    `Different PageSet already registered with id ${pageSet.id}`
  );

  // TODO: Move / handling to schema?
  function normalizePages(pages: PageList) {
    pages.forEach((page) => {
      if ("switch" in page) {
        expandSwitchCases(page);
      } else if ("block" in page) {
        normalizePages(page.block);
      } else {
        // eslint-disable-next-line no-param-reassign
        page.pathname = normalizePath(page.pathname);
      }
    });
  }

  // Normalize all saved paths to avoid annoyances
  normalizePages(pageSet.pages);

  PAGE_SETS[pageSet.id] = pageSet;
}

function registerAll(r: any) {
  r.keys().forEach((key: string) => {
    registerPageSet(r(key) as PageSet);
  });
}
registerAll(require.context("./", true, /\.json$/));

export async function getPageSetForLayer(layer: string, state: CoreReduxState) {
  await ensureLayerConditionsLoaded(state);

  const matchingSets = Object.values(PAGE_SETS)
    .map((pageSet) => {
      if (pageSet.layer !== layer) {
        return null;
      }
      const score = conditionsPass(
        pageSet.conditions,
        state,
        Direction.FORWARD
      );
      if (!score) {
        return null;
      }
      return { pageSet, score };
    })
    .filter(Boolean)
    .sort((a, b) => b.score - a.score);

  return matchingSets[0]?.pageSet;
}

export default PAGE_SETS as ReadonlyDeep<typeof PAGE_SETS>;
