import { send } from "@utils/fetch";
import { PatientIntakeSessionStorage } from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/usePatientIntakeSessionStorage";
import { getLocalFormattedDate } from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/getLocalFormattedDate";
import { ConsultType } from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";

const getIsRescheduleFromWindowLocation = () =>
  window.location.hash.split("/")[1] === "reschedule";

export const postPatientIntake = (
  userId: string,
  body: PatientIntakeSessionStorage
): Promise<JsonObject> => {
  const url = `/api/insurance/v3/patient_intake/${userId}/`;
  return send("POST", url, body);
};

// On success we get an empty response back
type ScheduleAndFinalizeValidResponse = Record<string, never>;

type ScheduleAndFinalizeInvalidResponse = {
  error: string;
};

export type ScheduleAndFinalizeResponse =
  | ScheduleAndFinalizeValidResponse
  | ScheduleAndFinalizeInvalidResponse;

export const postScheduleAndFinalize = (
  userId: string,
  wheelConsultId: string,
  workflow: string,
  consultType: ConsultType,
  start: string
): Promise<ScheduleAndFinalizeResponse> => {
  const startFormatted = getLocalFormattedDate(start, consultType);
  const isReschedule = getIsRescheduleFromWindowLocation();
  const url = "/api/insurance/v2/schedule_appointment_and_finalize_consult/";
  return send("POST", url, {
    userId,
    start,
    wheelConsultId,
    workflow,
    consultType,
    startFormatted,
    isReschedule,
  });
};

export const postMakeEligible = (userId: string): Promise<JsonObject> => {
  const url = "/api/insurance/v1/make_eligible_for_reimbursement/";
  return send("POST", url, { userId });
};

export type CreateConsultAndGetTimeslotsResponse = {
  wheelConsultId: string;
  workflow: string;
  consultType: ConsultType;
  timeslots: string[];
};

export const postCreateConsultAndGetTimeslots = (
  userId: string
): Promise<CreateConsultAndGetTimeslotsResponse> => {
  const isReschedule = getIsRescheduleFromWindowLocation();
  return send("POST", "/api/insurance/v2/create_consult_and_get_timeslots/", {
    userId,
    isReschedule,
  });
};

export const getLatestConsultInfo = (userId: string): Promise<JsonObject> =>
  send(
    "GET",
    `/api/insurance/v1/latest_consult_info/${encodeURIComponent(userId)}/`
  );
