import { useOnce } from "src/hooks/lifecycle";

declare const Cypress: any;

export function QuickSilver() {
  useOnce(() => {
    const isDevOrStaging =
      window.location.origin.includes("localhost") ||
      window.location.origin.includes("buyflow-lambda");
    const isExcludingQuicksilver = window.location.search.includes(
      "excludeQuicksilver=true"
    );
    if (
      isDevOrStaging &&
      !isExcludingQuicksilver &&
      typeof Cypress === "undefined"
    ) {
      import(
        /* webpackChunkName: "quicksilver" */ "./initializeQuicksilver"
      ).then((module) => module && module.default());
    }
  });

  return null as JSX.Element;
}
