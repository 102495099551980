import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const purchasedNoomPremium = createSlice({
  name: "purchasedNoomPremium",
  initialState: false,
  reducers: {
    setPurchasedNoomPremium(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setPurchasedNoomPremium } = purchasedNoomPremium.actions;

export default purchasedNoomPremium;
