/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ChangeEvent,
  ChangeEventHandler,
  MouseEvent,
  useRef,
  useEffect,
  SyntheticEvent,
} from "react";
import { CheckboxQuestion } from "./CheckboxQuestion";
import {
  QuestionOption,
  CustomQuestionDefinition,
  OptionsInputDefinition,
  QuestionProps,
} from "../QuestionDefinition";
import { textareaQuestion } from "./TextareaQuestion.module.less";

import { questionOptions, otherValueTextInput } from "./Question.module.less";
import {
  checkboxQuestionOption,
  checkbox,
  checked,
} from "./CheckboxQuestion.module.less";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";

const otherInputId = "other";

export type CheckboxWithOtherQuestionDefinition = OptionsInputDefinition &
  CustomQuestionDefinition & {
    otherInputType?: "input" | "textarea";
  };

export default class CheckboxWithOtherQuestion<
  Def extends CheckboxWithOtherQuestionDefinition = CheckboxWithOtherQuestionDefinition
> extends CheckboxQuestion<Def> {
  constructor(props: QuestionProps<Def>) {
    super(props);

    const answer = this.props.surveyAnswers[this.props.question.id];
    this.state = {
      otherInput: answer?.find((option) => option && option.other)?.other ?? "",
    };

    this.handleOtherInputChange = this.handleOtherInputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  override handleClick(e: SyntheticEvent, option) {
    e.preventDefault();
    let checkboxArray = this.getCheckboxArray();

    let checkboxOption = option;
    const optionIndex = this.findOptionIndex(checkboxOption);
    if (optionIndex >= 0) {
      checkboxArray.splice(optionIndex, 1);
    } else {
      if (option === "none") {
        checkboxArray = [];
        this.setState({ otherInput: "" });
      } else if (this.noneOption) {
        checkboxArray = checkboxArray.filter((id) => id !== this.noneOption.id);
      }
      if (option === otherInputId) {
        checkboxOption = { other: this.state.otherInput };
      }
      checkboxArray.push(checkboxOption);
    }
    this.registerAnswers(checkboxArray);
  }

  override isChecked(id: string) {
    return (
      this.props.question.id in this.props.surveyAnswers &&
      this.findOptionIndex(id) >= 0
    );
  }

  getCheckboxArray() {
    let checkboxArray: (string | { other: string })[] = [];
    if (this.props.question.id in this.props.surveyAnswers) {
      checkboxArray = [...this.props.surveyAnswers[this.props.question.id]];
    }
    return checkboxArray;
  }

  findOptionIndex(id: string) {
    const checkboxArray = this.getCheckboxArray();
    return checkboxArray.findIndex(
      (item) => item === id || (id === otherInputId && typeof item === "object")
    );
  }

  registerAnswers(checkboxArray) {
    const ans: SurveyAnswersState = {};
    ans[this.props.question.id] = checkboxArray;
    this.props.registerAnswer(ans, this.validateAnswer(ans));
  }

  handleOtherInputChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { value } = e.target;
    this.setState({ otherInput: value });
    let checkboxArray = this.getCheckboxArray();

    if (this.noneOption) {
      checkboxArray = checkboxArray.filter((id) => id !== this.noneOption.id);
    }
    checkboxArray = checkboxArray.filter((item) => typeof item !== "object");
    checkboxArray.push({ other: value });
    this.registerAnswers(checkboxArray);
  }

  override renderOptions(options: QuestionOption[]) {
    return (
      <div className={questionOptions}>
        {options.map((option) => {
          if (option.id === otherInputId) {
            if (this.props.question.otherInputType === "textarea") {
              return (
                <OtherInputWithTextArea
                  optionId={option.id}
                  questionId={this.props.question.id}
                  optionChecked={this.isChecked(otherInputId)}
                  inputValue={this.state.otherInput}
                  handleInputChange={(e) => this.handleOtherInputChange(e)}
                  handleClick={this.handleClick}
                  key={option.id}
                />
              );
            }
            return (
              <OtherInput
                inputValue={this.state.otherInput}
                handleInputChange={(e) => this.handleOtherInputChange(e)}
                name="other"
                key={option.id}
                placeholder="Other"
              />
            );
          }

          return (
            <label
              htmlFor={option.id}
              key={option.id}
              className={`${checkboxQuestionOption} ${
                this.isChecked(option.id) ? checked : ""
              }`}
              onClick={(e) => this.handleClick(e, option.id)}
            >
              <input
                type="checkbox"
                checked={this.isChecked(option.id)}
                name={this.props.question.id}
                id={option.id}
                onClick={(e) => this.handleClick(e, option.id)}
                onChange={(e) => this.handleClick(e, option.id)}
              />
              <span
                className={`${checkbox} ${
                  this.isChecked(option.id) ? checked : ""
                }`}
              />
              {option.text}
            </label>
          );
        })}
      </div>
    );
  }
}

function OtherInput({
  inputValue,
  handleInputChange,
  name,
  placeholder,
}: {
  inputValue?: string;
  name: string;
  placeholder?: string;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <label
      className={`${checkboxQuestionOption} ${
        inputValue !== "" ? checked : ""
      } `}
    >
      <input type="radio" name={name} defaultChecked={inputValue !== ""} />
      <span className="question__option--checkbox-box" />
      <span className={`${checkbox} ${inputValue !== "" ? checked : ""}`} />
      <input
        type="text"
        className={otherValueTextInput}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
    </label>
  );
}

function OtherInputWithTextArea({
  optionId,
  questionId,
  optionChecked,
  inputValue,
  handleInputChange,
  handleClick,
}: {
  optionId: string;
  questionId: string;
  optionChecked: boolean;
  inputValue?: string;
  handleInputChange: ChangeEventHandler<HTMLTextAreaElement>;
  handleClick: (e: ChangeEvent | MouseEvent, id: string) => void;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (optionChecked && textareaRef?.current) {
      textareaRef.current.focus();
    }
  }, [optionChecked]);
  return (
    <>
      <label
        className={`${checkboxQuestionOption} ${optionChecked ? checked : ""} `}
        onClick={(e) => handleClick(e, optionId)}
        style={{ marginBottom: optionChecked ? "16px" : "inherit" }}
      >
        <input
          type="checkbox"
          checked={optionChecked}
          name={questionId}
          id={optionId}
          onClick={(e) => handleClick(e, optionId)}
          onChange={(e) => handleClick(e, optionId)}
        />
        <span className={`${checkbox} ${optionChecked ? checked : ""}`} />
        Other
      </label>
      {optionChecked && (
        <div className={textareaQuestion}>
          <textarea
            value={inputValue}
            onChange={handleInputChange}
            style={{
              height: "70px",
              marginBottom: "16px",
              fontSize: "16px",
            }}
            ref={textareaRef}
          />
        </div>
      )}
    </>
  );
}
