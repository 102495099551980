// This is used within cypress context, so we want to avoid application specific code from leaking into
// the cypress test harness context.
/* eslint no-restricted-imports: ["error", { patterns: ["@*", "src/", "./"]}] */
import tinydate from "tinydate";

const dictionary = {
  Z: timezone_offset,
  MMMM: toMMMM,
  Do: toDo,
};

const suffices: Record<string, string> = {
  1: "st",
  2: "nd",
  3: "rd",
};

/**
 * Get the "1st", "2nd", "3rd", "Nth" of a date
 * NOTE: This formatting type only used for "en" locale.
 */
export function toDo(date: Date): string {
  const num = date.getDate();
  const str = String(num);
  return (
    str +
    (((num < 10 || num > 20) && suffices[str.charAt(str.length - 1)]) || "th")
  );
}

/**
 * Get the month's long-form name.
 * NOTE: Uses `Intl` class internally for locale-aware.
 */
export function toMMMM(date: Date): string {
  return date.toLocaleString(undefined, { month: "long" });
}

/**
 * Instantly apply a formatting template to a Date.
 * @param template The formatting string
 * @param date A specific Date; defaults to now.
 * @example format('{MM}-{DD}-{YYYY}', new Date(2019, 04, 12)); //=> "05-12-2019"
 */
export function format(template: string, date?: Date): string {
  return tinydate(template, dictionary)(date);
}

/**
 * Get an ISO8601-like date string, incorporating timezone offset.
 * IMPORTANT: This is NOT ISO8601 since it uses local TZO instead of UTC values.
 * @param date The date the format; defaults to now.
 * @example timestamp(); //=> "2020-03-25T20:27:20.855-07:00"
 */
export const timestamp = tinydate(
  "{YYYY}-{MM}-{DD}T{HH}:{mm}:{ss}.{fff}Z",
  dictionary
);

/**
 * Use the browser to tell us its timezone
 * @todo Add `Intl` to polyfills
 * @example "America/Los_Angeles"
 */
export function timezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Convert a number to padded string.
 * @param num The *positive* integer to pad
 * @param {Number} count The number of digits to take
 */
export function pad(num: number | string, count: number) {
  return `0000${num}`.slice(-count);
}

/**
 * Get the (numerical) timezone offset from UTC.
 * Because of FF24, we round to the nearest 15min
 * @param date A specific Date instance, if any.
 * @see https://github.com/moment/moment/pull/1871
 * @example "-07:00", "-10:00", "+05:30"
 */
export function timezone_offset(date: Date = new Date()) {
  // @note Remove if FF24 is irrelevant
  const offset = Math.floor(date.getTimezoneOffset() / 15) * 15;
  const minutes = Math.abs(offset);
  return `${(offset > 0 ? "-" : "+") + pad(Math.floor(minutes / 60), 2)}:${pad(
    minutes % 60,
    2
  )}`;
}

/**
 * Convert a value, if any, to ISO8601 string
 * @param date The date-like value
 * @example "2020-03-26T03:27:20.857Z"
 */
export function toISO(date: DateLike = Date.now()) {
  return new Date(date).toISOString();
}

/**
 * Add XYZ days to a Date.
 * @param count The number of days to add/subtract.
 * @param date The reference Date; defaults to now.
 */
export function shift_days(count: number, date: DateLike = Date.now()) {
  const ref = new Date(date);
  ref.setDate(ref.getDate() + count); // can be negative
  return ref;
}

/**
 * Add XYZ months to a Date.
 * @param count The number of months to add/subtract.
 * @param date The reference Date; defaults to now.
 */
export function shift_months(count: number, date: DateLike = Date.now()) {
  const ref = new Date(date);
  ref.setMonth(ref.getMonth() + count); // can be negative
  return ref;
}

export function addMonths(date: DateLike, months: number) {
  // NOTE(rose): This method handles end-of-month consistently.
  // January 31 + 1 month = February 28, not February 31 or March 3
  const newDate = new Date(date);
  const dateNum = newDate.getDate();
  newDate.setMonth(newDate.getMonth() + months);
  if (newDate.getDate() !== dateNum) {
    newDate.setDate(0);
  }
  return newDate;
}

/**
 * Get the difference in months between the 2 dates. Eg date2 - date1
 * @param date1 The first date, being subtracted from the second date
 * @param date2 The second date
 */
export function getMonthDelta(date1: Date, date2: Date) {
  return (
    date2.getMonth() -
    date1.getMonth() +
    12 * (date2.getFullYear() - date1.getFullYear())
  );
}

/**
 * Get the difference in days between the 2 dates. Eg date2 - date1
 * NOTE (kevinh): This formerly used Math.floor to convert to integer but is
 * now changed to Math.round to better handle time related edge cases
 * @param date1 The first date, being subtracted from the second date
 * @param date2 The second date
 */
export function getDayDelta(date1: Date, date2: Date) {
  return Math.round(
    (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24)
  );
}

export const toYYYY_MM_DD = (date: DateLike): string =>
  new Date(date).toISOString().slice(0, 10);

/**
 * Formats a date to a given locale. If the locale is not provided the date will be formatted using the system default
 * @param date Valid date represented as string
 * @param locale Local in which the given date must pe represented
 */
export const formatStringDateToLocale = (
  date: string,
  locale?: string
): string => {
  return new Date(date).toLocaleDateString(locale);
};
