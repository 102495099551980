import { captureException } from "@utils/sentry";
import Unit, { UnitType } from "./Unit";

// These are all nearly the same, so this is in some fashion for docs only
interface PoundsValue {
  /** value in pounds. */ mainUnitValue: number;
}
interface KilosValue {
  /** value in kilograms. */ mainUnitValue: number;
}
interface StoneValue {
  /** value in strong. */ mainUnitValue: number;
  /** value in stones */ secondaryUnitValue: number;
}

/**
 * Converts given value in pounds to be in kilograms.
 */
export function convertLbToKg({ mainUnitValue }: PoundsValue): KilosValue {
  return { mainUnitValue: mainUnitValue / 2.205 };
}

/**
 * Converts given value in kilograms to be in pounds.
 */
export function convertKgToLb({ mainUnitValue }: KilosValue): PoundsValue {
  return { mainUnitValue: mainUnitValue * 2.205 };
}

/**
 * Converts given value in kilograms to be in pounds.
 */
export function convertLbToSt({ mainUnitValue }: PoundsValue): StoneValue {
  return {
    mainUnitValue: Math.floor(mainUnitValue / 14.0),
    secondaryUnitValue: Math.floor(mainUnitValue % 14.0),
  };
}

/**
 * Converts given value in stones and pounds to be in pounds.
 */
export function convertStToLb({
  mainUnitValue,
  secondaryUnitValue = 0,
}: StoneValue): PoundsValue {
  return {
    mainUnitValue: mainUnitValue * 14.0 + secondaryUnitValue,
  };
}

/**
 * Converts given value in kilograms to be in stones and pounds.
 */
export function convertKgToSt({ mainUnitValue }: KilosValue) {
  const valueInLb = convertKgToLb({ mainUnitValue });
  return convertLbToSt(valueInLb);
}

/**
 * Converts given value in stones and pounds to be in kilogram.
 */
export function convertStToKg({
  mainUnitValue,
  secondaryUnitValue = 0,
}: StoneValue) {
  const valueInLb = convertStToLb({ mainUnitValue, secondaryUnitValue });
  return convertLbToKg(valueInLb);
}

interface InchValue {
  /** value in inches. */ mainUnitValue: number;
}
interface FootValue {
  /** value in feet. */ mainUnitValue: number;
  /** value in inches. */ secondaryUnitValue?: number;
}
interface CentimeterValue {
  /** value in centimeters. */ mainUnitValue: number;
}

/**
 * Converts given value in inches to be in centimeters.
 *
 * @param {number} mainUnitValue - value in inches.
 */
export function convertInToCm({ mainUnitValue }: InchValue): CentimeterValue {
  return { mainUnitValue: mainUnitValue * 2.54 };
}

/**
 * Converts given value in centimeters to be in inches.
 */
export function convertCmToIn({ mainUnitValue }: CentimeterValue): InchValue {
  return { mainUnitValue: mainUnitValue / 2.54 };
}

/**
 * Converts given value in feet to be in inches.
 */
export function convertFtToIn({
  mainUnitValue,
  secondaryUnitValue = 0,
}: FootValue): InchValue {
  return {
    mainUnitValue: mainUnitValue * 12 + secondaryUnitValue,
  };
}

/**
 * Converts given value in inches to be in feet.
 */
export function convertInToFt({ mainUnitValue }: InchValue): FootValue {
  return {
    mainUnitValue: Math.floor(mainUnitValue / 12.0),
    secondaryUnitValue: Math.floor(mainUnitValue % 12.0),
  };
}

/**
 * Converts given value in feet to be in centimeters.
 */
export function convertFtToCm({
  mainUnitValue,
  secondaryUnitValue = 0,
}: FootValue) {
  const valueInIn = convertFtToIn({ mainUnitValue, secondaryUnitValue });
  return convertInToCm(valueInIn);
}

/**
 * Converts given value in centimeters to be in feet.
 *
 * @param {number} mainUnitValue - value in centimeters.
 */
export function convertCmToFt({ mainUnitValue }: CentimeterValue) {
  const valueInIn = convertCmToIn({ mainUnitValue });
  return convertInToFt(valueInIn);
}

/**
 * A helper function to convert a value in one unit to another (i.e. converting kilogram to pounds).
 */
export function convertUnits(
  value: { mainUnitValue: number; secondaryUnitValue?: number },
  fromUnit: UnitType,
  toUnit: UnitType
): { mainUnitValue: number; secondaryUnitValue?: number } {
  // Kg <> Lb.
  if (fromUnit === Unit.KILOGRAM && toUnit === Unit.POUND) {
    return convertKgToLb(value);
  }
  if (fromUnit === Unit.POUND && toUnit === Unit.KILOGRAM) {
    return convertLbToKg(value);
  }

  // Kg <> St.
  if (fromUnit === Unit.KILOGRAM && toUnit === Unit.STONE) {
    return convertKgToSt(value);
  }
  if (fromUnit === Unit.STONE && toUnit === Unit.KILOGRAM) {
    return convertStToKg(value as StoneValue);
  }

  // Lb <> St.
  if (fromUnit === Unit.POUND && toUnit === Unit.STONE) {
    return convertLbToSt(value);
  }
  if (fromUnit === Unit.STONE && toUnit === Unit.POUND) {
    return convertStToLb(value as StoneValue);
  }

  // Cm <> In.
  if (fromUnit === Unit.CENTIMETER && toUnit === Unit.INCH) {
    return convertCmToIn(value);
  }
  if (fromUnit === Unit.INCH && toUnit === Unit.CENTIMETER) {
    return convertInToCm(value);
  }

  // Cm <> Ft.
  if (fromUnit === Unit.CENTIMETER && toUnit === Unit.FEET) {
    return convertCmToFt(value);
  }
  if (fromUnit === Unit.FEET && toUnit === Unit.CENTIMETER) {
    return convertFtToCm(value);
  }

  // Ft <> In.
  if (fromUnit === Unit.FEET && toUnit === Unit.INCH) {
    return convertFtToIn(value as FootValue);
  }
  if (fromUnit === Unit.INCH && toUnit === Unit.FEET) {
    return convertInToFt(value);
  }

  // Same unit.
  if (fromUnit === toUnit) {
    return value;
  }
  captureException(new Error("Failed to convert units."), "conmvertUnits");

  return undefined;
}
