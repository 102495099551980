import { CountryCode } from "../redux/slices/geoLocation";
import getStore, { CoreReduxState } from "../redux/store";

export const isEnLanguage = (state = getStore().getState()) => {
  const { language } = state;
  return language === "en";
};

export const isDeLanguage = (state = getStore().getState()) => {
  const { language } = state;
  return language === "de";
};

export const isEsLanguage = (state = getStore().getState()) => {
  const { language } = state;
  return language === "es";
};

export const isAU = (state = getStore().getState()) => {
  const { geoLocation } = state;
  return geoLocation.country_code === "AU";
};

export const isUS = (state = getStore().getState()) => {
  const { geoLocation } = state;
  return geoLocation.country_code === "US";
};

export const isCA = (state = getStore().getState()) => {
  const { geoLocation } = state;
  return geoLocation.country_code === "CA";
};

export const isUK = (state = getStore().getState()) => {
  const { geoLocation } = state;
  return geoLocation.country_code === "UK" || geoLocation.country_code === "GB";
};

export const isEU = (state = getStore().getState()) => {
  const { geoLocation } = state;
  return geoLocation.is_eu_citizen;
};

export const isEnUS = (state = getStore().getState()) => {
  return isUS(state) && isEnLanguage(state);
};

export const isEnIntl = (state = getStore().getState()) => {
  return !isUS(state) && isEnLanguage(state);
};

export const isCountry = (
  countries: CountryCode[],
  state = getStore().getState()
) => {
  const { geoLocation } = state;
  return countries.includes(geoLocation.country_code);
};

/**
 * Feet and pounds
 */
export const isCountryImperial = (state?: CoreReduxState) => {
  return isCountry(["US", "CA"], state);
};

/**
 * Kilos or stones and meters
 */
export const isCountryStones = (state?: CoreReduxState) => {
  return isUK(state) || isCountry(["IE"], state);
};

/**
 * Kilos and meters
 */
export const isCountryMetric = (state?: CoreReduxState) => {
  return !isCountryImperial(state) && !isCountryStones(state);
};
