import { createSlice } from "@reduxjs/toolkit";
import { getMeristemContext } from "src/utils/meristemContext";

const languageSlice = createSlice({
  name: "language",
  initialState: getMeristemContext().language_code || "en",
  reducers: {},
});

export default languageSlice;
