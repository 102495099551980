import { trackEvent } from "src/utils/api/tracker";
import { RecommendedPlanState } from "@utils/redux/slices/recommendedPlan";

export const decoratePurchaseRequestWithProductCatalog = (
  purchaseRequest: any,
  recommendedPlan: RecommendedPlanState
) => {
  const trialFee = recommendedPlan.trial_fee;
  const trialSaleItem = recommendedPlan?.trial_item_options?.find(
    (trial) => trial.price === trialFee
  );
  const trialSaleItemId = trialSaleItem?.trial_sale_item_id;

  // equality comparison coerces null<->undefined
  if (trialFee != null && trialSaleItemId === null) {
    trackEvent("ProductAsPlanTrialMismatch", {
      recommendedPlan,
      trialFee,
    });
  }
  const newPurchaseRequest = {
    ...purchaseRequest,
    trialSaleItemId,
  };
  newPurchaseRequest.subscriptionSaleItemId = recommendedPlan?.sale_item_id;
  return newPurchaseRequest;
};
