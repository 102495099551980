import { trackEvent } from "@utils/api/tracker";
import { registerHandler } from "../handler";
import { getTrackingParams } from "src/utils/services/VisitTracker";
import { isUS } from "src/utils/userSegment";

export default function registerHandlers() {
  registerHandler("purchase", () => {
    // For the healthline pixel the revenue is hardcoded.
    let revenue = isUS() ? 40 : 30;

    if (getTrackingParams().utm_campaign === "cpm") {
      revenue = 0;
    }

    const healthLineURL = new URL(
      "https://activation.healthline.com/api/member-offers/postback"
    );
    healthLineURL.searchParams.append("clid", getTrackingParams().clid || "-1");
    healthLineURL.searchParams.append("rev", revenue.toString());

    fetch(healthLineURL.toString())
      .then((response) => {
        if (response.ok) {
          trackEvent("HealthlineSignup");
        } else {
          trackEvent("HealthlineFail");
        }
      })
      .catch(() => {
        trackEvent("HealthlineFail");
      });
  });
}
