/* eslint-disable no-underscore-dangle */
import React from "react";
import { Helmet } from "react-helmet";

declare const __OG_TITLE__: string;
declare const __OG_DESCRIPTION__: string;
declare const __OG_IMAGE__: string;
declare const __OG_TYPE__: string;
declare const __OG_URL__: string;
declare const __OG_SITE_NAME__: string;

const OpenGraph = () => (
  <Helmet>
    <meta property="og:title" content={__OG_TITLE__} />
    <meta property="og:description" content={__OG_DESCRIPTION__} />
    <meta property="og:image" content={__OG_IMAGE__} />
    <meta property="og:type" content={__OG_TYPE__} />
    <meta property="og:url" content={__OG_URL__} />
    <meta property="og:site_name" content={__OG_SITE_NAME__} />
  </Helmet>
);

export default OpenGraph;
