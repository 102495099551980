import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { send } from "src/utils/fetch";
import { captureException } from "src/utils/sentry";

export interface ActiveSubscriptionDataState {
  hasActiveSubscription: boolean;
  subscriptionId?: string;
  isInTrialPeriod?: boolean;
  planId?: string;
  nextChargeAmount?: number;
  expiryTime?: string;
  activeSubscriptionCurriculumSlug?: string;
  autoRenewalEnabled?: boolean;
  isIAPsubscription?: boolean;
  billingCycleInterval?: string;
  billingCycleIntervalCount?: number;
  accessCode?: string;
  batchId?: number;
}

const activeSubscriptionDataSlice = createSlice({
  name: "activeSubscriptionData",
  initialState: { hasActiveSubscription: false } as ActiveSubscriptionDataState,
  reducers: {
    setActiveSubscriptionData(
      state,
      action: PayloadAction<ActiveSubscriptionDataState>
    ) {
      // Does not support partial updates
      return { ...action.payload };
    },
  },
});

export const { setActiveSubscriptionData } =
  activeSubscriptionDataSlice.actions;

export default activeSubscriptionDataSlice;

/**
 * Fetches the active subscription data of an user using the email
 */
export const loadActiveSubscriptionDataState = createAsyncThunk(
  "activeSubscriptionData/loadActiveSubscriptionDataState",
  async (email: string, thunkAPI) => {
    let activeSubscriptionData: ActiveSubscriptionDataState = {
      hasActiveSubscription: false,
    };
    const url = `/visitor/api/v1/getVisitorSubscriptionStatus/?email=${encodeURIComponent(
      email
    )}`;
    try {
      activeSubscriptionData = await send("GET", url);
    } catch (e) {
      captureException(e, "loadActiveSubscriptionDataState");
      return;
    }

    thunkAPI.dispatch(
      // eslint-disable-next-line no-use-before-define
      setActiveSubscriptionData(activeSubscriptionData)
    );
  }
);
