import { trackEvent } from "@utils/api/tracker";
import { addMissingInAppSurveyBiometrics } from "src/utils/redux/slices/surveyAnswers";
import { isInApp } from "src/utils/userSegment";
import { ActionHandler } from "..";
import { getPageSetSurvey } from "./util";

export const onStartMainSurvey: ActionHandler = ({
  store,
  pageSet,
  page,
  params: { eventName } = {},
}) => {
  const survey = getPageSetSurvey(pageSet, page);
  trackEvent(eventName || `Started${survey.name}`);

  // TODO: See if we can move this into the first load of the survey in the store.
  // This is a little too coupled on UI vs. data.
  // ------
  // Make sure the biometrics are in the correct units for the country
  // since the in-app flow works differently
  if (isInApp()) {
    store.dispatch(addMissingInAppSurveyBiometrics());
  }
};
