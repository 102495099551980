import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector, useStore } from "react-redux";
import { Direction, Page, PageSet } from "src/pageDefinitions/types";
import {
  getPageSet,
  getPageSetForLayer,
  getPageAtLocation,
  getActivePage,
  ensurePageSetLoaded,
} from "src/pageDefinitions/pageSets";
import { CoreReduxState } from "@utils/redux/store";

import goto from "src/pageDefinitions/goto";

import {
  ensureGotoSetup,
  gotoPage,
  GoToPageOptions,
} from "src/pageDefinitions/goto/pageSet";
import { useAsyncError } from "./error";
import { useHistoryHack } from "./historyHack";
import { preloadPagePath } from "src/router";
import { useCallbackRef, useOnce } from "./lifecycle";
import { captureException } from "src/utils/sentry";
import {
  PageSetEventHandler,
  pageSetEvents,
} from "src/pageDefinitions/pageSets/bus";
import {
  findFuturePageIndex,
  resolvePage,
} from "src/pageDefinitions/pageSets/eval";
import {
  clearActivePageSet,
  setActivePageSet,
} from "src/utils/redux/slices/linearBuyflow";
import { resolveParamPageSet } from "src/pageDefinitions/pageSets/init";
import { useAppDispatch } from "./redux";
import { useLocation } from "react-router";

/**
 * Allows ancestors of the usePageSet hook manage how errors are handled.
 * This is mostly used as DI for testing.
 */
const PageSetErrorContext = createContext<(err: Error) => void>(null);

/**
 * Blocks until we are able to fully load the current pageset
 */
export function PageSetGate({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const location = useLocation<{ pageSetId: string }>();

  const [loaded, setLoaded] = useState(false);
  const store = useStore();

  useOnce(() => {
    (async () => {
      const state = store.getState();
      const paramPageSet = await resolveParamPageSet(state, location);
      if (paramPageSet && paramPageSet.id !== state.linearBuyflow.pageSetName) {
        await dispatch(setActivePageSet(paramPageSet));
      } else if (state.linearBuyflow.pageSetName) {
        // On boot ensure that all async conditions are loaded
        await ensurePageSetLoaded(
          getPageSet(state.linearBuyflow.pageSetName),
          state
        );
      }
      setLoaded(true);
    })();
  });

  // Reset pageset on backwards nav
  useEffect(() => {
    const state = store.getState();
    const statePageSet = getPageSet(location.state?.pageSetId);
    if (statePageSet && statePageSet.id !== state.linearBuyflow.pageSetName) {
      dispatch(setActivePageSet(statePageSet));
    }
  }, [location.state?.pageSetId, store, dispatch]);

  const handlePageSetError = useCallback(
    (error: Error) => {
      dispatch(clearActivePageSet());

      captureException(error);

      /* istanbul ignore next */
      if (__NODE_ENV__ !== "test") {
        // eslint-disable-next-line no-debugger
        debugger;
      }

      goto.landing();
    },
    [dispatch]
  );

  if (!loaded) {
    return null;
  }

  return (
    <PageSetErrorContext.Provider value={handlePageSetError}>
      {children}
    </PageSetErrorContext.Provider>
  );
}

export function useGotoOptions(): GoToPageOptions {
  // TODO: Convert after we kill off hash routers
  const history = useHistoryHack(); // useHistory();
  const dispatch = useAppDispatch();
  const store = useStore<CoreReduxState>();
  return useMemo(
    () => ({
      history,
      dispatch,
      store,
    }),
    [history, dispatch, store]
  );
}

// TODO: Heavily document this. Why would you use it. What gotchas are there?
export function usePageSetEvents(cb: PageSetEventHandler) {
  const cbRef = useCallbackRef(cb);

  return useOnce(() => {
    pageSetEvents.add(cbRef);

    return () => {
      pageSetEvents.delete(cbRef);
    };
  });
}

function preloadFuturePage(
  gotoOptions: GoToPageOptions & {
    direction: Direction;
    activePageSet: PageSet;
  }
) {
  const { direction, activePageSet, store } = gotoOptions;
  const futurePageIndex = findFuturePageIndex(
    direction,
    activePageSet,
    gotoOptions
  );
  const futurePage = getPageAtLocation(activePageSet, futurePageIndex);
  if (futurePage) {
    const resolvedPage = resolvePage(futurePage, store.getState(), direction);

    if (resolvedPage && "pathname" in resolvedPage && resolvedPage.pathname) {
      preloadPagePath(resolvedPage.pathname);
    }
  }
}

pageSetEvents.add((event, eventParams) => {
  if (event === "after:goto") {
    preloadFuturePage(eventParams);
  }
});

export function usePageSetPreload(layer: string) {
  const gotoOptions = useGotoOptions();
  const state = useSelector((param: CoreReduxState) => param);

  useOnce(() => {
    getPageSetForLayer(layer, state).then((pageSet) => {
      if (pageSet) {
        preloadFuturePage({
          direction: Direction.FORWARD,
          activePageSet: pageSet,
          ...gotoOptions,
        });
      }
    });
  });
}

export type PageSetProps = {
  activePage: Page;
  activePageSet: PageSet;

  gotoNextPage: (replace?: boolean) => Promise<void>;
  gotoPrevPage: (replace?: boolean) => Promise<void>;
};

/**
 * Linear buyflow framework that wraps pool pages and handles users moving forward
 * and backwards.
 */
export function usePageSet(): PageSetProps {
  const gotoOptions = useGotoOptions();
  const onPageSetError = useContext(PageSetErrorContext);

  // Forces re-render when pageSet changes. Field is accessed below.
  useSelector((state: CoreReduxState) => state.linearBuyflow.pageSetName);

  const { activePageSet, activePage } = getActivePage(
    gotoOptions.history?.location.pathname,
    gotoOptions.store.getState()
  );

  useOnce(() => {
    ensureGotoSetup(activePageSet, activePage, gotoOptions);
    if (!activePageSet || !activePage) {
      onPageSetError?.(new Error("No active page set"));
    }
  });

  const asyncError = useAsyncError();

  return useMemo(
    () => ({
      activePage,
      activePageSet,

      gotoNextPage: async (replace?: boolean) =>
        gotoPage(Direction.FORWARD, activePageSet, gotoOptions, replace).catch(
          asyncError
        ),
      gotoPrevPage: async (replace?: boolean) =>
        gotoPage(Direction.BACKWARD, activePageSet, gotoOptions, replace).catch(
          asyncError
        ),
    }),
    [activePage, activePageSet, gotoOptions, asyncError]
  );
}
