import { setUserIdInSentryScope } from "@utils/sentry";
import { loadServerContext, setCookiesFromMeristemContext } from "./context";
import { getEmailAndNameFromURLParams } from "./urlParams";
import { USER_IS_BOT } from "./botDetector";

import getStore from "./redux/store";
import { ServerContextState } from "./redux/slices/serverContext";
import {
  updateUserData,
  initializeNewUser,
  fetchAndInitializeExistingUser,
} from "./redux/slices/userData";
import { updateVisitorStatusState } from "./redux/slices/visitorStatus";

import {
  setInitialPeopleProperties,
  trackEvent,
  formatMeristemExperimentStateVisitData,
} from "./api/tracker";

import OptimizelySXExperiment from "./services/OptimizelySXExperiment";
import visitTracker from "./services/VisitTracker";
import goto from "src/pageDefinitions/goto";
import { setInAppAutoCookie } from "./authCookieParser";
import { getExperimentState, getMeristemContext } from "./meristemContext";
import * as userSegments from "./userSegment";
import * as featureSegments from "./userSegment/features";
import { initConversionTracker } from "./services/ConversionTracker";
import { clearPromoCode } from "./redux/slices/promoCode";

/**
 * Update UserData slice (and possibly fetch an existing user's UserData).
 */
export async function updateUserDataSlice(serverContext: ServerContextState) {
  const store = getStore();

  // Fetch user from backend if they are in the database.
  // Guard against bots, don't attempt to fetch userData if we detect a bot.
  if (serverContext.user_in_db !== false && !USER_IS_BOT) {
    await store.dispatch(fetchAndInitializeExistingUser(serverContext.user_id));
  } else {
    await store.dispatch(initializeNewUser(serverContext));
  }
  const emailAndName = getEmailAndNameFromURLParams();
  if (Object.keys(emailAndName).length !== 0)
    await store.dispatch(updateUserData(emailAndName));
}

/**
 * Track changes to the user's visitor status to mixpanel
 *
 * 1. Add tracking observer to the visitorStatus slice in Redux.
 * 2. Track initial state.
 */
function trackVisitorState(userId: string) {
  // Update visitor status state on initialization to kick off VisitorStateDetermined tracking
  // Only update visitor status state on landing and payment to avoid unnecessary duplicate
  // visitorStateDetermined tracking events on post checkout contexts
  if (userSegments.isPreSignup()) {
    getStore().dispatch(updateVisitorStatusState({ user_id: userId }));
  }
}

/**
 * Initialize redux, set up services, and fire off tracking calls.
 * @returns App initializes successfully
 */
export default async function initializeApplication() {
  // Expose debug tools
  (window as any).reduxState = getStore().getState;
  (window as any).grow = {
    setInAppAutoCookie,
    goto,
    userSegments,
    featureSegments,
    clearPromoCode: () => getStore().dispatch(clearPromoCode()),
  };

  try {
    // 1 - get the meristemContext data
    const meristemContext = getMeristemContext();
    setCookiesFromMeristemContext(meristemContext);

    // 2 - get serverContext from Django API
    const serverContext = await loadServerContext();

    // 5 - set up Sentry and attach user_id as a property
    setUserIdInSentryScope(serverContext.user_id);

    // 6 - Initialize visitTracker. This must happen before we fire any mixpanel events.
    visitTracker.init(serverContext.session_cookie_domain);

    // 7 - update the UserData slice in redux (also, fetch userData if an existing user)
    await updateUserDataSlice(serverContext);

    // 8 - set up mixpanel tracker
    setInitialPeopleProperties();

    // 9 - maybe send an event that signals the user is allocated to an experiment
    const experimentsState = getExperimentState() || [];
    experimentsState.forEach((experimentState) => {
      trackEvent("OnMeristemExperimentDecided", {
        experimentName: experimentState.experimentName || null,
        variationName: experimentState.variationName || null,
        sha: experimentState.sha || experimentState.shaOverride || null,
      });
    });

    // 10 - track a visit
    visitTracker.createOrUpdateVisit(
      formatMeristemExperimentStateVisitData(experimentsState) || {}
    );

    // 11 - set up a redux observer that updates visitor state
    trackVisitorState(serverContext.user_id);

    initConversionTracker();

    // 12 - set up a helper for SX Optimizely experiments to pass through experiment variables.
    (window as any).osxe = new OptimizelySXExperiment();
  } catch (e) {
    // Identification error page for in app errors
    goto.error(e, "initialize");
    throw e;
  }
}
