import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import styled from "@emotion/styled";

import { hasValidAccount } from "@utils/redux/slices/userData";
import LoggedInHeader from "@components/logged-in-header/LoggedInHeader";
import Footer from "@components/footer/FooterPayment";

import { FlexFrame, MainColumn } from "src/components/core/BodyLayout";
import { fontLargeHeadline } from "src/design-system/styles/fonts";
import { useRecommendedPlan, useUserData } from "src/hooks/redux";
import { isFromEmail } from "src/utils/userSegment";
import { usePageView } from "src/hooks/tracking";
import { useOnce } from "src/hooks/lifecycle";
import { trackInitiateCheckout } from "src/utils/services/ConversionTracker";
import AccountSignup from "./AccountSignup";
import { getNoomSessionStorage } from "@utils/noomSessionStorage";
import { SessionStorageKey } from "@utils/constants";

type Props = {
  onComplete: () => void;
};

export const Body = styled.div`
  margin: 24px 0;
`;

export const Title = styled.h1`
  ${fontLargeHeadline};
  margin: 0;
  font-weight: normal;
`;

export default function Account({ onComplete = () => {} }: Props) {
  const recommendedPlan = useRecommendedPlan();
  const userData = useUserData();
  // Default loading to true to avoid DOM flashing on initial render.
  const [loading, setLoading] = useState(true);

  const isValidAccount = hasValidAccount(userData);
  const fromEmail = isFromEmail();

  usePageView("OnAccountPage", {
    hasValidAccount: isValidAccount,
    isFromEmail: fromEmail,
    hasAnswers: !isEmpty(userData),
  });

  useOnce(() => {
    const personaSurveyAnswers =
      userData.personaSurveyAnswers ||
      userData.personaSurveyUS ||
      userData.personaSurveyNonUS;

    trackInitiateCheckout(recommendedPlan, {
      ...personaSurveyAnswers,
      ...userData.purchaseSurveyAnswers,
    });
  });

  useOnce(() => {
    const alreadyConfirmedIdentity = getNoomSessionStorage(
      SessionStorageKey.userConfirmedIdentity
    );
    if (alreadyConfirmedIdentity) {
      // User already confirmed their identity with VerifyUser,
      // don't show account page again and pass them along.
      onComplete();
    } else {
      setLoading(false);
    }
  });

  return (
    <FlexFrame>
      <LoggedInHeader />
      <MainColumn margin={24} maxWidth={600} data-cy="account-entry">
        <Body>
          {!loading && (
            <AccountSignup userData={userData} onComplete={onComplete} />
          )}
        </Body>
        <Footer />
      </MainColumn>
    </FlexFrame>
  );
}
