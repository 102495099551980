import React, { SyntheticEvent, useEffect, useState } from "react";

import i18n from "@i18n";
import { isEmail, isName } from "@utils/validate";
import {
  updateUserData,
  saveProfile,
  UserDataState,
} from "@utils/redux/slices/userData";
import { trackEvent } from "@utils/api/tracker";
import Button from "@components/button/Button";

import { trackLead } from "src/utils/services/ConversionTracker";
import TextInput from "src/design-system/components/TextInput/TextInput";
import styled from "@emotion/styled";
import { useAppDispatch } from "src/hooks/redux";
import { usePageView } from "src/hooks/tracking";
import { compassColors } from "src/utils/styling";
import { flexMiddle } from "src/design-system/styles/layout";
import { Title } from "./Account";

type Props = {
  onComplete: () => void;
  userData: UserDataState;
};

const FormTitle = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${compassColors.grey1};
  padding: 14px 0;
  font-family: "Brown LL", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  margin-bottom: 15px !important;
  text-transform: uppercase;
  color: ${compassColors.blueberry};
  line-height: 14px;
`;

const EmailWrapper = styled.div`
  margin-top: 1rem;
`;

const ButtonWrapper = styled.div`
  ${flexMiddle};
  width: 100%;
  margin-top: 50px;
`;

/**
 * This component is shown if the user is anonymous or has no user data
 * and it visiting a page protected by <AccountRequired>. We first need
 * to capture their name and email before continuing, aka a "signup"
 */
export default function AccountSignup({ userData, onComplete }: Props) {
  const dispatch = useAppDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  usePageView("OnAccountSignupPage");

  // Update the internal email and name state if redux updated.
  useEffect(() => {
    if (userData.email) {
      setEmail(userData.email);
    }

    if (userData.name) {
      setName(userData.name);
    }
  }, [userData]);

  /**
   * On form submit, update Redux and move the user to the next page.
   */
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    const formattedEmail = email.toLowerCase();

    // Validate both fields are valid.
    if (isName(name) && isEmail(formattedEmail)) {
      const properties = {
        email,
        name,
      };

      // Not necessary to update the userData redux store again since saveProfile
      // will update once the request returns. However, save it to prevent the
      // name flashing in the logged-in-header.
      dispatch(updateUserData(properties));

      dispatch(saveProfile(properties)).then(() => {
        trackEvent("OnSignUpFormCompleted", properties);
        trackLead(email, name);
        onComplete();
      });
    }
  };

  return (
    <>
      <Title>{i18n.t("accountSignup:createProfile")}</Title>

      <form css={{ marginTop: "4rem" }} onSubmit={handleSubmit}>
        <FormTitle>{i18n.t("accountSignup:accountInfo")}</FormTitle>

        <TextInput
          label={i18n.t("accountSignup:name")}
          labelStyle="above"
          hasError={!isName(name)}
          size={40}
          minLength={3}
          required
          name="name"
          placeholder={i18n.t("accountSignup:namePlaceholder")}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <EmailWrapper>
          <TextInput
            label={i18n.t("accountSignup:email")}
            labelStyle="above"
            hasError={!isEmail(email)}
            size={40}
            required
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </EmailWrapper>

        <ButtonWrapper>
          <Button type="submit" data-cy="account-submit">
            {i18n.t("accountSignup:continue")}
          </Button>
        </ButtonWrapper>
      </form>
    </>
  );
}
