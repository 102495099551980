import { routeConstants } from "../constants";
import { checkSegments } from "./util";
import { isEnUS } from "./locale";

export const isApp14DayBaseRoute = checkSegments({
  oneOf: [routeConstants.app14DayWeb, routeConstants.app14Day],
});

export const isInAppWinback = checkSegments({
  oneOf: [
    routeConstants.app14DayFtWeb,
    routeConstants.app14DayReturning,
    routeConstants.app14DayWebReturning,
    routeConstants.appNoTrialReturning,
  ],
});

export const isInApp = checkSegments({
  oneOf: [isInAppWinback, isApp14DayBaseRoute, routeConstants.appNoTrial],
});
export const isEnUSInAppWinback = checkSegments({
  allOf: [isEnUS],
  oneOf: [isInAppWinback],
});
export const isInApp14DayiOS = checkSegments({
  oneOf: [routeConstants.app14Day, routeConstants.app14DayReturning],
});
