import dateOptions from "@utils/dateOptions";
import { millisecondsInMinute } from "date-fns";
import { ConsultType } from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";

const timeOffsetMinutes = 5;

export const getLocalFormattedDate = (
  start: string,
  consultType: ConsultType
) => {
  /*
    As a part of HC-278 we started shifting the time we tell RPM patients the
    appointment starts to be five minutes after the actual start time.
  */
  const actualStartTime = new Date(Date.parse(start));

  const offsetMilliseconds =
    consultType === ConsultType.RPM
      ? timeOffsetMinutes * millisecondsInMinute
      : 0;

  const timeToDisplayToUser = new Date(
    actualStartTime.getTime() + offsetMilliseconds
  );

  return timeToDisplayToUser.toLocaleString("en-US", dateOptions);
};
