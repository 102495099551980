import { ActionHandler, ActionHandlerParams } from "..";
import { CONSULTATION_SURVEY } from "@components/refactored-survey/question-sets/consultation-survey-questions/NOOM_PREMIUM_CONSULTATION_SURVEY";
import { QuestionOption } from "@components/refactored-survey/QuestionDefinition";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { CoreReduxState } from "@utils/redux/store";
import saveToBraze from "@utils/brazeUploader";

const CONSULTATION_QUESTION_BRAZE_ATTRIBUTE: Record<string, string> = {
  workoutPlanEquipment: "equipment",
  mealPlanDietaryRestrictions: "dietary_restrictions",
  workoutPlanLimitations: "physical_restrictions",
  workoutPlanFrequency: "workout_frequency",
};

const eventNames = {
  mealPlanDietaryRestrictions: "form_submitted-custom_meal_plan",
  workoutPlanEquipment: "form_submitted-custom_workout_plan",
};

const brazeTextAnswers = (questionId: string, answerIds) => {
  const question = CONSULTATION_SURVEY.find((x) => x.id === questionId);
  if ("options" in question) {
    const { options } = question;
    return answerIds.map((answerId) =>
      answerId.other != null
        ? `Other: ${answerId.other}`
        : (options as QuestionOption[]).find((a) => a.id === answerId).text
    );
  }
  return answerIds;
};

const onAnswerReceived = (
  currentSurveyAnswers: SurveyAnswersState,
  questionId: string,
  event: JsonObject = null,
  userData: CoreReduxState["userData"]
): Promise<void> => {
  if (!currentSurveyAnswers || !currentSurveyAnswers[questionId]) {
    return Promise.resolve();
  }
  const brazeAttributeId = CONSULTATION_QUESTION_BRAZE_ATTRIBUTE[questionId];
  if (!brazeAttributeId) {
    return Promise.resolve();
  }

  const brazeText = brazeTextAnswers(
    questionId,
    currentSurveyAnswers[questionId]
  ).join(" and ");

  return saveToBraze(
    userData.user_id,
    {
      [brazeAttributeId]: brazeText,
    },
    event
  );
};

type Params = {
  questionId?: string;
};

export const onQuestionAnsweredConsultationSurvey: ActionHandler = ({
  store,
  params,
}: {
  params: Params;
} & ActionHandlerParams): Promise<void> => {
  const { questionId } = params;
  const { surveyAnswers } = store.getState();
  const brazeEvent = eventNames[questionId]
    ? {
        event_name: eventNames[questionId],
      }
    : null;
  return onAnswerReceived(
    surveyAnswers,
    questionId,
    brazeEvent,
    store.getState().userData
  );
};
