import type { Context } from "@noom/meristem-context";
import { ReadonlyDeep } from "type-fest";
import { logInfo } from "./logging";

export interface MeristemContext {
  context: Context;
  sha: string;
  meristemState: MeristemState;
  experimentsCookie: ExperimentDefinition[];
  error?: string;
}

export interface MeristemState {
  id: number;
  owner: string;
  repo?: string;
  baseline: string;
  description: string;
  experiments: MeristemExperiment[];
}

export interface MeristemExperiment {
  name: string;
  targeting: Targeting[];
  variations: Variation[];
  isGreedyTargeting?: boolean;

  // Not found upstream but referenced in our code
  funnelId?: string;
  startEvents: {
    name: string;
    filters: Targeting[];
  }[];
}
export interface Targeting {
  key: string;
  operator: string;
  value?: string | string[];
}
interface Variation {
  name: string;
  repo?: string;
  sha: string;
  weight: number;
}

export interface ExperimentDefinition {
  // This name is mapped to experiment.id from meristem state.
  experimentName: string;
  variationName: string;
  sha: string;
  shaOverride?: string;
  repo?: string;
}

export function getSha() {
  const globalMeristemContext: MeristemContext = (window as any)
    .meristemContext;
  return globalMeristemContext.sha;
}

/**
 * Returns the meristemContext passed by buyflow-worker, or it fallbacks to default for development environment
 */
export function getMeristemContext(): ReadonlyDeep<Context> {
  const globalMeristemContext: MeristemContext = (window as any)
    .meristemContext;

  if (!globalMeristemContext) {
    // We want to trigger sentry error if the meristem context defaults for the production environment
    throw new Error("MeristemContextDefaulted-UnavailableOnWindow");
  }

  return globalMeristemContext.context;
}

/**
 * Returns user's two character country code (e.g. "US", "GB")
 */
export function getCountryCode(): string {
  const { country_code } = getMeristemContext();
  return country_code;
}

/**
 * Returns user's current locale (i.e. en-US)
 * Returns 'pure' language code if country not found (en)
 */
export function getLocale(): string {
  const { language_code = "en", country_code } = getMeristemContext();
  if (!country_code) {
    return language_code.toLowerCase();
  }
  return `${language_code.toLowerCase()}-${country_code.toUpperCase()}`;
}

export function getExperimentState(): ReadonlyDeep<ExperimentDefinition[]> {
  const globalMeristemContext: MeristemContext = (window as any)
    .meristemContext;
  return globalMeristemContext.experimentsCookie || [];
}

export function getMeristemState(): ReadonlyDeep<MeristemState> {
  const globalMeristemContext: MeristemContext = (window as any)
    .meristemContext;

  return globalMeristemContext.meristemState;
}

export function setContextType(context: string) {
  logInfo(`Setting context type to ${context}`);
  const globalMeristemContext: MeristemContext = (window as any)
    .meristemContext;
  globalMeristemContext.context.context_type = context;
}

export function extractStartEvent(experimentName: string, eventName: string) {
  const meristemExperiment = getMeristemState()?.experiments?.find(
    (experiment) => experiment.name === experimentName
  );
  if (meristemExperiment && meristemExperiment.startEvents) {
    const startEvent = meristemExperiment.startEvents.find(
      (needle) => needle.name === eventName
    );
    if (startEvent) {
      return startEvent;
    }
  }
  return null;
}
