import { css } from "@emotion/react";

/** Creates a flex parent that centers content horizontally. */
export const flexHorizontalCenter = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

/** Creates a flex parent that centers content on both axises. */
export const flexMiddle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const fixedFullSize = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;
