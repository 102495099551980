/**
 * Implements the primary redux store.
 *
 * Note that this has been separated from src/utils/redux/store.ts to allow for
 * indirect, deferred, loading of this file. This helps us avoid init time circular
 * dependencies, reducing some possible startup errors and allowing for much easier
 * testing of the redux store and it's components.
 */
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import checkoutSlice from "./slices/checkout";
import serverContextSlice from "./slices/serverContext";
import plansSlice from "./slices/plans";
import recommendedPlansSlice from "./slices/recommendedPlan";
import surveyAnswersSlice from "./slices/surveyAnswers";
import trialSlice from "./slices/trial";
import surveyNameSpaceSlice from "./slices/surveyNameSpace";
import languageSlice from "./slices/language";
import userDataReducer from "./slices/userData";
import visitorStatusSlice from "./slices/visitorStatus";
import paymentEnrollmentFormSlice from "./slices/paymentEnrollmentForm";
import geoLocationSlice from "./slices/geoLocation";
import routeIdSlice from "./slices/routeId";
import linearBuyflowSlice from "./slices/linearBuyflow";
import activeSubscriptionDataSlice from "./slices/activeSubscriptionData";
import upidSlice from "./slices/upid";
import winbackInfoSlice from "./slices/winbackInfo";

import { rehydrateStore } from "./persistence";
import programSwitchErrorDetailsSlice from "./slices/programSwitchErrorDetails";
import purchasedNoomPremiumSlice from "./slices/purchasedNoomPremium";
import promoCodeSlice from "./slices/promoCode";

export const rootReducer = combineReducers({
  serverContext: serverContextSlice.reducer,
  checkout: checkoutSlice.reducer,
  plans: plansSlice.reducer,
  recommendedPlan: recommendedPlansSlice.reducer,
  surveyAnswers: surveyAnswersSlice.reducer,
  trial: trialSlice.reducer,
  userData: userDataReducer,
  surveyNameSpace: surveyNameSpaceSlice.reducer,
  language: languageSlice.reducer,
  visitorStatus: visitorStatusSlice.reducer,
  paymentEnrollmentForm: paymentEnrollmentFormSlice.reducer,
  geoLocation: geoLocationSlice.reducer,
  routeId: routeIdSlice.reducer,
  linearBuyflow: linearBuyflowSlice.reducer,
  activeSubscriptionData: activeSubscriptionDataSlice.reducer,
  upid: upidSlice.reducer,
  programSwitchErrorDetails: programSwitchErrorDetailsSlice.reducer,
  purchasedNoomPremium: purchasedNoomPremiumSlice.reducer,
  promoCode: promoCodeSlice.reducer,
  winbackInfo: winbackInfoSlice.reducer,
});

export type ReducerStore = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  preloadedState: rehydrateStore(),
});

/**
 * WARN: This should only be imported by the getStore method. Direct imports will
 * potentially cause circular dependencies.
 */
export const dangerousInternalStore = store;

export type CoreReduxStore = typeof store;
export type CoreReduxState = ReturnType<typeof store.getState>;
export type GetAppState = typeof store.getState;
export type AppDispatch = typeof store.dispatch;
