import goto from "src/pageDefinitions/goto";

export const TELEHEALTH_PLAN = "option_k_20210608_ex680";

// exit the telehealth flow for a user that has ineligible insurance or opts out
export const exitTelehealthBuyflow = (): void => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const noomPlanId = urlSearchParams.get("noomPlanId");
  goto.chooseTrialPrice(noomPlanId);
};

export const continueToTelehealthPayment = (): void => {
  goto.telehealthPaymentDetails();
};
