export function getPaintTimings() {
  const ret: Record<string, number> = {};

  if (window.performance?.getEntriesByType) {
    const { performance } = window;
    const performanceEntries = performance.getEntriesByType("paint");
    performanceEntries.forEach((performanceEntry) => {
      const cameledName = performanceEntry.name.replace(/-[a-z]/g, (val) =>
        val.slice(1).toUpperCase()
      );
      ret[cameledName] = performanceEntry.startTime;
    });
  }

  return ret;
}

export function isNavShortened() {
  const { barTime } = window as any;
  return barTime != null;
}

export function navTimeoutDuration(timeout: number): number {
  const { barTime } = window as any;
  return barTime != null ? barTime : timeout;
}

export function setNavTimeout(cb: () => void, timeout: number) {
  const id = setTimeout(cb, navTimeoutDuration(timeout));
  return () => clearTimeout(id);
}
