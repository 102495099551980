import React, { ComponentType } from "react";
import Question from "./Question";
import {
  CustomQuestionDefinition,
  OptionsInputDefinition,
  QuestionOption,
  QuestionProps,
} from "../QuestionDefinition";
import { SurveyAnswersState } from "src/utils/redux/slices/surveyAnswers";
import { CoreReduxState } from "src/utils/redux/store";
import { questionOptions } from "@components/refactored-survey/question-types/Question.module.less";
import styles from "./RadioQuestion.module.less";
import { setNavTimeout } from "src/utils/timing";

type DisclaimerComponentDefinition = {
  answerValue?: string;
};

export type RadioQuestionDefinition = OptionsInputDefinition & {
  disclaimer?: string;
  checkboxText?:
    | string
    | ((
        context: Pick<
          CoreReduxState,
          "surveyAnswers" | "serverContext" | "userData"
        >
      ) => string);
  disclaimerComponent?: ComponentType<DisclaimerComponentDefinition>;
};

export type CustomRadioQuestionDefinition = RadioQuestionDefinition &
  CustomQuestionDefinition;

export class RadioQuestion<
  Def extends RadioQuestionDefinition = RadioQuestionDefinition,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ExtraProps = {}
> extends Question<Def, ExtraProps> {
  constructor(props: QuestionProps<Def, ExtraProps>) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.hideControls = true;
  }

  /**
   * Handle click of radio button label or input.
   * @param {Event} e
   */
  handleClick(e) {
    const answers: SurveyAnswersState = {};
    // NOTE(patrick): The old survey wrapped radio question answers in an array.
    // We want to preserve this aspect of the old survey for now. In the future,
    // figure we want to unwrap radio question values.
    answers[this.props.question.id] = [e.target.value];
    const answerIsValid = this.validateAnswer(answers);
    this.props.registerAnswer(answers, answerIsValid);

    if (answerIsValid && this.props.onClickNext) {
      setNavTimeout(this.props.onClickNext, 100);
    }
  }

  /**
   * Given an answer object, validate its contents and return
   * a bool indicating valid or invalid.
   * @param {object} answer
   */
  override validateAnswer(answer) {
    if (answer == null) {
      return false;
    }

    // If a custom validator is passed into props. use that instead.
    if (typeof this.props.question.validator === "function") {
      return this.props.question.validator(answer);
    }

    // Otherwise fall back to the built in validator.
    // Check to see if each answer exists.
    const answerValidation = Object.values(answer).map((ans) => {
      if (Array.isArray(ans) && ans[0]) {
        return true;
      }
      return !!(typeof ans === "string" && ans);
    });
    return Object.keys(answer).length > 0 && answerValidation.every(Boolean);
  }

  /**
   * Returns the key for the radio option in the active survey question.
   */
  getQuestionAnswerKey() {
    const surveyAnswer = this.getSurveyAnswer(this.props.question.id);
    if (Array.isArray(surveyAnswer) && surveyAnswer.length >= 1) {
      return surveyAnswer[0] as string;
    }
    if (typeof surveyAnswer === "string") {
      return surveyAnswer;
    }
    return null;
  }

  /**
   * Returns the stored survey answer for questionId or null
   */
  getSurveyAnswer(questionId: string) {
    if (questionId in this.props.surveyAnswers) {
      return this.props.surveyAnswers[questionId];
    }
    return null;
  }

  /**
   * Render the radio button inputs.
   */
  override renderOptions(options: QuestionOption[]) {
    return (
      <div className={questionOptions}>
        {options.map((option) => (
          <label
            htmlFor={option.id}
            key={option.id}
            className={`${styles.radioQuestionOption} ${
              option.id === this.getQuestionAnswerKey() ? styles.checked : ""
            }`}
          >
            <input
              type="radio"
              defaultValue={option.id}
              name={this.props.question.id}
              id={option.id}
              defaultChecked={option.id === this.getQuestionAnswerKey()}
              onClick={this.handleClick}
            />
            {option.text}
          </label>
        ))}
      </div>
    );
  }

  override renderExtraContent() {
    return (
      <>
        {this.props.question.disclaimer && (
          <p className={styles.disclaimer}>{this.props.question.disclaimer}</p>
        )}
      </>
    );
  }
}
