/* eslint-disable no-console */
import {
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

/**
 * Hook to run a callback on mount and unmount.
 */
export function useOnce(cb: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(cb, []);
}

/**
 * Returns a callback that will be invoked once during the component lifecycle.
 * The invoked function will be the most recent callback passed to the hook at call time.
 * Useful when _.once behavior is needed within a functional component.
 */
export function useCallbackOnce<T extends (...args: any[]) => any>(cb: T) {
  const hasExecuted = useRef(false);
  const callback = useCallbackRef(cb);

  return useCallback((...args) => {
    if (!hasExecuted.current) {
      callback(...args);
      hasExecuted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

/**
 * Provides a singleton callback that will call the most recent version of the
 * given callback. This is useful for components and hooks which need to avoid
 * issues due to passing callback props to deps arrays.
 */
export function useCallbackRef<T extends (...args: any[]) => any>(cb: T) {
  const ref = useRef(cb);
  ref.current = cb;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args: any[]) => ref.current(...args)) as T, []);
}

/**
 * Hook for keeping track of a "loading state". Call `load` with a promise and this will remain
 * `loading` until that promise resolves or rejects.
 */
export function useLoader<T>() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const load = useCallback((loader: Promise<T>) => {
    setLoading(true);
    setError(null);

    return loader.then(
      (result) => {
        setLoading(false);
        return result;
      },
      (err) => {
        setLoading(false);
        setError(err);
        throw err;
      }
    );
  }, []);

  return {
    loading,
    error,
    load,
  };
}
