import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const promoCodeInitialState = {
  promoCodeApplied: "",
  promoDiscountAmount: 0,
  promoDiscountPercentage: 0,
  promoCodeIsVip: false,
  promoCodeError: "",
} as PromoCodeState;

export type PromoCodeState = {
  promoCodeApplied: string;
  promoDiscountAmount: number;
  promoDiscountPercentage: number;
  promoCodeIsVip: boolean;
  promoCodeError: string;
};

const promoCode = createSlice({
  name: "promoCode",
  initialState: promoCodeInitialState,
  reducers: {
    setPromoCode(state, action: PayloadAction<Partial<PromoCodeState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearPromoCode() {
      return {
        ...promoCodeInitialState,
      };
    },
  },
});

export const { setPromoCode, clearPromoCode } = promoCode.actions;

export default promoCode;
