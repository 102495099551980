import i18n, { InitOptions } from "i18next";
import { getLocale } from "@utils/meristemContext";

/**
 * Core language translations
 * */
import en from "./en/translations.json";
import de from "./de/translations.json";
import es from "./es/translations.json";
import ja from "./ja/translations.json";
import ko from "./ko/translations.json";

/**
 * Locale-specific translations
 *
 * NOTE: All translations should be first added to the "core"
 * language files. These files are only meant to override
 * locale-specific differences (i.e. Color vs Colour).
 * See docs/translations.md for more information.
 * */
import enAU from "./en-AU/translations.json";
import enCA from "./en-CA/translations.json";
import enGB from "./en-GB/translations.json";
import enMX from "./en-MX/translations.json";
import enNZ from "./en-NZ/translations.json";
import enPK from "./en-PK/translations.json";
import enSG from "./en-SG/translations.json";
import enUS from "./en-US/translations.json";

const locale = getLocale();

const settings: InitOptions = {
  lng: locale,
  fallbackLng: "en",
  saveMissing: true,
  debug:
    __NODE_ENV__ !== "production" &&
    __NODE_ENV__ !== "test" &&
    typeof window !== "undefined",
  interpolation: {
    escapeValue: false,
  },
  react: {
    // Enables keeping the name of simple nodes (e.g. <br/>) in translations instead of indexed keys
    transSupportBasicHtmlNodes: true,
    // TODO: Drop ul and li once question sets support richer data structures
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "ul", "li"],
  },
  resources: {
    en,
    es,
    de,
    ja,
    ko,
    "en-AU": enAU,
    "en-CA": enCA,
    "en-GB": enGB,
    "en-MX": enMX,
    "en-NZ": enNZ,
    "en-PK": enPK,
    "en-SG": enSG,
    "en-US": enUS,
  },
};

// Report missing translations to Sentry
if (__NODE_ENV__ === "production") {
  settings.missingKeyHandler = (language, namespace, key, fallback) => {
    // TODO: Migrate error handling to Sentry once strings have stabilized
    // and are less noisy
    (window as any).mixpanel.track("MissingTranslationError", {
      language,
      namespace,
      key,
      fallback,
    });
  };
} else {
  settings.missingKeyHandler = (lng, ns, key, fallback) => {
    // Soft failure to reduce Sentry noise in development
    // eslint-disable-next-line no-console
    console.error("Key Missing!", lng, ns, key, fallback);
  };
}

i18n.init(settings);

export default i18n;
