/**
 * Get the email and name fields from url parameters.
 */

type EmailAndType = {
  email?: string;
  name?: string;
};

// WARN: This function must omit keys when missing rather than pass undefined.
export function getEmailAndNameFromURLParams() {
  const urlParams = getURLParams();
  const obj: EmailAndType = {};

  if (urlParams.email) {
    obj.email = urlParams.email;
  }
  if (urlParams.name) {
    obj.name = urlParams.name;
  }

  return obj;
}

/**
 * Get all url parameters.
 * NOTE(Patrick): This implementation uses the last key in the search string if
 * a key shows up more than once. While not ideal, this is the implementation used
 * by Conversion Tracker. If you need an implementation that supports search params
 * that appear more than once, please see
 * https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object.
 */
export function getURLParams() {
  const params = new URLSearchParams(window.location.search);
  // Object.create(null) creates an object with no prototype to prevent prototype pollution.
  // https://github.com/Kirill89/prototype-pollution-explained
  const urlParams: Record<string, string> = Object.create(null);
  params.forEach((value, key) => {
    urlParams[key] = value;
  });
  return urlParams;
}

export function getSearchQuery(params: URLSearchParams) {
  params?.sort();

  // If we have any parameters, output the query string
  if (params && !params.keys().next().done) {
    return `?${params}`;
  }
  return "";
}

export function removeURLParam(name: string) {
  // eslint-disable-next-line no-restricted-globals
  const url = new URL(location.href);
  url.searchParams.delete(name);

  // eslint-disable-next-line no-restricted-globals
  history.replaceState(history.state, null, url.toString());
}
