import React from "react";
import { Trans } from "react-i18next";
import i18n from "@i18n";

interface Props {
  // Key of i18n string
  i18nKey?: string;
  /**
   * Fallback used for LBF templates or any consumers cannot distinguish between data types
   * that distinguish between static strings and i18n keys.
   * Please use `i18nKey` over `i18nKeyOrText` whenever possible.
   * */
  i18nKeyOrText?: string;
  // A group of custom components to interpolate into the string
  components?: { readonly [tagName: string]: React.ReactNode };
  // Values used for static interpolation
  values?: Record<string, unknown>;
  // The HTML element to wrap the text with (i.e. "p", "span")
  parent?: string | React.ComponentType<any> | null;
  // Used for pluralization
  count?: number;
  // Optional fallback string if i18n key cannot be resolved
  defaults?: string;
  // Class name to assign to the component. Both <Trans /> and <Component /> support className
  className?: string;
}

/**
 * This component dynamically renders either text literals or translated i18n strings.
 * This component also supports interpolation of static content (using values prop)
 * or with rich/custom content (using components prop). For more information about interpolation,
 * See docs/translations.md for information on how and when to use this component.
 * See https://react.i18next.com/latest/trans-component for more information about the Trans component.
 */
export function Text(props: Props) {
  const { i18nKeyOrText, ...transProps } = props;
  if (i18nKeyOrText) {
    if (typeof i18nKeyOrText === "string" && i18nKeyOrText.startsWith("t:")) {
      transProps.i18nKey = i18nKeyOrText.substring(2);
    } else {
      // Static string was passed, render without translation
      const Component = props.parent || "span";
      return (
        <Component className={props.className} as={props.parent}>
          {i18nKeyOrText}
        </Component>
      );
    }
  }

  return <Trans i18n={i18n} {...transProps} />;
}
