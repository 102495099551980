import getStore from "./redux/store";

export const getEnrollmentParams = () => {
  const { userData, recommendedPlan } = getStore().getState();

  const {
    name,
    email,
    personaSurveyAnswers,
    personaSurveyUS,
    weight,
    heightFeet,
    heightInch,
  } = userData;

  const params: JsonObject = {
    name,
    email,
    planId: recommendedPlan.noom_plan_id,
  };

  const surveyAnswers = personaSurveyAnswers || personaSurveyUS;
  if (surveyAnswers != null) {
    params.weight_lbs = surveyAnswers.weight || weight;
    params.height_ft = surveyAnswers.heightFeet || heightFeet;
    params.height_in = surveyAnswers.heightInch || heightInch;
    params.gender =
      surveyAnswers.gender && surveyAnswers.gender.length > 0
        ? surveyAnswers.gender[0].toUpperCase()
        : undefined;
  }
  return params;
};
