import React, { Component, ReactNode } from "react";

import Controls from "@components/refactored-survey/Controls";
import getStore from "@utils/redux/store";
import styles from "./Question.module.less";
import {
  BaseQuestionDefinition,
  OptionsInputDefinition,
  QuestionOption,
  QuestionProps,
} from "../QuestionDefinition";
import {
  SurveyAnswer,
  SurveyAnswersState,
} from "src/utils/redux/slices/surveyAnswers";

interface QuestionOverrides {
  /**
   * This method is fired at the end of componentDidMount. Use this
   * to do stuff in componentDidMount without having to worry about
   * copying over the handy code that's run in componentDidMount. You're
   * free to override componentDidMount to modify any behavior if necessary.
   */
  onMount: () => void;

  /**
   * Given an answer object, validate its contents and return
   * a bool indicating valid or invalid.
   */
  validateAnswer: (answer: SurveyAnswer) => boolean;

  /**
   * This method is responsible for rendering the question text.
   */
  renderQuestion: () => React.ReactNode;

  /**
   * This method is responsible for rendering the options.
   */
  // eslint-disable-next-line class-methods-use-this
  renderOptions: (options: QuestionOption[]) => React.ReactNode | null;

  /**
   * This method is responsible for rendering extra content like the concierge blurb text.
   */
  renderExtraContent: () => React.ReactNode;

  /**
   * This method is responsible for rendering the footer text.
   */
  renderFooter: () => React.ReactNode;

  /**
   * Override
   * This method is responsible for rendering the controls
   * @returns {JSX.Element}
   */
  renderControls: () => React.ReactNode;

  /**
   * By overriding this method a custom question can be created with keeping the original controls
   */
  renderWithControls: () => React.ReactNode;

  onClickNextOverride?: () => void;
}

/**
 * Question base class, meant to be subclassed by more specific question types.
 */
class Question<
    Definition extends BaseQuestionDefinition,
    // eslint-disable-next-line @typescript-eslint/ban-types
    ExtraProps = {},
    // TODO: Scope reduction hack. Drop this for proper state generic in future.
    State = any
  >
  extends Component<QuestionProps<Definition, ExtraProps>, State>
  implements QuestionOverrides
{
  onClickNextOverride?: () => void;
  hideControls = false;
  useStickyControls = false;

  override componentDidMount() {
    this.props.onQuestionLoaded?.();

    // Update canProceed if the component mounts (i.e. when the user lands on the question).
    if (this.props.question.id in this.props.surveyAnswers) {
      const answer = this.props.surveyAnswers[this.props.question.id];
      const ans: SurveyAnswer = { [this.props.question.id]: answer };
      const answerIsValid = this.validateAnswer(ans);
      this.props.registerAnswer({}, answerIsValid);
    }

    this.onMount();
  }

  override componentDidUpdate(
    prevProps: QuestionProps<Definition, ExtraProps>
  ) {
    // Update canProceed if the surveyAnswerSeed was updated.
    if (prevProps.surveyAnswerSeed !== this.props.surveyAnswerSeed) {
      const answer: SurveyAnswersState = {};
      if (this.props.question.id in this.props.surveyAnswers) {
        answer[this.props.question.id] =
          this.props.surveyAnswers[this.props.question.id];
      } else if (this.props.surveyAnswerSeed) {
        answer[this.props.question.id] =
          this.props.surveyAnswerSeed[this.props.question.id];
      }
      const answerIsValid = this.validateAnswer(answer);
      this.props.registerAnswer({}, answerIsValid);
    }
  }

  onMount() {
    /* NOP */
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validateAnswer(answer: SurveyAnswer) {
    return true;
  }

  evalQuestion(): ReactNode {
    const questionHeader = this.props.question
      .question as BaseQuestionDefinition["question"];
    if (typeof questionHeader === "function") {
      return questionHeader({
        ...this.props,
        userData: getStore().getState().userData,
      });
    }

    return questionHeader;
  }

  /**
   * Since questions can be in the form of a function or string.
   * If it's a function, execute the question.
   */
  renderQuestionFunctionOrJSX() {
    const questionHeader = this.props.question
      .question as BaseQuestionDefinition["question"];
    if (typeof questionHeader === "function") {
      return this.evalQuestion();
    }
    return this.renderQuestion();
  }

  renderQuestion() {
    return <h1 className={styles.questionTitle}>{this.evalQuestion()}</h1>;
  }

  evalOptions(): QuestionOption[] {
    const { options } = this.props
      .question as unknown as OptionsInputDefinition;
    if (typeof options === "function") {
      return options(this.props);
    }
    return options || [];
  }

  /**
   * Options can be a function that returns an array of options.
   */
  renderOptionsFunctionOrJSX() {
    return this.renderOptions(this.evalOptions());
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderOptions(options: QuestionOption[]) {
    return null as ReactNode;
  }

  renderExtraContent() {
    return null as ReactNode;
  }

  renderFooterOrJSX() {
    const footer = this.props.question
      .footer as BaseQuestionDefinition["footer"];
    if (typeof footer === "function") {
      return footer();
    }

    return this.renderFooter();
  }

  renderFooter() {
    if (!this.props.question.footer) {
      return null as ReactNode;
    }
    return <div className={styles.footer}>{this.props.question.footer}</div>;
  }

  /**
   * Override
   * This method is responsible for rendering the controls
   */
  renderControls() {
    return (
      <Controls
        onClickNext={
          this.onClickNextOverride
            ? this.onClickNextOverride
            : this.props.onClickNext
        }
        next={this.props.question.next}
        canProceed={this.props.canProceed}
        isLoading={this.props.isLoading}
        hideControls={this.hideControls}
        useStickyControls={this.useStickyControls}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderWithControls() {
    return null as ReactNode;
  }

  override render() {
    return (
      <>
        {this.renderWithControls() || (
          <div
            className={`${styles.question} ${
              styles[this.props.question.id] || ""
            }`}
            data-cy="question"
            data-cy-question={this.props.question.id}
          >
            {this.renderQuestionFunctionOrJSX()}
            {this.renderOptionsFunctionOrJSX()}
            {this.renderExtraContent()}
          </div>
        )}
        {this.renderControls()}
        {this.renderFooterOrJSX()}
        {this.props.children}
      </>
    );
  }
}

export default Question;
