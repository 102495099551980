import { registerHandler } from "../handler";

declare const gtag: any;

export default function registerHandlers() {
  registerHandler("lead", () => {
    gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-10175718/conte0/email0+standard",
    });
  });

  registerHandler("initiateCheckout", () => {
    gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-10175718/purch0/initi0+standard",
    });
  });

  registerHandler("purchase", ({ order }) => {
    gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-10175718/purch0/purch0+standard",
      u1: order.subscriptionId,
      u2: order.erev_9_months,
    });
  });
}
