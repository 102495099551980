/* eslint-disable no-restricted-globals */
import buyflowGoto, { linkTo as buyflowLinkTo } from "./buyflow";
import purchaseGoto from "./purchase";
import offersGoto from "./offers";
import enrollmentGoto from "./enrollment";
import referralGoto from "./referral";
import telehealthGoto from "./telehealth";
import miniCoursesGoto from "./miniCourses";
import noomPremiumGoto from "./noomPremium";
import pageSetGoto from "./pageSet";
import courseGoto from "./course";
import { FetchInAppServerContextError } from "src/utils/context";
import { captureException } from "src/utils/sentry";
import { push } from "./utils";

export const linkTo = {
  ...buyflowLinkTo,
};

export default {
  ...pageSetGoto,
  ...buyflowGoto,
  ...purchaseGoto,
  ...offersGoto,
  ...enrollmentGoto,
  ...referralGoto,
  ...telehealthGoto,
  ...miniCoursesGoto,
  ...courseGoto,
  ...noomPremiumGoto,

  // loginBack() should be removed once Login page is ported to LBF
  loginBack() {
    history.back();
  },

  currentSurvey(questionId: string, currentQuestionId = "") {
    // WARN: We assume hash router for all surveys at this point.
    const url = new URL(location.href);
    const pathSansTailingSlash = url.hash.replace(/\/$/, "");
    // istanbul ignore if : sanity
    if (!pathSansTailingSlash.endsWith(currentQuestionId)) {
      throw new Error(
        `Unexpected survey path: ${pathSansTailingSlash} expected ${currentQuestionId}`
      );
    }

    const surveyPath = currentQuestionId
      ? pathSansTailingSlash.slice(0, -currentQuestionId.length - 1)
      : pathSansTailingSlash;
    url.hash = `${surveyPath}/${questionId}`;
    push(`${url}`);
  },

  error(err?: Error, fingerprint?: string) {
    if (err) {
      // Use react error view in development
      if (__NODE_ENV__ !== "production") {
        throw err;
      } else {
        captureException(err, fingerprint);
      }
    }

    const identificationError = err instanceof FetchInAppServerContextError;

    location.href = `/${
      identificationError ? "identification-" : ""
    }error.html${location.search}`;
  },
};
