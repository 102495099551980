import React, {
  forwardRef,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  useState,
} from "react";
import styled from "@emotion/styled";
import { compassColors } from "src/utils/styling";
import { fontP2Regular, fontP4Regular } from "src/design-system/styles/fonts";

export type TextInputProps = {
  "data-cy"?: string;
  errorMessage?: ReactNode;
  hasError: boolean;
  label: string;
  labelStyle: "hidden" | "above";
  suffixElement?: ReactNode;
  isSuffixInteractive?: boolean;
  /** Depricated. Ultimately we want to switch to using html form elements
   * which provide this behavior for free.
   * @deprecated
   */
  onHitEnter?: () => void;
  type?: "text" | "email" | "number";
} & InputHTMLAttributes<HTMLInputElement>;

const { cinnamon, grey2, grey4, lagoon, white } = compassColors;

const LabelText = styled.span`
  ${fontP2Regular}
  display: inline-block;
  color: ${grey4};
  margin-bottom: 8px;
`;

const Input = styled.input<{ hasError: boolean }>`
  ${fontP2Regular}
  background-color: ${white};
  border-radius: 8px;
  box-sizing: border-box;
  flex: 1;
  height: 62px;
  outline: 0;
  width: 100%;
  ${(props) =>
    props.hasError
      ? `border: solid 2px ${cinnamon}; padding: 16px 18px;`
      : `border: solid 1px ${grey2}; padding: 17px 19px;`}

  &::placeholder {
    color: ${grey2};
  }

  &:focus {
    border: 1px solid ${lagoon};
    padding: 17px 19px;
  }
`;

const Error = styled.p`
  ${fontP4Regular}
  color: ${cinnamon};
  margin: 0;
  width: 100%;
`;

// Container that a suffix can be absolutely positioned within
const InputWithSuffixContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const SuffixContainer = styled.div<{ interactive: boolean }>`
  margin: auto 18px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  font-size: 18px;

  // Allow the suffix to be clicked through to activate the input if it's non-interactive
  ${(props) => !props.interactive && "pointer-events: none"}
`;

// forwardRef is used to prevent a console error when using a functional component as customInput for react-datepicker
// in DateInput, see https://github.com/Hacker0x01/react-datepicker/issues/862
const TextInput = forwardRef(
  (
    {
      "data-cy": dataCy,
      errorMessage,
      hasError,
      label,
      labelStyle = "hidden",
      suffixElement,
      isSuffixInteractive,
      onHitEnter,
      type = "text",
      ...inputProps
    }: TextInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [touched, setTouched] = useState(!!inputProps.value);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      setTouched(true);
      inputProps.onFocus?.(e);
    };
    const handleKeyDown =
      onHitEnter &&
      ((e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
          onHitEnter();
        }
      });

    if (labelStyle === "above") {
      return (
        <>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label>
            <LabelText>{label}</LabelText>
            <InputWithSuffixContainer>
              <Input
                ref={ref}
                data-cy={dataCy}
                hasError={touched && hasError}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                type={type}
                {...inputProps}
              />
              {suffixElement && (
                <SuffixContainer interactive={isSuffixInteractive}>
                  {suffixElement}
                </SuffixContainer>
              )}
            </InputWithSuffixContainer>
          </label>
          {hasError && errorMessage && <Error>{errorMessage}</Error>}
        </>
      );
    }

    return (
      <>
        <InputWithSuffixContainer>
          <Input
            ref={ref}
            aria-label={label}
            data-cy={dataCy}
            hasError={touched && hasError}
            onKeyDown={handleKeyDown}
            type={type}
            {...inputProps}
            onFocus={handleFocus}
          />
          {suffixElement && (
            <SuffixContainer interactive={isSuffixInteractive}>
              {suffixElement}
            </SuffixContainer>
          )}
        </InputWithSuffixContainer>
        {touched && hasError && errorMessage && <Error>{errorMessage}</Error>}
      </>
    );
  }
);

export default TextInput;
