import { Store } from "redux";
import { getSearchQuery } from "src/utils/urlParams";

declare const Android: any;

let navRunning = false;
export function isGoing() {
  return navRunning;
}

/* eslint-disable no-restricted-globals */
export function reroll(url: string, queryParams?: URLSearchParams) {
  /* istanbul ignore next */
  if (__NODE_ENV__ !== "production" && !url) {
    throw new Error(`Unknown url passed to reroll`);
  }

  location.href = `${url}${getSearchQuery(queryParams)}`;
}

// Note that we are doing direct to API access here to allow us to work with any]
// type of router that we might be using. After we move completely away from the
// hash router, we can return to using the router history directly
export function push(url: string) {
  /* istanbul ignore next */
  if (__NODE_ENV__ !== "production" && !url) {
    throw new Error(`Unknown url passed to pushState`);
  }

  const { pathname: oldPathname, hash: oldHash } = location;

  // HACK: KILL THIS CODE WHEN WE HAVE A SINGLE ROUTER - KD 7/7/2021
  history.pushState(null, null, url);

  const { pathname: newPathname, hash: newHash } = location;

  navRunning = true;
  // This notifies history routers that we want to re-render the page.
  if (oldPathname !== newPathname) {
    const popState = new CustomEvent("popstate");
    (popState as any).state = {};
    window.dispatchEvent(popState);
  }
  if (oldHash !== newHash) {
    // This notifies hash routers that we want to re-render the page.
    // Note that this will cause a double render on IE.
    window.dispatchEvent(new CustomEvent("hashchange"));
  }
  navRunning = false;
}

export function pushReroll(url: string, check: () => boolean) {
  if (check()) {
    push(url);
  } else {
    reroll(url);
  }
}
export function setParam(
  queryParams: URLSearchParams,
  name: string,
  value: string | number | boolean
) {
  if (value || value === 0) {
    queryParams.set(name, `${value}`);
  }
}

export const addUrlSearchParamsDelimiter = (urlSearchParams: URLSearchParams) =>
  urlSearchParams.toString() ? `?${urlSearchParams}` : "";

export function isInsideIOSWebKit(): boolean {
  return !!(window as any).webkit?.messageHandlers;
}

export function isInsideAndroidWebView(): boolean {
  return typeof Android !== "undefined";
}

/**
 * Helper function for bridging between old code passing upid (query params) and new code (redux).
 * Necessary because users can be rerolled between different code versions.
 * Can be removed once all deployed code is updated to write to and read from redux.
 *
 * If provided with a upid, writes it into the redux store and appends to query params. Otherwise,
 * appends what is in redux to the query params.
 *
 * Mutation warning: `params`
 */
export function synchronizeUpid(params: URLSearchParams, store: Store) {
  const { upid } = store.getState();
  if (upid) {
    setParam(params, "upid", upid);
  }
}
