import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { matchPath } from "react-router";
import { Serializer } from "../persistence";
import { fetchProductPlanAsPlanById } from "src/utils/plans";
import { AppDispatch, GetAppState } from "../store";
import { Plan } from "./plans";

const forcedPlanId = new URLSearchParams(window.location.search).get(
  "noom_plan_id"
);

// Maps to all of the pre-LBF payment funnel paths. This
// only needs to be maintained while we still support those paths.
const pathsWithPlans = [
  "/purchase/(en|es|de)/:planId",
  "/purchase/:planId",
  "/purchase-hm/:planId",
];

function extractPlanFromPath(pathname: string) {
  for (let i = 0; i < pathsWithPlans.length; i++) {
    const match = matchPath<{ planId: string }>(pathname, {
      path: pathsWithPlans[i],
    });
    if (match?.params) {
      return match.params.planId;
    }
  }
  return undefined;
}

export type RecommendedPlanState = Partial<Plan> & {
  isForcedPlan?: boolean;
  userSelectedTrialFee?: number;
};

export const serialize: Serializer<"recommendedPlan"> = {
  load(storedValue: RecommendedPlanState): RecommendedPlanState {
    if (forcedPlanId) {
      // Note that we may want to remove the noom_plan_id query parameter in
      // the future, but for now it needs to remain to support rerolls into
      // older code.
      return {
        noom_plan_id: forcedPlanId,
        isForcedPlan: true,
        userSelectedTrialFee: storedValue?.userSelectedTrialFee,
      };
    }

    const pathPlan = extractPlanFromPath(window.location.pathname);
    if (pathPlan) {
      return {
        noom_plan_id: pathPlan,
        isForcedPlan: false,
        userSelectedTrialFee: storedValue?.userSelectedTrialFee,
      };
    }

    return storedValue;
  },
  save(plan: RecommendedPlanState) {
    // WARN: Remember backwards compatibility when changing this.
    const { noom_plan_id, isForcedPlan, userSelectedTrialFee } = plan;
    return { noom_plan_id, isForcedPlan, userSelectedTrialFee };
  },
};

export function planHasCustomTrialPrice(plan: RecommendedPlanState) {
  return plan.userSelectedTrialFee != null;
}

const recommendedPlansSlice = createSlice({
  name: "recommendedPlan",
  initialState: {
    noom_plan_id: forcedPlanId,
    isForcedPlan: !!forcedPlanId,
  } as RecommendedPlanState,
  reducers: {
    setRecommendedPlan: (state, action: PayloadAction<Plan>) => {
      return {
        ...action.payload,
        trial_fee: state.userSelectedTrialFee ?? action.payload.trial_fee ?? 0,
        userSelectedTrialFee: state.userSelectedTrialFee,
        isForcedPlan: action.payload.noom_plan_id === forcedPlanId,
      };
    },
    setTrialFee: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        trial_fee: action.payload,
        userSelectedTrialFee: action.payload,
      };
    },
    updateRecommendedPlan: (
      state,
      action: PayloadAction<RecommendedPlanState>
    ) => {
      // Does have partial updates
      return {
        ...state,
        ...action.payload,
        trial_fee: state.userSelectedTrialFee ?? action.payload.trial_fee ?? 0,
      };
    },
  },
});

export const { setTrialFee, updateRecommendedPlan } =
  recommendedPlansSlice.actions;

const { setRecommendedPlan } = recommendedPlansSlice.actions;

export default recommendedPlansSlice;

/**
 * allowIdOnly can only be used when transitioning to the payment context until the
 * product catalog API is available.
 */
export function setRecommendPlanById(noomPlanId: string, allowIdOnly = false) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const { recommendedPlan: currentPlan } = getState();

    // NOP if resetting to ourselves
    if (currentPlan?.noom_plan_id === noomPlanId) {
      return;
    }
    const recommendedPlan = await fetchProductPlanAsPlanById(noomPlanId);

    if (!recommendedPlan) {
      if (allowIdOnly) {
        dispatch(
          // This is a hack with the partial type, but is interim and opt in
          setRecommendedPlan({
            noom_plan_id: noomPlanId,
          } as Plan)
        );
        return;
      }
      throw new Error(`No plan found with noom_plan_id: ${noomPlanId}`);
    }

    dispatch(setRecommendedPlan(recommendedPlan));
  };
}
