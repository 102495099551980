/* eslint-disable max-classes-per-file */
import Cookies from "js-cookie";
import { ReadonlyDeep } from "type-fest";
import getApiDomain, {
  prepareGrowthAPIParameters,
} from "src/utils/services/api-params";
import { getInAppAutoCookie } from "./authCookieParser";
import { send } from "./fetch";
import { captureException } from "./sentry";
import type { Context } from "@noom/meristem-context";
import { getSearchQuery } from "./urlParams";
import { getMeristemContext } from "./meristemContext";
import { isHM } from "./userSegment";

import { updateRecommendedPlan } from "./redux/slices/recommendedPlan";
import { updatePlans } from "./redux/slices/plans";
import { updateTrialFromServerContext } from "./redux/slices/trial";
import {
  ServerContextInit,
  ServerContextState,
  updateServerContext,
} from "./redux/slices/serverContext";
import getStore from "./redux/store";

export class FetchServerContextError extends Error {}

export class FetchInAppServerContextError extends Error {}

/**
 * Validates the server context. Returns false if the context is undefined, empty or does not have the critical properties;
 */
export function isServerContextValid(context: ServerContextState) {
  // Protect from undefined toStringed values
  if (context?.user_id === "undefined") {
    return false;
  }

  return !!context?.user_id;
}

/**
 * Look through serverContext and if certain fields are found, set them in
 * the cookie.
 */
function setCampaignCookiesAndReturnContext(serverContext: ServerContextState) {
  if (!isServerContextValid(serverContext)) {
    throw Error(`Invalid context: ${JSON.stringify(serverContext)}`);
  }

  const { campaign_promo_cookie, session_cookie_domain } = serverContext;

  if (campaign_promo_cookie?.active) {
    Cookies.set("campaign_promo", campaign_promo_cookie.value, {
      domain: session_cookie_domain,
    });
  } else {
    Cookies.remove("campaign_promo");
  }

  return serverContext;
}

/**
 * Look through meristemContext and if certain fields are found, set them in
 * the cookie.
 */
export function setCookiesFromMeristemContext(
  meristemContext: ReadonlyDeep<Context>
) {
  if (meristemContext.route_id) {
    Cookies.set("_routeId", meristemContext.route_id, {
      domain: getApiDomain(),
    });
  }

  if (meristemContext.language_code) {
    Cookies.set("_languageCode", meristemContext.language_code, {
      domain: getApiDomain(),
    });
  }
}

async function fetchContext(
  contextType: string,
  URL: string,
  useInAppAuthCookie: boolean
): Promise<ServerContextState> {
  // Load in-app auth cookies, if available
  let authCookie: Record<string, string>;
  if (useInAppAuthCookie) {
    authCookie = getInAppAutoCookie();
  }

  const method = useInAppAuthCookie ? "POST" : "GET";
  try {
    const context: ServerContextState = await send(method, URL, authCookie);
    context.initiatingContextType = contextType;

    return setCampaignCookiesAndReturnContext(context);
  } catch (error) {
    captureException(error, [method, "server context fetch detailed"]);

    if (useInAppAuthCookie) {
      throw new FetchInAppServerContextError(error);
    } else {
      throw new FetchServerContextError(error);
    }
  }
}

const contextCache: Record<string, Promise<ServerContextState>> = {};

/**
 * Fetch the serverContext from the Growth API.
 * Currently has special handling in app context fetching.
 */
async function getContext() {
  const {
    context_type: contextType,
    language_code: language,
    route_id: routeId,
  } = getMeristemContext();

  const languageParam = encodeURIComponent(language);
  const routeIdParam = encodeURIComponent(routeId);

  const { pathname, search } = window.location;

  const queryParams = new URLSearchParams(search);
  prepareGrowthAPIParameters(queryParams);

  // WARN: The store is not hydrated with API-sourced data at this point.
  const { recommendedPlan } = getStore().getState();

  let requestPath = pathname.replace(/^\/|\/$/g, "");

  const isUnknownPPath = !contextType && pathname.startsWith("/p/");
  if (["landing", "main-survey"].includes(contextType) || isUnknownPPath) {
    if (isHM()) {
      requestPath = `programs/mood/${routeIdParam}`;
    } else {
      requestPath = `${languageParam}/programs/health-weight/${routeIdParam}`;
    }
  } else if (
    contextType === "payment" &&
    // Failover to path context if the recommended plan is not set.
    // This occurs if users hit the paths with noom plan ids embedded in the url.
    recommendedPlan.noom_plan_id
  ) {
    queryParams.set("route", routeIdParam);

    // Don't duplicate path field below
    queryParams.delete("noom_plan_id");

    const planIdParam = encodeURIComponent(recommendedPlan.noom_plan_id);
    if (isHM()) {
      requestPath = `purchase-hm/${planIdParam}`;
    } else {
      requestPath = `purchase/${languageParam}/${planIdParam}`;
    }
  }

  if (contextType === "noom-premium") {
    requestPath = "noom-premium";
  }

  // Use v2 routing for /add-ons, /offers, and /in-app-offers
  if (
    ["add-ons", "offers", "noom-premium"].some((str) =>
      requestPath.includes(str)
    )
  ) {
    requestPath += "/v2";
  }

  const pathParts = [requestPath, getSearchQuery(queryParams)].filter(Boolean);

  const URL = `/api/context/v2/${pathParts.join("/")}`;
  const isCached = !!contextCache[URL];

  if (!isCached) {
    const useInAppAuthCookie =
      requestPath.includes("in-app-offers") || requestPath.includes("course");

    contextCache[URL] = fetchContext(contextType, URL, useInAppAuthCookie);
  }
  return { context: await contextCache[URL], isCached };
}

export async function loadServerContext() {
  const { context, isCached } = await getContext();

  // Only update the store if if it is a brand new context or if
  // the current meristem context type has changed (i.e. landing -> payment, etc)
  // from what has been loaded.
  //
  // This is a little hacky, but we want to avoid rebuilding the context
  // (which will overwrite any promo codes from recommendedPlan) unless
  // it has changed.
  if (
    !isCached ||
    context.initiatingContextType !==
      getStore().getState().serverContext.initiatingContextType
  ) {
    (window as any).serverContext = context;
    updateStoreWithServerContext(context);
  }

  return getStore().getState().serverContext;
}

/**
 * After we fetch serverContext, Take the data and update the appropriate
 * redux slices with it.
 */
export function updateStoreWithServerContext(serverContext: ServerContextInit) {
  const store = getStore();
  store.dispatch(updateServerContext(serverContext));
  store.dispatch(updateTrialFromServerContext(serverContext));

  if (serverContext.plans) {
    store.dispatch(updatePlans(serverContext.plans));
  }

  const planId =
    serverContext.noom_plan_id ||
    getStore().getState().recommendedPlan.noom_plan_id;

  if (serverContext.plan) {
    store.dispatch(
      updateRecommendedPlan({
        // Server may or may not attach the noom plan id to the plan response.
        // Provide the server context copy in the event we do not have one.
        noom_plan_id: serverContext.noom_plan_id,
        ...serverContext.plan,
      })
    );
  } else if (planId && serverContext.plans) {
    // Load plan from plans if we have a plan_id
    const plan = serverContext.plans[planId];
    store.dispatch(
      updateRecommendedPlan({
        noom_plan_id: planId,
        ...plan,
      })
    );
  }
}
