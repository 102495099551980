import { registerHandler } from "../handler";

declare const uetq: any;

export default function registerHandlers() {
  registerHandler("lead", () => {
    (window as any).uetq = (window as any).uetq || [];
    uetq.push({
      ec: "Lead",
      ea: "Lead",
      el: "Lead",
      ev: "0",
    });
  });

  registerHandler("initiateCheckout", () => {
    (window as any).uetq = (window as any).uetq || [];
    uetq.push({
      ec: "Initiate Checkout",
      ea: "Initiate Checkout",
      el: "Initiate Checkout",
      ev: "0",
    });
  });

  registerHandler("purchase", ({ order }) => {
    (window as any).uetq = (window as any).uetq || [];
    uetq.push({
      ec: "Purchase",
      ea: "Purchase",
      el: "Purchase",
      ev: "0",
      gv: order.erev_9_months.toFixed(3),
      gc: "USD",
    });
  });
}
