import i18n from "@i18n";
import getStore from "@utils/redux/store";
import { updateUserContext } from "@utils/api/tracker";
import Unit, { UnitType } from "../components/unit/Unit";
import {
  convertUnits,
  convertLbToKg,
  convertKgToSt,
} from "../components/unit/UnitUtils";
import { SurveyAnswersState } from "./redux/slices/surveyAnswers";
import {
  isCountry,
  isCountryImperial,
  isCountryStones,
  isAffiliateWithoutTrial,
  isUS,
  isInAppWinback,
  isUK,
} from "./userSegment";
import { getPlanByKey, has7DayTrial } from "./plans";
import { isBraintreeStripeiOSEligible } from "src/utils/userSegment/features";
import { shift_days } from "./datetime";
import { calculateWeightAtMonth } from "./weight/weightPacing";
import { getMeristemContext } from "./meristemContext";

const MINIMUM_NORMAL_BMI = 18.5;
const MAXIMUM_NORMAL_BMI = 25;
const RECOMMENDED_WEIGHT_LOWER_BOUND_BMI = 19;
const RECOMMENDED_WEIGHT_UPPER_BOUND_BMI = 25;
const RECOMMENDED_WEIGHT_LOWER_BOUND_LIMIT_IN_POUNDS = 70;
const RECOMMENDED_WEIGHT_UPPER_BOUND_LIMIT_IN_POUNDS = 60;

export interface RecommendedPlan {
  recommendedPlanDuration: number;
  courseDuration: number;
  disclaimer?: string;
  peopleHelped: number;
  MAX_MONTHLY_WEIGHTLOSS: number;
  adjustedTargetWeight?: number;
  adjustedTargetWeightKg?: number;
  weightLossGoal?: number;
  weightLossGoalKg?: number;
}

export function getPlanKeyAndDuration(recommendedPlanDuration: number): {
  planKey: string | number;
  planDuration: number;
} {
  const { serverContext } = getStore().getState();
  if (!recommendedPlanDuration) {
    // this case only happened in cypress tests, where some answers were not available
    // so recommendedPlanDuration is NaN
    return {
      planKey: null,
      planDuration: null,
    };
  }

  let planDuration = recommendedPlanDuration;
  let planKey: number | string = planDuration;

  // Use 7 day trial plans for US-baseline routes.
  if (has7DayTrial()) {
    planKey = `${planDuration}-7-DAY-TRIAL`;
  }

  if (isUS() && isInAppWinback()) {
    planKey = `${planDuration}-IN-APP`;
  }

  if (isAffiliateWithoutTrial()) {
    planKey = `${planDuration}-affiliate`;
  }

  // NOTE(Eric): The key for new discounted 12 month plan is "12-149". Reset the planDuration to 12 months, so calculations work.
  if ((planDuration as unknown) === "12-149") {
    planDuration = 12;
  }

  // NOTE(Rose): If a plan override is passed in from the flow, use that plan.
  // For the in-app flows, we won't update recommendedPlanDuration,
  // because we want to display the lengthier graph instead of a 1-month graph
  if (serverContext?.plan_override) {
    planKey = serverContext.plan_override;
  }

  if (isBraintreeStripeiOSEligible()) {
    // 1 month plan that is also assigned for other in-app routes(e.g. app-14-day-ft-web)
    planKey = "1-IN-APP";
  }

  const isFreeTrialRoute = getMeristemContext().route_id === "exft";
  if (isFreeTrialRoute && isUK()) {
    planKey = `${planDuration}-betterhealth`;
  }
  return { planKey, planDuration };
}

export async function getRecommendedPlan(recommendedPlanDuration: number) {
  const { planKey, planDuration } = getPlanKeyAndDuration(
    recommendedPlanDuration
  );
  if (!planKey) {
    return {
      recommendedPlan: null,
      planDuration: null,
    };
  }
  const recommendedPlan = await getPlanByKey(planKey);
  return { recommendedPlan, planDuration };
}

export function calculateRecommendedPlan(surveyAnswers: SurveyAnswersState) {
  const targetsAndGoals = calculateBMI(surveyAnswers);
  const planDetails = recommendPlan(surveyAnswers);
  if (planDetails.weightLossGoal > 0) {
    updateUserContext({
      weightLossGoalLb: planDetails.weightLossGoal,
    });
  } else {
    updateUserContext({
      weightLossGoalKg: planDetails.weightLossGoalKg,
    });
  }
  const { currentBMI, targetBMI } = targetsAndGoals;
  return { ...planDetails, currentBMI, targetBMI };
}

/**
 * Calculate BMI with tracking option
 */
function calculateBMIImperial(
  heightFeet: number,
  heightInch: number,
  currentWeight: number,
  targetWeight: number,
  trackResults = true
) {
  const currentBMI = calculateCurrentBMIImperial(
    heightFeet,
    heightInch,
    currentWeight
  );
  const targetBMI = calculateTargetBMIImperial(
    heightFeet,
    heightInch,
    targetWeight
  );

  let BMILevel: string = i18n.t("plans:belowNormal");
  if (+currentBMI >= MINIMUM_NORMAL_BMI && +currentBMI < MAXIMUM_NORMAL_BMI) {
    BMILevel = i18n.t("plans:normal");
  } else if (MAXIMUM_NORMAL_BMI >= 25) {
    BMILevel = i18n.t("plans:aboveNormal");
  }

  if (trackResults) {
    // Store info as Mixpanel people properties.
    const heightInInch = heightInch + heightFeet * 12;
    updateUserContext({
      currentBMI: parseFloat(currentBMI),
      targetBMI: parseFloat(targetBMI),
      heightInInch,
    });
  }

  return {
    currentBMI,
    targetBMI,
    BMILevel,
  };
}

/**
 * Calculate current BMI using Imperial measures
 */
export function calculateCurrentBMIImperial(
  heightFeet: number,
  heightInch: number,
  currentWeight: number
) {
  const heightInInch = heightInch + heightFeet * 12;
  const currentBMI = (currentWeight / (heightInInch * heightInInch)) * 703;
  return currentBMI.toFixed(1);
}

/**
 * Calculate target BMI using Imperial measures
 */
export function calculateTargetBMIImperial(
  heightFeet: number,
  heightInch: number,
  targetWeight: number
) {
  const heightInInch = heightInch + heightFeet * 12;
  const targetBMI = (targetWeight / (heightInInch * heightInInch)) * 703;
  return targetBMI.toFixed(1);
}

/**
 * Calculate weight in imperial units from a BMI value and height in imperial units
 * This is the inverse of the formula from calculateTargetBMIImperial
 * <=> (targetBMI / 703) * (heightInInch * heighInInch) = targetWeight
 */
function calculateImperialWeightFromBMI(
  heightFeet: number,
  heightInch: number,
  bmi: number
) {
  const heightInInch = heightInch + heightFeet * 12;
  const weight = (bmi / 703) * (heightInInch * heightInInch);
  return Math.round(weight);
}

/**
 * Calculate weight in metric units from a BMI value and height in metric units
 * <=> bmi * (heightInMeters^2) = targetWeight
 */
function calculateMetricWeightFromBMI(height: number, bmi: number) {
  const heightInMeters = height / 100;
  return Math.round(bmi * (heightInMeters * heightInMeters));
}

function recommendPlanImperialCA(currentWeight: number, targetWeight: number) {
  // Adjust the plan recommendation based on the weight loss goal.
  const payload: RecommendedPlan = {
    recommendedPlanDuration: 4,
    courseDuration: 4,
    disclaimer: null,
    peopleHelped: 93,
    MAX_MONTHLY_WEIGHTLOSS: 10,
    adjustedTargetWeight: targetWeight,
    weightLossGoal: 0,
  };
  const weightLossGoal = currentWeight - targetWeight;
  payload.weightLossGoal = weightLossGoal;

  if (weightLossGoal > 0 && weightLossGoal <= 20) {
    payload.recommendedPlanDuration = 2;
    payload.courseDuration = 2;
    payload.peopleHelped = 93;
  } else if (weightLossGoal > 20 && weightLossGoal <= 38) {
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
    payload.peopleHelped = 92;
  } else if (weightLossGoal > 38 && weightLossGoal <= 58) {
    payload.recommendedPlanDuration = 6;
    payload.courseDuration = 6;
    payload.peopleHelped = 90;
  } else if (weightLossGoal > 58 && weightLossGoal <= 90) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = 8;
    payload.peopleHelped = 94;
  } else if (weightLossGoal > 90) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = Math.round(
      weightLossGoal / payload.MAX_MONTHLY_WEIGHTLOSS
    );
    payload.disclaimer = i18n.t("plans:disclaimer", {
      amount: "1-2",
      unit: "lbs",
    });
    payload.peopleHelped = 92;
    payload.adjustedTargetWeight =
      currentWeight -
      payload.recommendedPlanDuration * payload.MAX_MONTHLY_WEIGHTLOSS;
  } else {
    // Handle case where user wants to lose weight.
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
    payload.peopleHelped = 92;
    payload.adjustedTargetWeight = currentWeight;
  }

  return payload;
}

function recommendPlanImperial(currentWeight: number, targetWeight: number) {
  // Adjust the plan recommendation based on the weight loss goal.
  const payload: RecommendedPlan = {
    recommendedPlanDuration: 4,
    courseDuration: 4,
    disclaimer: null,
    peopleHelped: 93,
    MAX_MONTHLY_WEIGHTLOSS: 10,
    adjustedTargetWeight: targetWeight,
    weightLossGoal: 0,
  };
  const weightLossGoal = currentWeight - targetWeight;
  payload.weightLossGoal = weightLossGoal;

  if (weightLossGoal > 0 && weightLossGoal <= 12) {
    payload.recommendedPlanDuration = 2;
    payload.courseDuration = 2;
    payload.peopleHelped = 93;
  } else if (weightLossGoal > 12 && weightLossGoal <= 20) {
    payload.recommendedPlanDuration = 3;
    payload.courseDuration = 3;
    payload.peopleHelped = 92;
  } else if (weightLossGoal > 20 && weightLossGoal <= 29) {
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
    payload.peopleHelped = 92;
  } else if (weightLossGoal > 29 && weightLossGoal <= 38) {
    payload.recommendedPlanDuration = 5;
    payload.courseDuration = 5;
    payload.peopleHelped = 92;
  } else if (weightLossGoal > 38 && weightLossGoal <= 48) {
    payload.recommendedPlanDuration = 6;
    payload.courseDuration = 6;
    payload.peopleHelped = 90;
  } else if (weightLossGoal > 48 && weightLossGoal <= 58) {
    payload.recommendedPlanDuration = 7;
    payload.courseDuration = 7;
    payload.peopleHelped = 90;
  } else if (weightLossGoal > 58 && weightLossGoal <= 90) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = 8;
    payload.peopleHelped = 94;
  } else if (weightLossGoal > 90) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = Math.round(
      weightLossGoal / payload.MAX_MONTHLY_WEIGHTLOSS
    );
    payload.disclaimer = i18n.t("plans:disclaimer", {
      amount: "1-2",
      unit: "lbs",
    });
    payload.peopleHelped = 92;
    payload.adjustedTargetWeight =
      currentWeight -
      payload.recommendedPlanDuration * payload.MAX_MONTHLY_WEIGHTLOSS;
  } else {
    // Handle case where user wants to lose weight.
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
    payload.peopleHelped = 92;
    payload.adjustedTargetWeight = currentWeight;
  }

  return payload;
}

function calculateBMIMetric(
  heightCm: number,
  currentWeightKg: number,
  targetWeightKg: number,
  trackResults = true
) {
  const currentBMI = calculateCurrentBMIMetric(heightCm, currentWeightKg);
  const targetBMI = calculateTargetBMIMetric(heightCm, targetWeightKg);

  let BMILevel = i18n.t("plans:belowNormal");
  if (+currentBMI >= MINIMUM_NORMAL_BMI && +currentBMI < MAXIMUM_NORMAL_BMI) {
    BMILevel = i18n.t("plans:normal");
  } else if (+currentBMI >= MAXIMUM_NORMAL_BMI) {
    BMILevel = i18n.t("plans:aboveNormal");
  }

  // Store info as Mixpanel people properties.
  if (trackResults) {
    updateUserContext({
      currentBMI: parseFloat(currentBMI),
      targetBMI: parseFloat(targetBMI),
      heightCm,
    });
  }

  return {
    currentBMI,
    targetBMI,
    BMILevel,
  };
}

export function calculateCurrentBMI(
  surveyAnswers: SurveyAnswersState,
  isMetric: boolean
) {
  if (isMetric) {
    const { heightCm, weightKg } = surveyAnswers;
    return calculateCurrentBMIMetric(heightCm, weightKg);
  }
  const { heightFeet, heightInch, weight } = surveyAnswers;
  return calculateCurrentBMIImperial(heightFeet, heightInch, weight);
}

/**
 * Calculate current BMI using Metric measures
 */
export function calculateCurrentBMIMetric(
  heightCm: number,
  currentWeightKg: number
) {
  const heightInMeter = heightCm / 100;
  const currentBMI = currentWeightKg / (heightInMeter * heightInMeter);
  return currentBMI.toFixed(1);
}

/**
 * Calculate target BMI using Metric measures
 */
export function calculateTargetBMIMetric(
  heightCm: number,
  targetWeightKg: number
) {
  const heightInMeter = heightCm / 100;
  const targetBMI = targetWeightKg / (heightInMeter * heightInMeter);
  return targetBMI.toFixed(1);
}

/**
 *
 * @param bodyInformation - Information about the body (height, weight) that differ depending if is metric or imperial
 * @param extractedBMI - This needs to have one of the values "targetBMI" or "currentBMI". Use CURRENT_BMI and TARGET_BMI for ensure this
 * @param isMetric - If the body information represents metric measurements. If value false, then information contains imperial measurements
 */
export function isHealthyBMI(
  bodyInformation: SurveyAnswersState,
  extractedBMI: "currentBMI" | "targetBMI",
  isMetric: boolean
) {
  let targetBMI;
  if (isMetric) {
    const { heightCm, weightKg, idealWeightKg } = bodyInformation;
    targetBMI = calculateBMIMetric(heightCm, weightKg, idealWeightKg, false)[
      extractedBMI
    ];
  } else {
    const { heightFeet, heightInch, weight, idealWeight } = bodyInformation;
    targetBMI = calculateBMIImperial(
      heightFeet,
      heightInch,
      weight,
      idealWeight,
      false
    )[extractedBMI];
  }
  return +targetBMI >= MINIMUM_NORMAL_BMI;
}

function recommendPlanMetric(currentWeightKg: number, targetWeightKg: number) {
  // Adjust the plan recommendation based on the weight loss goal.
  const payload: RecommendedPlan = {
    recommendedPlanDuration: 4,
    courseDuration: 4,
    disclaimer: null,
    peopleHelped: 93,
    MAX_MONTHLY_WEIGHTLOSS: 4.5,
    adjustedTargetWeightKg: targetWeightKg,
    weightLossGoalKg: 0,
  };

  const weightLossGoalKg = currentWeightKg - targetWeightKg;
  payload.weightLossGoalKg = weightLossGoalKg;

  if (weightLossGoalKg > 0 && weightLossGoalKg <= 9) {
    payload.recommendedPlanDuration = 2;
    payload.courseDuration = 2;
    payload.peopleHelped = 93;
  } else if (weightLossGoalKg > 9 && weightLossGoalKg <= 18) {
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
    payload.peopleHelped = 92;
  } else if (weightLossGoalKg > 18 && weightLossGoalKg <= 27) {
    payload.recommendedPlanDuration = 6;
    payload.courseDuration = 6;
    payload.peopleHelped = 90;
  } else if (weightLossGoalKg > 27 && weightLossGoalKg <= 41) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = 8;
    payload.peopleHelped = 94;
  } else if (weightLossGoalKg > 41) {
    payload.recommendedPlanDuration = 8;
    payload.courseDuration = Math.round(
      weightLossGoalKg / payload.MAX_MONTHLY_WEIGHTLOSS
    );
    payload.disclaimer = i18n.t("plans:disclaimer", {
      amount: "0.5-1",
      unit: "kgs",
    });
    payload.peopleHelped = 92;
    payload.adjustedTargetWeightKg =
      currentWeightKg -
      payload.recommendedPlanDuration * payload.MAX_MONTHLY_WEIGHTLOSS;
  } else {
    // Handle case where user wants to lose weight.
    payload.recommendedPlanDuration = 4;
    payload.courseDuration = 4;
    payload.peopleHelped = 92;
    payload.adjustedTargetWeightKg = currentWeightKg;
  }

  return payload;
}

/**
 * Returns weight and display weight, targetWeight and displaTargetWeight
 * depending if is US or NON_US (if UK could have STONES)
 * If unit is STONES (for UK), the display value will be the converted value
 */
export function getWeightInformation(surveyAnswers: SurveyAnswersState) {
  let planDetails;
  let weightUnit;
  let weightDisplayUnit;
  let weightValue;

  if (isCountryImperial()) {
    const { weight, idealWeight } = surveyAnswers;
    if (isCountry(["CA"])) {
      planDetails = recommendPlanImperialCA(weight, idealWeight);
    } else {
      planDetails = recommendPlanImperial(weight, idealWeight);
    }
    weightUnit = Unit.POUND;
    weightDisplayUnit = Unit.POUND;
    weightValue = surveyAnswers.weight;
  } else {
    const { weightKg, idealWeightKg } = surveyAnswers;
    planDetails = recommendPlanMetric(weightKg, idealWeightKg);
    weightUnit = Unit.KILOGRAM;
    weightDisplayUnit = Unit.KILOGRAM;
    weightValue = surveyAnswers.weightKg;
  }
  const targetWeight =
    planDetails.adjustedTargetWeight || planDetails.adjustedTargetWeightKg;

  if (isCountryStones() && surveyAnswers.idealWeightUnit) {
    weightDisplayUnit = Unit[surveyAnswers.idealWeightUnit];
  }

  const targetWeightDisplay = convertUnits(
    { mainUnitValue: targetWeight },
    weightUnit,
    weightDisplayUnit
  );

  // Make sure that we're always returning the values in kg as well
  const weightValueKg = surveyAnswers.weightKg;
  const targetWeightKg = convertUnits(
    { mainUnitValue: targetWeight },
    weightUnit,
    Unit.KILOGRAM
  ).mainUnitValue;

  return {
    weightValue,
    weightValueKg,
    weightUnit,
    weightDisplayUnit,
    targetWeight,
    targetWeightKg,
    targetWeightDisplay,
  };
}

export function calculateBMI(surveyAnswers: SurveyAnswersState) {
  if (isCountryImperial()) {
    const { heightFeet, heightInch, weight, idealWeight } = surveyAnswers;
    return calculateBMIImperial(heightFeet, heightInch, weight, idealWeight);
  }
  const { heightCm, weightKg, idealWeightKg } = surveyAnswers;
  return calculateBMIMetric(heightCm, weightKg, idealWeightKg);
}

export function recommendPlan(
  surveyAnswers = getStore().getState().surveyAnswers
): RecommendedPlan {
  let planDetails;
  if (isCountryImperial()) {
    const { weight, idealWeight } = surveyAnswers;
    if (isCountry(["CA"])) {
      planDetails = recommendPlanImperialCA(weight, idealWeight);
    } else {
      planDetails = recommendPlanImperial(weight, idealWeight);
    }
  } else {
    const { weightKg, idealWeightKg } = surveyAnswers;
    planDetails = recommendPlanMetric(weightKg, idealWeightKg);
  }
  return { ...planDetails };
}

/**
 * Returns the recommended plan duration (months until weight-loss goal reached).
 */
export function getCourseDuration() {
  return recommendPlan().recommendedPlanDuration;
}

/**
 * Returns a boolean value indicating whether or not to use imperial units to display weight/height information based on
 * the ideal weight unit from the survey state or country code. The ideal weight unit has higher priority than the country code.
 *
 * NOTE: This should not be used to determine whether or not to read the weight/height information in metric or imperial units.
 *
 * @param surveyAnswers The survey state.
 */
export function shouldDisplayImperialUnits(surveyAnswers?: SurveyAnswersState) {
  if (surveyAnswers && surveyAnswers.idealWeightUnit) {
    return (
      surveyAnswers.idealWeightUnit === Unit.STONE.id ||
      surveyAnswers.idealWeightUnit === Unit.POUND.id
    );
  }
  return isCountryImperial();
}

/**
 * Converts a unit into a string value with the correct unit abbreviation.
 * @param value Weight value
 * @param unit The unit being used
 * @returns String value with the correct unit abbreviation
 * e.g. Pounds: 125 lb., Kilogram: 75 kg, Stone/Pounds: 14 st. 5 lb.
 */
export function unitToString(
  value: { mainUnitValue: number; secondaryUnitValue?: number },
  unit: UnitType
) {
  return `${value.mainUnitValue} ${unit.label}${
    unit.baseUnit && value.secondaryUnitValue
      ? ` ${value.secondaryUnitValue} ${Unit[unit.baseUnit].label}`
      : ""
  }`;
}

/**
 * Calculates the recommended upper and lower bounds for ideal weight given user weight and height
 *
 * @param surveyAnswers SurveyAnswersState
 * @param countryCode Two letter country code representing the country.
 * @param selectedWeightUnit String representing the weight unit selected by the user.
 * @returns Object containing recommended upper and lower bounds for ideal weight.
 */
export function calculateRecommendedWeightRange(
  surveyAnswers: SurveyAnswersState,
  selectedWeightUnit: UnitType
) {
  const { currentBMI } = surveyAnswers;
  // Return undefined range if the user is already below the upper bound for recommended weight
  if (currentBMI < RECOMMENDED_WEIGHT_UPPER_BOUND_BMI) {
    return {
      lowerRangeRecommendedWeight: undefined,
      upperRangeRecommendedWeight: undefined,
    };
  }

  if (isCountryImperial()) {
    const { weight, heightFeet, heightInch } = surveyAnswers;

    let recommendedWeightLowerBound = calculateImperialWeightFromBMI(
      heightFeet,
      heightInch,
      RECOMMENDED_WEIGHT_LOWER_BOUND_BMI
    );
    let recommendedWeightUpperBound = calculateImperialWeightFromBMI(
      heightFeet,
      heightInch,
      RECOMMENDED_WEIGHT_UPPER_BOUND_BMI
    );

    // NOTE(norbert): we don't want to be too aggressive if we max on the plan duration
    if (
      recommendedWeightUpperBound <
      weight - RECOMMENDED_WEIGHT_UPPER_BOUND_LIMIT_IN_POUNDS
    ) {
      recommendedWeightLowerBound =
        weight - RECOMMENDED_WEIGHT_LOWER_BOUND_LIMIT_IN_POUNDS;
      recommendedWeightUpperBound =
        weight - RECOMMENDED_WEIGHT_UPPER_BOUND_LIMIT_IN_POUNDS;
    }
    return {
      lowerRangeRecommendedWeight: unitToString(
        { mainUnitValue: recommendedWeightLowerBound },
        selectedWeightUnit
      ),
      upperRangeRecommendedWeight: unitToString(
        { mainUnitValue: recommendedWeightUpperBound },
        selectedWeightUnit
      ),
    };
  }
  // Metric flow
  const { weightKg, heightCm } = surveyAnswers;

  let recommendedWeightLowerBound = calculateMetricWeightFromBMI(
    heightCm,
    RECOMMENDED_WEIGHT_LOWER_BOUND_BMI
  );
  let recommendedWeightUpperBound = calculateMetricWeightFromBMI(
    heightCm,
    RECOMMENDED_WEIGHT_UPPER_BOUND_BMI
  );

  const lbs60InKg = Math.round(
    convertLbToKg({
      mainUnitValue: RECOMMENDED_WEIGHT_UPPER_BOUND_LIMIT_IN_POUNDS,
    }).mainUnitValue
  );
  const lbs70InKg = Math.round(
    convertLbToKg({
      mainUnitValue: RECOMMENDED_WEIGHT_LOWER_BOUND_LIMIT_IN_POUNDS,
    }).mainUnitValue
  );
  if (recommendedWeightUpperBound < weightKg - lbs60InKg) {
    recommendedWeightLowerBound = weightKg - lbs70InKg;
    recommendedWeightUpperBound = weightKg - lbs60InKg;
  }

  const lowerBoundInDynamicUnit = convertUnits(
    { mainUnitValue: recommendedWeightLowerBound },
    Unit.KILOGRAM,
    selectedWeightUnit
  );

  const upperBoundInDynamicUnit = convertUnits(
    { mainUnitValue: recommendedWeightUpperBound },
    Unit.KILOGRAM,
    selectedWeightUnit
  );

  return {
    lowerRangeRecommendedWeight: unitToString(
      lowerBoundInDynamicUnit,
      selectedWeightUnit
    ),
    upperRangeRecommendedWeight: unitToString(
      upperBoundInDynamicUnit,
      selectedWeightUnit
    ),
  };
}

// Calculates the estimated weight loss a user will see halfway into their plan duration accounting for their localized/ideal weight unit
// and max weight loss per week. This halway weight loss is shown as "bright spot" on the first update graph
export function calculateHalfwayWeightLoss(planDuration: number) {
  const halfwayMonthNumber = planDuration / 2;
  const state = getStore().getState();
  const survey = state.surveyAnswers;
  let startingWeight;
  let idealWeight;
  let maxWeightLoss;
  let unitToUse: string;
  if (isCountryImperial()) {
    startingWeight = survey.weight;
    idealWeight = survey.idealWeight;
    // The maximum weight loss Noom supports is 80 pounds past an 8 month duration
    maxWeightLoss = planDuration >= 8 ? planDuration * 10 : 80;
    unitToUse = Unit.POUND.id;
  } else {
    startingWeight = survey.weightKg;
    idealWeight = survey.idealWeightKg;
    maxWeightLoss = planDuration * 4.5;
    unitToUse = Unit.KILOGRAM.id;
    if (survey.idealWeightUnit) {
      unitToUse = survey.idealWeightUnit;
    }
  }
  // If the weight loss is more than the max weight loss we allow, reset the ideal weight to starting weight minus max weight loss.
  if (startingWeight - idealWeight > maxWeightLoss) {
    idealWeight = startingWeight - maxWeightLoss;
  }
  // Because we push the plan duration out by 40 days and count down 14 days by the first update graph, if the current date plus 26 days results in a new month,
  // we need to push the percieved plan duration out by 1 month to account for the fact that the first update graph will show an extra month durtaion for the user.
  const now = new Date();
  if (shift_days(26).getMonth() !== now.getMonth()) {
    // eslint-disable-next-line no-param-reassign
    planDuration += 1;
  }

  const halfwayWeight = calculateWeightAtMonth(
    startingWeight,
    idealWeight,
    halfwayMonthNumber,
    planDuration,
    unitToUse
  );

  // Compute the weight difference between halfway weight and starting weight and take localized/ideal weight unit into account
  let weightDifference;
  if (isCountryImperial()) {
    weightDifference = `${startingWeight - halfwayWeight} lb`;
  } else if (survey.idealWeightUnit === Unit.STONE.id) {
    const weightDifferenceInSt = convertKgToSt({
      mainUnitValue: startingWeight - halfwayWeight,
    });
    weightDifference = `${
      weightDifferenceInSt.mainUnitValue === 0
        ? ``
        : `${weightDifferenceInSt.mainUnitValue} st `
    }${
      weightDifferenceInSt.secondaryUnitValue === 0
        ? ""
        : `${weightDifferenceInSt.secondaryUnitValue} lb`
    }`;
  } else {
    weightDifference = `${startingWeight - halfwayWeight} kg`;
  }

  return weightDifference.trim();
}

export enum BrightSpotGraphStage {
  CHOOSE_PLAN = "CHOOSE_PLAN",
  FIRST_UPDATE_GRAPH = "FIRST_UPDATE_GRAPH",
  SECOND_UPDATE_GRAPH = "SECOND_UPDATE_GRAPH",
}

// Based on the user's survey data and which weight graph the user is seeing, this function computes various "bright spots" that are meant
// to highlight the progress or direction of the user's weight loss.
// For each stage of the plan, the user will see a different bright spots on the graph.
export function getBrightSpots(graphStage: BrightSpotGraphStage) {
  const { surveyAnswers } = getStore().getState();
  if (Object.keys(surveyAnswers).length === 0) {
    return [];
  }
  const brightSpots: string[] = [];
  if (graphStage === BrightSpotGraphStage.CHOOSE_PLAN) {
    if (surveyAnswers.targetBMI >= 20) {
      brightSpots.push(i18n.t("bright-spots:goodBMIGoal"));
    }
    const currentLifestyleEvaluationIResult =
      surveyAnswers.currentLifestyleEvaluationI?.[0];
    // Return default value of null if the user has not selcted a survey answer that meets the criteria for the bright spot
    brightSpots.push(
      i18n.t(
        `bright-spots:currentLifeStyleEvaluationI:${currentLifestyleEvaluationIResult}`,
        { defaultValue: null }
      )
    );
    const foodFrequencyResult = surveyAnswers.foodFrequency?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:foodFrequency:${foodFrequencyResult}`, {
        defaultValue: null,
      })
    );
  } else if (graphStage === BrightSpotGraphStage.FIRST_UPDATE_GRAPH) {
    const historyResult = surveyAnswers.history?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:history:${historyResult}`, { defaultValue: null })
    );
    const pastProgramResult = surveyAnswers.pastPrograms?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:pastPrograms:${pastProgramResult}`, {
        defaultValue: null,
      })
    );
  } else if (graphStage === BrightSpotGraphStage.SECOND_UPDATE_GRAPH) {
    const workingOutResult = surveyAnswers.workingOut?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:workingOut:${workingOutResult}`, {
        defaultValue: null,
      })
    );
    const physicalLimitationsResult = surveyAnswers.physicalLimitations?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:physicalLimitations:${physicalLimitationsResult}`, {
        defaultValue: null,
      })
    );
    const lessonTimingResult = surveyAnswers.lessonTiming?.[0];
    if (lessonTimingResult) {
      brightSpots.push(i18n.t("bright-spots:lessonTimingPlan"));
    }

    const myLifestyleResult = surveyAnswers.myLifestyle?.[0];
    brightSpots.push(
      i18n.t(`bright-spots:myLifestyle:${myLifestyleResult}`, {
        defaultValue: null,
      })
    );
  }
  // Always return a maximum of three bright spots and filter out null values
  return brightSpots.filter((value: string) => !!value).slice(0, 3);
}
