export const headConstants = {
  homepageHrefLangMappings: [
    { href: "https://www.noom.com/en-GB/", hreflang: "en-gb" },
    { href: "https://www.noom.com/en-AU/", hreflang: "en-au" },
    { href: "https://www.noom.com/en-NZ/", hreflang: "en-nz" },
    { href: "https://www.noom.com/de-DE/", hreflang: "de-de" },
    { href: "https://www.noom.com/es-ES/", hreflang: "es-es" },
    { href: "https://www.noom.com/en-IE/", hreflang: "en-ie" },
    { href: "https://www.noom.com/ja-JP/", hreflang: "ja-jp" },
    { href: "https://noom.co.kr/moneyback.html", hreflang: "ko-kr" },
    { href: "https://www.noom.com", hreflang: "en" },
    { href: "https://www.noom.com/ja/", hreflang: "ja" },
    { href: "https://www.noom.com/de/", hreflang: "de" },
    { href: "https://www.noom.com/es/", hreflang: "es" },
    { href: "https://www.noom.com", hreflang: "x-default" },
  ],
};

export const disclaimerLinks = {
  termsAndConditions: "https://web.noom.com/terms-and-conditions-of-use/",
  privacyPolicy:
    "https://web.noom.com/terms-and-conditions-of-use/noom-privacy-policy/",
  researchPolicy: "https://web.noom.com/research-policy/",
};

// Constants for visitorState property in visitorStatus state
export enum visitorLifecycleStates {
  newVisitor = "newVisitor",
  repeatVisitor = "repeatVisitor",
  lead = "lead",
  activeTrial = "activeTrial",
  activeSubscriber = "activeSubscriber",
  atRiskTrial = "atRiskTrial",
  atRiskSubscriber = "atRiskSubscriber",
  winbackEligible = "winbackEligible",
  unknown = "unknown",
}

export enum routeConstants {
  affiliateNoTrial = "aff-ntrl",
  affiliateNoTrialPromo = "aff-ntrl-prm",
  affiliateTrialNPB = "affiliate-trial-npb",
  app14Day = "app-14-day",
  app14DayFtWeb = "app-14-day-ft-web",
  app14DayReturning = "app-14-day-returning",
  app14DayWeb = "app-14-day-web",
  baseline = "_baseline",
  dietary = "dietary",
  direct = "_direct",
  hmDirect = "hm-direct",
  hmExsf01 = "hm-exsf01",
  hmExsf01d = "hm-exsf01d",
  hmAwsem = "hm-awsem",
  emailMain = "email-main",
  emailWinback = "email-winback",
  exsf01 = "exsf01",
  exsf01d = "exsf01d",
  awsem = "awsem",
  hmPaid = "hm-paid",
  ndpp = "ndpp",
  referrals = "referrals",
  winback = "winback",
  telehealth = "telehealth",

  // Added 7/7/21 from https://github.com/noom/growth/blob/552da1633e9f8689d849597cc8dc0b04ffbb7872/growth/flow/flows/en.py#L425
  control = "_control",
  experiment = "_experiment",
  phillips = "philips-airfryer",
  thesis = "thesis01",
  humana = "humana",
  affiliate = "affiliate-trial",
  appNoTrial = "app-0-day",
  testLanding = "_test_landing",
  controlLanding = "_control_landing",
  freeTrial = "exft",
  appUpsell = "app-upsell",
  appNoTrialReturning = "app-0-day-returning",
  app14DayWebReturning = "app-14-day-returning-web",
}

export enum hmActiveSubscriptionError {
  activeSubscription = "activeSubscription",
  nonUSSwitch = "nonUSSwitch",
}

export enum deviceOS {
  iOS = "iOS",
  android = "Android",
  macOS = "Mac OS",
}

export enum courseConstants {
  healthyWeight = "healthy_weight",
  healthyMind = "healthymind",
}

export enum paymentSource {
  stripe = "STRIPE",
  braintree = "BRAINTREE",
  itunes = "ITUNES",
  googlePlay = "GOOGLE_PLAY",
}

export const NOOM_PREMIUM = "meal-workout-49";

export const OFFERS_PURCHASE_URL = "/api/payment/v2/purchase_add_on/";
export const DISCOUNT_PROMO_CODE = "DISCOUNT25";
export const DISCOUNT_VALIDATION_URL = "/upsell_discounts/validate/";

export enum SessionStorageKey {
  patientIntake = "patientIntake",
  patientResponsibilityEstimateUsd = "patientResponsibilityEstimateUsd",
  appointmentClinicianIds = "appointmentClinicianIds",
  appointmentStart = "appointmentStart",
  wheelConsultId = "wheelConsultId",
  workflow = "workflow",
  consultType = "consultType",
  // This is `true` for users that are scheduling their first consult.
  isNewPatient = "isNewPatient",
  // This is `true` for users that are new to Noom and do not have existing accounts.
  // This is `false` or undefined for users that have been sent an email to sign up for telehealth.
  isTelehealthBuyflow = "isTelehealthBuyflow",
  insuranceSurvey = "insuranceSurvey",
  userConfirmedIdentity = "userConfirmedIdentity",
}
