/* eslint-disable no-console */
import { send } from "@utils/fetch";
import getStore from "@utils/redux/store";
import { captureMessageWithExtras } from "@utils/sentry";

const createPostRequest = (url: string, requestBody: JsonObject) => {
  return send("POST", url, requestBody);
};

const apiCreateVisit = async (requestBody: JsonObject) => {
  return createPostRequest(`/visit/v1/create/`, requestBody)
    .then((text: string) => {
      console.log(`${text}! User Id: ${requestBody.id} `);
    })
    .catch((e: any) => {
      const { serverContext } = getStore().getState();
      console.log("Visit creation failed", e);
      captureMessageWithExtras("Visit creation failed", {
        extra: {
          requestBody,
          serverContext,
        },
      });
    });
};

const apiUpdateVisit = async (requestBody: JsonObject) => {
  return createPostRequest(`/visit/v1/update/`, requestBody)
    .then((text: string) => {
      console.log(`${text}! User Id: ${requestBody.id} `);
    })
    .catch((e: any) => {
      const { serverContext } = getStore().getState();
      console.log("Visit update failed", e);
      captureMessageWithExtras("Visit update failed", {
        extra: {
          requestBody,
          serverContext,
        },
      });
    });
};

export { apiCreateVisit, apiUpdateVisit };
