import { Action, Block, PageList, PageSet } from "src/pageDefinitions/types";
import {
  getPageAtLocation,
  getPageLocation,
  PageLocation,
} from "src/pageDefinitions/pageSets";
import invariant from "tiny-invariant";
import { CoreReduxState } from "src/utils/redux/store";
import { conditionsPass } from "src/pageDefinitions/conditions";

/**
 * Finds all survey-related pages that are defined within page set at given point.
 */

export function getPageSetSurvey(
  pageSet: PageSet,
  page: PageList[0],
  state?: CoreReduxState
): {
  name: string;
  surveyNameSpace: string;
  loggedInHeader: boolean;
  blockLocation: PageLocation;
  pages?: PageList;
  props: Block["props"];
  onAnswered?: Action[];
} {
  const location = pageSet && page && getPageLocation(pageSet, page);
  if (!location) {
    return {
      name: undefined,
      blockLocation: undefined,
      surveyNameSpace: "surveyAnswers",
      loggedInHeader: false,
      props: {},
    };
  }

  const blockLocation = location.slice(0, 1);
  const block = getPageAtLocation(pageSet, blockLocation);
  invariant(
    "name" in block,
    `No survey block found wrapping location ${location}`
  );
  invariant(block.name, `Survey block as location ${location} has no name`);

  // Include pages if a state is passed to eval against.
  let pages;
  if (state) {
    pages = block.block.filter((pageToFilter) =>
      conditionsPass(pageToFilter.conditions, state)
    );
  }

  return {
    name: block.name,
    surveyNameSpace: block.surveyNameSpace || "surveyAnswers",
    loggedInHeader: block.loggedInHeader,
    blockLocation: location.slice(0, -1),
    pages,
    props: block.props || {},
    onAnswered: block.onAnswered,
  };
}
