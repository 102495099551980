import { getMeristemContext } from "@utils/meristemContext";
import { isDeLanguage, isEsLanguage, isFromEmail } from "../userSegment";
import getStore from "../redux/store";

const LOCALHOST_API_DOMAIN = "localhost";
const STAGING_API_DOMAIN = ".wsli.dev";
const PRODUCTION_API_DOMAIN = ".noom.com";

export default function getApiDomain() {
  const env = __NODE_ENV__;
  switch (env) {
    case "local":
      return LOCALHOST_API_DOMAIN;
    case "production":
      return PRODUCTION_API_DOMAIN;
    case "staging":
    default:
      return STAGING_API_DOMAIN;
  }
}

/**
 * Injects common API parameters that are required/helpful for all API requests.
 */
export function prepareGrowthAPIParameters(parameters = new URLSearchParams()) {
  const geoLocation = getMeristemContext();
  try {
    const { upid } = getStore().getState();
    // If we have a upid available, also send that along
    if (upid) {
      parameters.set("upid", upid);
    }
  } catch (e) {
    /* NOP */
    // Why the try/catch at all? This function might be called before store has initialized.
    // For example, from error paths that attempt to report the error via `trackEvent`.
  }

  // get_geo_details parameters reads this for most requests
  if (geoLocation?.is_forced_country_code) {
    parameters.set("fcc", geoLocation.country_code);
  }

  // Filter out legacy/local only parameters
  parameters.delete("idm");
  parameters.delete("psid");

  // ESDE uses the original /account page, so preserve the original functionality and assume
  // the user ID that is in the query params. TODO(mattr): Drop isESDE from this conditional once we migrate ESDE to LBF
  const isEsDe = isDeLanguage() || isEsLanguage();

  // If the user came from an email link with a nuid, we'll be asking them to confirm; so don't
  // automatically send it along here (which will set the cookie).
  // See `VerifyUser.tsx` for more details
  if (isFromEmail() && !isEsDe) {
    parameters.delete("nuid");
  }

  return parameters;
}
