import { useDispatch, useSelector, useStore } from "react-redux";
import { AppDispatch, CoreReduxState } from "src/utils/redux/store";

export function useAppDispatch() {
  return useDispatch<AppDispatch>();
}
export function useCoreStore() {
  return useStore<CoreReduxState>();
}

export function useCountryCode() {
  return useSelector((state: CoreReduxState) => state.geoLocation.country_code);
}

export function useServerContext() {
  return useSelector((state: CoreReduxState) => state.serverContext);
}

export function usePlans() {
  return useSelector((state: CoreReduxState) => state.plans);
}

export function useRecommendedPlan() {
  return useSelector((state: CoreReduxState) => state.recommendedPlan);
}

export function useUserData() {
  return useSelector((state: CoreReduxState) => state.userData);
}

export function useSurveyAnswers() {
  return useSelector((state: CoreReduxState) => state.surveyAnswers);
}

export function useCheckout() {
  return useSelector((state: CoreReduxState) => state.checkout);
}

export function useLanguage() {
  return useSelector((state: CoreReduxState) => state.language);
}

export function useRouteId() {
  return useSelector((state: CoreReduxState) => state.routeId);
}

// Use this to access upid in components. Do NOT use the query params -- that pattern is deprecated
export function useUpid() {
  return useSelector((state: CoreReduxState) => state.upid);
}
