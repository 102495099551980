import { CoreReduxState } from "src/utils/redux/store";
import { getPageLocation, PageLocation } from ".";
import { conditionsPass, PageCondition, resolveSwitch } from "../conditions";
import { GoToPageOptions } from "../goto/pageSet";
import { Direction, Page, PageList, PageSet } from "../types";
import { keyHasLoader } from "../values";

export function resolvePage(
  page: PageList[0],
  state: CoreReduxState,
  direction: Direction
): Page {
  const isSwitch = "switch" in page;
  const isBlock = "block" in page;

  // Ensure that we don't have a filter on our top level page object
  if (
    !page ||
    ((isSwitch || isBlock) &&
      !conditionsPass(page.conditions, state, direction))
  ) {
    return null;
  }

  if ("switch" in page) {
    return resolveSwitch(page, state, direction);
  }
  if ("block" in page) {
    return direction === Direction.FORWARD
      ? page.block[0]
      : page.block[page.block.length - 1];
  }
  return page;
}

export function findLoadablePageSetKeys(pageList: PageList): string[] {
  const pageSetKeys = new Set<string>();

  pageList.forEach(function handlePage(page) {
    if ("switch" in page) {
      if (keyHasLoader(page.switch)) {
        pageSetKeys.add(page.switch);
      }
    } else if ("block" in page) {
      page.block.forEach(handlePage);
    }
    findLoadableConditionKeys(page.conditions).forEach((key) =>
      pageSetKeys.add(key)
    );
  });

  return Array.from(pageSetKeys);
}

export function findLoadableConditionKeys(
  conditions: PageCondition[]
): string[] {
  const conditionKeys = new Set<string>();

  conditions?.forEach((condition) => {
    if (keyHasLoader(condition.key)) {
      conditionKeys.add(condition.key);
    } else if (!condition.key) {
      if (
        ["ALL_SEGMENTS", "ANY_SEGMENT", "NO_SEGMENTS"].includes(
          condition.operator
        ) &&
        condition.value
      ) {
        const values = Array.isArray(condition.value)
          ? condition.value
          : [condition.value];
        values.forEach((value) => {
          if (keyHasLoader(`${value}`)) {
            conditionKeys.add(`${value}`);
          }
        });
      }
    }
  });

  return Array.from(conditionKeys);
}

export function findFuturePageIndex(
  direction: Direction,
  activePageSet: PageSet,
  { history, store }: GoToPageOptions,

  pagePosition = getPageLocation(activePageSet, history.location.pathname),
  // If the caller cares about the traversal path used in finding the next page, they can
  // include this parameter, which will be mutated.
  traversePath?: PageList
): PageLocation {
  return findBlockFuturePageIndex(
    direction,
    activePageSet.pages,
    store,
    pagePosition,
    traversePath
  );
}

function findBlockFuturePageIndex(
  direction: Direction,
  pageList: PageList,
  store: GoToPageOptions["store"],

  pageLocation: PageLocation = [],
  traversePath?: PageList
): PageLocation {
  return findIndexFromIndex(
    pageList,
    pageLocation[0] ?? -1,
    direction,
    (nextPage) => {
      // Iterate into blocks
      if ("block" in nextPage) {
        if (conditionsPass(nextPage.conditions, store.getState(), direction)) {
          return findBlockFuturePageIndex(
            direction,
            nextPage.block,
            store,
            pageLocation.slice(1),
            traversePath
          );
        }
        traversePath?.push(nextPage);
        return false;
      }

      const resolvedPage = resolvePage(nextPage, store.getState(), direction);

      // Filtered by top level conditionals
      if (!resolvedPage) {
        return false;
      }
      if (
        conditionsPass(resolvedPage.conditions, store.getState(), direction)
      ) {
        return true;
      }
      traversePath?.push(resolvedPage);
      return false;
    }
  );
}

function findIndexFromIndex(
  array: PageList,
  currentPosition: number,
  direction: Direction,
  cb: (value: PageList[0], index: number) => PageLocation | boolean
): PageLocation {
  const OP = direction === Direction.FORWARD ? 1 : -1;

  let startPosition = currentPosition;
  if (startPosition === -1) {
    startPosition = direction === Direction.FORWARD ? 0 : array.length - 1;
  }

  const current = array[startPosition];

  // If we are currently on a block, do a depth first iteration
  if ("block" in current) {
    const newLocation = cb(current, startPosition);
    if (newLocation) {
      if (typeof newLocation === "boolean") {
        return [startPosition];
      }
      return [startPosition].concat(newLocation);
    }
  }

  for (
    let i = currentPosition + OP * 1;
    i >= 0 && i < array.length;
    i += OP * 1
  ) {
    const newLocation = cb(array[i], i);
    if (newLocation) {
      if (typeof newLocation === "boolean") {
        return [i];
      }
      return [i].concat(newLocation);
    }
  }
  return undefined;
}
