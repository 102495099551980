export const MAX_COPAY_DEDUCTIBLE_TO_DISPLAY_AMOUNT = 40;
export const OPTIMIZELY_TARGETED_FOR_TELEHEALTH =
  "optimizelyTargetedForTelehealth";

export const enum ConsultType {
  EM = "EM",
  RPM = "RPM",
}

export enum EligibleStateCode {
  CA = "CA",
  FL = "FL",
}

export const ELIGIBLE_HEALTH_RISKS = [
  "heartDiseaseOrStroke",
  "highBloodPressure",
  "diabetes",
  "nash",
  "kidneyDisease",
  "highCholesterol",
  "asthma",
  "insomnia",
];

export type PayerInfo = {
  payerId: string;
  workflowId: string;
};

export enum Payer {
  anthem = "anthem",
  bsca = "bsca",
  cigna = "cigna",
  uhc = "uhc",
  aetna = "aetna",
  kaiserPermanente = "kaiserPermanente",
  medicare = "medicare",
  bcbs = "bcbs",
  tricare = "tricare",
  humana = "humana",
}

export enum SurveyType {
  // create a new consult
  Schedule = "Schedule",
  // move an existing consult to a new time
  Reschedule = "Reschedule",
}

export const EligiblePayerMappings: {
  [stateCode in EligibleStateCode]: {
    [payer in Payer]?: PayerInfo;
  };
} = {
  CA: {
    anthem: { payerId: "47198", workflowId: "weight-management-anthem" },
    bsca: { payerId: "BS001", workflowId: "weight-management-bsca" },
    cigna: { payerId: "62308", workflowId: "weight-management-cigna" },
    uhc: { payerId: "87726", workflowId: "weight-management-uhc" },
  },
  FL: {
    // bcbs: { payerId: "00590", workflowId: "weight-management-bcbs-fl" }, // HC-397 Not yet reimbursing our program
    aetna: { payerId: "60054", workflowId: "weight-management-aetna-fl" },
    cigna: { payerId: "62308", workflowId: "weight-management-cigna" },
  },
};
