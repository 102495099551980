import { RecommendedPlanState } from "src/utils/redux/slices/recommendedPlan";
import { SurveyAnswersState } from "src/utils/redux/slices/surveyAnswers";
import { registerHandler } from "../handler";

declare const pintrk: any;

interface PinterestParams {
  [key: string]: any;
  planId: string;
  duration: number;
  ageRange?: string;
  age?: string;
  weight?: number;
  idealWeight?: number;
  idealWeightKg?: number;
  weightKg?: number;
  weightLossGoal?: number;
  weightLossGoalKg?: number;
}

const PINTERST_TAG_ID = "2620804621622";
const NOOM_WEIGHT_CONVERSION_EVENT = "checkout";
const NOOM_MOOD_CONVERSION_EVENT = "signup";

/**
 * Formats a new params object for use in the conv_param1 prop of the Pinterest Pixel tag.
 * NOTE(Rose): Make sure to handle surveyAnswers or its props being undefined due to
 * inconsistencies between US/nonUS survey data (e.g. ageRange is US, age is nonUS).
 */
function getPinParams(
  plan: RecommendedPlanState,
  surveyAnswers: SurveyAnswersState
) {
  const params: PinterestParams = {
    planId: plan.braintree_id,
    duration: plan.billing_cycle_in_months,
  };
  const requestedParams = [
    "ageRange",
    "age",
    "weight",
    "idealWeight",
    "weightKg",
  ];

  if (surveyAnswers) {
    requestedParams.forEach((key) => {
      if (surveyAnswers?.[key]) {
        params[key] = surveyAnswers[key];
      }
    });

    if (params.weight && params.idealWeight) {
      params.weightLossGoal = params.weight - params.idealWeight;
    } else if (params.weightKg && surveyAnswers.weightLossGoal) {
      params.weightLossGoalKg = surveyAnswers.weightLossGoal;
      params.idealWeightKg = params.weightKg - surveyAnswers.weightLossGoal;
    }
  }
  return params;
}

function convertToPinConvParam(params: Record<string, any>) {
  const convParam: string[] = [];
  Object.keys(params).forEach((key) => {
    const param = params[key];
    if (param != null && param) {
      convParam.push(`${key}:${param.toString()}`);
    }
  });
  return convParam.join(",");
}

function trackPinterestConversion(order, plan, surveyAnswers, event) {
  const convParam = convertToPinConvParam(getPinParams(plan, surveyAnswers));
  pintrk("track", event, {
    value: order.erev_9_months,
    order_quantity: 1,
    currency: "USD",
    order_id: order.subscriptionId,
    conv_param1: convParam,
  });
}

function trackPinterestLead(email) {
  // Advanced tracking for Pinterest (enables Enhanced Match).
  if (email) {
    pintrk("load", PINTERST_TAG_ID, {
      em: email.toLowerCase(),
    });
  }
  pintrk("track", "lead");
}

// https://developers.pinterest.com/docs/ad-tools/conversion-tag/
export default function registerHandlers() {
  registerHandler("lead", ({ email }) => {
    trackPinterestLead(email);
  });

  registerHandler("HMLead", ({ email }) => {
    trackPinterestLead(email);
  });

  registerHandler("initiateCheckout", () => {
    pintrk("track", "addtocart");
  });

  registerHandler("HMInitiateCheckout", () => {
    pintrk("track", "addtocart");
  });

  registerHandler("purchase", ({ order, plan, surveyAnswers }) => {
    trackPinterestConversion(
      order,
      plan,
      surveyAnswers,
      NOOM_WEIGHT_CONVERSION_EVENT
    );
  });

  registerHandler("HMPurchase", ({ order, plan, surveyAnswers }) => {
    trackPinterestConversion(
      order,
      plan,
      surveyAnswers,
      NOOM_MOOD_CONVERSION_EVENT
    );
  });
}
