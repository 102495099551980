import styled from "@emotion/styled";
import React, { ButtonHTMLAttributes, ReactNode, useMemo } from "react";
import { compassColors } from "@utils/styling";
import { fontP2Medium, fontP2Regular } from "src/design-system/styles/fonts";
import { flexMiddle } from "src/design-system/styles/layout";

const GenericButton = styled.button`
  ${flexMiddle};
  ${fontP2Medium}
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 16px;
  min-height: 60px;
  width: 100%;
  cursor: pointer;
  text-align: center;
`;

const PrimaryCtaButton = styled(GenericButton)`
  background-color: ${compassColors.tarocco};
  color: ${compassColors.white};

  &:hover {
    background-color: ${compassColors.cinnamon};
  }

  &:disabled {
    background-color: ${compassColors.grey2};
  }

  &:focus {
    border: solid 2px ${compassColors.cinnamon};
  }
`;

const SecondaryCta = styled(GenericButton)`
  background-color: ${compassColors.white};
  color: ${compassColors.grey3};

  &:hover:enabled {
    background-color: ${compassColors.offWhite};
    color: ${compassColors.tarocco};
  }

  &:disabled {
    color: ${compassColors.grey2};
  }

  &:focus {
    border: solid 2px ${compassColors.grey2};
  }
`;

const SingleSelectButton = styled(GenericButton)<{ isSelected: boolean }>`
  ${fontP2Regular}
  background-color: ${compassColors.offWhite};
  color: ${compassColors.black};

  &:disabled {
    opacity: 0.75;
  }

  ${(props) =>
    props.isSelected
      ? `
        background-color: ${compassColors.lagoon};
        color: ${compassColors.offWhite};

        &:focus {
          border: solid 2px ${compassColors.blueberry};
        }
        `
      : `
        &:hover:enabled {
          background-color: ${compassColors.sand1};
        }

        &:focus {
          border: solid 2px ${compassColors.gold};
        }
        `}
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Icon = styled.img<{ height?: string; margin?: Margin }>`
  ${(props) => `height: ${props.height || "18px"};`}
  ${(props) =>
    `margin: ${props.margin?.top || "auto"} ${props.margin?.right || "0"} ${
      props.margin?.bottom || "auto"
    } ${props.margin?.left || "0"};`}
`;

type Props = {
  designStyle?: "primaryCta" | "secondaryCta" | "singleSelect";
  isSelected?: boolean;
  icon?: IconInfo;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type IconInfo = {
  src: string;
  height?: string;
  margin?: Margin;
};

type Margin = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
};

function Button({
  designStyle = "primaryCta",
  isSelected,
  icon,
  children: childrenProp,
  ...buttonProps
}: Props) {
  const children = useIcon(icon, childrenProp);

  if (designStyle === "singleSelect") {
    return (
      <SingleSelectButton
        type="button"
        isSelected={isSelected}
        {...buttonProps}
      >
        {children}
      </SingleSelectButton>
    );
  }

  if (designStyle === "secondaryCta") {
    return (
      <SecondaryCta type="button" {...buttonProps}>
        {children}
      </SecondaryCta>
    );
  }

  return (
    <PrimaryCtaButton type="button" {...buttonProps}>
      {children}
    </PrimaryCtaButton>
  );
}

function useIcon(icon?: IconInfo, children?: ReactNode) {
  return useMemo(() => {
    // Wrap children around an icon container if needed
    if (icon) {
      const { src, height, margin } = icon;
      return (
        <IconContainer>
          <Icon
            src={src}
            height={height}
            margin={margin}
            alt=""
            role="presentation"
          />
          {children}
        </IconContainer>
      );
    }

    return children;
  }, [children, icon]);
}

export default Button;
