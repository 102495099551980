import axios, { AxiosRequestConfig, Method } from "axios";

// eslint-disable-next-line no-underscore-dangle
declare const __API_DOMAIN__: string;

/**
 * Convenience method that wraps Axios and generates a request for us with some
 * preset settings.
 */
export function send<T extends JsonObject>(
  method: Method,
  pathname: string,
  body?: JsonObject | URLSearchParams,
  opts: AxiosRequestConfig = {}
): Promise<T> {
  const instanceOptions: AxiosRequestConfig = {
    baseURL: __API_DOMAIN__,
    ...opts,
    headers: {
      Accept: "application/json",
      ...opts.headers,
    },
    // NOTE(Patrick): We set withCredentials to true by default because many of our
    // endpoints rely on the _userId cookie to be set.
    withCredentials: opts.withCredentials || opts.withCredentials == null,
  };

  const ax = axios.create(instanceOptions);

  return ax({
    method,
    url: pathname,
    data: body,
  }).then((r) => r.data);
}

export default send;
