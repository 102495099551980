import styled from "@emotion/styled";
import React, { ButtonHTMLAttributes } from "react";
import { compassColors } from "@utils/styling";
import { flexMiddle } from "src/design-system/styles/layout";

const CtaButton = styled.button`
  ${flexMiddle};
  background-color: ${compassColors.tarocco};
  border: none;
  border-radius: 8px;
  color: ${compassColors.white};
  font-family: "Brown LL", sans-serif;
  font-size: 16px;
  font-weight: 700;
  height: 56px;
  letter-spacing: 1px;
  max-width: 400px;
  text-transform: uppercase;
  width: 100%;
  margin: 0 auto;

  &:hover {
    background-color: ${compassColors.cinnamon};
  }

  &:disabled {
    background-color: ${compassColors.grey2};
    color: ${compassColors.black};
  }
`;

type Props = {
  isSelected?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ isSelected, ...buttonProps }: Props) => {
  return <CtaButton type="button" {...buttonProps} />;
};

export default Button;
