import { CoreReduxState } from "src/utils/redux/store";
import * as UserSegments from "@utils/userSegment";
import * as FeatureSegments from "@utils/userSegment/features";
import { isCountryImperial, isCountryStones } from "src/utils/userSegment";
import { Direction } from "./types";
import invariant from "tiny-invariant";
import { captureException } from "src/utils/sentry";

export type JsonScalar = number | string | boolean | null;

export function keyHasLoader(key: string) {
  const segmentFn = getSegmentFn(key, false);
  if (segmentFn) {
    return !!segmentFn.load;
  }
  return false;
}
export async function loadValue(key: string, state: CoreReduxState) {
  try {
    const segmentFn = getSegmentFn(key, false);
    if (segmentFn) {
      return await segmentFn.load?.(state);
    }
  } catch (err) {
    // We're erroring on the side of attempting to complete the survey without the value.
    // Segments that need to load data should fail safe if data is not available.
    captureException(err);
  }
  return undefined;
}

export function resolveValue(
  key: string,
  state: CoreReduxState,
  direction = Direction.FORWARD
) {
  const resolvers = {
    direction: () => direction,
    countryUnits: () => {
      if (isCountryImperial()) {
        return "imperial";
      }
      if (isCountryStones()) {
        return "stones";
      }
      return "metric";
    },
  };

  if (key in resolvers) {
    return resolvers[key]();
  }

  return resolveReduxValue(key, state) ?? resolveSegment(key, state);
}

function resolveSegment(name: string, state: CoreReduxState): boolean {
  const segmentFn = getSegmentFn(name, false);
  if (segmentFn) {
    return segmentFn(state);
  }
  return undefined;
}

function extractReduxPath(key: string) {
  return key.split(".");
}

function resolveReduxValue(key: string, object: JsonObject): AnyJson {
  if (!key) {
    return null;
  }

  const tokens = extractReduxPath(key);
  let currentObject = object;
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (currentObject[token] == null) {
      return null;
    }
    currentObject = currentObject[token];
  }
  return currentObject;
}
export function resolveScalar(object: AnyJson): JsonScalar {
  if (Array.isArray(object)) {
    return object[0];
  }
  return object;
}

export function getSegmentFn(
  name: string,
  assertMissing = true
): UserSegments.SegmentFunction {
  // WARN: Must use null change vs. 'in' to work with storybook mock
  if (UserSegments[name] != null) {
    return UserSegments[name];
  }
  if (FeatureSegments[name] != null) {
    return FeatureSegments[name];
  }
  if (assertMissing) {
    invariant(false, `Unknown segment ${name}`);
  }
  return undefined;
}
