import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";

import { CoreReduxState } from "src/utils/redux/store";
import { DEFAULT_COLUMN_MARGIN, MainColumn } from "../core/BodyLayout";
import { Avatar } from "./Avatar";
import { fontTitle } from "src/design-system/styles/fonts";
import { textTruncate } from "src/design-system/styles/utils";
import { HeaderToolbar } from "../core/HeaderToolbar";

// Default column width used when not nested within a MainColumn element.
const AVATAR_COLUMN_WIDTH = "780px";

const MAIN_COLUMN_WIDTH = `var(--main-column-width, ${AVATAR_COLUMN_WIDTH})`;
const MAIN_COLUMN_MARGIN = `var(--main-column-margin, ${DEFAULT_COLUMN_MARGIN})`;

const Column = styled(MainColumn)`
  height: 50px;

  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 16px;
  align-items: center;

  // This lovely thing pushes the avatar to the right to give that
  // a 780 column width alignment while keeping the left child on
  // the current main column alignment.
  --header-margin: max(
    0px,
    (100vw - ${MAIN_COLUMN_WIDTH}) / 2 - ${MAIN_COLUMN_MARGIN}
  );
  margin-left: var(--header-margin);
  max-width: min(
    100vw - var(--header-margin) - ${MAIN_COLUMN_MARGIN} * 2,
    ${MAIN_COLUMN_WIDTH} + (${AVATAR_COLUMN_WIDTH} - ${MAIN_COLUMN_WIDTH}) / 2
  );
`;

const Email = styled.div`
  ${fontTitle};
  ${textTruncate};
  text-align: right;
  font-weight: 700;
`;

export default function LoggedInHeader({ children }: { children?: ReactNode }) {
  const email =
    useSelector((state: CoreReduxState) => {
      let storeEmail = state.paymentEnrollmentForm?.enrollmentInfo?.email;
      if (state.promoCode.promoCodeIsVip || !storeEmail) {
        // eslint-disable-next-line prefer-destructuring
        storeEmail = state.userData.email;
      }
      return storeEmail;
    }) ?? "Noom";

  return (
    <nav>
      <HeaderToolbar>
        <Column>
          {children}
          <Email css={{ gridColumn: 2 }}>{email}</Email>
          <Avatar css={{ gridColumn: 3 }} email={email} />
        </Column>
      </HeaderToolbar>
    </nav>
  );
}
