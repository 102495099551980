import { send } from "@utils/fetch";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { captureException } from "@utils/sentry";

/**
 * Helper methods to save properties to Braze.
 * Copyright (C) 2019 Noom, Inc.
 * Author: Patrick
 */

/**
 * Send UserData, EventData or both to Braze.
 */
export default function saveToBraze(
  userId: string,
  userData: SurveyAnswersState,
  eventData: JsonObject
): Promise<void> {
  if (!userData && !eventData) {
    captureException(new Error("saveToBraze: No data to send to Braze"));
    return Promise.resolve();
  }

  const payload: JsonObject = {
    clientUserId: userId,
    user: userData,
    event: eventData,
  };

  return send("POST", "/userdata/api/v4/user/braze/save/", payload).catch(
    (err) => {
      captureException(err);
    }
  );
}
