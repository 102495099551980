import { ActionHandler, ActionHandlerParams } from "..";
import { completeMainSurvey } from "src/utils/mainSurvey";
import { getPageSetSurvey } from "./util";

type Params = {
  finishedEvent?: string;
  lead?: string;

  dateKey?: string;
  extraProps?: JsonObject;
};

export const onCompleteMainSurvey: ActionHandler = async ({
  params = {},
  pageSet,
  page,
  store,
}: {
  params: Params;
} & ActionHandlerParams): Promise<void> => {
  const survey = getPageSetSurvey(pageSet, page);
  const finishedEvent = params.finishedEvent || `Finished${survey.name}`;

  const { surveyAnswers } = store.getState();
  return completeMainSurvey({
    surveyAnswers,
    params: {
      ...params,
      finishedEvent,
    },
    dispatch: store.dispatch,
  });
};
