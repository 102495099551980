import { logInfo } from "src/utils/logging";
import { CoreReduxState } from "src/utils/redux/store";
import { removeURLParam } from "src/utils/urlParams";
import { getPage, getPageSet, getPageSetFromPage } from ".";
import { Direction, PageSet } from "../types";
import { emitPageSetEvents } from "./bus";

import type { Location } from "history";

export async function resolveParamPageSet(
  state: CoreReduxState,
  location: Location
) {
  const persistedPageSet = getPageSet(state.linearBuyflow.pageSetName);
  const persistedPage = getPage(
    persistedPageSet?.pages,
    location.pathname,
    state
  );

  // Urls set on query params override current
  let discoveredPageSet = pullPageSetFromUrl(location, state);

  if (!discoveredPageSet && !persistedPage) {
    // Check out current url and see if it matches a page set.
    discoveredPageSet = await getPageSetFromPage(location.pathname, state);
  }

  if (discoveredPageSet && discoveredPageSet !== persistedPageSet) {
    // Strip URL param and replace state
    removeURLParam("psid");

    logInfo(`Setting active page set to ${discoveredPageSet.id}`);
    emitPageSetEvents("set:page-set", {
      activePageSet: discoveredPageSet,
      direction: Direction.FORWARD,

      // These aren't available yet so make the listener figure it out.
      history: null,
      store: null,
    });
    return discoveredPageSet;
  }
  return undefined;
}

// When a user lands on a pool page, we need two pieces of information to
// direct them to the correct page.
// 1. Page Set ID
//    The Page Set ID is stored either in the URL's querystring ?psid=<pageSetId>
//    or in redux in the 'linearBuyflow' slice.
// 2. Page Pathname
//    For example: https://www.noom.com/p/landing -> `/p/landing`
function pullPageSetFromUrl(
  location: Location,
  state: CoreReduxState
): PageSet {
  const psid = new URLSearchParams(location.search).get("psid");
  const activePageSet = getPageSet(psid);
  if (!activePageSet) {
    return null;
  }

  // 3. Finally set the active page set and active page in redux.
  const isPartOfPageSet = getPage(
    activePageSet.pages,
    location.pathname,
    state
  );
  if (isPartOfPageSet) {
    return activePageSet;
  }

  return null;
}
