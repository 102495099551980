import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import { RecommendedPlanState } from "../redux/slices/recommendedPlan";
import getStore from "@utils/redux/store";
import registerHandlers, { firePixelEvent } from "../pixels/handler";
import { isHM } from "../userSegment";

export function initConversionTracker() {
  registerHandlers();
  firePixelEvent("init", {});
}

/**
 * Tracks the 'Lead' event.
 */
export function trackLead(
  email: string,
  name: string,
  surveyAnswers?: SurveyAnswersState
) {
  if (isHM()) {
    firePixelEvent("HMLead", { email, name, surveyAnswers });
  } else {
    firePixelEvent("lead", { email, name, surveyAnswers });
  }
}

/**
 * Tracks the 'Initiate Checkout' event.
 */
export function trackInitiateCheckout(
  plan: RecommendedPlanState,
  surveyAnswers: SurveyAnswersState
) {
  if (isHM()) {
    firePixelEvent("HMInitiateCheckout", { plan, surveyAnswers });
  } else {
    firePixelEvent("initiateCheckout", { plan, surveyAnswers });
  }
}

/**
 * Tracks the 'Purchase' event.
 */
export function trackPurchase(
  email: string,
  plan: RecommendedPlanState,
  surveyAnswers: SurveyAnswersState,
  order: JsonObject
) {
  const { userData } = getStore().getState();
  const eventName = isHM() ? "HMPurchase" : "purchase";
  firePixelEvent(eventName, {
    email,
    plan,
    surveyAnswers,
    order,
    userData,
  });
}

export function trackViewContent() {
  firePixelEvent("viewContent", {
    value: "2.00",
    currency: "USD",
  });
}

export function trackViewContentWithParamsExsf0(params: {
  content_type: string;
}) {
  const { serverContext, language } = getStore().getState();
  if (serverContext.is_paid_traffic && language === "en") {
    firePixelEvent("viewContent", params);
  }
}
