import getStore from "../redux/store";
import { captureException } from "../sentry";
import { genderConstants, sexConstants } from "../sexAndGender";

export const isMaleGender = (state = getStore().getState()) =>
  state.surveyAnswers.gender?.[0] === genderConstants.male;

export const isFemaleGender = (state = getStore().getState()) =>
  !state.surveyAnswers.gender ||
  state.surveyAnswers.gender[0] === genderConstants.female;

export const isMaleSex = (state = getStore().getState()) =>
  !state.surveyAnswers.sex
    ? isMaleGender(state)
    : state.surveyAnswers.sex[0] === sexConstants.male;

export const isOver30DaySubscriber = (state = getStore().getState()) => {
  try {
    const { subscriptions, subscription_id } = state.serverContext;
    const subscriptionsArr = JSON.parse(subscriptions);
    const subscription = subscriptionsArr.find(
      (s: any) => s.id === subscription_id
    );

    const startDate = new Date(
      subscription.time_started.year,
      subscription.time_started.month - 1 /* months are from 0 to 11 */,
      subscription.time_started.day
    );

    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - startDate.getTime();

    const daysDifference = timeDifference / (1000 * 3600 * 24);

    return daysDifference >= 30;
  } catch (e) {
    captureException(e, "isOver30DaySubscriber");
    return false;
  }
};
