import React, { ChangeEventHandler, MouseEventHandler, RefObject } from "react";
import { questionOption } from "@components/refactored-survey/question-types/Question.module.less";
import {
  radioQuestionOption,
  checkedWithInput,
} from "@components/refactored-survey/question-types/RadioQuestion.module.less";

const RadioTextInput = ({
  getQuestionAnswerKey,
  inputValue,
  handleInputChange,
  name,
  placeholder,
  handleClick,
  inputRef,
}: {
  getQuestionAnswerKey: () => string;
  inputValue?: string;
  name: string;
  placeholder?: string;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleClick: MouseEventHandler<HTMLElement>;
  inputRef: RefObject<HTMLInputElement>;
}) => (
  <label
    className={`${questionOption} ${radioQuestionOption} ${
      getQuestionAnswerKey() === name ? checkedWithInput : ""
    } `}
  >
    <input
      type="radio"
      name={name}
      defaultChecked={getQuestionAnswerKey() === name}
      onClick={handleClick}
    />
    <input
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      placeholder={placeholder}
      ref={inputRef}
    />
  </label>
);

export default RadioTextInput;
