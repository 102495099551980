import { registerHandler } from "../handler";

const AMAZON_LEAD_PIXEL =
  "https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D43627755-3008-0850-28d5-e5ad3dbc3a94%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://www.noom.com/&ex-hargs=v%3D1.0%3Bc%3D581704800071409613%3Bp%3D43627755-3008-0850-28D5-E5AD3DBC3A94";
const AMAZON_CHECKOUT_PIXEL =
  "https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D06055330-cf5b-7f6c-f9b5-21f1b576f0ba%26type%3D29%26m%3D1&ex-fch=416613&ex-src=https://www.noom.com/&ex-hargs=v%3D1.0%3Bc%3D581704800071409613%3Bp%3D06055330-CF5B-7F6C-F9B5-21F1B576F0BA";
const AMAZON_CONVERSION_PIXEL =
  "https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Deb4f2ab6-4872-c242-6da4-b2ee933e31e0%26type%3D30%26m%3D1&ex-fch=416613&ex-src=https://www.noom.com&ex-hargs=v%3D1.0%3Bc%3D581704800071409613%3Bp%3DEB4F2AB6-4872-C242-6DA4-B2EE933E31E0";

function fireAmazonPixel(url: string) {
  const amazonPixel = document.createElement("img");
  amazonPixel.setAttribute("src", url);
  amazonPixel.setAttribute("height", "1px");
  amazonPixel.setAttribute("width", "1px");
  amazonPixel.setAttribute("border", "0");
  amazonPixel.setAttribute("style", "display:none;");
  amazonPixel.setAttribute("alt", "");

  document.body.appendChild(amazonPixel);
}

export default function registerHandlers() {
  registerHandler("lead", () => {
    fireAmazonPixel(AMAZON_LEAD_PIXEL);
  });

  registerHandler("initiateCheckout", () => {
    fireAmazonPixel(AMAZON_CHECKOUT_PIXEL);
  });

  registerHandler("purchase", () => {
    fireAmazonPixel(AMAZON_CONVERSION_PIXEL);
  });
}
