import * as Sentry from "@sentry/browser";
import { AxiosError } from "axios";
import { collectAppStateProperties, trackEvent } from "@utils/api/tracker";
import {
  getExperimentState,
  getMeristemContext,
  getMeristemState,
  getSha,
} from "./meristemContext";

// eslint-disable-next-line no-underscore-dangle
declare const __SENTRY_PROJECT_DSN_JS__: string;

interface SentryMeristemContext {
  sha: string;
  city_name: string;
  country_code: string;
  postal_code: string;
  subdivision: string;
  meristemStateId: string;
  shaOverride?: string;
  [key: string]: string;
}

const KNOWN_SENTRY_ERRORS = [
  "Failed to fetch",
  "The operation couldn’t be completed. Software caused connection abort",
  "cancelled",
  "cancelado",
  "The request timed out.",
  "The Internet connection appears to be offline.",
  "The network connection was lost.",
];

let SENTRY_SAMPLE_RATE = 0.2;
let LOW_PRIORITY_SENTRY_SAMPLE_RATE = 0.05;
// NOTE(di): For non production env, we would like to capture all exceptions which will help with debugging.
if (process.env.NODE_ENV !== "production") {
  SENTRY_SAMPLE_RATE = 1.0;
  LOW_PRIORITY_SENTRY_SAMPLE_RATE = 1.0;
}

let sentryInit = false;

/**
 * Wrap the sentry initialization logic so we can place it where we'd like.
 */
export function initializeSentry() {
  if (sentryInit) {
    return;
  }
  sentryInit = true;

  Sentry.init({
    dsn: __SENTRY_PROJECT_DSN_JS__,
    // From tips and tricks: https://docs.sentry.io/clients/javascript/tips/
    // Only send 20% of errors.
    sampleRate: SENTRY_SAMPLE_RATE,
    // Disable default Sentry logging of unhandled rejection events.
    // From open issue around the new Sentry SDK: https://github.com/getsentry/sentry-javascript/issues/2019
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: false,
      }),
    ],
    environment: __NODE_ENV__,
    debug: __NODE_ENV__ !== "production",
    whitelistUrls: [
      /noom\.com/,
      ...(__NODE_ENV__ !== "production"
        ? [
            /buyflow-lambda\.(dev|test)\.wsli\.dev/,
            /buyflow-web-assets\.test\.wsli\.dev/,
          ]
        : []),
    ],
  });

  // If we're in the browser context, manually log unhandled promise rejection
  // events in Sentry with better error messages, instead of the default Sentry
  // logging for unhandled promise rejections.
  if (typeof window !== "undefined") {
    window.onunhandledrejection = (event) => {
      if (event && typeof event.preventDefault === "function") {
        event.preventDefault();
        captureException(event.reason);
      }
    };
  }

  // Add context to the error to help pinpoint SHA and experiment.
  try {
    const meristemContext = getMeristemContext();

    // Use a tag for SHA so we can search by it.
    Sentry.setTag("sha", getSha());

    const context: SentryMeristemContext = {
      sha: getSha(),
      city_name: meristemContext.city_name,
      country_code: meristemContext.country_code,
      postal_code: meristemContext.postal_code,
      subdivision: meristemContext.subdivision,
      language_code: meristemContext.language_code,
      meristemStateId: `${getMeristemState().id}`,
    };

    getExperimentState().forEach((experiment) => {
      if (experiment.shaOverride) {
        context.shaOverride = experiment.shaOverride;
      } else {
        context[
          `Experiment ${experiment.experimentName}`
        ] = `${experiment.variationName}`;

        Sentry.setTag(`Experiment`, experiment.experimentName);
        Sentry.setTag(`Variation`, experiment.variationName);
      }
    });

    Sentry.setContext("Meristem Context", context);
  } catch (err) {
    /** NOP */
  }
}

/**
 * Small utility to set the user id in the sentry scope whenever we assign the
 * user an id.
 */
export function setUserIdInSentryScope(userId: string) {
  if (!userId) return;
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: userId,
    });
  });
}

/**
 * Capture an exception and send it to Sentry. Optionally set a fingerprint
 * so errors of the same type are grouped together even if different browsers
 * throw different errors.
 */
export function captureException(
  e: (Error & { error?: unknown }) | AxiosError | any,
  fingerprint?: string[] | string,
  extras?: Record<string, unknown>
) {
  initializeSentry(); // In case Sentry is not initialized yet

  if (__NODE_ENV__ !== "production") {
    // eslint-disable-next-line no-console
    console.error("captureException", e, fingerprint);
    return;
  }

  // Kill known bad
  if (/Object Not Found Matching Id:/i.test(e)) {
    return;
  }

  // Sample (do not log all) low priority known sentry errors.
  if (
    e.message &&
    KNOWN_SENTRY_ERRORS.includes(e.message) &&
    Math.random() > LOW_PRIORITY_SENTRY_SAMPLE_RATE
  ) {
    return;
  }
  Sentry.withScope((scope) => {
    if (fingerprint) {
      scope.setFingerprint(
        Array.isArray(fingerprint) ? fingerprint : [fingerprint]
      );
    }

    if (extras) {
      Object.keys(extras).forEach((key) => {
        scope.setExtra(key, extras[key]);
      });
    }

    try {
      Sentry.setContext("FullStory Session", window.FS.getCurrentSessionURL());
    } catch (err) {
      /** NOP */
    }

    Sentry.setTags({
      country: getMeristemContext().country_code,
      language: getMeristemContext().language_code,
      routeId: getMeristemContext().route_id,
      ...collectAppStateProperties(),
    });

    try {
      if ("isAxiosError" in e && e.isAxiosError) {
        const status = e.response?.status;
        scope.setExtra("URL", e.request?.url);
        scope.setExtra("Response extra", {
          headers: e.response?.headers,
          ok: e.response?.statusText === "OK",
          statusText: e.response?.statusText,
          status,
        });

        // NOTE(Patrick): If we have some kind of low level network error then
        // Axios throws a generic "Network Error" which gets picked up in Sentry.
        // https://github.com/axios/axios/blob/7821ed20892f478ca6aea929559bd02ffcc8b063/lib/adapters/xhr.js#L97-L99
        if (!status || (status >= 400 && status < 500)) {
          trackEvent("OnNetworkError", {
            currentURL: window?.location?.href,
            requestURL: e.request.url,
            error: e.message,
          });
          return;
        }
      }

      // Emit to mixpanel to tie these to session and global analysis
      trackEvent("OnException", {
        error: e.message,
      });
    } catch (err) {
      /* NOP */
    }
    if ("error" in e) {
      scope.setExtra("error", e.error);
    }

    Sentry.captureException(e);
  });
}

/**
 * Send a custom message to Sentry with attached properties.
 */
export function captureMessageWithExtras(message: string, extras: PropertyBag) {
  Sentry.withScope((scope) => {
    Object.keys(extras).forEach((key) => {
      scope.setExtra(key, extras[key]);
    });
    Sentry.captureMessage(message);
  });
}
