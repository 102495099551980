import React, { useState } from "react";
import styles from "./CollapsibleHeader.module.less";
import Star from "./images/Star.png";

export const CHECK_EMAIL_REMINDER_PARAM = "showCheckEmailReminder";

export function shouldShowCollapsibleHeader() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams?.get(CHECK_EMAIL_REMINDER_PARAM) === "true";
}

const CollapsibleHeader = () => {
  const [isCollapsed, setCollapsed] = useState(false);

  setTimeout(() => {
    setCollapsed(true);
  }, 500);

  return (
    <div
      className={`${styles.collapsableHeader} ${
        isCollapsed ? styles.collapsed : ""
      }`}
    >
      <div className={styles.headerHeader}>
        <img className={styles.image} src={Star} alt="" />
        <div className={styles.headerText}>Great choice</div>
      </div>{" "}
      <p>
        You’ll receive an email with information about your custom plans soon so
        keep an eye out.
      </p>
    </div>
  );
};

export default CollapsibleHeader;
