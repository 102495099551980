import { captureException } from "src/utils/sentry";
import { isInsideAndroidWebView, isInsideIOSWebKit } from "./utils";

declare const Android: any;

export default {
  nativeProgramSwitchComplete() {
    if (isInsideIOSWebKit()) {
      try {
        (
          window as any
        ).webkit.messageHandlers.programSwitchComplete.postMessage({});
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativeProgramSwitchComplete.ios");
      }
    }
    if (isInsideAndroidWebView()) {
      try {
        Android.programSwitchComplete();
        return true;
      } catch (err) {
        // istanbul ignore next Error handling
        captureException(err, "nativeProgramSwitchComplete.android");
      }
    }

    // eslint-disable-next-line no-console
    console.error("nativeProgramSwitchComplete: no message handler found");

    return false;
  },
};
