/* eslint-disable no-restricted-globals */
import { CHECK_EMAIL_REMINDER_PARAM } from "@components/collapsible-header/CollapsibleHeader";
import { getEnrollmentParams } from "src/utils/enrollment";

import getStore from "src/utils/redux/store";
import { isHM } from "src/utils/userSegment";
import { pushReroll, reroll, setParam, synchronizeUpid } from "./utils";

function isEnrollment() {
  return location.pathname.startsWith("/enrollment");
}

function enrollmentBase() {
  return isHM() ? "/enrollment-hm" : "/enrollment";
}

export default {
  enrollment({
    claimedByEmail,
    enrollmentResult,
    showEmailReminder,
    ...overrides
  }: {
    name: string;
    email: string;
    claimedByEmail?: string;
    planId: string;
    route?: string;
    enrollmentResult?: string;
    showEmailReminder?: boolean;
  }) {
    // FIXME (cory): I'm honestly not sure if these are at all necessary.
    const params = { ...getEnrollmentParams(), ...overrides };
    const state = getStore().getState();
    const languageCode = state.language;
    const countryCode = state.geoLocation.country_code;
    localStorage.setItem(
      "enrollmentData",
      JSON.stringify({
        ...params,
        lc: languageCode,
        cc: countryCode,
      })
    );

    const queryParams = new URLSearchParams();
    synchronizeUpid(queryParams, getStore());
    setParam(queryParams, "lc", languageCode);
    setParam(queryParams, "cc", countryCode);
    setParam(queryParams, "rerollMerEx", "true");

    const currentQueryParams = new URLSearchParams(location.search);
    setParam(queryParams, "route", currentQueryParams.get("route"));
    setParam(queryParams, "experiment", currentQueryParams.get("experiment"));
    setParam(
      queryParams,
      "claimedByEmail",
      claimedByEmail ?? currentQueryParams.get("claimedByEmail")
    );
    setParam(
      queryParams,
      "enrollmentResult",
      enrollmentResult ?? currentQueryParams.get("enrollmentResult")
    );
    setParam(queryParams, CHECK_EMAIL_REMINDER_PARAM, showEmailReminder);
    reroll(enrollmentBase(), queryParams);
  },
  accountCreated(email: string) {
    pushReroll(
      `${enrollmentBase()}/account-created?email=${encodeURIComponent(email)}`,
      isEnrollment
    );
  },
};
