import goto from "./index";
import getStore from "@utils/redux/store";
import {
  hasPromoCode,
  isAddonStoreEligible,
  isFastForwardEligible,
} from "@utils/userSegment/features";

// This navigates outside of the Noom Premium context
export default {
  navigateFromNoomPremium() {
    const { geoLocation, language, userData, purchasedNoomPremium } =
      getStore().getState();

    const urlParams: JsonObject = {
      lc: language,
      cc: geoLocation.country_code,
    };

    if (userData) {
      urlParams.name = userData.name;
      urlParams.email = userData.email;

      const surveyAnswers =
        userData.personaSurveyAnswers || userData.personaSurveyUS;
      if (surveyAnswers != null) {
        urlParams.weight_lbs = surveyAnswers.weight || userData.weight;
        urlParams.height_ft = surveyAnswers.heightFeet || userData.heightFeet;
        urlParams.height_in = surveyAnswers.heightInch || userData.heightInch;
        urlParams.gender =
          surveyAnswers.gender && surveyAnswers.gender.length > 0
            ? surveyAnswers.gender[0].toUpperCase()
            : undefined;
      }
    }

    if (!hasPromoCode() && isFastForwardEligible()) {
      goto.fastForwardTrial({ showEmailReminder: purchasedNoomPremium });
      return;
    }

    if (!hasPromoCode() && isAddonStoreEligible()) {
      goto.addOns({ forceReroll: true, showEmailReminder: false });
      return;
    }

    goto.enrollment({
      ...urlParams,
      showEmailReminder: purchasedNoomPremium,
    });
  },
};
