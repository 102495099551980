import i18n from "@i18n";

export enum genderConstants {
  male = "male",
  female = "female",
  nonBinary = "nonBinary",
  selfIdentify = "selfIdentify",
  preferNotToSay = "preferNotToSay",
}

export enum sexConstants {
  male = "male",
  female = "female",
  intersex = "intersex",
}

export const genderConstantsMapping = {
  [genderConstants.male]: "MALE",
  [genderConstants.female]: "FEMALE",
  [genderConstants.nonBinary]: "SELF_ID",
  [genderConstants.selfIdentify]: "SELF_ID",
  [genderConstants.preferNotToSay]: "UNKNOWN",
  UNKNOWN: "UNKNOWN",
};

export const getSingularGenderedNoun = (gender: genderConstants) => {
  switch (gender) {
    case genderConstants.male:
      return i18n.t("genderedText:maleSingularNoun");
    case genderConstants.female:
      return i18n.t("genderedText:femaleSingularNoun");
    default:
      return i18n.t("genderedText:neutralSingularNoun");
  }
};

export const getPluralGenderedNoun = (gender: genderConstants) => {
  switch (gender) {
    case genderConstants.male:
      return i18n.t("genderedText:malePluralNoun");
    case genderConstants.female:
      return i18n.t("genderedText:femalePluralNoun");
    default:
      return i18n.t("genderedText:neutralPluralNoun");
  }
};
