import sha1 from "sha1";
import getStore from "@utils/redux/store";
import { registerHandler } from "../handler";
import { isAffiliateWithoutTrial } from "../../userSegment";
import Cookies from "js-cookie";

declare const ire: any;

// Owning PM for jumbleberry pixel [Kenneth]
export default function registerHandlers() {
  registerHandler("init", (): void => {
    const searchParams = new URLSearchParams(window.location.search);
    const cid = searchParams.get("cid");
    const sp = searchParams.get("sp");
    if (cid && sp === "impact") {
      Cookies.set("ircid", cid, { expires: 30 });
    }
  });

  registerHandler("lead", ({ email }) => {
    const hashedEmail = sha1(email) || "";
    const { serverContext, userData } = getStore().getState();
    const id = serverContext?.user_id || userData?.mixpanel_distinct_id || "";
    ire("identify", { customerId: id, customerEmail: hashedEmail });
  });

  registerHandler("purchase", ({ order, plan }) => {
    const { discounted_plan_price, price } = plan;
    const { userData, promoCode } = getStore().getState();

    let subTotal = price || 0;

    if (isAffiliateWithoutTrial()) {
      subTotal = discounted_plan_price;
    }

    let text1 = "Trial";
    let category = "Trial";

    if (!plan.has_trial) {
      text1 = "Sale";
      category = "Subscribed";
    }

    const payload = {
      orderId: order.subscriptionId,
      customerId: userData.user_id,
      customerStatus: "New",
      customerEmail: sha1(order.email),
      currencyCode: plan.currency,
      text1,
      orderPromoCode: promoCode.promoCodeApplied,
      items: [
        {
          subTotal,
          category,
          sku: plan.braintree_id,
          quantity: 1,
        },
      ],
    };
    ire("trackConversion", 15420, payload, {
      verifySiteDefinitionMatch: true,
    });
  });

  registerHandler("HMPurchase", ({ order, plan }) => {
    const { discounted_plan_price } = plan;
    const { userData, promoCode } = getStore().getState();

    const subTotal = discounted_plan_price || 0;
    const cid = Cookies.get("ircid") || "";

    let category = "Trial";
    if (!plan.has_trial) {
      category = "Subscribed";
    }

    const payload = {
      orderId: order.subscriptionId,
      customerId: userData.user_id,
      clickId: cid,
      customerStatus: "New",
      customerEmail: sha1(order.email),
      currencyCode: plan.currency,
      orderPromoCode: promoCode.promoCodeApplied,
      items: [
        {
          subTotal,
          category,
          sku: plan.braintree_id,
          quantity: 1,
        },
      ],
    };
    const conversionTag = category === "Trial" ? 26974 : 26975;
    ire("trackConversion", conversionTag, payload, {
      verifySiteDefinitionMatch: true,
    });
  });
}
