export enum errorConstants {
  errorUnknown = "ERROR_UNKNOWN",
  errorProgramSetup = "ERROR_PROGRAM_SETUP",
  errorActiveSubscription = "ERROR_ACTIVE_SUBSCRIPTION",
  errorPaymentProcessor = "ERROR_PAYMENT_PROCESSOR",
  errorNoSamsungRewardCode = "ERROR_NO_SAMSUNG_REWARD_CODE",
  errorInvalidCreditCard = "ERROR_INVALID_CREDIT_CARD",
  errorInvalidEmail = "ERROR_INVALID_EMAIL",
  errorPaymentMethod = "ERROR_PAYMENT_METHOD",
  errorSalesTaxLocation = "ERROR_SALES_TAX_LOCATION",
  errorTermAgreement = "ERROR_ACCEPT_TERMS_AGREEMENT",
  errorTransactionCreation = "ERROR_TRANSACTION_CREATION",
  errorUnavailableCountry = "ERROR_UNAVAILABLE_COUNTRY",
  paypalPopupClosed = "PAYPAL_POPUP_CLOSED",
  paypalAccountTokenizationFailed = "PAYPAL_ACCOUNT_TOKENIZATION_FAILED",
  paypalFlowFailed = "PAYPAL_FLOW_FAILED",
  hostedFieldsFieldsEmpty = "HOSTED_FIELDS_FIELDS_EMPTY",
  hostedFieldsFieldsInvalid = "HOSTED_FIELDS_FIELDS_INVALID",
  hostedFieldsTokenizationFailOnDuplicate = "HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE",
  hostedFieldsTokenizationCVVVerificationFailed = "HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED",
  hostedFieldsFailedTokenization = "HOSTED_FIELDS_FAILED_TOKENIZATION",
  hostedFieldsTokenizationNetworkError = "HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR",
  walletPopupClosed = "WALLET_POPUP_CLOSED",
}

const EN_ERROR_MESSAGE_MAP = {
  ERROR_PROGRAM_SETUP: "Unexpected error setting up your program.",
  ERROR_ACTIVE_SUBSCRIPTION:
    "Looks like there’s already an active Noom subscription for this email address. Please reach out to support@noom.com if you need help.",
  ERROR_PAYMENT_PROCESSOR: "Unexpected error processing payment.",
  ERROR_NO_SAMSUNG_REWARD_CODE: "No more Samsung Reward code available!",
  ERROR_UNKNOWN: "Unknown error occurred. Please try again.",
  ERROR_INVALID_CREDIT_CARD: "Error occurred when processing your card info.",
  ERROR_INVALID_EMAIL: "Invalid email. Please fix and try again.",
  ERROR_PAYMENT_METHOD:
    "There was an error processing your payment information. Please check your payment information or contact your bank and try again, or try a new payment method.",
  ERROR_SALES_TAX_LOCATION:
    "There was a problem with your ZIP code - please try a different one or contact our support team.",
  ERROR_TRANSACTION_CREATION:
    "There was an error processing your payment information. Please check your payment information or contact your bank and try again, or try a new payment method.",
  ERROR_UNAVAILABLE_COUNTRY:
    "Sorry, Noom Mood is currently unavailable for your country.",
  // PayPal Errors:
  PAYPAL_POPUP_CLOSED:
    "Sorry, we could not complete your PayPal transaction as the window was closed before it was authorized.",
  PAYPAL_ACCOUNT_TOKENIZATION_FAILED:
    "Sorry, we could not process your payment through PayPal. Please try again or use a different payment method.",
  PAYPAL_FLOW_FAILED:
    "Sorry, we were unable to start the PayPal transaction process. Please try again or use a different payment method.",
  HOSTED_FIELDS_FIELDS_EMPTY:
    "Please enter your payment information in the form below.",

  // Wallet Errors:
  WALLET_POPUP_CLOSED:
    "Sorry, we could not complete your transaction as the window was closed before it was authorized.",
};

const JA_ERROR_MESSAGE_MAP = {
  ERROR_PROGRAM_SETUP:
    "不明のエラーが発生しました。お手数ではございますが programsupport-ja@noom.com までご連絡ください。",
  ERROR_ACTIVE_SUBSCRIPTION:
    "ご入力頂いたEメールアドレスは既に登録されております。お困りの場合は programsupport-ja@noom.com までご連絡ください。",
  ERROR_PAYMENT_PROCESSOR:
    "不明のエラーで処理できませんでした。別のクレジットカードをお試しください。",
  ERROR_UNKNOWN: "不明のエラーで処理できませんでした。もう一度お試しください。",
  ERROR_PAYMENT_METHOD:
    "手続き中にエラーが発生しました。カード情報を改めてご確認ください。該当のカードをご利用いただけない場合には、カード会社にお問い合わせいただくか、別のクレジットカードをご利用ください。",
  ERROR_TRANSACTION_CREATION:
    "手続き中にエラーが発生しました。カード情報を改めてご確認ください。該当のカードをご利用いただけない場合には、カード会社にお問い合わせいただくか、別のクレジットカードをご利用ください。",
  HOSTED_FIELDS_FIELDS_EMPTY: "お支払い情報を以下のフォームにご入力ください",
};

const DE_ERROR_MESSAGE_MAP = {
  ERROR_PROGRAM_SETUP:
    "Unbekannter Fehler beim Versuch dein Programm zu starten.",
  ERROR_ACTIVE_SUBSCRIPTION:
    "Diese Email Adresse ist bereits registriert worden. Bitte melde dich bei programsupport-de@noom.com.",
  ERROR_PAYMENT_PROCESSOR: "Unbekannter Fehler beim Zahlvorgang aufgetreten.",
  ERROR_UNKNOWN: "Unbekannter Fehler aufgetreten. Bitte versuche es erneut.",
  ERROR_PAYMENT_METHOD:
    "Es gab einen Fehler beim Bearbeiten deiner Zahlungsinformation. Bitte prüfe deine Zahlungsinformation oder kontaktiere deine Bank und versuche es erneut, oder probiere eine andere Zahlungsmethode.",
  ERROR_SALES_TAX_LOCATION:
    "Es gab ein Problem mit deiner Postleitzahl. Versuche eine andere Postleitzahl anzugeben oder kontaktiere unseren Support.",
  ERROR_TRANSACTION_CREATION:
    "Es gab einen Fehler beim Bearbeiten deiner Zahlungsinformation. Bitte prüfe deine Zahlungsinformation oder kontaktiere deine Bank und versuche es erneut, oder probiere eine andere Zahlungsmethode.",
  HOSTED_FIELDS_FIELDS_EMPTY:
    "Bitte gib deine Zahlungsinformation unten im Formular an.",
};

const ES_ERROR_MESSAGE_MAP = {
  ERROR_PROGRAM_SETUP: "Hubo un error inesperado al crear tu programa.",
  ERROR_ACTIVE_SUBSCRIPTION:
    "El correo que elegiste ya está registrado con Noom. Por favor envia un correo a programsupport-es@noom.com.",
  ERROR_PAYMENT_PROCESSOR: "Hubo un error inesperado al procesar tu pago.",
  ERROR_UNKNOWN: "Hubo un error inesperado. Por favor inténtalo de nuevo.",
  ERROR_PAYMENT_METHOD:
    "Hubo un error al procesar tu información de pago. Por favor, verifica tu información de pago o comunícate con tu banco e inténtalo de nuevo, o intenta un nuevo método de pago.",
  ERROR_SALES_TAX_LOCATION:
    "Hubo un problema con su código postal. Pruebe con uno diferente o comuníquese con nuestro equipo de servicio al cliente.",
  ERROR_TRANSACTION_CREATION:
    "Hubo un error al procesar tu información de pago. Por favor, verifica tu información de pago o comunícate con tu banco e inténtalo de nuevo, o intenta un nuevo método de pago.",
  HOSTED_FIELDS_FIELDS_EMPTY:
    "Por favor, Ingrese su información de pago en el siguiente formulario.",
};

const PT_ERROR_MESSAGE_MAP = {
  ERROR_PROGRAM_SETUP:
    "Ocorreu um erro inesperado na configuração do seu programa.",
  ERROR_ACTIVE_SUBSCRIPTION:
    "Esta conta de email já foi registrada com Noom. Por favor envie email para programsupportbr@noom.com.",
  ERROR_PAYMENT_PROCESSOR: "Erro inesperado durante processo de pagamento.",
  ERROR_UNKNOWN: "Ocorreu um erro desconhecido. Por favor tente novamente.",
};

const KO_ERROR_MESSAGE_MAP = {
  ERROR_PROGRAM_SETUP: "고객님의 프로그램을 세팅하는 중 문제가 발생했습니다. ",
  ERROR_ACTIVE_SUBSCRIPTION:
    "이미 등록된 이메일 주소입니다. 고객센터로 문의해주세요.",
  ERROR_PAYMENT_PROCESSOR:
    "결제를 처리하는 도중 에러가 발생했습니다. 다시 시도해주세요.",
  ERROR_NO_SAMSUNG_REWARD_CODE: "해당 상품은 더 이상 유효하지 않습니다.",
  ERROR_UNKNOWN: "알 수 없는 에러가 발생하였습니다. 다시 시도해주세요.",
  ERROR_PAYMENT_METHOD:
    "고객님의 결제정보를 처리하는 과정에서 에러가 발생했습니다. 결제 정보를 확인하시거나, 새로운 결제 수단으로 다시 시도해주세요.",
  ERROR_TRANSACTION_CREATION:
    "고객님의 결제정보를 처리하는 과정에서 에러가 발생했습니다. 결제 정보를 확인하시거나, 새로운 결제 수단으로 다시 시도해주세요.",
  HOSTED_FIELDS_FIELDS_EMPTY: "결제 정보를 입력해주세요.",
  ERROR_INVALID_EMAIL:
    "이전에 가입한 이메일은 사용하실 수 없습니다. 다른 이메일을 사용해주세요.",
};

const LANGUAGE_TO_ERROR_MESSAGE_MAP = {
  en: EN_ERROR_MESSAGE_MAP,
  ja: JA_ERROR_MESSAGE_MAP,
  de: DE_ERROR_MESSAGE_MAP,
  es: ES_ERROR_MESSAGE_MAP,
  pt: PT_ERROR_MESSAGE_MAP,
  ko: KO_ERROR_MESSAGE_MAP,
};

const FIELD_VALIDATION_MESSAGE_MAP: Record<string, Record<string, string>> = {
  en: {
    number: "Card Number entered was invalid. Please try again.",
    cvv: "The security code entered is invalid. Please try again.",
    expirationDate:
      "The expiration date entered is invalid. Please make sure it's in MM/YY format.",
  },
};

/**
 * Get the language specific error message.
 */
const getPurchaseErrorMessage = (
  error: any,
  languageCode: keyof typeof LANGUAGE_TO_ERROR_MESSAGE_MAP | string
): string | Record<string, string> => {
  const errorMessageMap = LANGUAGE_TO_ERROR_MESSAGE_MAP[languageCode];
  const errorType = typeof error === "object" ? error.code : error;

  if (errorType in errorMessageMap) {
    return errorMessageMap[errorType as keyof typeof errorMessageMap];
  }

  const fieldValidationMessageMap = FIELD_VALIDATION_MESSAGE_MAP[languageCode];

  if (
    errorType === errorConstants.hostedFieldsFieldsEmpty &&
    fieldValidationMessageMap
  ) {
    const errorMessage: Record<string, string> = {};

    error.details.invalidFieldKeys.forEach((fieldKey: string) => {
      errorMessage[fieldKey] = fieldValidationMessageMap[fieldKey];
    });

    return errorMessage;
  }

  return errorMessageMap.ERROR_UNKNOWN;
};

export default getPurchaseErrorMessage;
