/* eslint-disable import/no-mutable-exports */
import React from "react";
import { Route } from "react-router";
import type { History } from "history";

// Hack to expose the root history object globally.
// For transition away from dual routers only. New code should use hooks, which will work as expected
// outside of hash routers.
//
// eslint-disable-next-line no-underscore-dangle
let _rootHistory: History;

// WARN: This should only be used once in a given app. We don't have a good way to ensure this, so
// be careful.
export function MultiRouterHack() {
  return (
    <Route
      path="*"
      render={({ history }) => {
        _rootHistory = history;
        return null;
      }}
    />
  );
}

export function useHistoryHack() {
  return _rootHistory;
}
