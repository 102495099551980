import getStore from "@utils/redux/store";
import { registerHandler } from "../handler";

declare const pdst: any;

export default function registerHandlers() {
  registerHandler("lead", () => {
    pdst("lead", {
      category: "healthy-weight",
    });
  });

  registerHandler("initiateCheckout", ({ plan }) => {
    const { promoCode } = getStore().getState();
    pdst("checkout", {
      value: plan.lv,
      currency: plan.currency ? plan.currency : "USD",
      discount_code: promoCode.promoCodeApplied,
    });
  });

  registerHandler("purchase", ({ order }) => {
    const { promoCode } = getStore().getState();
    pdst("purchase", {
      value: order.eltv_13_months,
      currency: "USD",
      order_id: order.subscriptionId,
      discount_code: promoCode.promoCodeApplied,
    });
  });
}
