import { registerHandler } from "../handler";
import { send } from "@utils/fetch";
import { captureException } from "@utils/sentry";

function trackEvent(event: string, data: JsonObject) {
  return send("POST", `/pixel/t/track/`, { event, data }).catch((e: any) => {
    captureException(e, "TextPixel-Failed to fetch");
  });
}

export default function registerHandlers() {
  registerHandler("lead", (email) => {
    trackEvent("lead", email);
  });

  registerHandler("initiateCheckout", ({ plan }) => {
    trackEvent("checkout", {
      lv: plan.lv,
      braintree_id: plan.braintree_id,
      currency: plan.currency,
    });
  });

  registerHandler("purchase", ({ order, plan, userData }) => {
    trackEvent("purchase", {
      erev_9_months: order.erev_9_months,
      currency: "USD",
      braintree_id: plan.braintree_id,
      order_id: order.subscriptionId,
      user_id: userData.user_id,
    });
  });
}
