import type { History } from "history";
import {
  Action,
  Block,
  Direction,
  Page,
  PageList,
  PageSet,
} from "src/pageDefinitions/types";
import getStore, { CoreReduxStore } from "src/utils/redux/store";
import ACTIONS, { ActionHandler } from "../actions";
import {
  getPage,
  getPageAtLocation,
  getPageLocation,
  getPageSet,
  getPageSetForLayer,
  PageLocation,
} from "../pageSets";
import invariant from "tiny-invariant";
import { setActivePageSet } from "src/utils/redux/slices/linearBuyflow";
import { logDebug } from "src/utils/logging";
import { typedKeys } from "src/utils/typeWrappers";
import { findCommonAncestors } from "src/utils/arrayMethods";
import {
  emitPageSetEvents,
  isInitialPageInit,
  isPageSetInit,
  pageSetEvents,
} from "../pageSets/bus";
import { useHistoryHack } from "src/hooks/historyHack";
import {
  findFuturePageIndex,
  resolvePage,
} from "src/pageDefinitions/pageSets/eval";
import goto from ".";
import { ConditionalKeys } from "type-fest";

export type GoToPageOptions = {
  history: History;
  store: CoreReduxStore;
};

export function staticGoToOptions(): GoToPageOptions {
  return {
    store: getStore(),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    history: useHistoryHack(),
  };
}

function causesNavigation(page: Page) {
  return !!page.pathname || !!page.goto;
}

export async function executeActions(
  activePageSet: PageSet,
  page: PageList[0],
  actions: Action[],
  gotoOptions: GoToPageOptions,
  extraParams?: JsonObject
) {
  for (const action of actions || []) {
    invariant(ACTIONS[action.type], `Unknown action type: ${action.type}`);
    // eslint-disable-next-line no-await-in-loop
    await (ACTIONS[action.type] as ActionHandler)({
      pageSet: activePageSet,
      page,
      params: { ...action.params, ...extraParams },
      ...gotoOptions,
    });
  }
}

async function execBlocks(
  activePageSet: PageSet,
  location: PageLocation = [],
  commonAncestors: PageLocation,
  list: ConditionalKeys<Block, Action[]>,
  gotoOptions: GoToPageOptions
) {
  const currentLocation = commonAncestors.slice();
  const diffBlocks = location.slice(commonAncestors.length);
  for (const blockIndex of diffBlocks) {
    currentLocation.push(blockIndex);
    const block = getPageAtLocation(activePageSet, currentLocation);
    // Ignore pages that are iterated through at the end
    if ("block" in block) {
      // eslint-disable-next-line no-await-in-loop
      await executeActions(activePageSet, block, block[list], gotoOptions);
    }
  }
}

/**
 * Ensures that appropriate goto actions are done on initial page load. This
 * should be kept in sync with the actions performed in gotoPage below.
 */
export function ensureGotoSetup(
  activePageSet: PageSet,
  activePage: Page,
  gotoOptions: GoToPageOptions
) {
  // We we just entered the page set, run any actions for this location.
  if (activePageSet && !isPageSetInit()) {
    emitPageSetEvents("enter:page-set", {
      ...gotoOptions,
      direction: Direction.FORWARD,
      activePageSet,
    });
  }

  // On load run actions for the current page. Unlike above, this case will
  // run if the page set is already assigned before the page is loaded.
  if (activePageSet && activePage && !isInitialPageInit()) {
    emitPageSetEvents("before:goto", {
      direction: Direction.FORWARD,
      activePageSet,
      fromPage: undefined,
      toPage: activePage,
      ...gotoOptions,
    });

    // Also execute any
    executeActions(activePageSet, activePage, activePage.actions, gotoOptions);

    // Trigger after goto here even though we were already "here".
    emitPageSetEvents("after:goto", {
      direction: Direction.FORWARD,
      activePageSet,
      fromPage: undefined,
      toPage: activePage,
      ...gotoOptions,
    });
  }
}

/**
 * Function that is passed into pool pages that the pages trigger to progress
 * a user forwards or backwards.
 */
export async function gotoPage(
  direction: Direction,
  activePageSet: PageSet,
  gotoOptions: GoToPageOptions,
  replace?: boolean,
  pagePosition = getPageLocation(
    activePageSet,
    gotoOptions.history.location.pathname
  )
): Promise<void> {
  invariant(direction !== Direction.CURRENT, "Cannot goto current page.");

  const { history, store } = gotoOptions;

  // Keep track of the pages/blocks we go by in this `goto`
  const traversePath: PageList = [];

  const toPageIndex = findFuturePageIndex(
    direction,
    activePageSet,
    gotoOptions,
    pagePosition,
    traversePath
  );
  if (!toPageIndex) {
    logDebug(`Goto ${direction} called. No toPage found.`);
    return;
  }

  const fromPage = getPageAtLocation(activePageSet, pagePosition);
  const toPage = getPageAtLocation(activePageSet, toPageIndex);

  const resolvedPage = resolvePage(toPage, store.getState(), direction);

  invariant(!("block" in resolvedPage), "Expected page to not be a block.");

  if (causesNavigation(resolvedPage)) {
    logDebug(
      `Goto page: ${direction} ${resolvedPage.pathname || resolvedPage.goto}`
    );

    await emitPageSetEvents("before:goto", {
      direction,
      activePageSet,
      fromPage,
      toPage,
      ...gotoOptions,
    });
  }

  // Execute actions for all blocks that we may have entered
  if (direction === Direction.FORWARD) {
    const commonAncestors = findCommonAncestors(pagePosition, toPageIndex);

    // Exit old blocks
    await execBlocks(
      activePageSet,
      pagePosition,
      commonAncestors,
      "onComplete",
      gotoOptions
    );

    // Enter new blocks
    await execBlocks(
      activePageSet,
      toPageIndex,
      commonAncestors,
      "actions",
      gotoOptions
    );
    // Execute `onSkip` actions of the passed-over pages/blocks, if any
    // TODO: Should this be done going BACKWARD as well?
    await Promise.all(
      traversePath
        .filter((item) => !!item.onSkip)
        .map((item) =>
          executeActions(activePageSet, item, item.onSkip, gotoOptions)
        )
    );
  }
  // Execute actions on the current page
  await executeActions(
    activePageSet,
    resolvedPage,
    resolvedPage.actions,
    gotoOptions
  );

  if (causesNavigation(resolvedPage)) {
    // istanbul ignore else
    if (resolvedPage.pathname) {
      history[replace ? "replace" : "push"]({
        pathname: `/${resolvedPage.pathname}`,
        search: history.location.search,
        state: { direction, pageSetId: activePageSet.id },
      });
    } else if (resolvedPage.goto) {
      const [name] = typedKeys(resolvedPage.goto);
      // @ts-expect-error The types don't simplify cleanly for TS
      goto[name](...resolvedPage.goto[name]);
    }

    await emitPageSetEvents("after:goto", {
      direction,
      activePageSet,
      fromPage,
      toPage,
      ...gotoOptions,
    });
  } else {
    // No navigation, go to the next page in this direction.
    await gotoPage(direction, activePageSet, gotoOptions, replace, toPageIndex);
  }
}

pageSetEvents.add(async (event, params) => {
  if (event === "enter:page-set") {
    const { activePageSet, history } = params;
    const pagePosition = getPageLocation(
      activePageSet,
      history.location.pathname
    );

    logDebug(`Entering page set at ${pagePosition}`);

    // Enter new blocks
    await execBlocks(activePageSet, pagePosition, [], "actions", params);
  }
});

export default {
  async pageSet(pageSetId: string, pathname?: string) {
    const pageSet = getPageSet(pageSetId);
    invariant(pageSet, `Page set "${pageSetId}" not found.`);

    await getStore().dispatch(setActivePageSet(pageSet));

    const gotoOptions = staticGoToOptions();
    if (!pathname) {
      await gotoPage(Direction.FORWARD, pageSet, gotoOptions);
    } else {
      if (!getPage(pageSet, pathname, getStore().getState())) {
        logDebug(`Page "${pathname}" not found in page set "${pageSetId}".`);
      }

      gotoOptions.history.push({
        pathname,
        search: gotoOptions.history.location.search,
        state: { direction: Direction.FORWARD, pageSetId },
      });
    }
  },

  async layer(layerName: string, replaceHistory?: boolean) {
    const pageSet = await getPageSetForLayer(layerName, getStore().getState());
    if (!pageSet) {
      throw new Error(`${layerName} pageSet not found`);
    }

    await getStore().dispatch(setActivePageSet(pageSet));
    await gotoPage(
      Direction.FORWARD,
      pageSet,
      staticGoToOptions(),
      replaceHistory
    );
  },
};
