import { trackEvent, trackGotEmail, trackSurveyAnswers } from "./api/tracker";
import { getAndMaybeSavePendingDoubleOptInStatus } from "./gdpr";
import {
  addSurveyAnswers,
  SurveyAnswersState,
} from "./redux/slices/surveyAnswers";
import { saveMainSurveyAnswers } from "./redux/slices/userData";
import getStore, { AppDispatch } from "./redux/store";
import { captureException } from "./sentry";
import { trackLead } from "./services/ConversionTracker";
import { isCountryImperial } from "./userSegment";
import { isDevMode, requiresDoubleOptInForEmail } from "./userSegment/features";

export function prepareAndTrackSurveyAnswers(
  currentQuestionId: keyof SurveyAnswersState,
  surveyAnswers: SurveyAnswersState
) {
  const currentAnswers: SurveyAnswersState = {};

  switch (currentQuestionId) {
    case "basic":
    case "basicUK":
    case "basicMet":
    case "basicMetUK":
      Object.keys(surveyAnswers).forEach((key) => {
        if (
          key.includes("height") ||
          key.includes("weight") ||
          key.includes("currentBMI")
        ) {
          currentAnswers[key] = surveyAnswers[key];
        }
      });
      break;
    case "idealWeight":
      currentAnswers.idealWeight = surveyAnswers.idealWeight;
      currentAnswers.idealWeightKg = surveyAnswers.idealWeightKg;
      currentAnswers.targetBMI = surveyAnswers.targetBMI;
      break;
    case "idealWeightKg":
      currentAnswers.idealWeightKg = surveyAnswers.idealWeightKg;
      currentAnswers.targetBMI = surveyAnswers.targetBMI;
      break;
    case "idealWeightImp":
      currentAnswers.idealWeightKg = surveyAnswers.idealWeightKg;
      currentAnswers.idealWeightUnit = surveyAnswers.idealWeightUnit;
      currentAnswers.targetBMI = surveyAnswers.targetBMI;
      break;
    case "gender":
      currentAnswers.gender = [surveyAnswers.gender[0]];
      if (surveyAnswers.gender[0] === "selfIdentify") {
        [, currentAnswers.selfIdentify] = surveyAnswers.gender;
      }
      break;
    case "importantDateDetails":
      currentAnswers.importantDateDetails = surveyAnswers.importantDateDetails;
      if (surveyAnswers.importantDate) {
        currentAnswers.importantDate = surveyAnswers.importantDate;
      }
      if (surveyAnswers.importantDateSubDetails) {
        currentAnswers.importantDateSubDetails =
          surveyAnswers.importantDateSubDetails;
      }
      break;
    default:
      currentAnswers[currentQuestionId] = surveyAnswers[currentQuestionId];
  }

  trackSurveyAnswers(currentQuestionId, currentAnswers);
}

export function mainSurveyAnswersValid(surveyAnswers: SurveyAnswersState) {
  // If we're in development mode, automatically validate survey answers.
  if (isDevMode()) {
    return true;
  }

  let properties: string[] = [];
  if (isCountryImperial()) {
    properties = ["weight", "idealWeight", "heightFeet", "heightInch", "email"];
  } else {
    properties = ["weightKg", "idealWeightKg", "heightCm", "email"];
  }
  for (let i = 0; i < properties.length; i += 1) {
    if (surveyAnswers[properties[i]] == null) {
      return false;
    }
  }
  return true;
}

export async function completeMainSurvey({
  surveyAnswers,
  params: { finishedEvent, dateKey, extraProps },
  dispatch,
}: {
  surveyAnswers: SurveyAnswersState;
  params: {
    finishedEvent: string;

    dateKey?: string;
    extraProps?: JsonObject;
  };
  dispatch: AppDispatch;
}): Promise<void> {
  try {
    // Update the Redux store.
    dispatch(addSurveyAnswers(surveyAnswers));

    const name = surveyAnswers.name || "";
    const email = surveyAnswers.email || "";

    await dispatch(
      saveMainSurveyAnswers(surveyAnswers, {
        dateKey,
        extraProps,
      })
    );

    const { user_id } = getStore().getState().userData;
    if (surveyAnswers.gdprConsent && requiresDoubleOptInForEmail()) {
      await getAndMaybeSavePendingDoubleOptInStatus(user_id);
    }

    trackLead(email, name, surveyAnswers);
    if (email) {
      await trackGotEmail(email);
    }

    await trackEvent(finishedEvent, surveyAnswers);
  } catch (e) {
    // TODO: Tag and throw or log and throw?
    captureException(e, "onCompleteMainSurvey");
    throw e;
  }
}
