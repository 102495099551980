import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type EnrollmentErrors = {
  name?: boolean | string;
  email?: boolean | string;
  password?: boolean | string;
  confirmPassword?: boolean | string;
  phoneNumber?: boolean | string;
  termAgreement?: boolean | string;
};

export interface PaymentEnrollmentFormState {
  hasErrors: boolean;
  enrollmentErrors: EnrollmentErrors;
  enrollmentInfo: {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    phoneNumberCountryCode: string;
    termAgreement: boolean;
  };
}

const initialState: PaymentEnrollmentFormState = {
  enrollmentErrors: {},
  enrollmentInfo: {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    phoneNumberCountryCode: "",
    termAgreement: false,
  },
  hasErrors: true,
};

const paymentEnrollmentFormSlice = createSlice({
  name: "paymentEnrollmentForm",
  initialState,
  reducers: {
    updatePaymentEnrollmentFormState(
      state,
      action: PayloadAction<Partial<PaymentEnrollmentFormState>>
    ) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updatePaymentEnrollmentFormState } =
  paymentEnrollmentFormSlice.actions;

export default paymentEnrollmentFormSlice;
