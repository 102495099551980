/**
 * Shuffle the elements of an array using Fisher-Yates.
 *
 * @returns {Array} A shuffled clone of the array
 */
export function shuffleArray<T>(array: T[]): T[] {
  const shuffledArray = array.slice(0);
  let i;
  let j;
  let temp;
  for (i = shuffledArray.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    temp = shuffledArray[i];
    shuffledArray[i] = shuffledArray[j];
    shuffledArray[j] = temp;
  }
  return shuffledArray;
}

export function findCommonAncestors<T>(arr1: T[] = [], arr2: T[] = []): T[] {
  const ret = [];
  while (
    ret.length < Math.min(arr1.length, arr2.length) &&
    arr1[ret.length] === arr2[ret.length]
  ) {
    ret.push(arr1[ret.length]);
  }
  return ret;
}

/**
 * Splice each array from the list into a random place of the first array.
 *
 * @example
 * ```
 * spliceRandomly(["a", "b", "c"], ["x, "y", "z"], ["noom"])
 * // returns e.g.: ["noom", "a", "x", "y", "z", "b", "c"]
 * ```
 *
 * @returns A random splice of otherArrays into mainArray.
 */
export function spliceRandomly<T>(mainArray: T[], ...otherArrays: T[][]): T[] {
  /*
    We put every element of the main array in a little array box temporarily,
    then insert otherArrays in random places and flatten the results.

    This way we guarantee that otherArray[1] will not be spliced between the contents
    of otherArray[0], which could happen if we simply splice the arrays one by one.
  */
  const result = mainArray.map((a: any) => [a]);
  for (const other of otherArrays) {
    result.splice(Math.floor(Math.random() * result.length), 0, other);
  }
  /* This only flatten one level. */
  return result.flat();
}
