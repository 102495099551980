/**
 * Application main entry point.
 */
import React, { ComponentType } from "react";
import ReactDOM from "react-dom";

import { captureException, initializeSentry } from "@utils/sentry";
import { preloadPagePath } from "./router";

// Set up sentry error handling so we can capture any errors as early as possible.
initializeSentry();

(window as any).serverContext = {};

export async function boot(App: ComponentType): Promise<void> {
  try {
    if (!window.fetch || !window.URL) {
      await import(/* webpackChunkName: "polyfills" */ "./polyfills");
    }

    // Preload the current page chunk
    preloadPagePath(window.location.pathname);

    ReactDOM.render(React.createElement(App), document.getElementById("root"));
  } catch (err) {
    captureException(err, "bootstrap");
  }
}
