/* eslint-disable no-restricted-globals */

import { CHECK_EMAIL_REMINDER_PARAM } from "src/components/collapsible-header/CollapsibleHeader";
import { getSearchQuery } from "src/utils/urlParams";
import { pushReroll, reroll, setParam, synchronizeUpid } from "./utils";
import getStore from "src/utils/redux/store";

export type OffersParams = {
  claimedByEmail?: string;
  promoCode?: string;
  enrollmentResult?: string;
  forceReroll?: boolean;
};

function isOffers() {
  return (
    location.pathname.startsWith("/add-ons/") ||
    location.pathname.startsWith("/en/add-ons/") ||
    location.pathname.startsWith("/offers/") ||
    location.pathname.startsWith("/en/offers/") ||
    location.pathname.startsWith("/in-app-offers/")
  );
}

const linkTo = {
  addOns({ claimedByEmail, enrollmentResult }: OffersParams) {
    const queryParams = new URLSearchParams(location.search);

    synchronizeUpid(queryParams, getStore());
    setParam(queryParams, "claimedByEmail", claimedByEmail);
    setParam(queryParams, "enrollmentResult", enrollmentResult);

    return `/add-ons${getSearchQuery(queryParams)}`;
  },
  addOnConfirmation(params: OffersParams) {
    return `${linkTo.addOns(params)}#/confirmation`;
  },
  limitedOffer(productId: string) {
    return `${linkTo.addOns({})}#/limitedOffer/${productId}`;
  },
};

export default {
  noomPremium({
    claimedByEmail,
    promoCode,
    enrollmentResult,
    forceReroll,
  }: OffersParams) {
    const queryParams = new URLSearchParams(location.search);
    synchronizeUpid(queryParams, getStore());
    setParam(queryParams, "claimedByEmail", claimedByEmail);
    setParam(queryParams, "promoCode", promoCode);
    setParam(queryParams, "enrollmentResult", enrollmentResult);
    setParam(queryParams, "rerollMerEx", forceReroll);

    reroll(`/noom-premium/`, queryParams);
  },
  addOns({
    claimedByEmail,
    enrollmentResult,
    forceReroll,
    showEmailReminder,
  }: OffersParams & { showEmailReminder?: boolean }) {
    const queryParams = new URLSearchParams(location.search);
    synchronizeUpid(queryParams, getStore());
    setParam(queryParams, "claimedByEmail", claimedByEmail);
    setParam(queryParams, "rerollMerEx", forceReroll);
    setParam(queryParams, "enrollmentResult", enrollmentResult);
    setParam(queryParams, CHECK_EMAIL_REMINDER_PARAM, showEmailReminder);

    reroll(`/add-ons`, queryParams);
  },
  addOnConfirmation(params: OffersParams) {
    pushReroll(linkTo.addOnConfirmation(params), isOffers);
  },
  limitedOffer(productId: string) {
    pushReroll(linkTo.limitedOffer(productId), isOffers);
  },

  fastForwardTrial({
    forceReroll,
    showEmailReminder,
  }: {
    forceReroll?: boolean;
    showEmailReminder?: boolean;
  }) {
    const queryParams = new URLSearchParams(location.search);
    synchronizeUpid(queryParams, getStore());
    setParam(queryParams, "rerollMerEx", forceReroll);
    setParam(queryParams, CHECK_EMAIL_REMINDER_PARAM, showEmailReminder);

    reroll(`/fast-forward-trial/`, queryParams);
  },
};
