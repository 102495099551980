import { Direction, Page, SwitchCondition } from "src/pageDefinitions/types";
import { CoreReduxState } from "src/utils/redux/store";
import invariant from "tiny-invariant";
import { typedRecord } from "src/utils/typeWrappers";
import {
  getSegmentFn,
  JsonScalar,
  loadValue,
  resolveScalar,
  resolveValue,
} from "./values";

export type PageCondition = {
  key?: string;
  operator?: keyof typeof PageConditionOperators;
  value: JsonScalar | Array<JsonScalar>;

  or?: PageCondition;
};

type ConditionCheck = (params: {
  lookupValue: JsonScalar[];
  scalarValue: AnyJson;
  condition: PageCondition;
  state: CoreReduxState;
}) => number;

// Explicit return types required below to avoid this type from collapsing to any.
// Must be exported for schema generation
// eslint-disable-next-line import/no-unused-modules
export const PageConditionOperators = typedRecord<ConditionCheck>()({
  EXISTS({ scalarValue, condition }) {
    invariant(condition.key, "key must be defined");
    return scalarValue != null ? 1 : 0;
  },
  IN({ scalarValue, condition }) {
    invariant(
      Array.isArray(condition.value),
      "IN operator requires an array of values"
    );
    return condition.value.includes(scalarValue) ? 1 : 0;
  },
  NOT_IN(params): number {
    return PageConditionOperators.IN(params) ? 0 : 1;
  },
  CONTAINS({ lookupValue, condition: { value } }) {
    return lookupValue?.includes(value as string) ? 1 : 0;
  },
  NOT_CONTAINS(params): number {
    return PageConditionOperators.CONTAINS(params) ? 0 : 1;
  },
  CONTAINS_ANY({ lookupValue, condition: { value } }) {
    invariant(Array.isArray(value), "IN operator requires an array of values");
    const found = lookupValue?.find((curr) =>
      value.includes(curr as string | number)
    );
    return found != null ? 1 : 0;
  },
  CONTAINS_NONE(params): number {
    return PageConditionOperators.CONTAINS_ANY(params) ? 0 : 1;
  },
  EQUALS({ scalarValue, condition }) {
    if (scalarValue == null) {
      return condition.value == null ? 1 : 0;
    }
    return scalarValue === condition.value ? 1 : 0;
  },
  NOT_EQUALS(params): number {
    return PageConditionOperators.EQUALS(params) ? 0 : 1;
  },
  GTE({ scalarValue, condition }) {
    return scalarValue >= condition.value ? 1 : 0;
  },
  LTE({ scalarValue, condition }) {
    return scalarValue <= condition.value ? 1 : 0;
  },
  ANY_SEGMENT({ condition: { value }, state }) {
    if (Array.isArray(value)) {
      const found = value.find((segmentName: string) => {
        return getSegmentFn(segmentName)(state);
      });
      return found ? 1 : 0;
    }
    return getSegmentFn(value as string)(state) ? 1 : 0;
  },
  ALL_SEGMENTS({ condition: { value }, state }) {
    if (Array.isArray(value)) {
      const allMatch = !value.find((segmentName: string) => {
        return !getSegmentFn(segmentName)(state);
      });
      return allMatch ? value.length : 0;
    }
    return getSegmentFn(value as string)(state) ? 1 : 0;
  },
  NO_SEGMENTS(params): number {
    const noneMatch = !PageConditionOperators.ANY_SEGMENT(params);
    if (noneMatch && Array.isArray(params.condition.value)) {
      return params.condition.value.length;
    }
    return noneMatch ? 1 : 0;
  },
});

export function loadKeys(keys: string[], state: CoreReduxState) {
  return Promise.all(keys.map((key) => loadValue(key, state))).then(() => {});
}

/**
 * Handles the processing of PageConditions.
 *
 * @returns an opaque numeric score that can be used to compare different sets of passing keys.
 */
export function conditionsPass(
  conditions: PageCondition[],
  state: CoreReduxState,
  direction = Direction.FORWARD
): number {
  if (!conditions?.length) {
    return 1;
  }

  function evaluateCondition(condition: PageCondition): number {
    const { operator = "EQUALS", key } = condition;

    const lookupValue = resolveValue(key, state, direction);

    invariant(
      PageConditionOperators[operator],
      `Unknown operator: ${operator}`
    );
    const evaled = PageConditionOperators[operator]({
      condition,
      state,
      lookupValue,
      scalarValue: resolveScalar(lookupValue),
    });

    let ret = evaled;
    if (condition.or) {
      ret += evaluateCondition(condition.or);
    }

    return ret;
  }

  const evaluatedConditions: number[] = conditions.map(evaluateCondition);
  const conditionScore = evaluatedConditions.reduce(
    (accum, value) => accum + value
  );

  // If there are any false checks, then and them together.
  if (
    evaluatedConditions.filter(Boolean).length !== evaluatedConditions.length
  ) {
    return 0;
  }

  return conditionScore ? conditionScore + 1 : 0;
}

export function resolveSwitch(
  switchCondition: SwitchCondition,
  state: CoreReduxState,
  direction?: Direction
): Page {
  const { switch: key } = switchCondition;
  const lookupValue = resolveValue(key, state, direction);

  return (
    switchCondition.cases[lookupValue] || switchCondition.cases.default || {}
  );
}
