import throttle from "lodash/throttle";
import { Store } from "redux";
import { trackEvent } from "../api/tracker";
import { getMeristemContext } from "../meristemContext";
import { captureException } from "../sentry";
import { serialize as recommendedPlanSerialize } from "./slices/recommendedPlan";
import { ReducerStore } from "./internal";

export type Serializer<
  K extends keyof ReducerStore = keyof ReducerStore,
  T = ReducerStore[K]
> = {
  save: (state: T) => T;
  load: (storedState: T) => T;
};

const persistSerializers: Partial<
  Record<keyof ReducerStore, boolean | Serializer>
> = {
  surveyAnswers: true,
  routeId: true,
  linearBuyflow: true,
  activeSubscriptionData: true,
  purchasedNoomPremium: true,
  promoCode: true,
  upid: {
    load: (persistedValue) => {
      // Give priority to the URL params over the persisted value.
      // This isn't really the place to do this, but we need to ensure this value is correctly
      // set before the first context call (ie, before any Redux actions have happened).
      const urlParams = new URLSearchParams(window.location.search);
      const paramUpid = urlParams.get("upid");
      return paramUpid || persistedValue;
    },
    save: (value) => value,
  },
  recommendedPlan: recommendedPlanSerialize,
};

// To maintain backwards-compatibility with redux-persist, use the same key.
const PERSIST_KEY = "persist:root";

export function rehydrateStore() {
  const state = {};
  let storedValue: JsonObject = {};
  try {
    storedValue = JSON.parse(localStorage.getItem(PERSIST_KEY)) || {};
  } catch (expectedError) {
    try {
      trackEvent("onPersistReadError", { error: expectedError.message });
    } catch (unexpectedError) {
      captureException(unexpectedError);
    }
  }

  // Again, maintaining backward-compat with redux-persist, which stringified the individual
  // keys as well
  Object.keys(persistSerializers).forEach((key) => {
    try {
      const serializer = persistSerializers[key];

      if (storedValue[key]) {
        state[key] = JSON.parse(storedValue[key]);
      }
      if (serializer !== true) {
        state[key] = serializer.load(state[key]);
      }
    } catch (err) {
      // Not ideal, but error on the side of attempted recovery.
      captureException(err);
    }
  });

  // Special case: routeId
  // Meristem context is the source of truth for this, but sometimes unavailable and thus
  // persisted. FIXME: It would probably make sense to use a different mechanism for this, and
  // perhaps not include it in Redux, since it's injected directly into the page.
  const meristemContext = getMeristemContext();
  // Always honor meristem context, we only serialize for cases where meristem can not
  // infer directly.
  if (meristemContext?.route_id) {
    (state as any).routeId = meristemContext.route_id;
  }

  return state;
}

export function subscribePersistence(store: Store, throttleTime = 0) {
  store.subscribe(
    throttle(() => {
      const toBeStored = {};
      try {
        Object.keys(persistSerializers).forEach((key) => {
          const serializer = persistSerializers[key];

          let valueToSerialized = store.getState()[key];
          if (typeof serializer === "object") {
            valueToSerialized = serializer.save(valueToSerialized);
          }
          toBeStored[key] = JSON.stringify(valueToSerialized);
        });
        localStorage.setItem(PERSIST_KEY, JSON.stringify(toBeStored));
      } catch (e) {
        // This can happen on in-app webviews that don't have access to localStorage.
        trackEvent("onPersistWriteError", { error: e.message });
        // If this happens while developing, more likely to be an unserializable value in the store;
        // explode.
        /* istanbul ignore next sanity */
        if (process.env.NODE_ENV !== "production") {
          throw new Error("Unserializable value in store");
        }
      }
    }, throttleTime)
  );
}
