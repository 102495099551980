import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  labels,
  productImages,
} from "src/funnels/offers/components/singleOffers/singleOffer/singleOfferImageImport";
import { paymentSource } from "src/utils/constants";
import { Plan } from "./plans";

export type Offer = {
  addon_product_id: string;
  title: string;
  description: string;
  image_name: keyof typeof productImages;
  price: number;
  regular_price: number;
  per_month: boolean;
  shipping_address_required: boolean;
  is_free_shipping: boolean;
  is_free: boolean;
  is_sold_out: boolean;
  discount: number;
  label: keyof typeof labels;
  is_hidden?: boolean;
};

export interface ServerContextState {
  initiatingContextType?: string;

  // Basic serverContext stubbing containing most of the fields we can expect
  // from serverContext.
  // Meta information
  flow_id: string;
  context_type: string;
  currency_symbol: string;
  mixpanel_properties: JsonObject;
  session_cookie_domain: string;

  // User Information
  user_id: string;
  user_in_db: boolean;
  distinct_id: string;
  name: string;
  email: string;
  gdpr_consent: string; // JSON in string

  // URLS
  static_url: string;
  MIXPANEL_PROJECT_TOKEN: string;
  SENTRY_PROJECT_DSN_JS: string;

  // Feature flags
  direct: boolean;
  is_paid_traffic: boolean;
  no_trial_active: boolean;
  trial_fee_waived: boolean;
  trial_fees: number[];
  choose_trial_fee: boolean;
  popup: any; // TODO: Align with the campaign popup props
  campaign_promo: string;
  promo_code: string;
  campaign_promo_cookie: {
    active: boolean;
    value?: string;
  };

  // Plan information
  plans: Record<string, Plan>;
  fast_forward_trial_plan?: Plan;
  purchased_fast_forward_trial?: boolean;
  plan_override?: string;
  claimed_by_email?: string;

  // Counter Offer
  // https://github.com/noom/growth/blob/develop/growth_api/context/counter_offer.py#L124
  // TODO: Can we provide better scoping based on the context that is active?
  // "result": "success",
  // "context_type": "counter-offer",
  foreign_subscription_id?: string;
  plan_duration?: number;
  access_code?: string;
  subscription_id?: string;
  subscription_next_charge_amount?: number;
  current_plan_price?: number;
  current_plan_sales_tax_amount?: number;
  current_plan_sales_tax_rate?: number;
  // https://github.com/noom/growth/blob/develop/visitor/constants.py#L23
  subscription_status?:
    | "ACTIVE"
    | "PAST_DUE"
    | "PENDING"
    | "INACTIVE"
    | "FRAUDULENT";
  subscription_statuses?: string[];
  has_pending_subscription?: boolean;
  currency?: Plan["currency"];
  offered_discounted_plan?: Plan;
  accepted_offer_duration?: number;
  is_in_trial?: boolean;
  expiry_time?: string;
  has_accepted_counter_offer?: boolean;
  subscription_is_hm?: boolean;
  counter_offer_price?: string;
  has_expiring_subscription?: boolean;
  upid?: string;
  eligible_for_counter_offer?: boolean;
  is_tax_inclusive?: boolean;
  discounted_plan_sales_tax_rate?: number;
  discounted_plan_sales_tax_amount?: number;
  // NOTE(sumin)?: Hackily add subscriptions until the frontend doesn't need it anymore.
  subscriptions?: string; // JSON encoded object...

  podcast_options?: Record<string, string>;

  experiment_tag?: string;

  // TODO: Dead code? Could not find in server via grep.
  discount_percent?: string;

  // Addons
  offers?: Record<
    /** channel */ string,
    Record</** placement type */ string, Offer[]>
  >;

  total_price?: string;
  trial_fee?: string;
  sales_tax_amount?: string;
  minimal_discount_fast_forward_trial_plan?: Plan;
  minimal_discount_noom_plan_id?: string;

  has_offers?: boolean;

  show_results_vary_disclaimer?: boolean;

  enrollment?: boolean;

  no_skip?: boolean;

  direct_traffic?: boolean;

  // Course
  display_counter_offer?: boolean;
  display_revert_flow?: boolean;
  is_legacy_page?: boolean;

  plan_id?: string;
  payment_source?: paymentSource;
  reason?: string;
  course?: string;
  result?: string;

  is_auto_renewal_enabled?: boolean;
  next_charge_amount?: number;
  billing_cycle_interval?: string;
  billing_cycle_interval_count?: number;
}

/**
 * Add properties that are intentionally omitted from the serverContext
 * during normal runtime.
 *
 * This should only be used during initialization of the redux store.
 */
export type ServerContextInit = ServerContextState & {
  noom_plan_id?: string;
  plan?: Plan;
};

const initialState: ServerContextState = {
  // Basic serverContext stubbing containing most of the fields we can expect
  // from serverContext.
  // Meta information
  flow_id: "",
  context_type: "",
  currency_symbol: "$",
  mixpanel_properties: {},
  session_cookie_domain: ".noom.com",

  // User Information
  user_id: "",
  user_in_db: false,
  distinct_id: "",
  name: "",
  email: "",
  gdpr_consent: "",

  // URLS
  static_url: "/static",
  MIXPANEL_PROJECT_TOKEN: "",
  SENTRY_PROJECT_DSN_JS: "",

  // Feature flags
  direct: false,
  is_paid_traffic: false,
  no_trial_active: false,
  trial_fee_waived: false,
  trial_fees: [],
  choose_trial_fee: true,
  popup: null,
  campaign_promo: "",
  promo_code: "",
  campaign_promo_cookie: {
    active: false,
  },

  // Plan information
  plans: {},
};

const serverContextSlice = createSlice({
  name: "serverContext",
  initialState,
  reducers: {
    // NOTE(patrick): ServerContext should only ever be updated as a whole from
    // the backend. Do not mutate serverContext!
    updateServerContext(state, action: PayloadAction<ServerContextState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateServerContext } = serverContextSlice.actions;

export default serverContextSlice;
