import {
  MeristemContext,
  ExperimentDefinition,
  getExperimentState,
} from "./meristemContext";
import OptimizelySXExperiment, {
  OptimizelyExperiment,
} from "./services/OptimizelySXExperiment";

interface ExperimentWindow extends Window {
  meristemContext: MeristemContext;
  osxe: OptimizelySXExperiment;
  experiments: OptimizelyExperiment[];
  experimentName: string;
  experimentVariation: string;
}

// Extends the definition of window for this local context to include meristemContext and URLSearchParams
declare let window: ExperimentWindow;

/**
 * Fetch the experiment assignment from the embedded Meristem Context.
 */
export function getStoredExperiments(): ExperimentDefinition[] | null {
  let experimentStates = getExperimentState();
  if (experimentStates.length) {
    if (typeof experimentStates === "string") {
      experimentStates = JSON.parse(experimentStates);
    }
    return [].concat(experimentStates);
  }
  return null;
}

export function getOptimizelyExperiments(): OptimizelyExperiment[] {
  // Provide experiments as GET query parameters for experiment overlap testing
  const params = new URLSearchParams(window.location.search);
  const experiments = params.getAll("experiment").map((item) => {
    const [name, variation] = item.split(".");
    return { name, variation };
  });
  if (experiments != null && experiments.length > 0) {
    return experiments;
  }

  if (window?.osxe) {
    return window.osxe.getActiveExperiments();
  }

  if (window.experiments) {
    return window.experiments;
  }

  if (window.experimentName && window.experimentVariation) {
    return [
      {
        name: window.experimentName,
        variation: window.experimentVariation,
      },
    ];
  }

  return [];
}
