export function scrollToTop(el: HTMLElement | typeof window = window) {
  el.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

/** Prevent the page from scrolling, such as behind a modal. */
export function disableBodyScroll() {
  document.body.style.overflow = "hidden";
}

/** Re-enable page scrolling, such as when a modal is dismissed. */
export function enableBodyScroll() {
  document.body.style.overflow = "unset";
}
