import { getNormalizedValueFromTick } from "./personalizedPaceWeightGraph";

export function getRealPoints(numPoints) {
  const dataPoints = new Array(numPoints)
    .fill("")
    .map((_, i) => i)
    .map((num) => calculateYCoords(num));
  return dataPoints;
}

export function getVisualPoints(numPoints) {
  const dataPoints = new Array(numPoints)
    .fill("")
    .map((_, i) => i)
    .map((num) => calculateYCoords(num, visualFormula));
  return dataPoints;
}

function calculateYCoords(tick, formula = realFormula) {
  const weightedTick = getNormalizedValueFromTick(tick);
  let value;
  if (weightedTick < 0) {
    value = 1;
  } else if (weightedTick > 1) {
    value = 0;
  } else {
    value = formula(weightedTick);
  }
  return value * 100;
}

// Domain x [0, 1]
// This formula represents the weight loss curve generated using actual data. This formula
// should be used for any calculations involving weight loss amounts at certain dates or in
// any situation where we would be displaying exact values to the user
// Source: https://github.com/noom/data-analysis/blob/master/notebooks/craig/wl-graph-shape/Shape.ipynb
export function realFormula(x) {
  return (
    0.993 +
    -2.47 * x +
    7.16 * x ** 2 +
    -18.6 * x ** 3 +
    26.8 * x ** 4 +
    -19.4 * x ** 5 +
    5.53 * x ** 6
  );
}

// Domain x [0, 1]
// This formula is a slightly tweaked version of the above formula so that it looks better when
// plotted on the weight loss graph (mainly making sure that hard angles are smoothed out a bit).
// This should only be used to calculate the points needed to plot the weight loss graph.
// Source: https://github.com/noom/data-analysis/blob/master/notebooks/craig/wl-graph-shape/Shape.ipynb
export function visualFormula(x) {
  if (x < 0.2) {
    return (
      (26045703.657813307 * x ** 10 +
        -56481943.66273403 * x ** 9 +
        43804886.5220569 * x ** 8 +
        -17172087.08703274 * x ** 7 +
        3794754.274643959 * x ** 6 +
        -476804.65934150806 * x ** 5 +
        30413.38042376813 * x ** 4 +
        -427.6633918028255 * x ** 3 +
        -47.59600787017528 * x ** 2 +
        -0.18347962739159138 * x ** 1 +
        1.0026686998856373) /
      1.0026686998856373
    );
  }

  return (
    -421.4799136392215 * x ** 10 +
    2494.1206910264414 * x ** 9 +
    -6425.971142665275 * x ** 8 +
    9486.65632562212 * x ** 7 +
    -8881.306276816962 * x ** 6 +
    5503.277077696115 * x ** 5 +
    -2278.8820227569545 * x ** 4 +
    617.6317117660982 * x ** 3 +
    -102.60603546556608 * x ** 2 +
    7.968966245527428 * x ** 1 +
    0.591535709818735
  );
}
