import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { send } from "@utils/fetch";
import { visitorLifecycleStates } from "@utils/constants";
import { captureException } from "src/utils/sentry";
import { trackEvent, updateUserContext } from "src/utils/api/tracker";

import { AppDispatch, GetAppState } from "../store";

const visitorStatusSlice = createSlice({
  name: "visitorStatus",
  initialState: {} as JsonObject,
  reducers: {
    updateVisitorStatus(state, action: PayloadAction<JsonObject>) {
      return { ...action.payload };
    },
  },
});

const { updateVisitorStatus } = visitorStatusSlice.actions;

/**
 * Fetches subscriptions by user id and updates the slice (subscriptions and determinations).
 */
export function updateVisitorStatusState({
  user_id,
  is_new_user,
  email,
}: {
  user_id: string;
  is_new_user?: boolean;
  email?: string;
}) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    // eslint-disable-next-line no-console
    console.log("Updating user visitor status");
    const { surveyAnswers } = getState();
    let visitorStatus: JsonObject = {};
    try {
      ({ visitorStatus } = await send(
        "POST",
        "/visitor/api/v1/getVisitorStatus/",
        {
          clientUserId: user_id,
          surveyAnswers,
          email,
        }
      ));
    } catch (e) {
      captureException(e, "updateVisitorStatusState");
      return;
    }

    // Override backend result if the user was freshly created
    if (is_new_user) {
      // NOTE(Kevin): This code should only be reachable if the user is in an email route
      visitorStatus.visitorState = visitorLifecycleStates.newVisitor;
    }

    updateUserContext({
      visitorState: visitorStatus.visitorState,
    });
    trackEvent("VisitorStateDetermined", visitorStatus);

    dispatch(
      // eslint-disable-next-line no-use-before-define
      updateVisitorStatus({
        ...visitorStatus,
      })
    );
  };
}

export default visitorStatusSlice;
