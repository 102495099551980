import { InputHTMLAttributes, ReactNode, ComponentType } from "react";
import { CoreReduxState } from "src/utils/redux/store";
import {
  SurveyAnswer,
  SurveyAnswersState,
} from "src/utils/redux/slices/surveyAnswers";
import { Opaque } from "type-fest";

// TODO: Inline with Question.jsx. Separating for now to avoid more refactoring in there

export type QuestionProps<
  Definition extends BaseQuestionDefinition = BaseQuestionDefinition,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ExtraProps = {}
> = Pick<
  CoreReduxState,
  "surveyAnswers" | "serverContext" | "recommendedPlan" | "language"
> & {
  question: Omit<Definition, "component">;

  isLoading: boolean;
  canProceed: boolean;
  currentQuestionIndex: number;
  currentVisibleQuestionIndex: number;

  // Pre-compass. Can drop after full migration
  useMobileOptimizedControls?: boolean;

  surveyAnswerSeed?: CoreReduxState["surveyAnswers"];

  registerAnswer: (
    answers: CoreReduxState["surveyAnswers"],
    canProceed: boolean
  ) => Promise<void>;
  onQuestionLoaded?: () => void;
  onSurveyComplete?: (surveyAnswers?: SurveyAnswersState) => Promise<void>;

  onClickBack?: () => void;
  onClickNext?: () => void;
  pressBackToExitSurvey: () => void;
} & ExtraProps;

type QuestionHeader =
  | string
  | JSX.Element
  | ((context: {
      surveyAnswers: CoreReduxState["surveyAnswers"];
      serverContext: CoreReduxState["serverContext"];
      recommendedPlan: CoreReduxState["recommendedPlan"];
      language: string;
      userData: CoreReduxState["userData"];
    }) => ReactNode);

type Footer = ReactNode;

export interface QuestionOption {
  id: string;
  text: ReactNode;
}

export type QuestionOptions =
  | QuestionOption[]
  | ((context: {
      surveyAnswers: CoreReduxState["surveyAnswers"];
      serverContext: CoreReduxState["serverContext"];
    }) => QuestionOption[]);

type ShouldShowQuestionFn = (
  context: Pick<CoreReduxState, "surveyAnswers" | "serverContext"> & {
    direction: "next" | "back";
  }
) => boolean;

export interface NavButtonConfig {
  text?: string;
  disabled?: boolean;
}

export interface BaseQuestionDefinition {
  /**
   * Optional flag used to de-duplicate questions that duplicate the name of a LBF question.
   * This is a temporary work around to avoid major rework while esde remains
   * on compass. This will need to be revisited when esde is migrated to LBF.
   * */
  disableLBF?: boolean;
  id: string;
  type?:
    | "input"
    | "textarea"
    | "checkbox"
    | "radio"
    | "select"
    | "custom"
    | "custom-radio"
    | "text-interstitial"
    | "image-interstitial"
    | "list-interstitial";
  question?: QuestionHeader;
  options?: QuestionOptions;
  footer?: Footer;
  questionDescription?: () => ReactNode;
  section?: number;

  back?: NavButtonConfig;
  next?: NavButtonConfig;

  validator?: (surveyAnswer: SurveyAnswer) => boolean;
  shouldShowQuestion?: ShouldShowQuestionFn;
}

export type InputQuestionDefinition = BaseQuestionDefinition & {
  label: ReactNode;
  htmlAttr?: Partial<InputHTMLAttributes<HTMLInputElement>>;
  inputType?: HTMLInputElement["type"];
};

export type CustomInputQuestionDefinition = BaseQuestionDefinition & {
  component: ComponentType<QuestionProps<CustomInputQuestionDefinition>>;
  label?: ReactNode;
  htmlAttr?: Partial<InputHTMLAttributes<HTMLInputElement>>;
  inputType?: HTMLInputElement["type"];
};

export type TextAreaQuestionDefinition = BaseQuestionDefinition & {
  optional?: boolean;
};

export type OptionsInputDefinition = BaseQuestionDefinition & {
  options: QuestionOptions;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type CustomQuestionDefinition<ExtraQuestionProps = {}> =
  BaseQuestionDefinition &
    ExtraQuestionProps & {
      component: ComponentType<
        QuestionProps<CustomQuestionDefinition & ExtraQuestionProps>
      >;
    };

export type QuestionDefinition =
  | BaseQuestionDefinition
  | InputQuestionDefinition
  | OptionsInputDefinition
  | CustomQuestionDefinition;

export type ImageInterstitialDefinition = BaseQuestionDefinition & {
  disclaimer?: ReactNode | ReactNode[];
  description?: ReactNode | ReactNode[];
  image:
    | ((props: QuestionProps<ImageInterstitialDefinition>) => string)
    | string;
  imageAlt?:
    | ((props: QuestionProps<ImageInterstitialDefinition>) => string)
    | string;
  text?:
    | ((
        props: QuestionProps<ImageInterstitialDefinition>,
        state: any
      ) => ReactNode)
    | ReactNode;
  subtitle?: ReactNode | ReactNode[];
  useStickyControls?: boolean;
  useWideImage?: boolean;
};

export type CustomImageInterstitialDefinition =
  CustomQuestionDefinition<ImageInterstitialDefinition>;

export type ListInterstitialDefinition = BaseQuestionDefinition & {
  image?: string;
  imageAlt?: string;
  list: {
    key: number;
    title?:
      | ((props: QuestionProps<ListInterstitialDefinition>) => ReactNode)
      | ReactNode;
    image: string;
    text:
      | ((props: QuestionProps<ListInterstitialDefinition>) => ReactNode)
      | ReactNode;
    shouldShowListItem?: (
      props: QuestionProps<ListInterstitialDefinition>
    ) => boolean;
  }[];
};

export type TextInterstitialDefinition = BaseQuestionDefinition & {
  icon?: string;
  title:
    | ((props: QuestionProps<TextInterstitialDefinition>) => ReactNode)
    | ReactNode;
  text:
    | ((props: QuestionProps<TextInterstitialDefinition>) => ReactNode)
    | ReactNode;
};

// Intentionally break duck typing here to force questions through registerQuestion
// https://github.com/sindresorhus/type-fest/blob/main/source/opaque.d.ts
type QuestionListItem<T extends BaseQuestionDefinition = QuestionDefinition> =
  Opaque<T, "registerQuestion">;
export type QuestionList = QuestionListItem[];

const registeredDefinitions: Record<string, QuestionListItem[]> = {};

export function registerQuestion<
  T extends BaseQuestionDefinition = QuestionDefinition
>(question: T) {
  if (!registeredDefinitions[question.id]) {
    registeredDefinitions[question.id] = [];
  }

  const questionListItem = question as unknown as QuestionListItem<T>;

  if (!question.disableLBF) {
    registeredDefinitions[question.id].push(questionListItem);
  }

  return questionListItem;
}

export function getQuestionsById(id: string) {
  return registeredDefinitions[id];
}
