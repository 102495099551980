import Chart from "chart.js";
import { getMonthDelta } from "@utils/datetime";
import {
  getImportantEventText,
  getImportantEventTextLong,
} from "@components/refactored-survey/question-sets/payment-survey-questions/dates/Dates";
import { UnitType } from "@components/unit/Unit";
import i18n from "@i18n";
import { renderGoal, drawLabel } from "./drawAdditionalChartelements";
import { getRealPoints, getVisualPoints, realFormula } from "./v3GraphPoints";
import {
  getTickIndexesForMonthLabelsInRange,
  getDateFromTick,
} from "./getTickIndexesForMonthLabelsInRange";
import { compassColors } from "@utils/styling";
import { SurveyAnswer } from "src/utils/redux/slices/surveyAnswers";

const CHART_WIDTH_TICKS = 200;
const CHART_HEIGHT = 115;

const START_TICK = 10; // Tick that represents current date (also represents length of flat head)
const END_TICK = CHART_WIDTH_TICKS - 20; // Tick that represents target date (also represents the beginning of the flat tail)

const MIN_TICK_OFFSET = 10; // Min amount of ticks apart it takes for two labels to not be overlapping

// Function that takes a tick and normalizes it so that the tick at START_TICK has value 0
// and tick at END_TICK has value 1
export function getNormalizedValueFromTick(tick: number) {
  return (tick - START_TICK) / (END_TICK - START_TICK);
}

export function getTickFromNormalizedValue(value: number) {
  return Math.ceil(value * (END_TICK - START_TICK) + START_TICK);
}

export function getTickFromTargetWeight(
  startWeight: number,
  endWeight: number,
  targetWeight: number
) {
  const points = getRealPoints(CHART_WIDTH_TICKS);

  const yCoord =
    (1 - (targetWeight - startWeight) / (endWeight - startWeight)) * 100;
  for (let i = 0; i < points.length; i++) {
    // Kind of a jank way to get the tick associated with a given weight value
    // TODO Make this better?
    if (yCoord >= points[i]) {
      return i;
    }
  }
  return 0;
}

export function getDateFromTargetWeight(
  startWeight: number,
  endWeight: number,
  targetWeight: number,
  today: Date,
  endDate: Date
) {
  const tick = getTickFromTargetWeight(startWeight, endWeight, targetWeight);
  return getDateFromTick(tick, today, endDate);
}

export function calculateWeightAtDate(
  startWeight: number,
  idealWeight: number,
  targetDate: Date,
  endDate: Date
) {
  if (targetDate > endDate) {
    return idealWeight;
  }
  const todayTimeStamp = new Date().getTime();
  const endOfPlanTimeStamp = endDate.getTime();
  const targetDateTimeStamp = targetDate.getTime();
  const weightedXCoord =
    (targetDateTimeStamp - todayTimeStamp) /
    (endOfPlanTimeStamp - todayTimeStamp);
  const yCoordOfFinalWeight = realFormula(weightedXCoord);
  const finalWeightAboveIdealWeight =
    yCoordOfFinalWeight * (startWeight - idealWeight);
  return idealWeight + Math.round(finalWeightAboveIdealWeight);
}

export function getImportantEventSubtitle(
  startWeight: number,
  idealWeight: number,
  endDate: Date,
  surveyAnswers: SurveyAnswer
) {
  if (!surveyAnswers?.importantDateTime) {
    return "";
  }
  const today = new Date();
  const importantDate = new Date(surveyAnswers.importantDateTime);

  const sevenDays = 1000 * 60 * 60 * 24 * 7;
  const fourteenDays = sevenDays * 2;

  const monthsApart = getMonthDelta(today, importantDate);
  if (importantDate > today) {
    const event = getImportantEventTextLong(surveyAnswers);
    if (
      monthsApart === 0 ||
      importantDate.getTime() - today.getTime() < sevenDays
    ) {
      return i18n.t("plans:subtitleEarlyEvent", { event });
    }
    if (
      importantDate > endDate &&
      importantDate.getTime() - endDate.getTime() > fourteenDays
    ) {
      return i18n.t("plans:subtitleLateEventPersonalizedPace", { event });
    }
    const importantDateWeight = calculateWeightAtDate(
      startWeight,
      idealWeight,
      importantDate,
      endDate
    );
    const weightLoss = startWeight - importantDateWeight;
    return i18n.t("plans:subtitleEvent", { weightLoss, event });
  }

  return "";
}

function drawWeightGraph(
  startWeight: number,
  idealWeight: number,
  unit: UnitType,
  targetDate: Date,
  graph: Chart,
  surveyAnswers = null,
  drawImportantDate = false,
  drawFivePercent = false,
  hostEl = "analysis-result--mobile"
) {
  const planDuration = getMonthDelta(new Date(), targetDate);
  const planEndDate = targetDate;
  const importantEventDate = new Date(surveyAnswers?.importantDateTime);
  const shouldDrawImportantEvent =
    !!surveyAnswers?.importantDateTime &&
    Object.prototype.toString.call(importantEventDate) === "[object Date]" &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(importantEventDate.getTime()) &&
    drawImportantDate;

  const dataSetPoints = getVisualPoints(CHART_WIDTH_TICKS).slice(
    0,
    CHART_WIDTH_TICKS
  );

  const shouldSkipEveryOtherTick =
    (window.innerWidth < 600 && planDuration >= 7) || planDuration >= 10;

  const { labels } = getTickIndexesForMonthLabelsInRange(
    new Date(),
    planEndDate,
    CHART_WIDTH_TICKS,
    CHART_WIDTH_TICKS - 10,
    shouldSkipEveryOtherTick
  );

  const MAIN_LINE_COLOR = compassColors.stream;
  const X_AXIS_COLOR = compassColors.grey2;

  let importantEventTick: number;
  let importEventWeight: number;
  if (shouldDrawImportantEvent) {
    const currentTimestamp = new Date().getTime();
    importantEventTick = getTickFromNormalizedValue(
      (importantEventDate.getTime() - currentTimestamp) /
        (planEndDate.getTime() - currentTimestamp)
    );
    importEventWeight = calculateWeightAtDate(
      startWeight,
      idealWeight,
      importantEventDate,
      targetDate
    );
  }

  let fivePercentTick: number;
  const fivePercentWeight = startWeight * 0.95;
  if (drawFivePercent) {
    fivePercentTick = getTickFromTargetWeight(
      startWeight,
      idealWeight,
      fivePercentWeight
    );
  }

  // If fivePercentDate and importantEventDate are too close to eachother, skooch one of them over
  if (
    shouldDrawImportantEvent &&
    drawFivePercent &&
    Math.abs(importantEventTick - fivePercentTick) < MIN_TICK_OFFSET
  ) {
    const fivePercentDate = getDateFromTargetWeight(
      startWeight,
      idealWeight,
      fivePercentWeight,
      new Date(),
      targetDate
    );
    const offset =
      MIN_TICK_OFFSET - Math.abs(importantEventTick - fivePercentTick);
    if (fivePercentDate <= importantEventDate) {
      fivePercentTick -= offset;
    } else {
      fivePercentTick += offset;
    }
  }

  const importantTicks = [END_TICK, importantEventTick, fivePercentTick];
  const goalColor = compassColors.tarocco;
  const importantEventColor = compassColors.lagoon;
  const fivePercentColor = compassColors.blueberry;

  function drawAdditionalChartElements(this: Chart) {
    renderGoal(
      this.chart,
      "Goal",
      `${idealWeight} ${unit.label}`,
      END_TICK,
      goalColor
    );

    if (shouldDrawImportantEvent) {
      drawLabel(
        this.chart,
        `${getImportantEventText(surveyAnswers)} (${importEventWeight} ${
          unit.label
        })`,
        importantEventTick,
        START_TICK,
        END_TICK,
        importantEventColor
      );
    }

    if (drawFivePercent) {
      drawLabel(
        this.chart,
        `Lower Risk (${Math.round(fivePercentWeight)} ${unit.label})`,
        fivePercentTick,
        START_TICK,
        END_TICK,
        fivePercentColor
      );
    }
  }

  const config = {
    type: "line",
    data: {
      labels,
      datasets: [
        {
          data: new Array(CHART_WIDTH_TICKS).fill(0),
          backgroundColor: "transparent",
          borderRadius: 0,
          borderColor: "rgba(60, 184, 199, 0)",
          pointRadius: 0,
          borderWidth: 0,
        },
        {
          data: dataSetPoints,
          borderWidth: 4,
          borderColor: MAIN_LINE_COLOR,
          pointBorderWidth: 2,
          pointRadius(ctx) {
            if (
              ctx.dataIndex <= END_TICK &&
              importantTicks.includes(ctx.dataIndex)
            ) {
              return 10;
            }
            return 0;
          },
          pointBorderColor: "#FFF",
          pointBackgroundColor(ctx) {
            if (ctx.dataIndex === END_TICK) {
              return goalColor;
            }
            if (ctx.dataIndex === fivePercentTick) {
              return fivePercentColor;
            }
            if (ctx.dataIndex === importantEventTick) {
              return importantEventColor;
            }
            return compassColors.stream;
          },
          backgroundColor: compassColors.offWhite,
          fill: "-1",
        },
      ],
    },
    options: {
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
      },
      bezierCurve: true,
      scales: {
        xAxes: [
          {
            position: "bottom",
            ticks: {
              autoSkip: false,
              maxRotation: 0, // Layout becomes funky and we loose width if the ticks rotate
              maxTicksLimit: 20,
              display: true,
              textStrokeColor: "transparent",
              textStrokeWidth: 0,
              fontColor: compassColors.grey3,
              fontSize: 14,
              fontFamily: "Untitled Sans, sans-serif",
            },
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              drawTicks: false,
              borderDash: [0, 1000], // TODO: this is a hack to get rid of the grid lines
              drawBorder: false,
              zeroLineColor: X_AXIS_COLOR,
              zeroLineWidth: 7,
              maxLines: 4,
            },
            ticks: {
              fontColor: compassColors.grey3,
              fontSize: 14,
              fontFamily: "Untitled Sans, sans-serif",
              padding: 3,
              display: true,
              min: 0,
              max: CHART_HEIGHT,
              callback(label, index) {
                if (index === 0) return null;
                const weightDiffPerNotch = (startWeight - idealWeight) / 5;
                const notchValue = Math.floor(
                  startWeight - (index - 1) * weightDiffPerNotch
                );
                return `${notchValue}`;
              },
            },
          },
        ],
      },
      animation: {
        duration: 0,
        onProgress() {
          drawAdditionalChartElements.apply(this);
        },
        onComplete() {
          drawAdditionalChartElements.apply(this);
        },
      },
    },
  };

  if (graph) {
    graph.destroy();
  }

  const hostDomEl = document.getElementById(hostEl);
  if (!hostDomEl) {
    return null;
  }

  hostDomEl.setAttribute("data-noom-graph", "personalized-pace");
  return new Chart(hostEl, config);
}

export default drawWeightGraph;
