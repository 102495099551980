import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { flexMiddle } from "src/design-system/styles/layout";

export const FlexFrame = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const CenteredBody = styled.section`
  width: 100%;
  ${flexMiddle};
  flex-direction: column;
  box-sizing: border-box;
`;

export const DEFAULT_COLUMN_MARGIN = "16px";
export const MainColumn = styled.div<{
  maxWidth?: number | string;
  margin?: number;
}>`
  display: flex;
  flex-direction: column;
  box-sizing: content-box;

  ${({ maxWidth }) => {
    // If we are passed a specific width, save to a var so it
    // can be used for nested children.
    if (maxWidth) {
      const widthValue =
        typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth;
      return `--main-column-width: ${widthValue}`;
    }
    return "";
  }};
  ${({ margin }) => (margin ? `--main-column-margin: ${margin}px` : "")};

  max-width: var(--main-column-width, 500px);
  padding: 0 var(--main-column-margin, ${DEFAULT_COLUMN_MARGIN});
  width: calc(100% - 2 * var(--main-column-margin, ${DEFAULT_COLUMN_MARGIN}));
  flex: 1;

  margin: 0 auto;
`;

/**
 * Allows children of MainColumn to fill with width of the page.
 * This is useful when it would be a pain to stack layouts with and
 * without the MainColumn.
 *
 * https://css-tricks.com/full-width-containers-limited-width-parents/
 */
const Spreader = styled.div`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;
export function FullWidth({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  // The wrapper div here removes us from the layout constraints of
  // flex, grid, etc layouts that may not honor our explicit margins.
  // The outer div follows those and the inner is free to fill the screen.
  return (
    <div>
      <Spreader className={className}>{children}</Spreader>
    </div>
  );
}
