import md5 from "md5";
import { registerHandler } from "../handler";

declare let liQ: any;

// Owner: Ronnie (Affiliate)
export default function registerHandlers() {
  registerHandler("lead", ({ email }) => {
    (window as any).liQ = (window as any)?.liQ || [];
    liQ.push({
      event: "conversion",
      name: "email_signup",
      emailHash: md5(email),
    });
  });

  registerHandler("purchase", ({ plan, order }) => {
    const { price } = plan;
    (window as any).liQ = (window as any)?.liQ || [];
    liQ.push({
      event: "conversion",
      name: "product_purchase",
      emailHash: md5(order.email),
      conversionId: order.subscriptionId,
      amount: price,
      currency: plan.currency,
      items: [
        {
          id: plan.braintree_id,
          price,
          quantity: 1,
          currency: plan.currency,
        },
      ],
    });
  });
}
