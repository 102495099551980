import { trackEvent } from "src/utils/api/tracker";
import { getPaintTimings } from "src/utils/timing";
import { useOnce } from "./lifecycle";

export function trackPageView(eventName: string, extraProps?: JsonObject) {
  trackEvent(eventName, {
    ...getPaintTimings(),
    ...extraProps,
  });
}

export function usePageView(eventName: string, extraProps?: JsonObject) {
  useOnce(() => {
    trackPageView(eventName, extraProps);
  });
}
