import { ServerContextState } from "./redux/slices/serverContext";
import getStore, { AppDispatch, GetAppState } from "./redux/store";
import { isBaseline, isUS } from "./userSegment";
import { Plan, updatePlans } from "src/utils/redux/slices/plans";
import { formatPriceWithCurrencySymbol } from "./priceUtils";
import saveToBraze from "./brazeUploader";
import { RecommendedPlanState } from "./redux/slices/recommendedPlan";
import { trackEvent } from "src/utils/api/tracker";
import mapPlanKeyToNoomPlanId from "./plansMapping";
import { captureException } from "@utils/sentry";
import { send } from "src/utils/fetch";

export const HM_CURRICULUM = "HM";
export const VIP_HM_CURRICULUM = "VIP_HM";

// c/p logic from growth repo
const GEO_DATA_TO_CURRENCY = {
  // By countries.
  US: "USD",
  CA: "CAD",
  GB: "GBP",
  AU: "AUD",
  NZ: "NZD",
  JP: "JPY",
  KR: "KRW",
  // By continents.
  EU: "EUR",
  NA: "USD",
};

const getUserCurrency = () => {
  const state = getStore().getState();
  const { country_code, continent_code } = state.geoLocation;

  if (GEO_DATA_TO_CURRENCY[country_code]) {
    return GEO_DATA_TO_CURRENCY[country_code];
  }
  if (GEO_DATA_TO_CURRENCY[continent_code]) {
    return GEO_DATA_TO_CURRENCY[continent_code];
  }
  return GEO_DATA_TO_CURRENCY.US;
};

export const getNoomPlanIdFromKey = (key: string) => {
  const noomPlanId = mapPlanKeyToNoomPlanId[key];

  if (!noomPlanId) {
    // should not happen, we mapped all keys defined in growth
    trackEvent("CannotMapKeyToNoomPlanId", { key });
    throw new Error(`Missing plan key mapping for: ${key}`);
  }

  return noomPlanId;
};

export function getDefaultTrialFee(plan: RecommendedPlanState) {
  if (plan?.trial_item_options?.length > 0) {
    const prices = plan.trial_item_options.map((o) => o.price);
    const rawFee = Math.min(...prices);
    return rawFee < 1 && rawFee > 0 ? +rawFee.toFixed(2) : rawFee;
  }
  return 0;
}

/**
 * Determine if user eligible for 7 day trial.
 */
export function has7DayTrial() {
  if (isUS() && isBaseline()) {
    return true;
  }
  return false;
}

/**
 * Determine the length of user's trial for in trial users
 */
export function getTrialLength(serverContext: ServerContextState) {
  const subscriptions = JSON.parse(serverContext.subscriptions);
  const subscription = subscriptions.find((sub) => sub.status === "ACTIVE");
  if (subscription) {
    if (/32_day/.test(subscription.plan_id)) {
      return 32;
    }
    if (/22_day/.test(subscription.plan_id)) {
      return 22;
    }
    if (/8_day/.test(subscription.plan_id)) {
      return 8;
    }
  }
  return 15;
}

/**
 * Determine whether a plan is part of the HM curriculum
 */
export function isHMPlan(plan: Partial<Plan>) {
  return [HM_CURRICULUM, VIP_HM_CURRICULUM].includes(plan.curriculum);
}

export async function fetchProductPlanAsPlanById(noomPlanId: string) {
  const { dispatch, getState } = getStore();
  const state = getState();
  const currency = getUserCurrency();
  const planKey = `${noomPlanId}_${currency}`;

  const plan = state.plans[planKey];
  if (plan) {
    return plan;
  }

  try {
    const url = `/services/api/product_catalog/v1/productAsPlan/${currency}/${noomPlanId}`;
    const result = await send<Plan>("GET", url);
    result.noom_plan_id = noomPlanId;

    dispatch(
      updatePlans({
        [planKey]: result,
      })
    );

    return result;
  } catch (e) {
    captureException(e);
    return null;
  }
}

const keysToCheckForDiscrepancies = ["braintree_id", "price"];

export const getPlanByKey = async (key: string | number): Promise<Plan> => {
  const oldPlan = getStore().getState()?.plans?.[key];
  const noomPlanId = getNoomPlanIdFromKey(`${key}`);
  const productPlanAsPlan = await fetchProductPlanAsPlanById(noomPlanId);

  // compare old plan and fetched plan
  const discrepancies = [];
  let status = "match";

  if (!productPlanAsPlan) {
    status = "notFound";
  } else if (oldPlan) {
    // use keys of an old plan, since transformed product plan will contain
    // additional properties (e.g. )
    // only run the comparison in landing context (payment context has no plans dict)
    Object.keys(oldPlan).forEach((k) => {
      if (
        keysToCheckForDiscrepancies.includes(k) &&
        oldPlan[k] !== productPlanAsPlan[k]
      ) {
        discrepancies.push(k);
        status = "discrepancy";
      }
    });
  }

  trackEvent("ProducPlanAsPlanSearch", {
    key,
    noomPlanId,
    oldPlan,
    productPlanAsPlan,
    status,
    discrepancies,
  });

  return productPlanAsPlan ?? oldPlan;
};

export function savePlanToBraze(data: {
  recommendedPlan: RecommendedPlanState;
  currentBMI: string;
  targetBMI: string;
  targetMonthNameFull: string;
}) {
  return async (dispatch: AppDispatch, getState: GetAppState) => {
    const { userData } = getState();
    let recommendedPlan: RecommendedPlanState;

    switch (data.recommendedPlan.trial_duration) {
      case 0:
      case 14:
        ({ recommendedPlan } = data);
        break;
      default:
        recommendedPlan = await getPlanByKey(
          `${data.recommendedPlan.billing_cycle_in_months}`
        );
    }

    const recommendedPlan7Days = await getPlanByKey(
      `${data.recommendedPlan.billing_cycle_in_months}-7-DAY-TRIAL`
    );

    const formatPrice = (price) =>
      formatPriceWithCurrencySymbol(price, recommendedPlan);

    const properties: JsonObject = {
      current_bmi: data.currentBMI,
      target_bmi: data.targetBMI,
      recommended_month_goal: data.targetMonthNameFull,
      recommended_plan_url: recommendedPlan.noom_plan_id,
      recommended_plan_regular_price: formatPrice(
        recommendedPlan.regular_price
      ),
      recommended_plan_regular_monthly_price: formatPrice(
        recommendedPlan.regular_monthly_price
      ),
      recommended_plan_price: formatPrice(recommendedPlan.price),
      recommended_plan_monthly_price: formatPrice(
        recommendedPlan.monthly_price
      ),
      recommended_plan_duration: recommendedPlan.billing_cycle_in_months,
    };
    if (recommendedPlan7Days) {
      properties.recommended_plan_url_7day = recommendedPlan7Days.noom_plan_id;
    }
    if (recommendedPlan.isForcedPlan) {
      properties.forcedNoomPlanId = recommendedPlan.noom_plan_id;
    }

    if (recommendedPlan.starter_fee) {
      properties.recommended_plan_starter_fee = formatPrice(
        recommendedPlan.starter_fee
      );
    }
    return saveToBraze(userData.user_id, properties, null);
  };
}
