/* eslint-disable no-underscore-dangle */
import Chart from "chart.js";

import { compassColors } from "@utils/styling";
import { UnitType } from "@components/unit/Unit";
import { convertUnits } from "@components/unit/UnitUtils";
import { getMonthDelta } from "@utils/datetime";
import {
  getImportantEventText,
  getImportantEventTextLong,
} from "@components/refactored-survey/question-sets/payment-survey-questions/dates/Dates";
import i18n from "@i18n";
import { getTargetWeightDate } from "@utils/weight/getTargetWeightDate";
import { SurveyAnswersState } from "@utils/redux/slices/surveyAnswers";
import {
  calculateWeightAtMonth,
  shouldShowSteeperSlope,
  REGULAR_WEIGHT_LOSS_PACING,
  STEEPER_WEIGHT_LOSS_PACING,
} from "@utils/weight/weightPacing";

import { MONTH_NAMES_ABBR } from "../constants";

export function calculateWeightAtDate(
  startWeight: number,
  idealWeight: number,
  date: Date,
  planDuration: number
) {
  const today = new Date();
  const months = getMonthDelta(today, date);
  return calculateWeightAtMonth(startWeight, idealWeight, months, planDuration);
}

export function getImportantEventSubtitle(
  startWeight: number,
  idealWeight: number,
  planDuration: number,
  planEndDate: Date,
  surveyAnswers: SurveyAnswersState
) {
  if (!surveyAnswers?.importantDateTime) {
    return "";
  }
  const today = new Date();
  const importantDate = new Date(surveyAnswers.importantDateTime);
  const endDate = new Date(planEndDate);

  const sevenDays = 1000 * 60 * 60 * 24 * 7;
  const fourteenDays = sevenDays * 2;

  const monthsApart = getMonthDelta(today, importantDate);
  if (importantDate > today) {
    const event = getImportantEventTextLong(surveyAnswers);
    if (
      monthsApart === 0 ||
      importantDate.getTime() - today.getTime() < sevenDays
    ) {
      return i18n.t("plans:subtitleEarlyEvent", { event });
    }
    if (
      importantDate > endDate &&
      importantDate.getTime() - endDate.getTime() > fourteenDays
    ) {
      return i18n.t("plans:subtitleLateEvent", { event });
    }
    const importantDateWeight = calculateWeightAtDate(
      startWeight,
      idealWeight,
      importantDate,
      planDuration
    );
    const weightLoss = startWeight - importantDateWeight;
    return i18n.t("plans:subtitleEvent", { weightLoss, event });
  }

  return "";
}

// Gets the index on the weight graph a date should fall on
function getWeightGraphIndex(date: Date, planDuration: number) {
  const today = new Date();
  let monthIndex;
  if (date > today) {
    monthIndex = getMonthDelta(today, date);

    // These plan durations don't have an extra month at the start of their
    // pacing steps, this accounts for the missing start month.
    if (
      planDuration === 7 ||
      planDuration === 8 ||
      planDuration === 10 ||
      planDuration === 12
    ) {
      monthIndex -= 1;
    }

    return monthIndex;
  }

  return undefined;
}

function drawWeightGraph(
  startWeight: number,
  idealWeight: number,
  planDuration: number,
  unit: UnitType,
  graph: Chart,
  canvas: HTMLCanvasElement,
  keepItOffLength = 0,
  weightDisplayUnit: UnitType = null,
  surveyAnswers: SurveyAnswersState = null,
  drawImportantDate = false,
  drawFivePercent = false,
  skipEveryOtherLabel = false
) {
  const today = new Date();
  const programFirstMonth = today.getMonth();

  const totalWeightLossGoal = startWeight - idealWeight;
  const showSteeperSlope = shouldShowSteeperSlope(totalWeightLossGoal, unit);

  const weightLossPacing = showSteeperSlope
    ? STEEPER_WEIGHT_LOSS_PACING
    : REGULAR_WEIGHT_LOSS_PACING;

  const monthlyWeightLossGoal = totalWeightLossGoal / planDuration;

  const data: number[] = [];
  const pacing = [...weightLossPacing[planDuration]];

  let importantDate: Date;
  let importantDateMonthIndex: number;
  if (surveyAnswers?.importantDateTime) {
    importantDate = new Date(surveyAnswers.importantDateTime);
    importantDateMonthIndex = getWeightGraphIndex(importantDate, planDuration);
  }

  const canDrawImportantEvent =
    importantDate && importantDate > today && drawImportantDate;

  const fivePercentDate =
    drawFivePercent &&
    getTargetWeightDate(
      startWeight,
      idealWeight,
      startWeight * 0.95,
      planDuration
    );

  let fivePercentMonthIndex: number;
  const canDrawFivePercent = fivePercentDate && fivePercentDate > today;
  if (canDrawFivePercent) {
    fivePercentMonthIndex = getWeightGraphIndex(fivePercentDate, planDuration);
  }

  if (keepItOffLength) {
    pacing.pop();
    if (planDuration === 8) {
      pacing.pop();
      pacing.unshift();
    }
    if (planDuration === 12) {
      pacing.push(...[0.1, 0, 0, 0, 0]);
    } else {
      pacing.push(...[0.1, 0, 0]);
    }
  }

  let weight = startWeight;
  pacing.forEach((p, k) => {
    let amountToReduce = p * monthlyWeightLossGoal;
    if (!showSteeperSlope) {
      amountToReduce = Math.round(amountToReduce);
    }
    weight -= amountToReduce;
    if (k === pacing.length - 2 - keepItOffLength || weight < idealWeight) {
      weight = idealWeight;
    }
    data.push(weight);
  });

  const labels = [];
  const pointBackgroundColor = [];
  const pointBorderColor = [];
  const pointBorderWidth = [];
  const pointRadius = [];

  function evalShouldSkip(i: number) {
    const targetWeightIndex = data.length - 2 - keepItOffLength;
    const isTargetWeight = i === targetWeightIndex;
    if (isTargetWeight) {
      return false;
    }
    if (i === 0 || i === data.length - 1) {
      return true;
    }

    // WARN: Non-functional hack to make more room on small devices. This should be moved into a proper prop backed by
    // mediaMatch or similar to ensure consistent rendering.
    if (window.innerWidth < 600) {
      if (planDuration + keepItOffLength >= 8) {
        // Every other element, outward from target
        return Math.abs((targetWeightIndex - i) % 2) === 1;
      }
    }
    // Update graphs set skipEveryOtherLabel to true to avoid text collisions
    // on longer duation programs because they are smaller width than the other weight graphs
    if (skipEveryOtherLabel && planDuration + keepItOffLength >= 10) {
      return Math.abs((targetWeightIndex - i) % 2) === 1;
    }
    return false;
  }

  // Put additional points on both ends for shape of graph
  for (let i = 0; i < data.length; i += 1) {
    const shouldSkip = evalShouldSkip(i);
    if (shouldSkip) {
      labels.push("");
      pointBackgroundColor.push("");
      pointBorderColor.push("");
      pointBorderWidth.push(0);
      pointRadius.push(0);
    } else {
      let monthIndex = programFirstMonth + i;
      if (planDuration === 1) monthIndex -= 1;
      if (
        planDuration === 7 ||
        planDuration === 8 ||
        planDuration === 10 ||
        planDuration === 12
      ) {
        monthIndex += 1;
      }

      if (monthIndex >= 12) {
        monthIndex -= 12;
      }
      if (planDuration < 18) {
        labels.push(MONTH_NAMES_ABBR[monthIndex]);
      }

      pointBorderColor.push("#ffffff");
      pointBorderWidth.push(2);
      pointRadius.push(8);
      if (keepItOffLength) {
        if (i >= data.length - 2 - keepItOffLength) {
          pointBackgroundColor.push("#3cb8c7");
        } else {
          pointBackgroundColor.push("#fe7351");
        }
      } else if (i === data.length - 2) {
        pointBackgroundColor.push("#f75462");
      } else {
        pointBackgroundColor.push("#2196f3");
      }
    }
  }

  function getTextLabel(dataPoint: number) {
    let { mainUnitValue, secondaryUnitValue } = convertUnits(
      { mainUnitValue: dataPoint },
      unit,
      weightDisplayUnit
    );

    mainUnitValue = Math.round(mainUnitValue);
    let text = `${mainUnitValue} ${weightDisplayUnit.label}`;
    if (secondaryUnitValue) {
      secondaryUnitValue = Math.round(secondaryUnitValue);
      text = `${text} ${secondaryUnitValue}`;
    }
    return text;
  }

  const config = {
    type: "line",
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: compassColors.lagoon,
          borderColor: compassColors.lagoon,
          pointBackgroundColor,
          pointBorderColor,
          pointBorderWidth,
          pointRadius,
        },
      ],
    },
    options: {
      legend: {
        display: false,
      },
      maintainAspectRatio: !drawImportantDate,
      tooltips: {
        enabled: false,
      },
      bezierCurve: true,
      scales: {
        xAxes: [
          {
            position: "top",
            ticks: {
              autoSkip: !drawImportantDate,
              maxRotation: 0, // Layout becomes funky and we loose width if the ticks rotate
              fontFamily: "'Untitled Sans', sans-serif",
              fontColor: "#969696",
              fontSize: "16",
              padding: canDrawImportantEvent || canDrawFivePercent ? 70 : 24,
              lineHeight: 2.5,
            },
            gridLines: {
              drawBorder: false,
              borderDash: [5, 5],
            },
          },
        ],
        yAxes: [
          {
            zeroLineWidth: 20,
            gridLines: {
              display: false,
              drawTicks: !drawImportantDate,
            },
            ticks: {
              display: false,
              suggestedMin:
                keepItOffLength || showSteeperSlope
                  ? idealWeight - 2
                  : idealWeight - 25,
            },
          },
        ],
      },
      animation: {
        onComplete(this: Chart) {
          const chartInstance = this.chart;
          const { ctx } = chartInstance;
          ctx.font = `10pt 'Untitled Sans', sans-serif`;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          ctx.fillStyle = "#4d4d4d";

          this.data.datasets.forEach((dataset: any, i: number) => {
            const meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach((bar: any, index: number) => {
              function drawMultiLineText(text: string, initialYOffset: number) {
                ctx.fillStyle = "#000000";
                ctx.font = "10pt 'Untitled Sans', sans-serif";
                const lines = text.split(/\n/g).reverse();
                let renderYOffset = initialYOffset;
                lines.forEach((line) => {
                  ctx.fillText(
                    line,
                    bar._model.x,
                    bar._model.y - renderYOffset
                  );
                  const textMeasurement = ctx.measureText(line);
                  const textHeight =
                    textMeasurement.fontBoundingBoxDescent +
                    textMeasurement.fontBoundingBoxAscent;
                  renderYOffset += textHeight + 2; // Slight line height pad
                });

                return renderYOffset;
              }

              function drawTargetArrow(bottomY: number) {
                for (let barY = 50; barY >= bottomY + 2; barY -= 5) {
                  ctx.fillText("|", bar._model.x, bar._model.y - barY);
                }
                ctx.fillText("v", bar._model.x, bar._model.y - bottomY - 3);
                ctx.fillText("v", bar._model.x, bar._model.y - bottomY - 2);
                ctx.fillText("v", bar._model.x, bar._model.y - bottomY);
              }

              function drawDashedAsciiLine(
                x: number,
                top: number,
                bottom: number
              ) {
                ctx.font = `6pt 'Untitled Sans', sans-serif`;
                let y = top;
                while (y <= bottom) {
                  ctx.fillText("|", x, y);
                  y += 10;
                }
              }

              const shouldSkip = evalShouldSkip(index);

              // Render label and target text
              let textTop = 5;
              if (!shouldSkip) {
                ctx.font = `10pt 'Untitled Sans', sans-serif`;
                ctx.textAlign = "center";

                const isTargetWeight =
                  index === dataset.data.length - 2 - keepItOffLength;
                const text = getTextLabel(dataset.data[index]);
                if (isTargetWeight) {
                  ctx.fillStyle = "#f75462";
                  ctx.fillText(text, bar._model.x, bar._model.y - 10);
                  if (keepItOffLength) {
                    textTop = drawMultiLineText("Your Plan\nEnds", 75);
                    drawTargetArrow(33);
                  } else {
                    textTop = 30;
                  }
                } else if (
                  index === 1 &&
                  dataset.data.length + keepItOffLength >= 10
                ) {
                  // Adjust the first weight unit label in the graph to render slightly to the right so that it doesn't get cut off for 8 month plus duration plans
                  ctx.fillText(text, bar._model.x + 10, bar._model.y - 10);
                  textTop = 30;
                } else if (index < dataset.data.length - 2 - keepItOffLength) {
                  ctx.fillText(text, bar._model.x, bar._model.y - 10);
                  textTop = 30;
                }
              }

              function renderAdditionalMarker(
                markerIndex: number,
                height: number,
                text: string,
                drawVerticalLine?: boolean
              ) {
                if (index > 0 && index <= dataset.data.length - 2) {
                  ctx.fillStyle = "#000000";
                  ctx.font = `10pt 'Untitled Sans', sans-serif`;

                  if (markerIndex <= 0 && index === 1) {
                    // Marker before start of graph
                    ctx.textAlign = "left";
                    ctx.fillText(text, bar._model.x / 2, height);

                    if (drawVerticalLine) {
                      drawDashedAsciiLine(
                        bar._model.x / 2,
                        height + 10,
                        bar._model.y - textTop
                      );
                    }
                  } else if (
                    // Marker after end of graph
                    index === dataset.data.length - 2 &&
                    markerIndex >= dataset.data.length - 1
                  ) {
                    ctx.textAlign = "right";
                    ctx.fillText(`${text} —`, bar._model.x + 15, height);
                    ctx.fillText(`—`, bar._model.x + 20, height);
                    ctx.fillText(`>`, bar._model.x + 20, height);
                  } else if (markerIndex === index) {
                    ctx.textAlign = "center";
                    ctx.fillText(text, bar._model.x, height);

                    if (drawVerticalLine) {
                      drawDashedAsciiLine(
                        bar._model.x,
                        height + 10,
                        bar._model.y - textTop
                      );
                    }
                  }
                }
              }

              const multipleMarkersPresent =
                canDrawImportantEvent && canDrawFivePercent;
              const markerHeight = multipleMarkersPresent ? 60 : 80;

              // Render important date
              if (canDrawImportantEvent) {
                const text = getImportantEventText(surveyAnswers);
                renderAdditionalMarker(
                  importantDateMonthIndex,
                  markerHeight,
                  text,
                  importantDateMonthIndex !== fivePercentMonthIndex
                );
              }

              // Render five percent lost marker
              if (canDrawFivePercent) {
                const text = "Lower Risk";
                renderAdditionalMarker(
                  fivePercentMonthIndex,
                  multipleMarkersPresent ? markerHeight + 20 : markerHeight,
                  text,
                  true
                );
              }
            });
          });
        },
      },
    },
  };

  if (graph) {
    graph.destroy();
  }
  if (!canvas) {
    return null;
  }

  canvas.setAttribute("data-noom-graph", "v2");
  return new Chart(canvas, config);
}

export default drawWeightGraph;
