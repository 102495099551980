export type UnitName =
  | "POUND"
  | "KILOGRAM"
  | "STONE"
  | "CENTIMETER"
  | "FEET"
  | "INCH";

export interface UnitType {
  id: UnitName;
  label: string;
  baseUnit?: UnitName;
  baseUnitRate?: number;
}

export enum UnitLabel {
  kilograms = "kg",
  pounds = "lb",
  stones = "st.",
  centimeter = "cm",
  feet = "ft.",
  inch = "in.",
  stonePounds = "lb.",
}

const Unit: Record<string, UnitType> = {
  POUND: {
    id: "POUND",
    label: "lb.",
  },
  KILOGRAM: {
    id: "KILOGRAM",
    label: "kg",
  },
  STONE: {
    id: "STONE",
    label: "st.",
    baseUnit: "POUND",
    baseUnitRate: 14,
  },
  CENTIMETER: {
    id: "CENTIMETER",
    label: "cm",
  },
  FEET: {
    id: "FEET",
    label: "ft.",
    baseUnit: "INCH",
    baseUnitRate: 12,
  },
  INCH: {
    id: "INCH",
    label: "in.",
  },
};
export default Unit;
