import {
  isAffiliateWithoutTrial,
  isCoreDirect,
  isCountry,
  isEnIntlBaseline,
  isUS,
} from "@utils/userSegment";
import { getCourseDuration } from "@utils/calculateGoals";
import getStore from "@utils/redux/store";

export function getKeepItOffLength() {
  const duration = getCourseDuration();
  if (
    isUS() ||
    (isCountry(["CA"]) && !isCoreDirect() && !isAffiliateWithoutTrial())
  ) {
    const { weight, idealWeight } = getStore().getState().surveyAnswers;
    const weightLossGoal = weight - idealWeight;
    if (
      duration === 2 ||
      duration === 3 ||
      duration === 4 ||
      (duration === 5 && weightLossGoal < 90)
    ) {
      return 2;
    }
  }
  if (isEnIntlBaseline() && [2, 4, 6, 8].includes(duration)) {
    return 2;
  }
  return 0;
}
