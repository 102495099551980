import styled from "@emotion/styled";
import React from "react";
import { fontTitle } from "src/design-system/styles/fonts";
import { flexMiddle } from "src/design-system/styles/layout";
import { compassColors } from "src/utils/styling";

type Props = { email?: string; className?: string };

const Circle = styled.div`
  ${flexMiddle};
  ${fontTitle};
  font-size: 16px;

  width: 32px;
  height: 32px;
  flex: 0;

  border-radius: 32px;
  background-color: ${compassColors.sand2};
  color: ${compassColors.white};
`;

export function Avatar({ email, className }: Props) {
  let firstLetter = "?";
  if (email) {
    firstLetter = email.trim().charAt(0).toUpperCase();
  }

  return <Circle className={className}>{firstLetter}</Circle>;
}
