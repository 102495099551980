import getStore from "src/utils/redux/store";
import {
  addUrlSearchParamsDelimiter,
  reroll,
  setParam,
  synchronizeUpid,
} from "./utils";
import { OffersParams } from "@pageDefinitions/goto/offers";
import { postMakeEligible } from "src/funnels/insurance-reimbursement/apiRequests";
import { setNoomSessionStorage } from "src/utils/noomSessionStorage";
import { SessionStorageKey } from "src/utils/constants";

const telehealthRoot = (
  additionalQueryParams?: Record<string, string | number | boolean>
) => {
  const store = getStore();
  const state = store.getState();
  const { userData } = state;

  const queryParams = new URLSearchParams(window.location.search);
  synchronizeUpid(queryParams, store);
  setParam(queryParams, "userId", userData.user_id);

  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const key in additionalQueryParams) {
    if (additionalQueryParams[key]) {
      setParam(queryParams, key, additionalQueryParams[key]);
    }
  }

  return `/telehealth/${addUrlSearchParamsDelimiter(queryParams)}`;
};

export default {
  async insurance(isTelehealthBuyflow: boolean) {
    const state = getStore().getState();

    if (isTelehealthBuyflow) {
      setNoomSessionStorage(SessionStorageKey.isTelehealthBuyflow, true);
      await postMakeEligible(state.userData.user_id);
    } else {
      setNoomSessionStorage(SessionStorageKey.isTelehealthBuyflow, false);
    }

    const additionalQueryParams = {
      noomPlanId: state.recommendedPlan.noom_plan_id,
    };
    reroll(`${telehealthRoot(additionalQueryParams)}#/insurance`);
  },

  patientIntake(params: OffersParams) {
    const additionalQueryParams = {
      claimedByEmail: params.claimedByEmail,
      promoCode: params.promoCode,
      enrollmentResult: params.enrollmentResult,
    };
    reroll(`${telehealthRoot(additionalQueryParams)}#/patientIntake`);
  },

  schedule() {
    reroll(`${telehealthRoot()}#/schedule`);
  },

  reschedule() {
    reroll(`${telehealthRoot()}#/reschedule`);
  },
};
