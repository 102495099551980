import { registerHandler } from "../handler";
import { getHealthyMindRevenueMetrics } from "../metrics";

declare const gtag: any;

export default function registerHandlers() {
  registerHandler("lead", () => {
    gtag("event", "conversion", {
      send_to: "AW-783925782/tDBSCM2Kq4sBEJaE5_UC",
      value: 1.0,
      currency: "USD",
    });
  });

  registerHandler("initiateCheckout", () => {
    gtag("event", "conversion", {
      send_to: "AW-783925782/OeaPCP-QuYsBEJaE5_UC",
      value: 1.0,
      currency: "USD",
    });
  });

  registerHandler("purchase", ({ order }) => {
    gtag("event", "conversion", {
      send_to: "AW-783925782/XdY_CJWPuYsBEJaE5_UC",
      value: order.erev_9_months,
      currency: "USD",
    });
  });

  registerHandler("HMLandingPageView", () => {
    gtag("event", "conversion", {
      send_to: "AW-783925782/v0hcCNvXmOwBEJaE5_UC",
    });
  });

  registerHandler("HMLead", () => {
    gtag("event", "conversion", {
      send_to: "AW-783925782/RxMYCOD3uuwBEJaE5_UC",
    });
  });

  registerHandler("HMPurchase", ({ order }) => {
    const { erev9 } = getHealthyMindRevenueMetrics(order);
    gtag("event", "conversion", {
      send_to: "AW-783925782/fCpkCLbkqOwBEJaE5_UC",
      valueErev9: erev9,
    });
  });
}
