import React, {
  ComponentProps,
  ComponentType,
  ReactNode,
  Suspense,
} from "react";
import lazy, { PreloadableComponent } from "react-lazy-with-preload";
import { loadServerContext } from "@utils/context";
import { Route } from "react-router";
import goto from "./pageDefinitions/goto";
import { useOnce } from "./hooks/lifecycle";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import { matchPath } from "react-router-dom";
import { AppInitGate } from "./AppInitGate";
import invariant from "tiny-invariant";
import { VerifyUser } from "./components/account/VerifyUser";

declare const PAGE_ROUTES: Record<
  /** routeToken */ string,
  /** modulePath */ string
>;

// Ensure that we aren't re-generating (and forcing unnecessary
// remount or async load) when navigating in the site.
const pageCache: Record<string, PreloadableComponent<any>> = {};
const componentCache: Record<string, ComponentType<any>> = {};

let isLoading = false;
export function isRouterLoading() {
  return isLoading;
}

function LoadingTracker({ children }: { children: ReactNode }) {
  useOnce(() => {
    isLoading = true;
    return () => {
      isLoading = false;
    };
  });

  return <>{children}</>;
}

function routeChunkLoader<T extends ComponentType<any>>(
  dynamicImport: () => Promise<{ default: T }>
) {
  return () =>
    dynamicImport().catch((err: Error) => {
      // If loading the baseline fails, then send the user to /error.
      goto.error(err, "lazy-load");
      throw err;
    });
}

function getPageComponent<T extends ComponentType<any>>(
  modulePath: string
): PreloadableComponent<T> {
  invariant(modulePath, "Module path must be defined");

  if (!pageCache[modulePath]) {
    pageCache[modulePath] = lazy(
      routeChunkLoader(() => {
        // eslint-disable-next-line import/dynamic-import-chunkname
        return import(
          /* webpackChunkName: "page/[request]", webpackExclude: /.*\.(stories|test)\..*$/ */ `./pages/${modulePath}`
        );
      })
    );
  }
  return pageCache[modulePath] as PreloadableComponent<T>;
}

/**
 * Get the lazy loaded component for the intended route. If it fails to do so,
 * reset to the baseline and track error.
 */
export function getLazyComponent<T extends ComponentType<any>>(
  dynamicImport: () => Promise<{ default: T }>
): T {
  const LazyLoadedComponent = lazy(routeChunkLoader(dynamicImport));

  return getLazyComponentWrapper(LazyLoadedComponent);
}

function getLazyComponentWrapper<T extends ComponentType<any>>(
  LazyLoadedComponent: T
): T {
  return (({
    fallback = <div />,
    ...props
  }: ComponentProps<T> & { fallback: ReactNode }) => {
    useOnce(() => {
      // Ensure that we reload the server context on navigation
      loadServerContext();
    });

    return (
      <Suspense fallback={<LoadingTracker>{fallback}</LoadingTracker>}>
        <AppInitGate>
          <VerifyUser>
            <ScrollToTop />

            <LazyLoadedComponent {...(props as ComponentProps<T>)} />
          </VerifyUser>
        </AppInitGate>
      </Suspense>
    );
  }) as T;
}

export function getRouterComponents({
  routes = PAGE_ROUTES,
  fallback,
}: {
  routes?: Record<string, string>;
  fallback?: ReactNode;
} = {}) {
  return Object.keys(routes).map((path) => {
    const modulePath = routes[path];

    if (!componentCache[modulePath]) {
      componentCache[modulePath] = getLazyComponentWrapper(
        getPageComponent(modulePath)
      );
    }

    const Component = componentCache[modulePath];
    return (
      <Route
        key={path}
        exact
        path={`/${path}`}
        render={(props) => <Component fallback={fallback} {...props} />}
      />
    );
  });
}

export function preloadPagePath(
  pathname: string,
  routes = PAGE_ROUTES
): Promise<void> {
  const matchingRoute = Object.keys(routes).find((key) =>
    matchPath(`/${pathname}`, { path: `/${key}`, exact: true })
  );
  const matchingPath = routes[matchingRoute];
  if (matchingPath) {
    return getPageComponent(matchingPath)
      .preload()
      .catch(() => {
        // NOP: Will be reported if this is a user face error later
      });
  }
  return Promise.resolve();
}
