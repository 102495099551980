import { createAsyncThunk } from "@reduxjs/toolkit";
import { trackEvent } from "src/utils/api/tracker";
import { getOptimizelyExperiments } from "src/utils/experiment";
import { send } from "src/utils/fetch";
import { OptimizelyExperiment } from "src/utils/services/OptimizelySXExperiment";
import { CoreReduxState } from "../redux/store";

export interface FastForwardTrialRequest {
  noomPlanId: string;
  braintreeId: string;
  upid: string;
  trialFee: number;
  language: string;
  experiments: OptimizelyExperiment[];
}

interface FastForwardTrialResponse {
  upid: string;
}

const FAST_FORWARD_TRIAL_URL = "/api/payment/v3/fast_forward_trial/";

export const requestFastForwardTrial = createAsyncThunk<
  FastForwardTrialResponse,
  { discountOffered: boolean },
  {
    state: CoreReduxState;
  }
>(
  "fastForwardTrial/request",
  async (params: { discountOffered: boolean }, thunkApi) => {
    const { discountOffered } = params;
    const planDiscountApplied = discountOffered ? "Yes" : "No";
    const { serverContext, recommendedPlan, language } = thunkApi.getState();

    const noomPlanId = discountOffered
      ? recommendedPlan.noom_plan_id
      : serverContext.minimal_discount_noom_plan_id;
    const braintreeId = discountOffered
      ? serverContext.fast_forward_trial_plan.braintree_id
      : serverContext.minimal_discount_fast_forward_trial_plan.braintree_id;
    const request: FastForwardTrialRequest = {
      noomPlanId,
      braintreeId,
      upid: serverContext.upid,
      trialFee: recommendedPlan.trial_fee,
      language,
      experiments: getOptimizelyExperiments(),
    };

    try {
      const response = await send<FastForwardTrialResponse>(
        "POST",
        FAST_FORWARD_TRIAL_URL,
        request
      );
      trackEvent("SkipTrialAccepted", { planDiscountApplied });
      return response;
    } catch (e) {
      const errorType = e.response?.data?.errorType;
      trackEvent("FailedToFastForwardTrial", {
        error: errorType,
        result: errorType,
        request,
        planDiscountApplied,
      });
      throw e;
    }
  }
);
