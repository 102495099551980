import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Payment type constants
 */
export enum paymentTypes {
  CREDIT_CARD = "CREDIT_CARD",
  PAYPAL = "PAYPAL",
}

export interface CheckoutState {
  zipcode: string;
  salesTaxAmount: number;
  salesTaxState: string;
  salesTaxRate: number;
  paypalAccount: string;
  paymentType: paymentTypes;
  purchaseError: string | Record<string, string>;
  purchaseErrorCode: string;
  processing: boolean;
  goalMonth: string;
  // TODO(patrick): Move these modal states out of redux and into the component states if possible.
  madeTrialPriceChoice: boolean;
  showFairTrialDisclaimer: boolean;
  shouldShowExitIntentModal: boolean;
  showExitIntentModal: boolean;
  isTimerExpired: boolean;
  isTaxInclusive: boolean;
  walletPay: boolean;
  applePay: boolean;
  googlePay: boolean;
  paymentRequest: any; // react-stripe-elements object

  billingName?: string;
  billingNameChangeMade?: boolean;
  billingAddress?: {
    country: string;
    city: string;
    region: string;
    address1: string;
    address2: string;
    zipcode: string;
  };
  billingAddressErrors?: {
    COUNTRY: boolean;
    CITY: boolean;
    REGION: boolean;
    ADDRESS1: boolean;
    ZIPCODE: boolean;
  };

  showEnrollmentErrors?: boolean;
  stripeError?: Record<string, boolean | string>;

  userStartedEnteringPaymentInfo: boolean;
}

const initialState: CheckoutState = {
  zipcode: "",
  salesTaxAmount: 0,
  salesTaxState: "",
  salesTaxRate: 0,
  paypalAccount: "",
  paymentType: paymentTypes.CREDIT_CARD,
  purchaseError: "",
  purchaseErrorCode: "",
  processing: false,
  goalMonth: "",
  madeTrialPriceChoice: false,
  showFairTrialDisclaimer: false,
  shouldShowExitIntentModal: false,
  showExitIntentModal: false,
  isTimerExpired: false,
  isTaxInclusive: false,
  walletPay: false,
  applePay: false,
  googlePay: false,
  paymentRequest: null,
  userStartedEnteringPaymentInfo: false,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    updateCheckoutState(state, action: PayloadAction<Partial<CheckoutState>>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateCheckoutState } = checkoutSlice.actions;

export default checkoutSlice;
