import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, GetAppState } from "../store";
import { captureException } from "src/utils/sentry";
import { send } from "src/utils/fetch";
import { AxiosError } from "axios";
import { trackEvent } from "src/utils/api/tracker";

export type WinbackInfoState = {
  winback_status?: "NONE" | "TRIAL" | "ADVANCED";
  will_reup?: boolean;
};

const initialState: WinbackInfoState = {
  winback_status: "NONE",
  will_reup: false,
};

const winbackInfoSlice = createSlice({
  name: "winbackInfo",
  initialState,
  reducers: {
    updateWinbackInfo(state, action: PayloadAction<WinbackInfoState>) {
      return { ...state, ...action.payload };
    },
  },
});

const { updateWinbackInfo } = winbackInfoSlice.actions;

let pendingLoad: Promise<void | WinbackInfoState>;

export function loadWinbackInfo() {
  return (dispatch: AppDispatch, getState: GetAppState) => {
    if (pendingLoad) {
      return pendingLoad;
    }

    // eslint-disable-next-line no-console
    console.log("Updating winback info");
    const { language, geoLocation, recommendedPlan } = getState();

    // NOTE(norbert): From the backend it seems that the consumer batch symbol is tied to the curriculum that a plan has.
    // e.g. So if a user is recommended a HW plan, we should compare winback eligibility against a HW batch symbol
    const consumerBatchSymbol = recommendedPlan?.curriculum || "HW";

    const queryParams = new URLSearchParams({
      consumerBatchSymbol,
      language,
      country: geoLocation.country_code,
    });
    pendingLoad = send("GET", `/visitor/api/v1/winbackState?${queryParams}`)
      .then(({ winback_status, will_reup }) => {
        trackEvent("WinbackInfoFetched", {
          winback_status,
          will_reup,
        });
        dispatch(
          updateWinbackInfo({
            winback_status,
            will_reup,
          })
        );
      })
      .catch((e: AxiosError) => {
        if (e?.response?.status === 404) {
          // 404 is a successful response for new users
          trackEvent("WinbackInfoNotFound");
        } else {
          // Hard error here. Allow for retry on future call.
          pendingLoad = null;
          captureException(e, "updateWinbackInfo");
        }
      })
      .then(() => getState().winbackInfo);

    return pendingLoad;
  };
}

export default winbackInfoSlice;
