import { send } from "@utils/fetch";
import saveToBraze from "src/utils/brazeUploader";
import { captureException } from "src/utils/sentry";

export function getAndMaybeSavePendingDoubleOptInStatus(user_id: string) {
  return send(
    "GET",
    `/userdata/api/v4/user/gdpr/getDoubleOptInStatus/?clientUserId=${user_id}`
  )
    .then((json) => {
      const { status } = json;
      // If user status is null or pending set their double opt in status to pending and inform Braze
      if (!status || status === "PENDING") {
        return send(
          "POST",
          `/userdata/api/v4/user/gdpr/setDoubleOptInStatus/`,
          {
            clientUserId: user_id,
            status: "PENDING",
          }
        ).then(() => {
          saveToBraze(user_id, { pendingDoubleOptIn: true }, null);
        });
      }
      return Promise.resolve();
    })
    .catch((error) => {
      captureException(error, "GDPRDoubleOptIn");
    });
}
