import { onQuestionAnsweredConsultationSurvey } from "@pageDefinitions/actions/survey/onQuestionAnsweredConsultationSurvey";
import { onCompletePaymentSurvey } from "@pageDefinitions/actions/survey/onCompletePaymentSurvey";
import { onStartPaymentSurvey } from "@pageDefinitions/actions/survey/onStartPaymentSurvey";
import { onStartMainSurvey } from "@pageDefinitions/actions/survey/onStartMainSurvey";
import { onCompleteMainSurvey } from "@pageDefinitions/actions/survey/onCompleteMainSurvey";
import { mixpanelTrackAction } from "@pageDefinitions/actions/tracking";

import { GoToPageOptions } from "../goto/pageSet";
import { PageList, PageSet } from "../types";

export type ActionHandlerParams = {
  params: JsonObject;
  pageSet: PageSet;
  page: PageList[0];
} & GoToPageOptions;

export type ActionHandler = (params: ActionHandlerParams) => void;

const ACTIONS = {
  TRACK: mixpanelTrackAction,

  // Main Survey
  ON_START_MAIN_SURVEY: onStartMainSurvey,
  ON_COMPLETE_MAIN_SURVEY: onCompleteMainSurvey,

  // Payment Survey
  ON_START_PAYMENT_SURVEY: onStartPaymentSurvey,
  ON_COMPLETE_PAYMENT_SURVEY: onCompletePaymentSurvey,

  // Consultation Survey (Noom Premium)
  ON_QUESTION_ANSWERED_CONSULTATION_SURVEY:
    onQuestionAnsweredConsultationSurvey,
};

export default ACTIONS;
