import { captureException } from "./sentry";
import invariant from "tiny-invariant";
import { getLocale } from "./meristemContext";
import { Plan } from "./redux/slices/plans";

/**
 * Given a discounted price and a integral price, calculates the discount in percentage
 * @returns percent 0-100
 */
export function calculateDiscount(
  discountedPrice: number,
  integralPrice: number
) {
  return Math.round(100 - (discountedPrice / integralPrice) * 100);
}

/**
 * Given a price and a discount, computes the price
 * @param discount - percent 0-100
 */
export function priceAfterDiscount(price: number, discount: number) {
  invariant(
    discount >= 0 && discount <= 100,
    "discount must be between 0 and 100"
  );
  return (price * (100 - discount)) / 100;
}

/**
 * Given a price, display it with two digits but no trailing zeros
 * Example: priceTwoDigitsNoTrailingZeros(1.23) === 1.23, and price priceTwoDigitsNoTrailingZeros(1.00) === 1
 */
export function priceTwoDigitsNoTrailingZeros(price: number) {
  return parseFloat(price.toFixed(2));
}

/**
 * Formats price with given currency symbol.
 */
export function formatPrice(
  rawPrice: number | string,
  currency: string,
  showTrailingZeroes?: boolean
) {
  const castNumber = Number(rawPrice);

  // Show decimals in price if they exist or showTrailingZeroes is enabled
  const hasDecimals = castNumber % 1 !== 0;
  const fractionDigits = showTrailingZeroes || hasDecimals ? 2 : 0;

  // Locale required to know when to display '123,00 €' or '€123.00'
  return new Intl.NumberFormat(getLocale(), {
    style: "currency",
    currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(castNumber);
}

/**
 * Formats price with localized currency symbol.
 */
export function formatPriceWithCurrencySymbol(
  rawPrice: string | number,
  plan: Partial<Plan>,
  showTrailingZeroes?: boolean
) {
  if (rawPrice == null) return "";

  // Dangerous assumption to fallback to USD, but better than a catastrophic failure
  let { currency } = plan;
  if (!currency) {
    currency = "USD";
    captureException(
      new Error("Unable to derive currency from plan"),
      "formatPriceWithCurrencySymbol"
    );
  }

  return formatPrice(rawPrice, currency, showTrailingZeroes);
}
