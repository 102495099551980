import { SurveyAnswersState } from "../redux/slices/surveyAnswers";
import { RecommendedPlanState } from "../redux/slices/recommendedPlan";
import { UserDataState } from "../redux/slices/userData";
import AmazonHandlers from "./publishers/amazon";
import FacebookHandlers from "./publishers/facebook";
import JumbleberryHandlers from "./publishers/jumbleberry";
import TestPixelHandlers from "./publishers/testPixel";
import AdsWizzHandlers from "./publishers/adswizz";
import PodsightsHandlers from "./publishers/podsights";
import SnapchatHandlers from "./publishers/snapchat";
import LiveIntentHandlers from "./publishers/liveIntent";
import ImpactHandlers from "./publishers/impact";
import GoogleAdwordsHandlers from "./publishers/adwords";
import ArtsaiHandlers from "./publishers/artsai";
import BingHandlers from "./publishers/bing";
import PinterestHandlers from "./publishers/pinterest";
import HealthlineHandlers from "./publishers/healthline";
import UKTVHandlers from "./publishers/uktv";

import {
  fireCallbackWithRetry,
  PIXEL_RETRY_DELAY_MS,
  PIXEL_RETRY_MAX_ATTEMPTS,
} from "./utils";
import { typedKeys } from "../typeWrappers";

type InitCheckoutArgs = {
  plan: RecommendedPlanState;
  surveyAnswers: SurveyAnswersState;
};

type LeadArgs = {
  email: string;
  name: string;
  surveyAnswers?: SurveyAnswersState;
};

type PurchaseArgs = {
  email: string;
  plan: RecommendedPlanState;
  surveyAnswers: SurveyAnswersState;
  order: JsonObject;
  userData: UserDataState;
};

export type HandlerCallback<T> = (args: T) => void;

export interface HandlerOptions {
  retry?: boolean;
}

const events = {
  init: [] as HandlerCallback<unknown>[],
  lead: [] as HandlerCallback<LeadArgs>[],
  initiateCheckout: [] as HandlerCallback<InitCheckoutArgs>[],
  purchase: [] as HandlerCallback<PurchaseArgs>[],
  viewContent: [] as HandlerCallback<JsonObject>[],
  HMLead: [] as HandlerCallback<LeadArgs>[],
  HMInitiateCheckout: [] as HandlerCallback<InitCheckoutArgs>[],
  HMPurchase: [] as HandlerCallback<PurchaseArgs>[],
  HMLandingPageView: [] as HandlerCallback<unknown>[],
};

export type EVENTS = keyof typeof events;

/**
 * Fire a conversion pixel event and pass in necessary props.
 * */
export async function firePixelEvent<T extends EVENTS>(
  event: T,
  props: Parameters<typeof events[T][0]>[0]
): Promise<void> {
  const handlers = events[event];
  const promises = handlers.map((handler) => handler(props));
  await Promise.all(promises);
}

/**
 * Register a conversion pixel event handler.
 * */
export function registerHandler<T extends EVENTS>(
  event: T,
  callback: typeof events[T][0],
  options: HandlerOptions = {}
) {
  // Retry is on by default. Need to explicitly pass in false to turn off.
  const maxAttempts = options?.retry === false ? 1 : PIXEL_RETRY_MAX_ATTEMPTS;
  function wrappedCallback(
    args: Parameters<typeof callback>[0]
  ): Promise<void> {
    return fireCallbackWithRetry(
      callback,
      args,
      PIXEL_RETRY_DELAY_MS,
      maxAttempts
    );
  }
  events[event].push(wrappedCallback);
}

/**
 * Util for unit tests -- unlikely you would ever want to call this in production code
 */
export function clearAllHandlers() {
  typedKeys(events).forEach((key) => {
    events[key] = [];
  });
}

export default function initializeHandlers() {
  AmazonHandlers();
  FacebookHandlers();
  PinterestHandlers();
  GoogleAdwordsHandlers();
  BingHandlers();
  SnapchatHandlers();
  UKTVHandlers();
  ArtsaiHandlers();
  ImpactHandlers();
  LiveIntentHandlers();
  HealthlineHandlers();
  JumbleberryHandlers();
  PodsightsHandlers();
  AdsWizzHandlers();
  TestPixelHandlers();
}
