import Cookies from "js-cookie";
import getStore from "@utils/redux/store";
import { getURLParams } from "@utils/urlParams";
import { trackEvent } from "@utils/api/tracker";
import { fireFacebookCapi } from "@utils/api/FacebookCapiApi";
import { generateEventId } from "@utils/pixels/utils";
import { shouldAdjustELTV, adjustELTV, extractAdName } from "../eltvAdjustment";
import { registerHandler } from "../handler";
import { SurveyAnswersState } from "src/utils/redux/slices/surveyAnswers";
import { getHealthyMindRevenueMetrics } from "../metrics";
import { getMeristemContext } from "src/utils/meristemContext";

declare const fbq: any;

interface FacebookAdvancedMatchingParams {
  em?: string;
  fn?: string;
  ln?: string;
  email?: string;
  external_id?: string;
}

interface FacebookCustomLeadParams {
  [key: string]: any;

  weight?: number;
  idealWeight?: number;
  weightKg?: number;
  idealWeightKg?: number;
  weightLossGoal?: number;
  weightLossGoalKg?: number;
}

export interface FacebookPixelParameters {
  order_id?: string;
  external_id?: string;
  content_category?: string;
  content_ids?: string;
  content_type?: string;
  value?: number;
  currency?: string;
  product?: string;
}

const FB_EREV9_PIXEL = "1204952429523059";
const FB_ELTV13_PIXEL = "1557275494440599";
const FB_HM_PIXEL = "373833553789964";

function getCustomParams(surveyAnswers?: SurveyAnswersState) {
  // NOTE(di): We would like to parse and send all survey answers to Facebook.
  const params: FacebookCustomLeadParams = {};

  // NOTE: Facebook has flagged that we shouldn’t be passing the following parameters from the buyflow.
  const notAllowedParamList = [
    "gender",
    "antibiotic",
    "weight",
    "weightKg",
    "pregnant",
    "email",
    "currentHealthRisk",
    "lifeEvent",
    "lifeEventMale",
    "currentHealthRiskMale",
  ];

  if (surveyAnswers) {
    Object.entries(surveyAnswers).forEach(([key, value]) => {
      if (notAllowedParamList.includes(key)) return;
      if (Array.isArray(value)) {
        params[key] = value.join(",");
      } else {
        params[key] = value;
      }
    });
  }

  // NOTE(di): Since Facebook doesn't allow advance calculation on passed values, we pre-calculate some key values here.
  if (params.weight && params.idealWeight) {
    params.weightLossGoal = params.weight - params.idealWeight;
  } else if (params.weightKg && params.idealWeightKg) {
    const weightLossGoalKg = params.weightKg - params.idealWeightKg;
    params.weightLossGoal = Math.round(weightLossGoalKg * 2.2046);
  } else if (params.weightLossGoalKg) {
    params.weightLossGoal = Math.round(params.weightLossGoalKg * 2.2046);
  }

  return params;
}

// NOTE(patrick): I don't like having to cache the URL parameters like this but
// the original conversion tracker caches it in the singleton. It was probably
// due to the url parameters not being successfully passed along from landing to
// payment. Preserve this for now but It would be great to just pull the URL params
// when we need it directly from the URL.
let cachedURLParams = {};

/**
 * Enables the advance matching feature for Facebook Pixel.
 */
function initAdvancedMatching(email: string, name: string) {
  const { userData } = getStore().getState();
  const advancedMatchingParams: FacebookAdvancedMatchingParams = {
    ...(userData?.user_id && { external_id: userData.user_id }),
  };
  // NOTE(patrick): We don't collect name until the payment page so this block
  // will probably never get evaluated.
  if (email && name) {
    advancedMatchingParams.em = email.toLowerCase();

    const nameParts = name.trim().split(" ");
    [advancedMatchingParams.fn] = nameParts;
    if (nameParts.length > 1) {
      advancedMatchingParams.ln = nameParts[nameParts.length - 1];
    }
  }

  // NOTE(Patrick): We already do this in the `init` handler but the logic
  // in ConversionTracker fires it twice so leaving it in.
  // Check to add LDU flag for CCPA opt out.
  if (Cookies.get("ccpaOptOut")) {
    fbq("dataProcessingOptions", ["LDU"], 1, 1000);
  }

  fbq("init", FB_EREV9_PIXEL, advancedMatchingParams);
  fbq("init", FB_ELTV13_PIXEL, advancedMatchingParams);
  fbq("init", FB_HM_PIXEL, advancedMatchingParams);
}

export default function registerHandlers() {
  registerHandler("init", () => {
    // Check to add LDU flag for CCPA opt out.
    if (Cookies.get("ccpaOptOut")) {
      fbq("dataProcessingOptions", ["LDU"], 1, 1000);
    }

    fbq("init", FB_EREV9_PIXEL);
    fbq("init", FB_ELTV13_PIXEL);
    fbq("init", FB_HM_PIXEL);

    // We are generating 2 different custom IDs. One for ELTV13 Pixel, and one for the HM Pixel
    const eventIDEltv13Payload = { eventID: generateEventId() };
    const eventIDHMPayload = { eventID: generateEventId() };

    fbq("trackSingle", FB_ELTV13_PIXEL, "PageView", {}, eventIDEltv13Payload);

    fbq("trackSingle", FB_HM_PIXEL, "PageView", {}, eventIDHMPayload);
    fbq("trackSingle", FB_EREV9_PIXEL, "PageView");

    fireFacebookCapi(FB_ELTV13_PIXEL, "PageView", eventIDEltv13Payload);
    fireFacebookCapi(FB_HM_PIXEL, "PageView", eventIDHMPayload);

    // Cache the URL Params on page load to preserve tracking params.
    cachedURLParams = getURLParams();
  });

  registerHandler("viewContent", (params) => {
    fbq("trackSingle", FB_EREV9_PIXEL, "ViewContent", params);
    fbq("trackSingle", FB_ELTV13_PIXEL, "ViewContent", params);
  });

  registerHandler("lead", ({ email, name, surveyAnswers }) => {
    // Enables the advance matching feature for Facebook Pixel.
    const { userData } = getStore().getState();
    const eventID = generateEventId(userData.user_id);

    initAdvancedMatching(email, name);

    // Handle tracking the Lead event.
    const trackLeadParams = getCustomParams(surveyAnswers);
    const weightLossGoal = surveyAnswers?.weightLossGoal;
    const fbParams: JsonObject = {
      ...(weightLossGoal && { value: weightLossGoal }),
      ...trackLeadParams,
      ...cachedURLParams,
    };

    fbq("trackSingle", FB_ELTV13_PIXEL, "Lead", fbParams, { eventID });
    fbq("trackSingle", FB_EREV9_PIXEL, "Lead", fbParams);

    fireFacebookCapi(FB_ELTV13_PIXEL, "Lead", {
      customProperties: {
        ...trackLeadParams,
        ...cachedURLParams,
      },
      ...(weightLossGoal && { value: weightLossGoal }),
      external_id: userData.user_id,
      eventID,
      email,
    });
  });

  registerHandler("HMLead", ({ email, name, surveyAnswers }) => {
    const { userData } = getStore().getState();
    const eventID = generateEventId(userData.user_id);

    initAdvancedMatching(email, name);

    const trackLeadParams = getCustomParams(surveyAnswers);
    const fbParams: JsonObject = {
      product: "hm",
      ...trackLeadParams,
      ...cachedURLParams,
    };

    fbq("trackSingle", FB_ELTV13_PIXEL, "Lead", fbParams, { eventID });
    fbq("trackSingle", FB_EREV9_PIXEL, "Lead", fbParams);
    fbq("trackSingle", FB_HM_PIXEL, "Lead", fbParams, { eventID });

    fireFacebookCapi(FB_HM_PIXEL, "Lead", {
      ...fbParams,
      eventID,
      email,
      customProperties: fbParams,
    });
  });

  registerHandler("initiateCheckout", ({ plan, surveyAnswers }) => {
    const eventID = generateEventId();
    const fbParams: FacebookPixelParameters = {
      value: plan.lv,
      currency: plan.currency ? plan.currency : "USD",
      content_ids: plan.braintree_id,
      content_type: "product",
      content_category: "recurring",
    };
    const customProperties = {
      ...getCustomParams(surveyAnswers),
      ...cachedURLParams,
    };
    const clientFbParams = { ...fbParams, ...customProperties };

    fbq("trackSingle", FB_ELTV13_PIXEL, "InitiateCheckout", clientFbParams, {
      eventID,
    });
    fbq("trackSingle", FB_EREV9_PIXEL, "InitiateCheckout", clientFbParams);

    fireFacebookCapi(FB_ELTV13_PIXEL, "InitiateCheckout", {
      ...fbParams,
      customProperties,
      eventID,
    });
  });

  registerHandler("purchase", ({ email, order, plan, surveyAnswers }) => {
    const { userData, geoLocation, paymentEnrollmentForm } =
      getStore().getState();

    const phone =
      paymentEnrollmentForm?.enrollmentInfo?.phoneNumber || undefined;
    const customProperties = {
      ...getCustomParams(surveyAnswers),
      ...cachedURLParams,
    };

    const pixelProperties: FacebookPixelParameters = {
      value: order.erev_9_months,
      currency: "USD",
      content_ids: plan.braintree_id,
      content_type: "product",
      content_category: "recurring",
      external_id: userData.user_id,
      ...(order.subscriptionId && { order_id: order.subscriptionId }),
      ...customProperties,
    };

    // NOTE(di): For FB algorithm optimization, we need to track both
    // Purchase and StartTrial events with identical properties.
    fbq("trackSingle", FB_EREV9_PIXEL, "StartTrial", pixelProperties);
    fbq("trackSingle", FB_ELTV13_PIXEL, "StartTrial", pixelProperties);

    const eltv13Value = shouldAdjustELTV()
      ? adjustELTV(order.eltv_13_months, geoLocation.country_code)
      : order.eltv_13_months;

    if (eltv13Value !== order.eltv_13_months) {
      trackEvent("AdjustedELTV13", {
        originalValue: order.eltv_13_months,
        adjustedValue: eltv13Value,
        adName: extractAdName(),
      });
    }

    fbq("trackSingle", FB_EREV9_PIXEL, "Purchase", pixelProperties);

    const eventID = generateEventId(userData.user_id);
    const fbParamsELTV13: FacebookPixelParameters = {
      ...pixelProperties,
      value: eltv13Value,
    };
    fbq("trackSingle", FB_ELTV13_PIXEL, "Purchase", fbParamsELTV13, {
      eventID,
    });
    fireFacebookCapi(FB_ELTV13_PIXEL, "Purchase", {
      ...fbParamsELTV13,
      customProperties,
      eventID,
      email,
      phone,
    });
  });

  registerHandler("HMPurchase", ({ email, order, plan, surveyAnswers }) => {
    const { erev9, eltv13 } = getHealthyMindRevenueMetrics(order);
    const { userData } = getStore().getState();
    const customProperties = {
      ...getCustomParams(surveyAnswers),
      ...cachedURLParams,
      product: "hm",
      device_os: getMeristemContext().device_os,
    };

    const pixelProperties: FacebookPixelParameters = {
      value: erev9,
      currency: "USD",
      content_ids: plan.braintree_id,
      content_type: "product",
      content_category: "recurring",
      external_id: userData.user_id,
      ...(order.subscriptionId && { order_id: order.subscriptionId }),
      ...customProperties,
    };

    const eventID = generateEventId(userData.user_id);
    const fbParamsELTV13: FacebookPixelParameters = {
      ...pixelProperties,
      value: eltv13,
    };

    // NOTE(di): For FB algorithm optimization, we need to track both Purchase and StartTrial events with identical properties.
    fbq("trackSingle", FB_EREV9_PIXEL, "StartTrial", pixelProperties);
    fbq("trackSingle", FB_ELTV13_PIXEL, "StartTrial", pixelProperties);
    fbq("trackSingle", FB_HM_PIXEL, "StartTrial", pixelProperties);

    fbq("trackSingle", FB_EREV9_PIXEL, "Purchase", pixelProperties);
    fbq("trackSingle", FB_ELTV13_PIXEL, "Purchase", fbParamsELTV13, {
      eventID,
    });

    fbq("trackSingle", FB_HM_PIXEL, "Purchase", pixelProperties, {
      eventID,
    });

    fireFacebookCapi(FB_HM_PIXEL, "Purchase", {
      ...pixelProperties,
      customProperties,
      eventID,
      email,
    });
  });

  registerHandler("HMInitiateCheckout", ({ plan, surveyAnswers }) => {
    const eventID = generateEventId();

    const params = getCustomParams(surveyAnswers);
    const fbParams: FacebookPixelParameters = {
      value: plan.lv,
      currency: plan.currency ? plan.currency : "USD",
      content_ids: plan.braintree_id,
      content_type: "product",
      content_category: "recurring",
      product: "hm",
    };
    const customProperties = { ...params, ...cachedURLParams };

    Object.assign(fbParams, customProperties);

    fbq("trackSingle", FB_ELTV13_PIXEL, "InitiateCheckout", fbParams);
    fbq("trackSingle", FB_EREV9_PIXEL, "InitiateCheckout", fbParams);
    fbq("trackSingle", FB_HM_PIXEL, "InitiateCheckout", fbParams, {
      eventID,
    });

    fireFacebookCapi(FB_HM_PIXEL, "InitiateCheckout", {
      ...fbParams,
      eventID,
      customProperties,
    });
  });
}
