import React, { ChangeEvent, ReactNode } from "react";
import DatePicker from "react-datepicker";
import i18n from "@i18n";
import { addMonths, format } from "@utils/datetime";
import { recommendPlan } from "@utils/calculateGoals";
import { trackEvent } from "@utils/api/tracker";
import RadioWithInputOther, {
  RadioWithInputOtherQuestionDefinition,
} from "../shared-inputs/RadioWithInputOther";
import Question from "../../../question-types/Question";

import styles from "./Dates.module.less";
import "react-datepicker/dist/react-datepicker.css";
import {
  SurveyAnswer,
  SurveyAnswersState,
} from "src/utils/redux/slices/surveyAnswers";
import {
  CustomQuestionDefinition,
  QuestionProps,
  registerQuestion,
} from "src/components/refactored-survey/QuestionDefinition";
import {
  question,
  questionTitle,
  questionOptions,
} from "@components/refactored-survey/question-types/Question.module.less";
import {
  questionInput,
  inputWithUnit,
  unitLabel,
  valid,
} from "@components/refactored-survey/question-types/InputQuestion.module.less";
import Calendar from "./images/calendar.svg";
import { isMobile } from "src/utils/userSegment";

const DATE_FORMAT = "{MMMM} {DD} {YYYY}";

type DateQuestionDefinition = RadioWithInputOtherQuestionDefinition & {
  question: (surveyAnswer: SurveyAnswer) => ReactNode;
};

class ImportantDateDetails extends RadioWithInputOther<DateQuestionDefinition> {
  override handleClick(e) {
    const ans: SurveyAnswer = {};

    ans[this.props.question.id] = [e.target.value];
    const answerIsValid = this.validateAnswer(ans);
    ans.importantDateSubDetails = undefined;
    ans.importantDate = e.target.value === "none" ? ["no"] : ["yes"];
    this.props.registerAnswer(ans, answerIsValid);
  }

  override handleOtherInputChange(e: ChangeEvent<HTMLInputElement>) {
    super.handleOtherInputChange(e);

    const { value } = e.target;
    this.setState({ otherInput: value });
    const ans = { [this.props.question.id]: [this.otherFieldId, value] };
    const isValid = this.validateAnswer(ans);

    ans.importantDateSubDetails = [value];
    ans.importantDate = ["yes"];
    this.props.registerAnswer(ans, isValid);
  }
}

export function getImportantEventText(surveyAnswers: SurveyAnswersState) {
  if (!surveyAnswers.importantDateDetails) {
    return "Event";
  }
  const mapping: Record<string, string> = {
    vacation: "Vacation",
    wedding: "Wedding",
    holiday: "Holiday",
    summer: "Summer",
    reunion: "Reunion",
    birthday: "Birthday",
  };
  return mapping[surveyAnswers.importantDateDetails[0]] || "Event";
}

export function getImportantEventTextLong(surveyAnswers: SurveyAnswersState) {
  const mapping: Record<string, string> = {
    vacation: "the vacation",
    wedding: "the wedding",
    holiday: "the holiday",
    summer: "the summer",
    reunion: "the reunion",
    birthday: "the birthday",
  };
  return (
    mapping[surveyAnswers.importantDateDetails?.[0]] || "your important event"
  );
}

export const Q_IMPORTANT_DATE_DETAILS =
  registerQuestion<RadioWithInputOtherQuestionDefinition>({
    id: "importantDateDetails",
    type: "custom",
    component: ImportantDateDetails,
    question: () => {
      return (
        <>
          <h1 className={questionTitle}>
            Having something to look forward to can be a big motivator to stick
            to your plan.
          </h1>
          <h2 className={questionTitle}>
            Do you have an important event coming up?
          </h2>
        </>
      );
    },
    options: [
      {
        id: "vacation",
        text: "Vacation",
      },
      {
        id: "wedding",
        text: "Wedding",
      },
      {
        id: "holiday",
        text: "Holiday",
      },
      {
        id: "summer",
        text: "Summer",
      },
      {
        id: "reunion",
        text: "Reunion",
      },
      {
        id: "birthday",
        text: "Birthday",
      },
    ],
    belowOtherOptions: [
      {
        id: "none",
        text: "No, I don't have an event",
      },
    ],
    section: 1,
  });

interface CalendarContainerProps {
  className?: string;
  children: ReactNode;
}

// Centers the desktop date picker pop up with the input.
const CalendarContainer = ({ className, children }: CalendarContainerProps) => (
  <div className={styles.calendarContainer}>
    <div className={className}>{children}</div>
  </div>
);

export class DatePickerQuestion extends Question<CustomQuestionDefinition> {
  constructor(props: QuestionProps) {
    super(props);

    const storeDate = this.props.surveyAnswers?.importantDateTime;
    this.state = {
      date: storeDate ? new Date(storeDate) : null,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  override onMount() {
    // Hack to disable keyboard pop up on mobile when user clicks on date field
    // https://stackoverflow.com/questions/48805053/hide-native-keyboard-on-mobile-when-using-react-date-picker
    const datePickers = document.getElementsByClassName(
      "react-datepicker__input-container"
    );
    Array.from(datePickers).forEach((el) => {
      if (el.childNodes[0]) {
        (el.childNodes[0] as HTMLElement).setAttribute("readOnly", "readOnly");
      }
    });
  }

  onDateChange(date: Date) {
    this.setState({ date }, () => {
      const { recommendedPlanDuration } = recommendPlan();
      const planEndDate = addMonths(new Date(), recommendedPlanDuration);
      trackEvent("ImportantDateComparedToPlanDate", {
        date: date > planEndDate ? "after" : "before",
      });

      const formattedDate = date ? format(DATE_FORMAT, new Date(date)) : null;
      this.props.registerAnswer({ importantDateTime: formattedDate }, !!date);
    });
  }

  override validateAnswer() {
    return !!this.state.date;
  }

  handleSkip() {
    trackEvent("SkippedImportantDateTime");
    this.props
      .registerAnswer({ importantDateTime: null }, true)
      .then(this.props.onClickNext);
  }

  override renderControls() {
    return (
      <>
        {super.renderControls()}
        <button
          className={styles.datePickerSkip}
          onClick={this.handleSkip}
          type="button"
        >
          Skip to next question &gt;&gt;
        </button>
      </>
    );
  }

  override renderWithControls() {
    return (
      <div
        data-cy="question"
        data-cy-question={this.props.question.id}
        className={question}
      >
        {this.renderQuestion()}
        <div
          className={`${questionOptions} ${inputWithUnit} ${styles.dateWrapper}`}
        >
          <DatePicker
            value={this.state.date}
            selected={this.state.date}
            onChange={this.onDateChange}
            dateFormat="MMMM d yyyy"
            placeholderText="Select a date"
            isClearable
            // @ts-expect-error Not defined in typings but implemented in react-datepicker
            style={{ width: "100%" }}
            id="important-date-picker"
            className={`${questionInput} ${this.state.date && valid}`}
            withPortal={isMobile()}
            onFocus={(e) => {
              // eslint-disable-next-line no-param-reassign
              e.target.readOnly = true;
            }}
            calendarContainer={CalendarContainer}
          />
          <img
            src={Calendar}
            alt=""
            className={`${unitLabel} ${styles.datePickerIcon}`}
            // Hide calendar icon when date is selected so it doesn't block
            // the button to clear the field.
            style={this.state.date ? { display: "none" } : {}}
          />
        </div>
      </div>
    );
  }
}

export const Q_IMPORTANT_DATE_TIME = registerQuestion({
  id: "importantDateTime",
  type: "custom",
  component: DatePickerQuestion,
  question: ({ surveyAnswers }) => {
    const mapping: Record<string, string> = {
      vacation: i18n.t("survey:questions:importantDateTime:vacation"),
      wedding: i18n.t("survey:questions:importantDateTime:wedding"),
      holiday: i18n.t("survey:questions:importantDateTime:holiday"),
      summer: i18n.t("survey:questions:importantDateTime:summer"),
      reunion: i18n.t("survey:questions:importantDateTime:reunion"),
      birthday: i18n.t("survey:questions:importantDateTime:birthday"),
    };
    return (
      mapping[surveyAnswers.importantDateDetails?.[0]] ||
      i18n.t("survey:questions:importantDateTime:default")
    );
  },
  section: 1,
});
