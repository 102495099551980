import { trackEvent } from "@utils/api/tracker";

/**
 * A simple way to track events via Actions. If you need to pass custom properties
 * into your event, fire the event in a component.
 */
export const mixpanelTrackAction = ({
  params,
}: {
  params: { eventName: string };
}): void => {
  trackEvent(params.eventName);
};
