import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { getPageSetSurvey } from "src/pageDefinitions/actions/survey/util";
import { Direction } from "src/pageDefinitions/types";
import { trackEvent } from "src/utils/api/tracker";
import {
  addSurveyAnswers,
  SurveyAnswersState,
} from "src/utils/redux/slices/surveyAnswers";
import { trackViewContent } from "src/utils/services/ConversionTracker";
import { setNavTimeout } from "src/utils/timing";
import { useCallbackRef } from "./lifecycle";
import { useGotoOptions, usePageSet, usePageSetEvents } from "./pageSet";
import { useCoreStore, useSurveyAnswers } from "./redux";
import { executeActions } from "@pageDefinitions/goto/pageSet";
import { prepareAndTrackSurveyAnswers } from "@utils/mainSurvey";
import { createStateContext } from "src/utils/StateContext";

type UseSurveyConfig<Q extends keyof SurveyAnswersState> = {
  /**
   * Custom validator. Used on init and when `canNowProceed` is
   * omitted from `addAnswers` called.
   *
   * Defaults to marking responses without explicit validation as invalid.
   */
  validate?: (
    answer: SurveyAnswersState[Q],
    answers: SurveyAnswersState
  ) => boolean;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SurveyContextData {}

export const { Context: SurveyContext, Provider: SurveyContextProvider } =
  createStateContext<SurveyContextData>({});

export function useSurvey<Q extends keyof SurveyAnswersState>(
  questionId: Q,
  { validate = () => false }: UseSurveyConfig<Q> = {}
) {
  const store = useCoreStore();
  const surveyAnswers = useSurveyAnswers();
  const gotoOptions = useGotoOptions();

  const { activePageSet, activePage } = usePageSet();

  const validateRef = useCallbackRef(() => {
    const currentSurveyAnswers = store.getState().surveyAnswers;
    return validate(currentSurveyAnswers[questionId], currentSurveyAnswers);
  });

  const [canProceed, setCanProceed] = useState(validateRef());
  const [isSending, setIsSending] = useState(false);

  const surveyBlock = getPageSetSurvey(activePageSet, activePage);
  const surveyName = activePage ? `On${surveyBlock.name}` : "OnUnknownSurvey";

  const questionSetRef = useRef(null);

  useEffect(() => {
    // We want to only reset the proceed status if it has not been previously set
    // for the current question. This can happen if `addAnswers` is called
    // prior to this effect running.
    if (questionId !== questionSetRef.current) {
      setCanProceed(validateRef());
    }

    trackEvent(surveyName, { questionId });
    trackViewContent();
  }, [questionId, surveyName, validateRef]);

  usePageSetEvents(async (event, params) => {
    if (event === "before:goto") {
      setIsSending(false);

      if (params.direction === Direction.BACKWARD) {
        trackEvent(`${surveyName}-BackBtnClicked`, {
          clickedOnQuestionId: questionId,
        });
      } else {
        const currentSurvey = getPageSetSurvey(activePageSet, activePage);
        // Tracking event and saving for each question in all surveys
        prepareAndTrackSurveyAnswers(
          questionId,
          store.getState().surveyAnswers || {}
        );
        await executeActions(
          activePageSet,
          activePage,
          currentSurvey?.onAnswered,
          gotoOptions,
          { questionId }
        );
      }
    }
  });

  // Returns if the user can proceed, given their current answer selection.
  const addAnswers = useCallback(
    (answers?: SurveyAnswersState, canNowProceed?: boolean) => {
      if (answers) {
        store.dispatch(addSurveyAnswers(answers));
      }
      questionSetRef.current = questionId;

      const newCanProceed =
        canNowProceed == null ? validateRef() : canNowProceed;
      setCanProceed(newCanProceed);
      return newCanProceed;
    },
    [questionId, store, validateRef]
  );

  /**
   * Helper for when the survey question is a single input, where the answer should equal
   * that input's value.
   */
  const handleChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const answers: SurveyAnswersState = {
        [questionId]: e.target.value,
      };

      addAnswers(answers);
    },
    [questionId, addAnswers]
  );

  const sendAnswers = useCallback(() => {
    setIsSending(true);
  }, []);

  return {
    questionId,
    surveyAnswers,

    canProceed,
    isSending,

    addAnswers,
    handleChanged,
    sendAnswers,
  };
}

export function useRadioSurvey<Q extends keyof SurveyAnswersState>(
  questionId?: Q,
  { validate = () => true }: UseSurveyConfig<Q> = {}
) {
  const { addAnswers, sendAnswers, handleChanged, ...ret } = useSurvey(
    questionId,
    { validate }
  );
  const { gotoNextPage } = usePageSet();

  const setAnswer = useCallback(
    (answer: SurveyAnswersState[Q][0]) => {
      // NOTE(patrick): The old survey wrapped radio question answers in an array.
      // We want to preserve this aspect of the old survey for now. In the future,
      // figure we want to unwrap radio question values.
      const answers: SurveyAnswersState = {
        [questionId]: [answer],
      };

      const canProceed = addAnswers(answers);
      if (canProceed) {
        sendAnswers();
        setNavTimeout(gotoNextPage, 100);
      }
    },
    [addAnswers, sendAnswers, questionId, gotoNextPage]
  );

  return {
    ...ret,
    setAnswer,
  };
}

/**
 * `useCheckboxSurvey` accepts an optional `postProcessors` array of functions that run
 * on each click, after the default handling of toggling the checked state of the item.
 * By default, the only postProcessor is a special handler for `none`, which implements special
 * behavior for that choice: checking it unchecks all others, and checking anything else unchecks
 * it.
 */
export const defaultPostProcessors = [
  (item, itemWasChecked, currentAnswer) => {
    if (item === "none") {
      if (itemWasChecked) {
        return [];
      }
      return [item];
    }
    if (!itemWasChecked) {
      return currentAnswer?.filter((i) => i !== "none");
    }
    return currentAnswer;
  },
];

/**
 * Helper for questions which have a list of checkboxes as choices.
 * @param questionId eg `currentHealthRisk`
 * @param conf (Optional) custom validator. Default is to require at least 1 checked item.
 * @param postProcessors (Optional) Array of custom handlers to be run after each click.
 */
export function useCheckboxSurvey<Q extends keyof SurveyAnswersState>(
  questionId: Q,
  conf: UseSurveyConfig<Q> & {
    postProcessors: Array<
      (
        item: SurveyAnswersState[Q][0],
        itemWasChecked: boolean,
        currentAnswer: SurveyAnswersState[Q]
      ) => SurveyAnswersState[Q]
    >;
  } = {
    validate: (answer) => answer && !!answer.length,
    postProcessors: defaultPostProcessors,
  }
) {
  const { surveyAnswers, addAnswers, ...ret } = useSurvey(questionId, conf);
  const postProcessors = useRef(conf.postProcessors);

  const currentAnswer = surveyAnswers[questionId];
  // Small helper that simply boxes the answer as { [questionId]: answer }
  const setAnswer = useCallback(
    (answer: SurveyAnswersState[Q][0]) => {
      const answers: SurveyAnswersState = {
        [questionId]: answer,
      };

      addAnswers(answers);
    },
    [addAnswers, questionId]
  );
  // Attach this to the `onChange` handler of `CheckboxOptions`
  const handleItemChange = useCallback(
    (item, itemWasChecked) => {
      let newAnswer;
      if (!itemWasChecked) {
        // Order of answers in the array is not important, so just concat
        newAnswer = ((currentAnswer || []) as string[]).concat(item);
      } else {
        newAnswer = ((currentAnswer || []) as string[]).filter(
          (i) => i !== item
        );
      }
      postProcessors.current.forEach((processor) => {
        newAnswer = processor(item, itemWasChecked, newAnswer);
      });
      setAnswer(newAnswer);
    },
    [setAnswer, currentAnswer, postProcessors]
  );
  return {
    ...ret,
    checkedItems: currentAnswer,
    handleItemChange,
  };
}
